import self from '../../api/self'
let _ = require('lodash');
import eventBus from "../../event-bus";
import router from "../../router";
// import axios from 'axios';

export const state = {
    isLoggedIn: false,

    isLoadingOrganisations: false,
    isLoadingPresences: false,
    serverErrors: [],

    organisations: [],
    presences: []
};

export const mutations = {
    LOGGED_IN(state) {
        state.isLoggedIn = true;
    },

    LOGGED_OUT(state) {
        state.isLoggedIn = false;
    },

    START_LOADING_ORGANISATIONS(state) {
        state.isLoadingOrganisations = true
    },

    STOP_LOADING_ORGANISATIONS(state) {
        state.isLoadingOrganisations = false
    },

    START_LOADING_PRESENCES(state) {
        state.isLoadingPresences = true
    },

    STOP_LOADING_PRESENCES(state) {
        state.isLoadingPresences = false
    },

    SET_ORGANISATIONS(state, orgs) {
        state.organisations = orgs
    },

    SET_PRESENCES(state, presences) {
        state.presences = presences
    },

    SET_ERRORS(state, serverErrors) {
        state.serverErrors = serverErrors
    },

    UPDATE_ORGANISATION_INLINE(state, organisation) {
        let index = _.findIndex(state.organisations, { id: organisation.id });
        if (index !== -1) {
            state.organisations[index] = organisation;
        }
    }
};

export const getters = {
    env: () => {
        let env = 'testing';
        if (window.location.host == 'app.teamsos.co.uk') {
            env = 'live';
        }
        if (window.location.host == 'app.staging.teamsos.co.uk') {
            env = 'staging';
        }
        return env;
    },

    isLoading: (state, getters, rootState, rootGetters) => {
        return (
            state.isLoadingOrganisations
            || state.isLoadingPresences
            || rootGetters['allPresences/isLoading']
            || rootGetters['subjectPreferences/isLoading']
        )
    },

    isLoggedIn: (state) => {
        return state.isLoggedIn
    },

    organisations: (state) => {
        return state.organisations
    },

    userBelongsToMultipleOrganisations: (state) => {
        return state.organisations.length > 1;
    },

    organisationsIAmAnAdminFor: (state) => {
        return _.filter(state.organisations, organisation => {
            let myPresencesThatBelongToThisOrgThatAreAdmin = _.filter(state.presences, presence => {
                let doesPresenceBelongToThisOrg = presence.ownerId == organisation.id;
                let isPresenceAdmin = (presence.permissions & 4) == 4; // hasAdmin = (Presence.Permission <bitwise-and> 4) == 4
                // let isPresenceAdmin = true;
                return doesPresenceBelongToThisOrg && isPresenceAdmin;
            });
            return myPresencesThatBelongToThisOrgThatAreAdmin.length > 0;
        });
    },

    organisationsIAmAnAdminForWithIncompleteOnboarding: (state, getters) => {
        return _.filter(getters.organisationsIAmAnAdminFor, organisation => {
            // If onboarding stage key doesn't exist, is null, or undefined, return false
            if (!organisation.content['$v']['onboardingStage']) {
                return false;
            }

            return organisation.content['$v']['onboardingStage'] !== 'Complete';
        });
    },

    organisationsIAmNotAnAdminFor: (state, getters) => {
        return _.differenceBy(state.organisations, getters.organisationsIAmAnAdminFor, 'id');
    },

    organisationsWithChatEnabled: (state) => {
        return _.filter(state.organisations, organisation => {
            return !organisation.content['$v']['hideChats'];
        });
    },

    organisationsWithChatDisabled: (state) => {
        return _.filter(state.organisations, organisation => {
            return organisation.content['$v']['hideChats'];
        });
    },

    idsOfOrganisationsWithChatDisabled: (state, getters) => {
        return _.map(getters.organisationsWithChatDisabled, 'id');
    },

    isChatDisabledForAllOrganisations: (state, getters) => {
        if (!getters.organisations || getters.organisations.length == 0) {
            return false;
        }
        return getters.organisations.length == getters.organisationsWithChatDisabled.length;
    },

    idsOfOrganisationsIAmAnAdminFor: (state, getters) => {
        return _.map(getters.organisationsIAmAnAdminFor, 'id');
    },

    canCreateAnnouncements: (state, getters) => {
        return getters['organisationsIAmAnAdminFor'].length > 0;
    },

    isWebAccessPermitted: (state) => {
        let isPermitted = false;
        if (state.organisations.length) {
            _.each(state.organisations, organisation => {
                if (
                    organisation['content']
                    && organisation['content']['$v']['permitWebAccess']
                    && organisation['content']['$v']['permitWebAccess'] == true
                ) {
                    isPermitted = true;
                }
            });
        }
        return isPermitted;
    },

    canViewAdminArea: (state, getters) => {
        return getters.organisationsIAmAnAdminFor.length > 0;
        // return true;
        // return getters['isWebAccessPermitted'];
    },

    isPlatformAdmin: (state) => {
        let isPlatformAdmin = false;
        if (state.presences.length) {
            _.each(state.presences, presence => {
                if (
                    presence.subjectId == '8fba20cc-f69d-44cb-8e8b-fdfa42fb1c95'
                ) {
                    isPlatformAdmin = true;
                }
            });
        }
        return isPlatformAdmin;
    },

    guidanceModeEnabled: (state) => {
        let guidanceEnabled = false;
        if (state.organisations.length) {
            _.each(state.organisations, organisation => {
                if (
                    organisation['content']
                    && organisation['content']['$v']['guidanceMode']
                    && organisation['content']['$v']['guidanceMode'] == 2
                ) {
                    guidanceEnabled = true;
                }
            });
        }
        return guidanceEnabled;
    },

    presences: (state) => {
        return state.presences
    },

    userPresence: (state) => {
        if (state.presences && state.presences.length) {
            return state.presences[0]
        }
        return null;
    },

    userDisplayName: (state) => {
        if (state.presences && state.presences.length) {
            return state.presences[0].displayName
        }
        return '';
    },

    nonHiddenPresences: (state) => {
        if (state.presences && state.presences.length) {
            return _.filter(state.presences, presence => {
                return !presence.isHiddenPresence
            })
        }
        return [];
    },

    hasNonHiddenPresences: (state, getters) => {
        if (state.presences && state.presences.length) {
            return getters.nonHiddenPresences.length > 0
        }
        return true;
    },

    isReseller: (state, getters) => {
        return !getters.hasNonHiddenPresences;
    }
};

export const actions = {
    launch({ dispatch, getters, rootGetters }) {
        dispatch('identifier/launch', {}, { root: true })
        dispatch('loadContext').then(() => {
            dispatch('chats/loadChats', {}, { root: true })
            dispatch('incidents/loadIncidents', {}, { root: true })
            dispatch('tasks/loadTasks', {}, { root: true })
            dispatch('announcements/loadAnnouncements', {}, { root: true })
            dispatch('faqs/loadFaqs', {}, { root: true })

            if (
                rootGetters['onboarding/onboardingOrgsToShow'].length == getters.organisations.length
                && rootGetters['onboarding/onboardingOrgsToShow'].length > 0
                && router.currentRoute.name !== 'Onboarding'
                && this._vm.$oidc.isAuthenticated
                && !getters.isPlatformAdmin
            ) {
                router.push('/onboarding');
            }
        }).catch(e => {
            console.log(e);
            eventBus.$emit('idsrv-logout');
        })
    },

    loggedIn({ commit }) {
        commit('LOGGED_IN');
    },

    loggedOut({ commit }) {
        commit('LOGGED_OUT');
    },

    loadContext({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('loadOrganisations').then(() => {
                dispatch('allPresences/loadPresencesForAllOrganisations', {}, { root: true }).then(() => {
                    dispatch('loadPresences').then(() => {
                        // dispatch('onboarding/loadOnboardingStatusesForAllOrganisations', {}, { root: true });
                        dispatch('subjectPreferences/loadSubjectPreferences', {}, { root: true }).then(() => {
                            resolve();
                        }).catch(e => {
                            console.log(e);
                            reject(e);
                        })
                    }).catch(e => {
                        console.log(e);
                        reject(e);
                    });
                }).catch(e => {
                    console.log(e);
                    reject(e);
                });
            }).catch(e => {
                console.log(e);
                reject(e);
            });
        });
    },

    loadOrganisations({ commit }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING_ORGANISATIONS');

            self.getOrgs(true).then(r => {
                commit('STOP_LOADING_ORGANISATIONS');
                commit('SET_ORGANISATIONS', r.data);
                // dispatch('logoutIfWebAccessNotPermitted');

                // console.log('Checking if netnotify available...');
                // axios.get(window.API_BASE + '/api/v1/f7b16582-749d-45cf-85e5-0f2f526824eb/f7b16582-749d-45cf-85e5-0f2f526824eb/feature/NetNotify').then(r => {
                //     console.log('Netnotify gave us this:')
                //     console.log(r.data);
                // }).catch(e => {
                //     console.log('Error with netnotify:');
                //     console.log(e);
                // });

                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING_ORGANISATIONS');
                // this._vm.$message.error('Error loading organisations');

                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            })
        });
    },

    updateOrganisationInline({ commit }, organisation) {
        commit('UPDATE_ORGANISATION_INLINE', organisation);
    },

    logoutIfWebAccessNotPermitted({ getters }) {
        if (!getters.isWebAccessPermitted) {
            this._vm.$message.error("Sorry, we're not ready for your organisation to use this yet");
            eventBus.$emit('idsrv-logout');
            // this._vm.$oidc.signOut();
        }
    },

    loadPresences({ commit }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING_PRESENCES');

            self.getPresences(true).then(r => {
                commit('SET_PRESENCES', r.data);
                commit('STOP_LOADING_PRESENCES');
                // dispatch('logoutIfIsPlatformAdmin');
                // dispatch('logoutIfHasNoPresences');
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING_PRESENCES');
                // this._vm.$message.error('Error loading presences');

                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            })
        });
    },

    logoutIfIsPlatformAdmin({ getters }) {
        if (getters.isPlatformAdmin) {
            this._vm.$message.error('Platform admins cannot login');
            // this._vm.$oidc.signOut();
            eventBus.$emit('idsrv-logout');
        }
    },

    logoutIfHasNoPresences({ getters }) {
        if (getters.presences.length == 0) {
            this._vm.$message.error('No presences');
            eventBus.$emit('idsrv-logout');
        }
    },

    getPresenceForOrganisationId({ getters }, organisationId) {
        return _.find(getters.presences, {
            ownerId: organisationId
        })
    },

    getOrganisationById({ getters }, organisationId) {
        return _.find(getters.organisations, {
            id: organisationId
        })
    }
};
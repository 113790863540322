import helpVideos from "../../api/guidance/help-videos";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");

export const state = {
    isLoading: false,
    helpVideos: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_HELP_VIDEOS(state, helpVideos) {
        state.helpVideos = helpVideos
    },

    UPDATE_HELP_VIDEO_LOCALLY(state, updatedHelpVideo) {
        state.helpVideos = _.map(state.helpVideos, helpVideo => {
            if (updatedHelpVideo['$v']['id'] == helpVideo['$v']['id']) {
                return updatedHelpVideo;
            } else {
                return helpVideo;
            }
        });
    }
}

export const getters = {
    isLoading: (state) => (state.isLoading),
    helpVideos: (state) => (state.helpVideos),
    helpVideosToShow: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.helpVideos, topic => {
                return topic['$v']['ownerId'] == ownerIdFilter
            });
        }
        return state.helpVideos;
    }
}

export const actions = {
    reset({ commit }) {
        commit('SET_HELP_VIDEOS', []);
    },

    startLoading({ commit }) {
        commit('START_LOADING');
    },

    stopLoading({ commit }) {
        commit('STOP_LOADING');
    },

    loadHelpVideos({ commit, rootGetters }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING');

            let org = rootGetters['admin/selectedOrganisation'];
            if (!org) {
                commit('SET_HELP_VIDEOS', []);
                return false;
            }
            let tenantId = organisationHelpers.getOrganisationTenantId(org);

            helpVideos.getHelpVideos(tenantId, org.id, true).then(r => {
                commit('STOP_LOADING');
                commit('SET_HELP_VIDEOS', r.data);
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                reject(e);
                this._vm.$message.error('Error loading help videos');
            })
        });
    },

    updateHelpVideoLocally({ commit }, topic) {
        commit('UPDATE_HELP_VIDEO_LOCALLY', topic);
    }
}
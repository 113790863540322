import axios from 'axios'

export default {
    getOrgs(includeHiddenAndContentOrgs = false) {
        let query = window.API_BASE + '/api/v1/self/orgs';
        if (includeHiddenAndContentOrgs) {
            query = query + '?includeHiddenAndContentOrgs=true';
        }
        return axios.get(query)
    },

    getChats() {
        return axios.get(window.API_BASE + '/api/v1/self/chats')
    },

    getIncidents() {
        return axios.get(window.API_BASE + '/api/v1/self/incidents')
    },

    getArchivedIncidents(startDate = null, endDate = null) {
        if (startDate && endDate) {
            return axios.get(window.API_BASE + '/api/v1/self/ArchivedIncidents?fromdate=' + startDate + '&todate=' + endDate);
        }
        return axios.get(window.API_BASE + '/api/v1/self/ArchivedIncidents');
    },

    getAnnouncements() {
        return axios.get(window.API_BASE + '/api/v1/self/announcements')
    },

    getPresences(includeHidden = false) {
        let query = window.API_BASE + '/api/v1/self/presences';
        if (includeHidden) {
            query = query + '?includeHiddenAndContentOrgs=true'
        }
        return axios.get(query);
    },

    getAssignments() {
        return axios.get(window.API_BASE + '/api/v1/self/assignments')
    },

    getGuidancePacks() {
        return axios.get(window.API_BASE + '/api/v1/GuidancePacks');
    },

    getLastViews() {
        return axios.get(window.API_BASE + '/api/v1/self/EntityViews');
    }
}

<template>
  <div>
    <!-- Is loading -->
    <div class="text-center" v-if="isLoadingIncidentTypes">
      <a-spin />
    </div>
    <!-- / Is loading -->

    <!-- Options wrapper -->
    <div class="options-wrapper" v-if="!isLoadingIncidentTypes">
      <!-- Options -->

      <incident-type-option v-for="option in sosIncidentTypesSorted"
      :in-widget="true"
      :key="`tsos-widget-incident-type-option-${option.id}`"
      :incident-type="option" :sos="true"></incident-type-option>
      <!-- / Options -->

      <!-- <div class="options-separator"></div> -->

      <!-- Options -->
      <incident-type-option
      v-for="option in otherIncidentTypesSorted"
      :in-widget="true"
      :key="`tsos-widget-incident-type-option-${option.id}`"
       :incident-type="option" :categories="categories"></incident-type-option>
      <!-- / Options -->
    </div>
    <!-- / Options wrapper -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import IncidentTypeOption from "../../../../views/CallForHelp/IncidentTypeSelector/IncidentTypeOption.vue";

export default {
  components: { IncidentTypeOption },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("helpCaller", {
      isLoadingIncidentTypes: "isLoadingIncidentTypes",
      sosIncidentTypes: "sosIncidentTypes",
      otherIncidentTypes: "otherIncidentTypes",
    }),

    sosIncidentTypesSorted() {
      let ranked = _.sortBy(this.sosIncidentTypes, "rank");
      // reverse
      return ranked.reverse();
    },

    otherIncidentTypesSorted() {
      let filtered = _.filter(this.otherIncidentTypes, (f) => {
        return f.incidentTypeGroupId;
      });
      let ranked = _.sortBy(filtered, "rank");
      // reverse
      return ranked.reverse();
    },

    categories() {
      return _.filter(this.otherIncidentTypes, (f) => {
        return !f.incidentTypeGroupId;
      });
    }
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.options-wrapper {
  // padding-bottom: 20px;

  .incident-type-option {
    // margin-bottom: 5px;
  }
}

.options-separator {
  padding-top: 30px;
}
</style>
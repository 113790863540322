import incidents from "../../api/incidents";
import apiClients from "../../api/api-clients";
const _ = require('lodash')
import axios from 'axios'
import moment from "moment";

export const state = {
    serverErrors: [],
    isLoading: false,
    isSaving: false,
    tenantId: null,
    incident: null,

    isLoadingMembership: false,
    membership: null,

    isLoadingGroupMembers: false,
    groupMembers: [],

    isLoadingResponseTeams: false,
    responseTeams: [],

    groupMemberDetails: {},
    activePresences: [],

    isLoadingApiClientGroupLinks: false,
    apiClientGroupLinks: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    START_SAVING(state) {
        state.isSaving = true
    },

    STOP_SAVING(state) {
        state.isSaving = false
    },

    SET_INCIDENT(state, incident) {
        state.incident = incident
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    },

    START_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = true
    },

    STOP_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = false
    },

    SET_MEMBERSHIP(state, membership) {
        state.membership = membership
    },

    START_LOADING_GROUP_MEMBERS(state) {
        state.isLoadingGroupMembers = true
    },

    STOP_LOADING_GROUP_MEMBERS(state) {
        state.isLoadingGroupMembers = false
    },

    SET_GROUP_MEMBERS(state, groupMembers) {
        state.groupMembers = groupMembers
    },

    APPEND_GROUP_MEMBER(state, groupMember) {
        state.groupMembers.push(groupMember);
    },

    START_LOADING_RESPONSE_TEAMS(state) {
        state.isLoadingResponseTeams = true
    },

    STOP_LOADING_RESPONSE_TEAMS(state) {
        state.isLoadingResponseTeams = false
    },

    SET_RESPONSE_TEAMS(state, responseTeams) {
        state.responseTeams = responseTeams
    },

    SET_GROUP_MEMBER_DETAILS(state, groupMemberDetails) {
        state.groupMemberDetails = groupMemberDetails
    },

    SET_DETAILS_FOR_GROUP_MEMBER(state, params) {
        let { presenceId, details } = params
        state.groupMemberDetails[presenceId] = details
    },

    SET_ACTIVE_PRESENCES(state, activePresences) {
        state.activePresences = activePresences;
    },

    SET_PRESENCE_ACTIVE(state, presenceId) {
        state.activePresences.push(presenceId);
        state.activePresences = _.uniq(state.activePresences);
    },

    SET_PRESENCE_INACTIVE(state, presenceId) {
        state.activePresences = _.without(state.activePresences, presenceId);
    },

    INCIDENT_NAME_UPDATED(state, newName) {
        state.incident.displayName = newName;
    },

    START_LOADING_API_CLIENT_GROUP_LINKS(state) {
        state.isLoadingApiClientGroupLinks = true;
    },

    STOP_LOADING_API_CLIENT_GROUP_LINKS(state) {
        state.isLoadingApiClientGroupLinks = false;
    },

    SET_API_CLIENT_GROUP_LINKS(state, apiClientGroupLinks) {
        state.apiClientGroupLinks = apiClientGroupLinks
    },

    UPDATE_INCIDENT_GROUP_MEMBER(state, groupMemberDetails) {
        state.groupMembers = _.map(state.groupMembers, groupMember => {
            if (groupMember.presenceId == groupMemberDetails.presenceId) {
                return groupMemberDetails;
            } else {
                return groupMember;
            }
        })
    }
};

export const getters = {
    isLoading: (state) => {
        return (state.isLoading || state.isLoadingMembership || state.isLoadingGroupMembers || state.isLoadingResponseTeams)
    },

    isSaving: (state) => {
        return state.isSaving
    },

    incident: (state) => {
        return state.incident
    },

    tenantId: (state) => {
        return state.tenantId
    },

    membership: (state) => {
        return state.membership
    },

    activeMembers: (state, getters) => {
        return _.filter(state.membership, m => {
            if (getters.myPresenceId === m.id) {
                return true;
            }
            return _.includes(state.activePresences, m.id);
        });
    },

    inactiveMembers: (state, getters) => {
        return _.filter(state.membership, m => {
            if (getters.myPresenceId === m.id) {
                return false;
            }
            return !_.includes(state.activePresences, m.id);
        });
    },

    groupMembers: (state) => {
        return state.groupMembers
    },

    responseTeams: (state) => {
        return state.responseTeams
    },

    groupMemberDetails: (state) => {
        return state.groupMemberDetails
    },

    myPresenceId: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInIncident = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInIncident);
        if (intersection.length) {
            return _.first(intersection)
        }
        return null;
    },

    activePresences: (state) => {
        return state.activePresences
    },

    isLoadingApiClientGroupLinks: (state) => {
        return state.isLoadingApiClientGroupLinks
    },

    apiClientGroupLinks: (state) => {
        return state.apiClientGroupLinks
    },

    sharedWithApiClientIds: (state) => {
        return _.map(state.apiClientGroupLinks, 'apiClientId');
    },

    firstArrivalGroupMember: (state, getters) => {
        let createdByPresenceId = getters.incident.createdBy;
        let arrived = _.filter(state.groupMembers, member => {
            return member.firstArrival && (member.presenceId !== createdByPresenceId);
        });
        if (arrived.length) {
            let withArrivalMoments = _.map(arrived, m => {
                return {
                    ...m,
                    unix: moment(m.firstArrived).unix()
                }
            });
            return _.first(_.sortBy(withArrivalMoments, 'unix'));
        }
        return null;
    },

    firstArrivalMembership: (state, getters) => {
        if (getters.firstArrivalGroupMember) {
            return _.find(getters.membership, { id: getters.firstArrivalGroupMember.presenceId });
        }
        return null;
    },

    isWaitingForFirstResponder: (state) => {
        if (state.incident) {
            return !(state.incident['content']['$v']['firstResponded'] !== undefined);
        }
        return false;
    }
};

export const actions = {
    reset({ commit }) {
        commit('SET_INCIDENT', null);
        commit('SET_MEMBERSHIP', null);
        commit('SET_GROUP_MEMBERS', []);
        commit('SET_RESPONSE_TEAMS', []);
        commit('SET_GROUP_MEMBER_DETAILS', {});
        commit('SET_ACTIVE_PRESENCES', []);
        commit('SET_API_CLIENT_GROUP_LINKS', []);
    },

    setIncident({ commit, dispatch, getters }, params) {
        let { incident, tenantId } = params;
        dispatch('reset');
        commit('SET_TENANT_ID', tenantId);
        commit('SET_INCIDENT', incident);
        dispatch('timeline/reset', {}, { root: true });
        dispatch('loadMembership');
        dispatch('loadGroupMembers');
        dispatch('loadResponseTeams');
        dispatch('loadApiClientGroupLinks');
        dispatch('timeline/loadTimeline', {
            model: 'incident',
            tenantId: getters.tenantId,
            guid: getters.incident.id,
            ownerType: 3,
            ownerId: getters.incident.id,
            organisationId: getters.incident.ownerId,
            behaviour: 0
        }, { root: true });
        dispatch('timeline/loadQuickMessages', {}, { root: true });
        dispatch('metadata/setMetadataIds', getters.incident.metadataIds ? getters.incident.metadataIds : [], { root: true });
    },

    loadIncident({ commit, dispatch, getters }, params) {
        let { id, tenantId } = params
        commit('START_LOADING');
        commit('SET_TENANT_ID', tenantId);
        dispatch('reset');
        dispatch('timeline/reset', {}, { root: true });
        incidents.getIncident(tenantId, id).then(r => {
            commit('STOP_LOADING');
            commit('SET_INCIDENT', r.data)
            dispatch('loadMembership');
            dispatch('loadGroupMembers');
            dispatch('loadResponseTeams');
            dispatch('loadApiClientGroupLinks');
            dispatch('timeline/loadTimeline', {
                model: 'incident',
                tenantId: getters.tenantId,
                guid: getters.incident.id,
                ownerType: 3,
                ownerId: getters.incident.id,
                organisationId: getters.incident.ownerId,
                behaviour: 0
            }, { root: true });
            dispatch('timeline/loadQuickMessages', {}, { root: true });
            dispatch('metadata/setMetadataIds', getters.incident.metadataIds ? getters.incident.metadataIds : [], { root: true });
        }).catch(e => {
            commit('STOP_LOADING');
            this._vm.$message.error('Error loading incident');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadMembership({ commit, getters }, params = {}) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incident.id

        let silent = params.silent;

        if (!silent) {
            commit('START_LOADING_MEMBERSHIP');
        }

        commit('SET_MEMBERSHIP', null);
        incidents.getMembershipForIncident(tenantId, incidentId).then(r => {
            if (!silent) {
                commit('STOP_LOADING_MEMBERSHIP');
            }
            commit('SET_MEMBERSHIP', r.data)
        }).catch(e => {
            if (!silent) {
                commit('STOP_LOADING_MEMBERSHIP');
            }
            this._vm.$message.error('Error loading membership');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadGroupMembers({ commit, getters }, params = {}) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incident.id

        let silent = params.silent;

        if (!silent) {
            commit('START_LOADING_GROUP_MEMBERS');
        }
        commit('SET_GROUP_MEMBERS', null);
        incidents.getGroupMembersForIncident(tenantId, incidentId).then(r => {
            if (!silent) {
                commit('STOP_LOADING_GROUP_MEMBERS');
            }
            commit('SET_GROUP_MEMBERS', r.data);
            let activePresences = _.map(_.filter(r.data, m => (m.isActive === true)), 'presenceId');
            commit('SET_ACTIVE_PRESENCES', activePresences);
            // dispatch('loadAllGroupMemberDetails');
        }).catch(e => {
            if (!silent) {
                commit('STOP_LOADING_GROUP_MEMBERS');
            }
            this._vm.$message.error('Error loading group members');
            console.log(e);
            let errors;
            if (e.response && e.response.data && typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    handleParticipantAdded({ dispatch }, groupMemberDetails) {
        console.log('Group member added:');
        console.log(groupMemberDetails);
        dispatch('loadMembership', { silent: true });
        dispatch('loadGroupMembers', { silent: true });
    },

    loadResponseTeams({ commit, getters }) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incident.id
        commit('START_LOADING_RESPONSE_TEAMS');
        commit('SET_RESPONSE_TEAMS', []);
        incidents.getResponseTeamsForIncident(tenantId, incidentId).then(r => {
            commit('STOP_LOADING_RESPONSE_TEAMS');
            commit('SET_RESPONSE_TEAMS', r.data);
            // dispatch('loadAllGroupMemberDetails');
        }).catch(e => {
            commit('STOP_LOADING_RESPONSE_TEAMS');
            this._vm.$message.error('Error loading response teams');
            console.log(e);
            let errors;
            if (e.response && e.response.data && typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadAllGroupMemberDetails({ getters, commit }) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incident.id

        let groupMembers = getters.groupMembers
        let groupMemberPresenceIds = _.map(groupMembers, 'presenceId')

        incidents.getAllGroupMemberDetailsForIncident(tenantId, incidentId, groupMemberPresenceIds).then(axios.spread((...responses) => {
            _.each(responses, (response, i) => {
                console.log(response);
                console.log(i);
            })
        })).catch(e => {
            this._vm.$message.error('Error loading group member details');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    setActivePresences({ commit }, activePresences) {
        commit('SET_ACTIVE_PRESENCES', activePresences);
    },

    setPresenceActive({ commit }, presenceId) {
        commit('SET_PRESENCE_ACTIVE', presenceId);
    },

    setPresenceInactive({ commit }, presenceId) {
        commit('SET_PRESENCE_INACTIVE', presenceId);
    },

    incidentNameUpdated({ commit }, newName) {
        commit('INCIDENT_NAME_UPDATED', newName);
    },

    loadApiClientGroupLinks({ commit, getters }) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incident.id;
        let organisationId = getters.incident.ownerId;

        commit('START_LOADING_API_CLIENT_GROUP_LINKS');
        apiClients.getApiClientGroupLinksForIncident(tenantId, organisationId, incidentId).then(r => {
            commit('STOP_LOADING_API_CLIENT_GROUP_LINKS');
            commit('SET_API_CLIENT_GROUP_LINKS', r.data);
        }).catch(e => {
            commit('STOP_LOADING_API_CLIENT_GROUP_LINKS');
            this._vm.$message.error('Error loading previously shared with');
            console.log(e);
        })
    },

    updateIncidentGroupMember({ commit }, groupMemberDetails) {
        commit('UPDATE_INCIDENT_GROUP_MEMBER', groupMemberDetails);
    }
};
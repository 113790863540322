<template>
  <a-modal
    class="create-chat-modal"
    centered
    :width="600"
    v-model="showModal"
    :footer="null"
    title="New Chat"
  >
    <!-- <div class="avatars">d</div> -->
    <template slot="footer">
      <!-- <a-button v-if="stage === 0" key="back" @click="$emit('close-modal')">
        Cancel
      </a-button>
      <a-button
        v-if="stage === 0 && selectedMembers.length <= 1"
        :disabled="selectedMembers.length === 0"
        key="submit"
        type="primary"
        :loading="isSaving"
        @click.prevent="createChat"
      >
        Chat
      </a-button>
      <a-button
        v-if="stage === 0 && selectedMembers.length > 1"
        key="submit"
        type="primary"
        @click.prevent="goToNextStage"
      >
        Next
      </a-button> -->

      <a-button v-if="stage === 1" key="back" @click="goToPreviousStage">
        Previous
      </a-button>
      <a-button
        v-if="stage === 1 && selectedMembers.length > 1"
        key="submit"
        type="primary"
        :loading="isSaving"
        @click.prevent="createChat"
      >
        Create Group
      </a-button>
    </template>

    <!-- Stage 0 -->
    <div class="inner inner-stage-0" v-if="stage === 0">
      <a-tabs
        v-model="selected_organisation"
        :class="{ hidden: organisationsWithChatEnabled.length == 1 }"
      >
        <a-tab-pane
          v-for="organisation in organisationsWithChatEnabled"
          :key="organisation.id"
        >
          <template slot="tab">
            <organisation-thumbnail
              class="org-thumbnail"
              :tenantId="getOrgTenantId(organisation)"
              :orgId="organisation.id"
              size="small"
            ></organisation-thumbnail>
            {{ organisation.displayName }}
          </template>
        </a-tab-pane>
      </a-tabs>

      <div class="member-list">
        <div class="loader" v-if="isLoadingMembers">
          <a-spin></a-spin>
        </div>

        <!-- Selected Members -->
        <initial-topbar
          @create-chat="createChat"
          @go-to-next-stage="goToNextStage"
          :members="membersToShow"
          :my-membership="myMembership"
          :selected-members="selectedMembers"
          :is-saving="isSaving"
        ></initial-topbar>
        <!-- / Selected Members -->

        <!-- Members -->
        <div class="members-wrapper" v-if="members.length">
          <a-input-search
            placeholder="Search..."
            style="width: 230px; margin-bottom: 15px"
            v-model="searchQuery"
          />

          <a-row :gutter="10" type="flex">
            <a-col
              v-if="myMembership && shouldShowMyMembership"
              :span="12"
              class="member-list-item-col"
            >
              <div class="member-list-item selected" :key="myMembership">
                <div class="left">
                  <presence-thumbnail
                    :owner-id="myMembership.ownerId"
                    :presence-id="myMembership.id"
                  >
                  </presence-thumbnail>
                </div>
                <div class="middle">You ({{ myMembership.displayName }})</div>
                <div class="right">
                  <a-icon
                    theme="twoTone"
                    two-tone-color="#52c41a"
                    type="check-circle"
                  />
                </div>
              </div>
            </a-col>
            <a-col
              class="member-list-item-col"
              :span="12"
              v-for="member in membersToShow"
              :key="member.id"
            >
              <div
                class="member-list-item"
                @click.prevent="toggleMemberSelected(member)"
                :class="{ selected: isMemberSelected(member) }"
              >
                <div class="left">
                  <presence-thumbnail
                    :owner-id="member.ownerId"
                    :presence-id="member.id"
                  >
                  </presence-thumbnail>
                </div>
                <div class="middle">
                  {{ member.displayName }}
                </div>
                <div class="right">
                  <a-icon
                    theme="twoTone"
                    two-tone-color="#52c41a"
                    v-if="isMemberSelected(member)"
                    type="check-circle"
                  />
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- / Members -->
      </div>
    </div>
    <!-- / Stage 0 -->

    <!-- Stage 1 -->
    <div class="inner-stage-1" v-if="stage === 1">
      <!-- Topbar -->
      <div class="stage-1-topbar">
        <a-button v-if="stage === 1" key="back" @click="goToPreviousStage">
          Previous
        </a-button>
        <a-button
          v-if="stage === 1 && selectedMembers.length > 1"
          key="submit"
          type="primary"
          :loading="isSaving"
          @click.prevent="createChat"
        >
          Create Group
        </a-button>
      </div>
      <!-- / Topbar -->

      <div class="group-details-flex-wrapper">
        <div class="left" @change="handleFileUploaded">
          <input type="file" ref="file" class="file-input" />
          <div class="avatar-wrapper">
            <a-avatar
              @click.prevent="changeAvatar"
              size="large"
              icon="message"
              v-if="!uploadedFileBase64"
              style="filled"
            ></a-avatar>
            <a-avatar
              @click.prevent="changeAvatar"
              v-if="uploadedFileBase64"
              size="large"
              :src="uploadedFileBase64"
              style="filled"
            ></a-avatar>
          </div>
        </div>
        <div class="right">
          <a-input
            placeholder="Enter group name here..."
            v-model="groupName"
            size="large"
          ></a-input>
        </div>
      </div>

      <!-- Did you mean -->
      <div v-if="matchingChats.length" class="matching-chats-wrapper">
        <p>Did you mean...</p>

        <a-row :gutter="15">
          <a-col :span="12" v-for="chat in matchingChats" :key="chat.id">
            <matching-chat-card
              @selected="() => navigateToChat(chat)"
              :chat="chat"
            >
            </matching-chat-card>
          </a-col>
        </a-row>

        <!-- {{ matchingChats }} -->
      </div>
      <!-- / Did you mean -->
    </div>
    <!-- / Stage 1 -->
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
import organisationHelpers from "../../helpers/organisations";
import organisations from "../../api/organisations";
import chats from "../../api/chats";
import PresenceThumbnail from "../Presences/PresenceThumbnail.vue";
import MatchingChatCard from "./CreateChatModal/MatchingChatCard.vue";
import InitialTopbar from "./CreateChatModal/InitialTopbar.vue";
import OrganisationThumbnail from "../../components/Organisations/OrganisationThumbnail.vue";
export default {
  props: ["visible"],
  components: {
    PresenceThumbnail,
    MatchingChatCard,
    InitialTopbar,
    OrganisationThumbnail,
  },
  data() {
    return {
      stage: 0,
      selectedOrganisation: null,
      selectedMembers: [],

      isLoadingMembers: false,
      members: [],

      searchQuery: "",

      groupName: "",
      isSaving: false,

      uploadedFile: null,
      uploadedFileBase64: null,
    };
  },
  created() {
    this.selectFirstOrganisation();
  },
  watch: {
    selectedOrganisation(newVal) {
      this.selectedMembers = [];
      if (newVal) {
        this.loadMembers();
      }
    },
    visible(newVal) {
      if (newVal) {
        this.selectFirstOrganisation();
        this.stage = 0;
        this.selectedMembers = [];
        this.groupName = "";
      }
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      // organisations: "organisations",
      organisationsWithChatEnabled: "organisationsWithChatEnabled",
      presences: "presences",
    }),

    ...mapGetters("chats", {
      chats: "chats",
    }),

    selected_organisation: {
      get() {
        return this.selectedOrganisation ? this.selectedOrganisation.id : null;
      },
      set(val) {
        this.selectedOrganisation = _.find(this.organisationsWithChatEnabled, { id: val });
      },
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },

    shouldShowMyMembership() {
      if (this.searchQuery && this.searchQuery.trim().length) {
        return this.myMembership.displayName
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      }
      return true;
    },

    membersToShow() {
      let notMe = _.filter(this.members, (member) => {
        return member.id !== this.myPresenceId;
      });

      if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(notMe, (member) => {
          return member.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return notMe;
    },

    myMembership() {
      return _.find(this.members, { id: this.myPresenceId });
    },

    myPresenceId() {
      let myPresenceIds = _.map(this.presences, "id");
      let presenceIdsInOrganisation = _.map(this.members, "id");
      let intersection = _.intersection(
        myPresenceIds,
        presenceIdsInOrganisation
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    proposedMembers() {
      return _.flatten([this.selectedMembers, [this.myPresenceId]]);
    },

    matchingChats() {
      let proposedMembersSorted = _.sortBy(this.proposedMembers);
      let proposedMembersSortedJson = JSON.stringify(proposedMembersSorted);

      // Other signatures
      return _.filter(this.chats, (chat) => {
        let membership = chat.membership ? chat.membership : [];
        if (membership.length) {
          let membershipIdsSorted = _.sortBy(_.map(membership, "id"));
          return (
            proposedMembersSortedJson === JSON.stringify(membershipIdsSorted)
          );
        }
        return true;
      });
    },
  },
  methods: {
    ...mapActions("chats", {
      pushChat: "pushChat",
    }),

    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },

    selectFirstOrganisation() {
      if (this.organisationsWithChatEnabled.length && !this.selectedOrganisation) {
        this.selectedOrganisation = _.first(this.organisationsWithChatEnabled);
      }
    },

    isMemberSelected(member) {
      return this.selectedMembers.includes(member.id);
    },

    toggleMemberSelected(member) {
      this.selectedMembers = _.xor(this.selectedMembers, [member.id]);
    },

    loadMembers() {
      let vm = this;
      vm.isLoadingMembers = true;
      vm.members = [];

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );
      organisations
        .getMembershipForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.members = r.data;
          vm.isLoadingMembers = false;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading members");
        });
    },

    goToPreviousStage() {
      this.stage -= 1;
    },

    goToNextStage() {
      this.stage += 1;
    },

    getOrganisationById(id) {
      return _.find(this.organisationsWithChatEnabled, { id });
    },

    navigateToChat(chat) {
      let organisation = this.getOrganisationById(chat.ownerId);
      let tenantId = organisationHelpers.getOrganisationTenantId(organisation);
      this.$router.push("/chats/" + tenantId + "/" + chat.id);
    },

    createChat() {
      let vm = this;
      vm.isSaving = true;

      if (!this.selectedMembers.length) {
        return false;
      }

      // Find matching chat if only one member selected
      // And open that instead of creating new one
      if (this.selectedMembers.length == 1) {
        let currentChatsWithThisMember = _.filter(this.chats, (chat) => {
          if (chat.membership && chat.membership.length == 2) {
            let membershipIds = _.map(chat.membership, "id");
            return membershipIds.includes(vm.selectedMembers[0]);
          }
          return false;
        });
        if (currentChatsWithThisMember.length) {
          let chatToSelect = _.first(currentChatsWithThisMember);

          let organisation = this.getOrganisationById(chatToSelect.ownerId);
          let tenantId =
            organisationHelpers.getOrganisationTenantId(organisation);
          vm.$message.info("Chat already exists. Redirecting...");
          vm.$router.push("/chats/" + tenantId + "/" + chatToSelect.id);
          return false;
        }
      }

      let membersToAdd = _.flatten([this.selectedMembers, [this.myPresenceId]]);

      let chatRequest = {
        AddMembers: membersToAdd,
        CreatedBy: this.myPresenceId,
        DisplayName: this.groupName.trim().length ? this.groupName : null,
        HasCustomAvatar: false,
        OwnerId: this.selectedOrganisation.id,
      };

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .createChat(tenantId, chatRequest)
        .then((r) => {
          vm.$message.success("Chat created successfully");
          // vm.$router.push("/chats/" + tenantId + "/" + r.data.id);

          vm.isSaving = false;
          if (vm.uploadedFile) {
            chats
              .addChatThumb(
                tenantId,
                r.data.id,
                vm.myPresenceId,
                vm.uploadedFile
              )
              .then(() => {
                vm.pushChat(r.data);
                vm.$emit("close-modal");
                vm.$router.push("/chats/" + tenantId + "/" + r.data.id);
                vm.isSaving = false;
              })
              .catch((e) => {
                console.log(e);
                vm.$router.push("/chats/" + tenantId + "/" + r.data.id);
                vm.$message.error("Error uploading file");
              });
          } else {
            vm.pushChat(r.data);
            vm.$emit("close-modal");
            vm.$router.push("/chats/" + tenantId + "/" + r.data.id);
            vm.isSaving = false;
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating chat");
        });
    },

    changeAvatar() {
      this.$refs.file.click();
    },

    handleFileUploaded(e) {
      let vm = this;
      vm.uploadedFile = e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(vm.uploadedFile);
      reader.onload = function () {
        vm.uploadedFileBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
  },
};
</script>

<style lang="scss">
.create-chat-modal {
  .org-thumbnail {
    display: inline;
    margin-right: 5px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .hidden {
    display: none;
  }

  .avatars {
    background: #eee;
    padding: 16px 24px;
  }

  .inner {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 20px;
  }

  .inner-stage-0 {
    padding-top: 20px;
  }

  .inner-stage-1 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .loader {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .member-list-item-col {
    margin-bottom: 10px;
  }

  .member-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    // margin-bottom: 10px;
    border-radius: 6px;
    // background: #eee;
    border: 1px solid #eee;
    padding: 8px 9px;
    &:last-child {
      margin-bottom: 0;
    }

    //  &:hover {
    //   color: #5155ea;
    //   border: 1px solid #5155ea;
    // }
    &:hover {
      background: #f9f9f9;
    }

    &.selected {
      background: #eee !important;
    }

    .left {
      flex-shrink: 1;
    }

    .middle {
      flex-grow: 1;
      padding-left: 15px;
    }

    .right {
      flex-shrink: 1;
      .anticon {
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }

  .matching-chat-card {
    margin-bottom: 10px;
  }

  .group-details-flex-wrapper {
    // margin-bottom: 25px;
    display: flex;
    .left {
      flex-shrink: 1;
      display: flex;
      align-items: center;
    }
    .right {
      flex-grow: 1;
      padding-left: 15px;
      display: flex;
      align-items: center;
    }
  }

  .file-input {
    display: none;
  }

  .avatar-wrapper {
    cursor: pointer;
  }

  .members-wrapper {
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  .matching-chats-wrapper {
    margin-top: 25px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  .stage-1-topbar {
    margin-bottom: 25px;
    // text-align: right;

    display: flex;
    justify-content: space-between;

    background: rgb(247, 248, 250);
    border-radius: 10px;
    padding: 10px;

    .ant-btn {
      // margin-left: 10px;
    }
  }
}
</style>
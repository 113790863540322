<template>
    <div class="tsos-call-for-help-header">
        <div v-if="false" class="left">
            <!-- <a href="#"
            @click.prevent.stop="navigateToRoute('home')"
             class="back-button">
                <LeftOutlined />
            </a> -->

            <h3 style="margin-bottom: 0px; padding-bottom: 0px;">
                Call For Help
            </h3>
        </div>
        <div v-if="false" class="right"></div>

        <h3 style="margin-bottom: 0px; padding-bottom: 0px;">
            Call For Help
        </h3>
    </div>
</template>

<script>
// import {LeftOutlined} from '@ant-design/icons-vue'
import { mapActions } from 'vuex';

export default {
    mixins: [],

    // components: {LeftOutlined},

    methods: {
        ...mapActions('tsosWidget', {
            navigateToRoute: 'navigateToRoute'
        })
    }
}
</script>

<style lang="scss" scoped>
.tsos-call-for-help-header {
    padding-top: 15px;
    padding-bottom: 15px;
    // padding-left: 25px;
    // padding-right: 25px;
    text-align: center;

    h3 {
        // letter-spacing: -0.5px;
        letter-spacing: -0.4px;
        font-size: 19px;
    }

    // display: flex;

    // .left {
    //     display: flex;
    //     align-items: center;
    //     flex-grow: 1;
    //     text-align: left;

    //     .back-button {
    //         margin-right: 15px;
            
    //         .anticon {
    //             font-size: 14px;
    //             color: #fff;
    //         }
    //     }

    //     h3 {
    //         // letter-spacing: -0.5px;
    //         letter-spacing: -0.4px;
    //         font-size: 19px;
    //     }
    // }

    // .right {
    //     flex-shrink: 1;
    //     padding-left: 15px;
    //     display: flex;
    //     align-items: center;
    // }
}
</style>
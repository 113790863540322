<template>
  <div class="content-type-groups">
    <a-row type="flex" :gutter="20">
      <a-col
        v-for="(group, i) in contentTypeGroupsToShow"
        :key="i"
        :span="colSpan"
      >
        <div
          @click.prevent="() => selectContentType(group)"
          class="content-type-group"
        >
          <div class="left">
            <!-- <i v-if="group.icon" :class="group.icon"></i> -->
            <a-icon
              :style="{ fontSize: '19px', color: '#1890ff' }"
              :type="group.icon"
            ></a-icon>
          </div>
          <div class="middle">
            <div class="name">{{ group.name }}</div>
          </div>
          <div class="right">
            {{ group.count }}
          </div>
          <div
            class="progress-bar"
            :style="getProgressBarStyle(group.percentageRead)"
          ></div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
export default {
  methods: {
    ...mapActions("guidancePackViewer", {
      selectContentType: "selectContentType",
    }),

    getContentTypeGroupCount(type) {
      let filtered = _.filter(this.guidancePackContents, (g) => {
        return g["$t"] == type;
      });
      return filtered.length;
    },

    getContentTypePercentageRead(type) {
      let filtered = _.filter(this.guidancePackContents, (g) => {
        return g["$t"] == type;
      });
      let contentIds = _.map(filtered, "$v.id");

      let viewedEntityIds = _.map(this.lastViews, "viewedEntityId");

      let intersection = _.intersection(contentIds, viewedEntityIds);

      // console.log("INTERSECTION");
      // console.log(contentIds);

      // Last major change content > last read at - don't include in read
      let validIntersection = [];
      _.each(intersection, (entityId) => {
        let entity = _.find(this.guidancePackContents, (content) => {
          return content["$v"]["id"] == entityId;
        });
        if (entity) {
          if (entity["$v"]["lastMajorChange"]) {
            let lastMajorChangeMoment = window.moment(
              entity["$v"]["lastMajorChange"]
            );

            let lastView = _.find(this.lastViews, (lv) => {
              return lv["viewedEntityId"] == entityId;
            });
            let viewedAtMoment = window.moment(lastView["viewedAt"]);
            if (viewedAtMoment.isAfter(lastMajorChangeMoment)) {
              validIntersection.push(entityId);
            }
          } else {
            validIntersection.push(entityId);
          }
        } else {
          validIntersection.push(entityId);
        }
      });

      if (validIntersection == 0) {
        return 1; // so some progress
      } else {
        return (100 * validIntersection.length) / contentIds.length;
      }
    },

    getProgressBarStyle(percentage) {
      return {
        width: percentage + "%",
      };
    },
  },
  computed: {
    ...mapGetters("guidancePackViewer", {
      guidancePackContents: "guidancePackContents",
    }),

    ...mapGetters("guidance", {
      lastViews: "lastViews",
    }),

    colSpan() {
      if (this.contentTypeGroupsToShow.length > 1) {
        return 12;
      }
      return 24;
    },

    contentTypeGroups() {
      return [
        {
          name: "Articles",
          model: "Soteria.Models.ArticleDetails, Soteria.Models",
          icon: "book",
          count: this.getContentTypeGroupCount(
            "Soteria.Models.ArticleDetails, Soteria.Models"
          ),
          percentageRead: this.getContentTypePercentageRead(
            "Soteria.Models.ArticleDetails, Soteria.Models"
          ),
        },
        {
          name: "Help Videos",
          model: "Soteria.Models.HelpVideoDetails, Soteria.Models",
          icon: "play-circle",
          count: this.getContentTypeGroupCount(
            "Soteria.Models.HelpVideoDetails, Soteria.Models"
          ),
          percentageRead: this.getContentTypePercentageRead(
            "Soteria.Models.HelpVideoDetails, Soteria.Models"
          ),
        },
        {
          name: "Downloads",
          model: "Soteria.Models.PolicyDetails, Soteria.Models",
          icon: "copy",
          count: this.getContentTypeGroupCount(
            "Soteria.Models.PolicyDetails, Soteria.Models"
          ),
          percentageRead: this.getContentTypePercentageRead(
            "Soteria.Models.PolicyDetails, Soteria.Models"
          ),
        },
        {
          name: "External Links",
          model: "Soteria.Models.ExternalLinkDetails, Soteria.Models",
          icon: "link",
          count: this.getContentTypeGroupCount(
            "Soteria.Models.ExternalLinkDetails, Soteria.Models"
          ),
          percentageRead: this.getContentTypePercentageRead(
            "Soteria.Models.ExternalLinkDetails, Soteria.Models"
          ),
        },
        {
          name: "Playbooks",
          model: "Soteria.Models.ChecklistContentDetails, Soteria.Models",
          icon: "unordered-list",
          count: this.getContentTypeGroupCount(
            "Soteria.Models.ChecklistContentDetails, Soteria.Models"
          ),
          percentageRead: this.getContentTypePercentageRead(
            "Soteria.Models.ChecklistContentDetails, Soteria.Models"
          ),
        },
      ];
    },

    contentTypeGroupsToShow() {
      return _.filter(this.contentTypeGroups, (group) => {
        return group.count > 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-type-group {
  margin-bottom: 15px;
  position: relative;

  background: #fff;
  padding: 25px 25px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  overflow: hidden;
  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 30px;
    text-align: center;
  }

  .middle {
    padding-left: 15px;
    flex-grow: 1;
  }

  .right {
    padding-left: 15px;
    flex-shrink: 1;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .progress-bar {
    background: #87d13e;
    display: block;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
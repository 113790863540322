<template>
  <div class="mute-display-button">
    <a-icon type="notification"></a-icon> Muted
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.mute-display-button {
    color: #999;
}
</style>
import axios from 'axios';

export default {
    getOnboardingStage(orgId) {
        return axios.get(window.API_BASE + '/api/v1/Onboarding/Stage/' + orgId);
    },

    setOnboardingStage(orgId, stage) {
        return axios.post(window.API_BASE + '/api/v1/Onboarding/Stage/' + orgId, stage, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    doesOrgExist(externalProvider, externalId) {
        return axios.get(window.API_BASE + '/api/v1/Onboarding/ExternalId/' + externalProvider + '/' + externalId);
    },

    createUser(params) {
        return axios.post(window.API_BASE + '/api/v1/Onboarding/User', params);
    },

    createOrg(params) {
        return axios.post(window.API_BASE + '/api/v1/Onboarding/Org', params);
    },

    getTemplates() {
        return axios.get(window.API_BASE + '/api/v1/Orgs/Templates');
    },

    doesOrganisationWithExternalProviderIdExist(externalProvider, externalId) {
        return axios.get(window.API_BASE + '/api/v1/Onboarding/ExternalId/' + externalProvider + '/' + externalId);
    }
}
import router from "../../router";

export const state = {
    windowFocusStatus: 'foreground'
};

export const mutations = {
    SET_FOREGROUND(state) {
        state.windowFocusStatus = 'foreground';
    },

    SET_BACKGROUND(state) {
        state.windowFocusStatus = 'background';
    }
};

export const getters = {
    windowFocusStatus: (state) => {
        return state.windowFocusStatus
    }
};

export const actions = {
    setForeground({ commit }) {
        commit('SET_FOREGROUND');
    },

    setBackground({ commit }) {
        commit('SET_BACKGROUND');
    },

    globalNotify({ getters, rootGetters }, params) {
        let { title, body, tag, icon, navigateTo, onlyInBackground, guid } = params;

        if (guid && rootGetters['mutedGroups/mutedGroupIds'].includes(guid)) {
            // alert('notifcation muted aaaaaah');
            return false;
        }

        if (getters.windowFocusStatus == 'foreground') {
            if (onlyInBackground && onlyInBackground == true) {
                return false;
            }
            this._vm.$nextTick(() => {
                // this._vm.$message.info(title + ' - ' + body);
                this._vm.$toast(title + ' - ' + body, {
                    onClick: function () {
                        if (navigateTo) {
                            router.push(navigateTo);
                        } else {
                            return false;
                        }
                    }
                });
            })
        } else {
            this._vm.$notification.show(title, {
                body: body,
                tag: tag,
                icon: icon
            }, {
                onclick: function () {
                    if (navigateTo) {
                        router.push(navigateTo);
                    } else {
                        return false;
                    }
                }
            })
        }
    }
};
const _ = require('lodash')

export const state = {
    metadataIds: []
};

export const mutations = {
    SET_METADATA_IDS(state, guids) {
        state.metadataIds = guids;
    },

    ADD_GUID_TO_METADATA(state, guid) {
        state.metadataIds.push(guid);
        state.metadataIds = _.uniq(state.metadataIds);
    }
};

export const getters = {
    metadataIds: (state) => {
        return state.metadataIds
    }
};

export const actions = {
    setMetadataIds({ commit }, guids) {
        commit('SET_METADATA_IDS', guids);
    },

    addGuidToMetadata({ commit }, guid) {
        commit('ADD_GUID_TO_METADATA', guid)
    }
};
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import eventBus from './event-bus'

export default {
    install(Vue) {
        let profiles = {
            testing: 'https://api.testing.teamsos.co.uk',
            live: 'https://api.teamsos.co.uk',
            staging: 'https://api.teamsos.co.uk',
            alex: 'https://id4api.alexfelton.com',
            martin: 'https://id4api.thirks.com',
        };

        let base = profiles['testing'];
        if (window.location.host == 'app.teamsos.co.uk') {
            base = profiles['live'];
        }
        if (window.location.host == 'app.staging.teamsos.co.uk') {
            base = profiles['staging'];
        }
        // base = profiles['alex'];

        const connection = new HubConnectionBuilder()
            .withUrl(base + '/soteriahub', { accessTokenFactory: () => Vue.prototype.$oidc.accessToken })
            .configureLogging(LogLevel.Debug)
            .withAutomaticReconnect()
            .build()

        // const connection = new HubConnectionBuilder()
        //     .withUrl('https://api.testing.teamsos.co.uk/soteriahub', { accessTokenFactory: () => Vue.prototype.$oidc.accessToken })
        //     .configureLogging(LogLevel.Debug)
        //     .build()


        connection.logging = true

        eventBus.$emit('signal-r-disconnected')

        // let startedPromise = null

        // function waitUntilAuthenticatedToConnect(resolve, reject, err) {
        //     console.log('Waiting until authenticated to connect...');
        //     let isAuthenticated = Vue.prototype.$oidc.isAuthenticated;
        //     if (isAuthenticated) {
        //         start().then(resolve).catch(err)
        //     } else {
        //         window.setTimeout(waitUntilAuthenticatedToConnect, 1000);
        //     }
        // }

        // function start() {
        //     startedPromise = connection.start().then(() => {
        //         eventBus.$emit('signal-r-connected')
        //     }).catch(err => {
        //         eventBus.$emit('signal-r-disconnected')
        //         console.error('Failed to connect with hub', err)

        //         let isAuthenticated = Vue.prototype.$oidc.isAuthenticated;
        //         console.log(isAuthenticated);
        //         console.log(Vue.prototype.$oidc);
        //         if (isAuthenticated) {
        //             return new Promise((resolve) => setTimeout(() => {
        //                 start().then(resolve).catch(err)
        //             }, 5000))
        //         }  else {
        //             return new Promise((resolve) => setTimeout()); 
        //         }
        //     })
        //     return startedPromise
        // }

        function start() {
            let isAuthenticated = Vue.prototype.$oidc.isAuthenticated;

            if (isAuthenticated) {
                connection.start().then(() => {
                    eventBus.$emit('signal-r-connected')
                }).catch(e => {
                    console.log(e);
                    console.log('Attempting to connect again in 5 seconds');
                    eventBus.$emit('signal-r-disconnected')
                    window.setTimeout(start, 5000);
                })
            } else {
                console.log('Unauthenticated. Check again in 2 seconds...');
                window.setTimeout(start, 2000);
            }
        }

        connection.onreconnecting(() => {
            eventBus.$emit('signal-r-disconnected');
        });

        connection.onreconnected(() => {
            eventBus.$emit('signal-r-connected');
        });

        connection.onclose(() => {
            eventBus.$emit('signal-r-disconnected');
            start();
        })

        start()

        Vue.prototype.$signalR = connection;

        // use new Vue instance as an event bus
        const signalRHub = new Vue()
        // every component will use this.$questionHub to access the event bus
        Vue.prototype.$signalRHub = signalRHub
        // Forward server side SignalR events through $signalRHub, where components will listen to them
        // connection.on('-', (questionId, score) => {
        // signalRHub.$emit('score-changed', { questionId, score })
        // })
    }
}
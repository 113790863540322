import axios from 'axios';

export default {
    getReportsForOrg(tenantId, orgId, fromInstant, toInstant) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Reports/' + fromInstant + '/' + toInstant);
    },

    getReportUrl(tenantId, reportId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Reports/' + reportId + '/Media');
    },

    addReport(tenantId, reportRequest) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Reports', reportRequest);
    },

    getReport(tenantId, reportId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Reports/' + reportId);
    }
}
import self from '../../api/self'
let _ = require('lodash');

export const state = {
    isLoading: false,
    serverErrors: [],

    guidancePacks: [],

    isLoadingLastViews: false,
    lastViews: [],
    loadedLastViews: false
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_GUIDANCE_PACKS(state, guidancePacks) {
        state.guidancePacks = guidancePacks
    },

    SET_ERRORS(self, serverErrors) {
        state.serverErrors = serverErrors
    },

    START_LOADING_LAST_VIEWS(state) {
        state.isLoadingLastViews = true
    },

    STOP_LOADING_LAST_VIEWS(state) {
        state.isLoadingLastViews = false
    },

    SET_LAST_VIEWS(state, lastViews) {
        state.lastViews = lastViews
    },

    SET_LAST_VIEWS_LOADED(state) {
        state.loadedLastViews = true;
    },

    ADD_ENTITY_VIEW(state, entityView) {
        if (state.lastViews && state.lastViews.length) {
            state.lastViews.push(entityView);
        } else {
            state.lastViews = [entityView];
        }
        state.lastViews = _.uniqBy(state.lastViews, 'id');
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    chats: (state) => {
        return state.chats
    },

    guidancePacks: (state) => {
        return state.guidancePacks
    },

    guidancePacksSorted: (state) => {
        let withoutOwnerId = _.filter(state.guidancePacks, (gp) => {
            return gp["$v"]["ownerId"] === null;
        });
        let withOwnerId = _.filter(state.guidancePacks, (gp) => {
            return gp["$v"]["ownerId"] !== null;
        });
        let sorted = _.flatten([
            _.sortBy(withoutOwnerId, "$v.displayName"),
            _.sortBy(withOwnerId, "$v.displayName"),
        ]);
        return _.uniqBy(sorted, '$v.id');
    },

    lastViews: (state) => {
        return state.lastViews
    },

    loadedLastViews: (state) => {
        return state.loadedLastViews
    }
};

export const actions = {
    loadGuidancePacks({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING');
            dispatch('loadLastViews');

            self.getGuidancePacks().then(r => {
                commit('STOP_LOADING');
                commit('SET_GUIDANCE_PACKS', r.data);
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING');
                commit('SET_GUIDANCE_PACKS', []);
                // this._vm.$message.error('Error loading guidance packs');

                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(errors);
            })
        });
    },

    loadLastViews({ commit }) {
        commit('START_LOADING_LAST_VIEWS');
        self.getLastViews().then(r => {
            commit('STOP_LOADING_LAST_VIEWS');
            commit('SET_LAST_VIEWS', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_LAST_VIEWS');
            commit('SET_LAST_VIEWS', []);
            this._vm.$message.error('Eror loading last views');
        })
    },

    loadLastViewsIfNotLoaded({ dispatch, getters }) {
        if (!getters.loadedLastViews) {
            dispatch('loadLastViews');
        }
    },

    addEntityView({ commit }, entityView) {
        commit('ADD_ENTITY_VIEW', entityView);
    }
};
import leads from "../../api/leads";

export const state = {
    isLoading: false,

    startDate: null,
    endDate: null,

    hasSearchBeenPerformed: false,
    searchResults: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_START_DATE(state, startDate) {
        state.startDate = startDate
    },

    SET_END_DATE(state, endDate) {
        state.endDate = endDate
    },

    SET_SEARCH_HAS_BEEN_PERFORMED(state, boolVal) {
        state.hasSearchBeenPerformed = boolVal
    },

    SET_SEARCH_RESULTS(state, searchResults) {
        state.searchResults = searchResults
    }
}

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    startDate: (state) => {
        return state.startDate
    },

    endDate: (state) => {
        return state.endDate
    },

    hasSearchBeenPerformed: (state) => {
        return state.hasSearchBeenPerformed
    },

    searchResults: (state) => {
        return state.searchResults
    }
}

export const actions = {
    reset({ commit }) {
        commit('SET_START_DATE', window.moment().startOf('day').subtract(1, 'month'));
        commit('SET_END_DATE', window.moment().endOf('day'));
        commit('SET_SEARCH_HAS_BEEN_PERFORMED', false);
        commit('SET_SEARCH_RESULTS', []);
    },

    setStartDate({ commit }, startDate) {
        commit('SET_START_DATE', startDate);
    },

    setEndDate({ commit }, endDate) {
        commit('SET_END_DATE', endDate);
    },

    searchLeads({ commit, getters }) {
        let fromInstant = getters.startDate.clone().startOf('day').format('x') * 10000;
        let toInstant = getters.endDate.clone().endOf('day').format('x') * 10000;

        commit('START_LOADING');
        leads.searchLeads(fromInstant, toInstant).then(r => {
            commit('STOP_LOADING');
            commit('SET_SEARCH_HAS_BEEN_PERFORMED', true);
            commit('SET_SEARCH_RESULTS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_SEARCH_RESULTS', []);
            console.log(e);
            this._vm.$message.error('Error getting leads');
        });
    }
}
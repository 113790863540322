var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-guidance-pack"},[(_vm.isLoading)?_c('div',{staticClass:"text-center loading-wrapper"},[_c('a-spin')],1):_vm._e(),(!_vm.isLoading && !_vm.guidancePackContents)?_c('div',{staticClass:"no-guidance-pack-wrapper"},[_c('a-alert',{attrs:{"type":"error","message":"Error loading guidance pack contents, please try again."}})],1):_vm._e(),(!_vm.isLoading && _vm.guidancePackContents && _vm.guidancePack)?_c('div',{staticClass:"guidance-pack-inner"},[_c('div',{staticClass:"guidance-pack-inner-top"},[_c('div',{staticClass:"guidance-pack-header"},[_c('div',{staticClass:"left"},[(!_vm.hideBackButton)?_c('a-button',{staticStyle:{"display":"inline","margin-right":"15px"},attrs:{"type":"default","icon":"left"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}}):_vm._e(),_c('organisation-thumbnail',{staticStyle:{"display":"inline"},attrs:{"tenant-id":_vm.tenantId,"org-id":_vm.guidancePackId}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"page-header",staticStyle:{"margin-bottom":"10px !important"}},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.guidancePack["$v"]["displayName"])+" ")])])])])]),_c('div',{staticClass:"guidance-pack-inner-bottom"},[(
          !(
            (_vm.selectedTopic && !_vm.selectedTopicShouldBeDisplayedInModal) ||
            _vm.selectedParent
          )
        )?_c('div',[(!_vm.selectedContentType)?_c('div',[_c('a-row',{attrs:{"type":"flex","gutter":20}},_vm._l((_vm.topicsSorted),function(topic){return _c('a-col',{key:topic['$v']['id'],staticClass:"guidance-pack-column",attrs:{"span":12}},[_c('guidance-pack-topic-card',{attrs:{"show-send-button":_vm.showSendButton,"topic":topic},on:{"send":(params) => _vm.$emit('send', params),"selected":() => _vm.handleParentSelected(topic)}})],1)}),1),_c('content-type-groups')],1):_vm._e(),(_vm.selectedContentType)?_c('div',[_c('content-type-page',{attrs:{"show-send-button":_vm.showSendButton},on:{"send":(params) => _vm.$emit('send', params)}})],1):_vm._e()]):_vm._e(),(
          _vm.selectedParent &&
          !(_vm.selectedTopic && !_vm.selectedTopicShouldBeDisplayedInModal)
        )?_c('div',[_c('parent-children-page',{attrs:{"show-send-button":_vm.showSendButton},on:{"send":(params) => _vm.$emit('send', params)}})],1):_vm._e(),(_vm.selectedTopic && !_vm.selectedTopicShouldBeDisplayedInModal)?_c('topic-tabs',{attrs:{"show-send-button":_vm.showSendButton},on:{"send":(params) => _vm.$emit('send', params)}}):_vm._e(),(_vm.selectedTopic && _vm.selectedTopicShouldBeDisplayedInModal)?_c('topic-modal',{attrs:{"show-send-button":_vm.showSendButton},on:{"send":(params) => _vm.$emit('send', params)}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios';

// A playbook is a checklist under the hood

export default {
    getPlaybooksForOrg(tenantId, orgId, includeHidden = false, includeIncidentTypeChecklists = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Checklists';

        if (includeHidden) {
            query = query + '?includeHidden=true'
            if (includeIncidentTypeChecklists) {
                query = query + '&includeIncidentTypeChecklists=true'
            }
        } else {
            if (includeIncidentTypeChecklists) {
                query = query + '?includeIncidentTypeChecklists=true'
            }
        }

        return axios.get(query);
    }
}
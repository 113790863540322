<template>
    <div class="global-search-button-and-modal">
        <a-button icon="search" @click.prevent="openModal" class="btn-rounded global-search-button">
            Search
            <span class="keyboard-shortcut">⌘K</span>
        </a-button>

        <!-- Modal -->
        <div v-show="showModal" class="global-search-modal">
            <div class="modal-bg" @click.prevent="closeModal"></div>

            <div class="modal-dialog">
                <div class="modal-search-bar" :class="{ 'no-border-bottom': !q }">
                    <div class="search-bar-form">
                        <label class="magnifier-label" for="docsearch-input" id="docsearch-label"><svg width="20"
                                height="20" class="search-icon" viewBox="0 0 20 20">
                                <path
                                    d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                                    stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg></label>

                        <input ref="search-input" class="search-input" v-model="searchQuery" @keyup.escape="closeModal"
                            placeholder="Search teamSOS">
                    </div>
                    <button class="close-modal-button">Cancel</button>
                </div>

                <div class="modal-dropdown">
                    <!-- Recent searches -->
                    <div class="modal-recent-searches" v-if="!searchQuery && false">
                        <!-- No recent searches -->
                        <div class="no-recent-searches" v-if="recentSearches.length == 0">
                            <div class="no-recent-searches-title modal-title">
                                No recent searches
                            </div>
                        </div>
                        <!-- / No recent searches -->

                        <!-- Recent searches list -->
                        <div class="modal-hits" v-if="recentSearches.length">
                            <div class="modal-hit-source recent-searches-title">
                                Recent
                            </div>
                            <div class="modal-hits-list">
                                <div class="modal-hit" v-for="(hit, i) in recentSearches.reverse()"
                                    :key="`recent-search-${i}`">
                                    <a href="#" @click.prevent="navigateToHit(hit)">
                                        <div class="hit-inner">
                                            <div class="left-icon">
                                                <div class="icon-wrapper">
                                                    <a-icon v-if="hit.item.icon" :type="hit.item.icon"
                                                        theme="outlined" />
                                                    <a-icon v-else type="info" theme="outlined" />
                                                </div>
                                            </div>
                                            <div class="hit-content">
                                                {{ hit.item.displayName }}
                                            </div>
                                            <div class="right-icon">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- / Recent searches list -->
                    </div>
                    <!-- / Recent searches -->

                    <!-- No results -->
                    <div class="modal-no-results" v-if="searchQuery && results.length == 0">
                        <div class="no-results-title modal-title">
                            No results for "<strong>{{ searchQuery }}</strong>"
                        </div>
                        <div class="no-results-prefill-list">
                            <p class="search-help">
                                Try searching for:
                            </p>

                            <ul>
                                <li v-for="(keyword, i) in trySearchingFor" :key="`try-searching-for-${i}`">
                                    <button class="prefill-button" @click.prevent="searchFor(keyword)">
                                        {{ keyword }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- / No results -->

                    <!-- Results -->
                    <div class="modal-results" v-if="searchQuery && results.length">
                        <div class="modal-dropdown-container">
                            <div class="modal-hits" v-for="(hitSection, i) in results" :key="`hit-section-${i}`">
                                <div class="modal-hit-source">
                                    {{ hitSection.title }}
                                </div>
                                <div class="modal-hits-list">
                                    <div class="modal-hit" v-for="(hit, j) in hitSection.hits"
                                        :key="`hit--${i}-hit-${j}`">
                                        <a href="#" @click.prevent="navigateToHit(hit)">
                                            <div class="hit-inner">
                                                <div class="left-icon">
                                                    <incident-thumbnail size="sm"
                                                        v-if="hit.item.searchType && hit.item.searchType == 'incident'"
                                                        :tenant-id="hit.item.tenantId" :incident-id="hit.item.id"
                                                        :incident-owner-id="hit.item.ownerId"
                                                        :created-by="hit.item.createdBy"></incident-thumbnail>
                                                    <div v-if="!hit.item.searchType" class="icon-wrapper">
                                                        <a-icon v-if="hit.item.icon" :type="hit.item.icon"
                                                            theme="outlined" />
                                                        <a-icon v-else type="info" theme="outlined" />
                                                    </div>
                                                </div>
                                                <div class="hit-content">
                                                    {{ hit.item.displayName }}
                                                </div>
                                                <div class="right-icon">
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / Results -->
                </div>
            </div>
        </div>
        <!-- / Modal -->
    </div>
</template>

<script>
const _ = require('lodash');
import Fuse from 'fuse.js'
import { mapGetters, mapActions } from 'vuex';
import organisations from "../helpers/organisations";
import IncidentThumbnail from "../components/Incidents/IncidentThumbnail.vue";

const TRY_SEARCHING_FOR = [
    'Incidents',
    'Chats',
    'Announcements'
];

const PAGES = [
    {
        displayName: 'Home',
        icon: 'home',
        to: '/dashboard'
    },
    {
        displayName: 'Messages',
        icon: 'message',
        to: '/chats'
    },
    {
        displayName: 'Incidents',
        icon: 'warning',
        to: '/incidents'
    },
    {
        displayName: 'Announcements',
        icon: 'notification',
        to: '/announcements'
    },
    {
        displayName: 'Guidance',
        icon: 'book',
        to: '/guidance'
    },
    {
        displayName: 'My Tasks',
        icon: 'unordered-list',
        to: '/my-tasks'
    },
    {
        displayName: 'Start A Drill',
        icon: 'info-circle',
        to: '/start-a-drill'
    },
    {
        displayName: 'Call For Help',
        icon: 'phone',
        to: '/call-for-help'
    }
];

const ADMIN_PAGES = [
    {
        displayName: 'Dashboard',
        icon: 'home',
        admin: true,
        to: '/admin/stats'
    },
    {
        displayName: 'Managed Groups',
        admin: true,
        to: '/admin/managed-groups'
    },
    {
        displayName: 'Announcements',
        admin: true,
        to: '/admin/announcements'
    },
    {
        displayName: 'Active Incidents',
        admin: true,
        to: '/admin/incidents'
    },
    {
        displayName: 'Closed Incidents',
        admin: true,
        to: '/admin/archived-incidents'
    },
    {
        displayName: 'Reporting',
        admin: true,
        to: '/admin/reporting'
    },
    {
        displayName: 'Guidance',
        admin: true,
        to: '/admin/guidance'
    },
    {
        displayName: 'Integration',
        admin: true,
        to: '/admin/integration'
    },
    {
        displayName: 'Users',
        admin: true,
        to: '/admin/users'
    },
    {
        displayName: 'Responders',
        admin: true,
        to: '/admin/responders'
    },
    {
        displayName: 'Settings',
        admin: true,
        to: '/admin/settings'
    },
    {
        displayName: 'Settings - Emergency Types',
        admin: true,
        to: '/admin/settings/emergency-types'
    },
    {
        displayName: 'Settings - Incident Types',
        admin: true,
        to: '/admin/settings/incident-types'
    },
    {
        displayName: 'Settings - Incident Type Groups',
        admin: true,
        to: '/admin/settings/incident-type-groups'
    },
    {
        displayName: 'Settings - Windows Installs',
        admin: true,
        to: '/admin/settings/windows-installs'
    },
    {
        displayName: 'Settings - Quick Messages',
        admin: true,
        to: '/admin/settings/quick-messages'
    },
    {
        displayName: 'Settings - Playbooks',
        admin: true,
        to: '/admin/settings/playbooks'
    },
    {
        displayName: 'Settings - Locations',
        admin: true,
        to: '/admin/settings/locations'
    }
];

const FUSE_OPTIONS = {
    includeScore: true,
    keys: ['displayName']
}

export default {
    data() {
        return {
            showModal: false,
            searchQuery: '',
            recentSearches: [],

            trySearchingFor: TRY_SEARCHING_FOR,
            pages: PAGES,
            adminPages: ADMIN_PAGES,
            incidentsForSearch: [],
            faqsForSearch: [],

            fuseOptions: FUSE_OPTIONS,
            pagesFuse: null,
            adminPagesFuse: null,
            incidentsFuse: null,
            faqsFuse: null
        }
    },
    components: { IncidentThumbnail },
    created() {
        let vm = this;
        document.addEventListener('keydown', function (event) {
            if (event.ctrlKey && event.key === 'k') {
                vm.openModal();
            }
            if (event.metaKey && event.key === 'k') {
                vm.openModal();
            }
        });
    },
    computed: {
        ...mapGetters('incidents', {
            incidents: 'incidents'
        }),

        ...mapGetters('missionControl', {
            organisations: "organisations",
            organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor"
        }),

        ...mapGetters("admin", {
            selectedOrganisation: "selectedOrganisation",
        }),

        ...mapGetters("subjectPreferences", {
            lastOrgId: "lastOrgId",
        }),

        ...mapGetters("faqs", {
            faqs: "faqs"
        }),

        q() {
            return this.searchQuery.trim();
        },

        results() {
            if (this.q.length == 0) {
                return [];
            }

            if (!this.pagesFuse || !this.adminPagesFuse || !this.incidentsFuse || !this.faqsFuse) {
                return [];
            }

            let results = [];

            // Search main pages
            // let pagesFuse = new Fuse(this.pages, fuseOptions);
            let pagesHits = this.pagesFuse.search(this.q);
            if (pagesHits.length) {
                results.push({
                    title: 'Pages',
                    hits: _.orderBy(pagesHits, 'item.score')
                });
            }

            // Search admin pages
            if (this.organisationsIAmAnAdminFor.length) {
                let adminPagesHits = this.adminPagesFuse.search(this.q);
                if (adminPagesHits.length) {
                    results.push({
                        title: 'Admin',
                        hits: _.orderBy(adminPagesHits, 'item.score')
                    });
                }
            }

            // Search incidents
            // let incidentsFuse = new Fuse(this.incidentsForSearch, fuseOptions);
            let incidentsHits = this.incidentsFuse.search(this.q);
            if (incidentsHits.length) {
                results.push({
                    title: 'Incidents',
                    hits: _.orderBy(incidentsHits, 'item.score')
                });
            }

            // Search FAQs
            let faqsHits = this.faqsFuse.search(this.q);
            if (faqsHits.length) {
                results.push({
                    title: 'FAQs',
                    hits: _.orderBy(faqsHits, 'item.score')
                });
            }

            return results;
        }
    },
    methods: {
        ...mapActions("admin", {
            selectOrganisation: "selectOrganisation",
        }),

        getOrganisationById(id) {
            return _.find(this.organisations, { id });
        },

        openModal() {
            // Setup
            this.incidentsForSearch = _.map(this.incidents, incident => {
                let organisation = this.getOrganisationById(incident.ownerId);
                let tenantId = organisations.getOrganisationTenantId(organisation);

                return {
                    ...incident,
                    // icon: 'warning',
                    to: '/incidents/' + tenantId + '/' + incident.id,
                    searchType: 'incident',
                    tenantId: tenantId
                }
            });
            this.faqsForSearch = _.map(this.faqs, faq => {
                return {
                    ...faq,
                    displayName: faq.question,
                    to: '/faqs?q=' + faq.question,
                    icon: 'book'
                }
            });
            this.pagesFuse = new Fuse(this.pages, this.fuseOptions);
            this.adminPagesFuse = new Fuse(this.adminPages, this.fuseOptions);
            this.incidentsFuse = new Fuse(this.incidentsForSearch, this.fuseOptions);
            this.faqsFuse = new Fuse(this.faqsForSearch, this.fuseOptions);

            // Open modal
            this.showModal = true;
            this.$nextTick(() => {
                this.$refs['search-input'].focus();
            });
        },

        closeModal() {
            this.showModal = false;
        },

        searchFor(keyword) {
            this.searchQuery = keyword.trim();
        },

        selectDefaultAdminOrgIfNoOrgSelected() {
            if (this.organisationsIAmAnAdminFor.length && !this.selectedOrganisation) {
                // Attempt to get from subject preferences, otherwise select first org
                if (
                    this.lastOrgId &&
                    _.map(this.organisationsIAmAnAdminFor, "id").includes(this.lastOrgId)
                ) {
                    this.selectOrganisation(
                        _.find(this.organisationsIAmAnAdminFor, { id: this.lastOrgId })
                    );
                } else {
                    this.selectOrganisation(_.first(this.organisationsIAmAnAdminFor));
                }
            }
        },

        navigateToHit(hit) {
            // this.recentSearches = _.filter(this.recentSearches, search => {
            //     return search.item.to !== hit.item.to;
            // });
            // this.recentSearches.push({
            //     item: {
            //         to: hit.item.to,
            //         displayName: hit.item.displayName,
            //         icon: hit.item.icon
            //     }
            // });
            if (hit.item.admin) {
                this.selectDefaultAdminOrgIfNoOrgSelected();
            }

            this.$router.push(hit.item.to);
            this.closeModal();
            this.searchQuery = '';
        }
    }
}
</script>

<style scoped lang="scss">
.global-search-button-and-modal {
    display: inline-block;
    margin-right: 15px;

    .global-search-button {
        font-weight: 500;

        .keyboard-shortcut {
            margin-left: 10px;
            opacity: 0.6;
        }
    }

    .global-search-modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5000;
        display: flex;
        height: 100vh;
        width: 100vw;
        cursor: auto;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .2);
        padding: 1rem;
        backdrop-filter: blur(4px);
        align-items: center;
        justify-items: center;
        justify-content: center;

        .search-help {
            margin-bottom: 0.75rem;
            font-size: .875rem;
            font-weight: 600;
            line-height: 1.5rem;
            --tw-text-opacity: 1;
            color: rgb(15 23 42);
        }

        .modal-title {
            text-align: center;
            color: #94a3b8;
            margin-bottom: 0;
            font-size: 1.125rem;
            margin-bottom: 2.5rem;
            // line-height: 1.5rem;

            strong {
                font-weight: 400;
                color: rgb(15 23 42);
            }
        }

        .modal-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(15, 23, 42, .8);
        }

        .modal-dialog {
            margin: 0 auto;
            width: 100%;
            max-width: 47.375rem;
            display: flex;
            flex-direction: column;
            min-height: 0;
            border-radius: 0.5rem;
            box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
            background: #fff;
            z-index: 5001;

            .modal-search-bar {
                padding: 0 1rem;
                position: relative;
                display: flex;
                flex: none;
                align-items: center;
                border: 0 solid #e5e7eb;
                border-color: rgb(241 245 249);
                border-bottom-width: 1px;

                &.no-border-bottom {
                    border-bottom-width: 0 !important;
                }

                .search-bar-form {
                    display: flex;
                    min-width: 0;
                    flex: 1 1 auto;
                    align-items: center;

                    .magnifier-label {
                        height: 1.5rem;
                        width: 1.5rem;
                        flex: none;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m19 19-3.5-3.5' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='11' cy='11' r='6' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

                        .search-icon {
                            display: none;
                        }
                    }

                    .search-input {
                        -webkit-appearance: none;
                        appearance: none;
                        background: transparent;
                        height: 3.5rem;
                        font-size: 1rem;
                        color: #0f172a;
                        margin-left: 0.75rem;
                        margin-right: 1rem;
                        flex: auto;
                        min-width: 0;
                        border: 0 solid #e5e7eb;
                        line-height: inherit;

                        &:focus {
                            outline: 2px dotted transparent;
                        }
                    }
                }

                .close-modal-button {
                    width: 1.75rem;
                    height: 1.5rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.506 6h3.931V4.986H1.736v-1.39h2.488V2.583H1.736V1.196h2.69V.182H.506V6ZM8.56 1.855h1.18C9.721.818 8.87.102 7.574.102c-1.276 0-2.21.705-2.205 1.762-.003.858.602 1.35 1.585 1.585l.634.159c.633.153.986.335.988.727-.002.426-.406.716-1.03.716-.64 0-1.1-.295-1.14-.878h-1.19c.03 1.259.931 1.91 2.343 1.91 1.42 0 2.256-.68 2.259-1.745-.003-.969-.733-1.483-1.744-1.71l-.523-.125c-.506-.117-.93-.304-.92-.722 0-.375.332-.65.934-.65.588 0 .949.267.994.724ZM15.78 2.219C15.618.875 14.6.102 13.254.102c-1.537 0-2.71 1.086-2.71 2.989 0 1.898 1.153 2.989 2.71 2.989 1.492 0 2.392-.992 2.526-2.063l-1.244-.006c-.117.623-.606.98-1.262.98-.883 0-1.483-.656-1.483-1.9 0-1.21.591-1.9 1.492-1.9.673 0 1.159.389 1.253 1.028h1.244Z' fill='%23334155'/%3E%3C/svg%3E");
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 57.1428571429% auto;

                    appearance: none;
                    flex: none;
                    font-size: 0;
                    border-radius: 0.375rem;
                    padding: 0.25rem 0.375rem;
                    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(15, 23, 42, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                    background-color: transparent !important;
                    border: none !important;
                }
            }

            .modal-dropdown {
                flex: 1 1 auto;
                overflow: auto;

                .modal-dropdown-container {
                    padding-bottom: 1.5rem;
                }

                .modal-results {
                    max-height: 60vh;
                    overflow-y: scroll;
                }

                .modal-hits {


                    .modal-hit-source {
                        padding-top: 1.5rem;
                        line-height: 1.5rem;
                        font-weight: 600;
                        color: #0f172a;
                        padding-top: 2.5rem;
                        margin: 0 1.5rem 0.75rem;

                        &:first-child {
                            padding-top: 1.5rem;
                        }
                    }

                    .modal-hits-list {
                        margin: 0 1.5rem 1rem;

                        .modal-hit {
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            a {
                                display: block;
                                border-color: rgb(241 245 249);
                                position: relative;
                                font-size: .875rem;
                                border-bottom: 1px solid #f1f5f9;
                                border-radius: 0.5rem;
                                background-color: rgb(248 250 252);
                                padding: 10px 15px !important;

                                &:hover {
                                    // background-color: rgb(14 165 233);
                                    background-color: #00C0B2;

                                    .icon-wrapper {
                                        // background: rgb(14 165 233) !important;
                                        background: #00C0B2 !important;
                                        box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(255, 255, 255, 0.6) 0px 0px 0px 1px, rgba(255, 255, 255, 0.3) 0px 1px 2px 0px !important;

                                        .anticon {
                                            color: #fff !important
                                        }
                                    }

                                    .hit-content {
                                        color: #fff !important;
                                    }

                                    .right-icon {
                                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11 9 3 3-3 3' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
                                    }
                                }

                                .hit-inner {
                                    display: flex;
                                    align-items: center;

                                    .left-icon {
                                        flex-shrink: 1;

                                        .icon-wrapper {
                                            background: #fff;
                                            box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(15, 23, 42, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                                            width: 1.9rem;
                                            height: 1.9rem;
                                            border-radius: 0.375rem;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            .anticon {
                                                font-size: 14px;
                                                // color: rgb(2, 132, 199);
                                                color: #00C0B2;
                                            }
                                        }
                                    }

                                    .hit-content {
                                        flex-grow: 1;
                                        color: rgb(51 65 85);
                                        padding-left: 30px;
                                        line-height: 40px;
                                    }

                                    .right-icon {
                                        flex-shrink: 1;
                                        height: 1.5rem;
                                        width: 1.5rem;
                                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11 9 3 3-3 3' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                                    }
                                }
                            }

                        }
                    }
                }

                .modal-recent-searches {
                    .no-recent-searches {
                        padding: 2.5rem 1rem 3rem;

                        .no-recent-searches-title {
                            margin-bottom: 0;
                        }
                    }

                    .modal-hits {
                        padding-bottom: 20px;
                    }

                    .recent-searches-title {
                        font-size: 17px;
                    }
                }

                .modal-no-results {
                    padding: 2.5rem 1rem 3rem;

                    .no-results-prefill-list {

                        ul {
                            overflow: hidden;
                            border-radius: 0.5rem;
                            border: 0 solid #e5e7eb;
                            border-width: 1px;
                            border-color: rgb(226 232 240);
                            background-color: rgb(248 250 252);
                            list-style: none;
                            margin: 0;
                            padding: 0;

                            li {
                                display: list-item;
                                text-align: -webkit-match-parent;

                                button.prefill-button {
                                    cursor: pointer;
                                    padding: 0.5rem 0.75rem;
                                    display: block;
                                    width: 100%;
                                    text-align: left;
                                    font-size: .875rem;
                                    line-height: 1.5rem;
                                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11 9 3 3-3 3' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                                    background-repeat: no-repeat;
                                    background-position: right 0.75rem center;
                                    color: rgb(51 65 85);
                                    -webkit-appearance: button;
                                    background-color: transparent;
                                    border: 0 solid #e5e7eb;
                                }
                            }

                            li+li {
                                border: 0 solid #e5e7eb;
                                border-top-width: 1px;
                                border-color: rgb(226 232 240);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
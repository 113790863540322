<template>
  <div class="announcement-tags-wrapper" v-if="isUrgent">
    <a-tag v-if="isUrgent" class="mode-tag" color="#00C0B2">URGENT</a-tag>
  </div>
</template>

<script>
import announcements from "../../helpers/announcements";
export default {
    props: ["announcement"],
    computed: {
        isUrgent() {
            return announcements.isAnnouncementUrgent(this.announcement);
        }
    }
};
</script>

<style scoped>
.announcement-tags-wrapper {
    display: inline-block;
}
</style>
const _ = require('lodash');

export const state = {
    mutedGroupIds: []
};

export const mutations = {
    TOGGLE_GROUP_MUTED(state, id) {
        state.mutedGroupIds = _.xor(state.mutedGroupIds, [id]);
    }
};

export const getters = {
    mutedGroupIds: (state) => {
        return state.mutedGroupIds
    }
};

export const actions = {
    toggleGroupMuted({commit}, id) {
        commit('TOGGLE_GROUP_MUTED', id);
    }
};
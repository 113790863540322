<template>
  <div
    class="guidance-pack-card-wrapper"
    @click.prevent="$emit('selected', guidancePack)"
  >
    <div class="top" v-bind:style="bannerImageStyle">
      <a-spin v-if="isLoadingBannerImage"></a-spin>
    </div>
    <div class="bottom">
      <div class="left">
        <organisation-thumbnail
          :tenant-id="tenantId"
          :org-id="id"
        ></organisation-thumbnail>
      </div>
      <div class="right">
        <div class="name">
          {{ guidancePack["$v"]["displayName"] }}
        </div>
        <!-- {{ guidancePack["$v"]["contextQuote"] }} -->
      </div>
      <div v-if="showSendButton" class="super-right">
        <a-button type="primary" @click.prevent.stop="emitSend">Share</a-button>
      </div>
      <div class="progress-bar" :style="progressBarStyle"></div>
    </div>
    <div class="inner"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrganisationThumbnail from "../../components/Organisations/OrganisationThumbnail.vue";
import organisations from "../../api/organisations";
const _ = require("lodash");
export default {
  components: { OrganisationThumbnail },
  props: ["guidancePack", "showSendButton"],
  data() {
    return {
      isLoadingBannerImage: false,
      bannerImage: null,
    };
  },
  created() {
    if (this.hasBannerImage) {
      this.getBannerImageUrl();
    }
  },
  computed: {
    ...mapGetters("guidance", {
      lastViews: "lastViews",
    }),
    hasBannerImage() {
      return this.guidancePack["$v"]["isContentPack"];
    },
    tenantId() {
      return this.guidancePack["$v"]["tenantId"];
    },
    id() {
      return this.guidancePack["$v"]["id"];
    },
    isContentPack() {
      return this.guidancePack["$v"]["isContentPack"];
    },
    bannerImageStyle() {
      if (this.hasBannerImage && this.bannerImage) {
        return {
          backgroundImage: "url(" + this.bannerImage.url + ")",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };
      }
      return {
        backgroundColor: "rgba(81, 85, 234, 0.1)",
      };
    },
    percentageOfGuidancePackRead() {
      // Flter out content links that are topics
      let contentLinksThatAreNotTopics = _.filter(
        this.guidancePack["$v"]["contentLinks"],
        (contentLink) => {
          return contentLink["childEntityType"] !== 5;
        }
      );
      let idsOfContentLinksThatAreNotTopics = _.map(
        contentLinksThatAreNotTopics,
        "childEntityId"
      );

      let viewedEntityIds = _.map(this.lastViews, "viewedEntityId");

      // TODO: last major change content > last read at - don't include in read

      let intersection = _.intersection(idsOfContentLinksThatAreNotTopics, viewedEntityIds);

      if (intersection == 0) {
        return 3; // so some progress
      } else {
        return (100 * intersection.length) / idsOfContentLinksThatAreNotTopics.length;
      }
    },
    progressBarStyle() {
      return {
        width: this.percentageOfGuidancePackRead + "%",
      };
    },
  },
  methods: {
    emitSend() {
      this.$emit('send', {
        guidancePackId: this.guidancePack['$v']['id'],
        contentId: null,
        name: this.guidancePack['$v']['displayName'],
        guidancePackName: this.guidancePack['$v']['displayName']
      });
    },

    getBannerImageUrl() {
      let vm = this;
      vm.isLoadingBannerImage = true;
      organisations
        .getOrgBannerImageProtectedUrl(this.tenantId, this.id)
        .then((r) => {
          vm.isLoadingBannerImage = false;
          vm.bannerImage = r.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.guidance-pack-card-wrapper {
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;
  position: relative;

  .top {
    height: 130px;
    min-height: 130px;
    max-height: 130px;
  }

  .bottom {
    display: flex;
    padding: 20px;
    position: relative;

    .left {
      flex-shrink: 1;
      display: flex;
      align-items: center;

      min-width: 45px;
      text-align: center;
    }

    .right {
      padding-left: 15px;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    .super-right {
      padding-left: 10px;
      display: flex;
      align-items: center;
      flex-shrink: 1;
    }

    .name {
      font-weight: 500;
    }
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .progress-bar {
    background: #87d13e;
    display: block;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
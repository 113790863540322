import moment from "moment";
const _ = require('lodash');

export default {
    sortByCreated(announcements) {
        let withCreatedMoments = _.map(announcements, i => {
            return {
                ...i,
                unix: moment(i.created).unix()
            }
        });
        return _.sortBy(withCreatedMoments, 'unix')
    },

    isAnnouncementUrgent(announcement) {
        return announcement['content']['$v']['urgent'] == true;
    }
}
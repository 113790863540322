import { render, staticRenderFns } from "./MatchingChatCard.vue?vue&type=template&id=d3ede8bc&scoped=true&"
import script from "./MatchingChatCard.vue?vue&type=script&lang=js&"
export * from "./MatchingChatCard.vue?vue&type=script&lang=js&"
import style0 from "./MatchingChatCard.vue?vue&type=style&index=0&id=d3ede8bc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3ede8bc",
  null
  
)

export default component.exports
import organisationHelpers from "../../helpers/organisations";
import organisations from "../../api/organisations";
import announcements from "../../api/announcements";
const _ = require('lodash');

export const state = {
    isLoading: false,
    announcements: [],
    serverErrors: [],

    searchQuery: ''
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_ANNOUNCEMENTS(state, announcements) {
        state.announcements = announcements
    },

    SET_SEARCH_QUERY(state, searchQuery) {
        state.searchQuery = searchQuery
    },

    START_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT(state, announcementId) {
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                return {
                    ...announcement,
                    isLoadingGroupMembers: true
                };
            }
            return announcement;
        })
    },

    STOP_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT(state, announcementId) {
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                return {
                    ...announcement,
                    isLoadingGroupMembers: false
                };
            }
            return announcement;
        })
    },

    SET_GROUP_MEMBERS_FOR_ANNOUNCEMENT(state, params) {
        let { announcementId, groupMembers } = params;
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                return {
                    ...announcement,
                    groupMembers: _.uniqBy(groupMembers, 'presenceId')
                };
            }
            return announcement;
        })
    },

    UPDATE_ADMIN_ANNOUNCEMENT_GROUP_MEMBER(state, params) {
        let { announcementId, groupMember } = params;
        state.announcements = _.map(state.announcements, announcement => {
            let announcementLocal = { ...announcement };

            if (announcementLocal.id == announcementId) {

                let isGroupMemberInGroupMembers = _.map(announcementLocal.groupMembers, 'presenceId').includes(groupMember.presenceId);
                if (isGroupMemberInGroupMembers) {
                    announcementLocal.groupMembers = _.map(announcementLocal.groupMembers, gm => {
                        if (gm.presenceId === groupMember.presenceId) {
                            return {
                                ...gm,
                                ...groupMember
                            };
                        }
                        return gm;
                    });
                } else {
                    if (announcementLocal.groupMembers && announcement.groupMembers.length) {
                        announcementLocal.groupMembers.push(groupMember);
                    } else {
                        announcementLocal.groupMembers = [groupMember];
                    }
                }

            }
            return announcementLocal;
        });
    },

    UPDATE_ADMIN_ANNOUNCEMENT_LAST_ACTIVITY(state, params) {
        let { announcementId, lastActivity } = params;
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                let announcementCopy = { ...announcement };
                announcementCopy.lastActivity = lastActivity;
                return announcementCopy;
            }
            return announcement;
        });
    },

    UPDATE_ADMIN_ANNOUNCEMENT_LAST_HUMAN_ACTIVITY(state, params) {
        let { announcementId, lastHumanActivity } = params;
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                let announcementCopy = { ...announcement };
                announcementCopy.lastHumanActivity = lastHumanActivity;
                return announcementCopy;
            }
            return announcement;
        });
    },

    UPDATE_ADMIN_ANNOUNCEMENT(state, newAnnouncement) {
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id === newAnnouncement.id) {
                return {
                    ...announcement,
                    ...newAnnouncement
                };
            }
            return announcement;
        });
    }
};

export const getters = {
    announcements: (state) => {
        return state.announcements
    },

    adminAnnouncements: (state) => {
        return state.announcements
    },

    announcementsToShow: (state) => {
        let searchQuery = state.searchQuery;
        let filtered = _.filter(state.announcements, announcement => {
            if (searchQuery.trim().length) {
                return announcement.displayName
                    .toLowerCase()
                    .includes(searchQuery.trim().toLowerCase());
            } else {
                return true;
            }
        });
        // return _.orderBy(filtered, ['lastActivity', announcement => announcement.displayName.toLowerCase()], 'desc', 'desc');
        return _.orderBy(filtered, 'lastActivity', 'desc');
    },

    isLoading: (state) => {
        return state.isLoading
    },

    searchQuery: (state) => {
        return state.searchQuery
    }
};

export const actions = {
    loadAnnouncements({ commit, dispatch, rootGetters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_ANNOUNCEMENTS', []);
            return false;
        }

        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        commit('START_LOADING');
        organisations.getAnnouncementsForOrg(tenantId, org.id).then(r => {
            commit('STOP_LOADING');
            commit('SET_ANNOUNCEMENTS', r.data);
            dispatch('loadGroupMembers');
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_ANNOUNCEMENTS', []);

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    async loadGroupMembers({ commit, getters, rootGetters }, announcementId = null) {
        _.each(getters.announcements, async (announcement) => {
            if (announcementId == null || announcement.id == announcementId) {
                let tenantId = rootGetters['admin/tenantId'];

                commit('START_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT', announcement.id);
                announcements.getGroupMembersForAnnouncement(tenantId, announcement.id).then(r => {
                    commit('STOP_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT', announcement.id);
                    commit('SET_GROUP_MEMBERS_FOR_ANNOUNCEMENT', {
                        announcementId: announcement.id,
                        groupMembers: r.data
                    });
                }).catch(e => {
                    console.log(e);
                });
            }
        });
    },

    setSearchQuery({ commit }, query) {
        commit('SET_SEARCH_QUERY', query);
    },

    updateAdminAnnouncementGroupMember({ commit }, params) {
        commit('UPDATE_ADMIN_ANNOUNCEMENT_GROUP_MEMBER', params);
    },

    updateAdminAnnouncementLastActivity({ commit }, params) {
        commit('UPDATE_ADMIN_ANNOUNCEMENT_LAST_ACTIVITY', params);
    },

    updateAdminAnnouncementLastHumanActivity({ commit }, params) {
        commit('UPDATE_ADMIN_ANNOUNCEMENT_LAST_HUMAN_ACTIVITY', params);
    },

    updateAdminAnnouncement({ commit }, announcement) {
        commit('UPDATE_ADMIN_ANNOUNCEMENT', announcement);
    },

    reloadAdminAnnouncementMembership({ dispatch }, announcementId) {
        dispatch('loadGroupMembers', announcementId);
    }
};
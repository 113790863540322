import subjectPreferences from "../../api/subject-preferences";

export const state = {
    preferences: [],
    isLoading: false,
    isSaving: false
};

export const mutations = {
    SET_PREFERENCES(state, preferences) {
        state.preferences = preferences;
    },

    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    START_SAVING(state) {
        state.isSaving = true;
    },

    STOP_SAVING(state) {
        state.isSaving = false;
    },

    SET_LAST_ORG_ID(state, orgId) {
        if (state.preferences) {
            state.preferences.lastOrg = orgId;
        } else {
            state.preferences = {
                lastOrg: orgId
            }
        }
    }
};

export const getters = {
    preferences: (state) => (state.preferences),
    isLoading: (state) => (state.isLoading),
    isSaving: (state) => (state.isSaving),
    lastOrgId: (state) => {
        if (state.preferences && state.preferences.lastOrg) {
            return state.preferences.lastOrg;
        }
        return null;
    }
};

export const actions = {
    loadSubjectPreferences({ commit }) {
        return new Promise((resolve, reject) => {
            commit('START_SAVING');

            subjectPreferences.getSubjectPreferencesForSelf().then(r => {
                commit('STOP_LOADING');
                commit('SET_PREFERENCES', r.data);
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING');
                console.log('Error loading subject preferences:');
                console.log(e);
                reject(e);
            })
        });
    },

    setLastOrgId({commit}, orgId) {
        commit('SET_LAST_ORG_ID', orgId);
        subjectPreferences.markSubjectPreferencesLastOrgForSelf(orgId);
    }
};
import axios from "axios"

export default {
    getAnnouncementPresetsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/AnnouncementPresets?deviceCaps=1');
    },

    addAnnouncement(tenantId, announcementRequest) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Announcements', announcementRequest);
    },

    confirmAnnouncement(tenantId, confirmAnnouncementRequest) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Announcements', confirmAnnouncementRequest);
    },

    getAnnouncement(tenantId, announcementId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Announcements/' + announcementId);
    },

    getGroupMembersForAnnouncement(tenantId, announcementId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Announcements/' + announcementId + '/Members');
    },

    getGroupMemberForAnnouncement(tenantId, announcementId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Announcements/' + announcementId + '/Members/' + presenceId);
    },

    setAnnouncementResponse(tenantId, announcementId, choiceId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Announcements/' + announcementId + '/' + choiceId + '/' + presenceId);
    },

    deleteAnnouncement(tenantId, announcementId, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Announcements/' + announcementId + '/Presences/' + assigningPresenceId);
    }
}
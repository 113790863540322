<template>
    <div class="organisation-personal-availability-inner">
        <!-- Is Loading -->
        <div class="is-loading" v-if="isLoading">
            <a-spin></a-spin>
        </div>
        <!-- / Is Loading -->

        <loading-screen :is-loading="isSaving"></loading-screen>

        <!-- Am A Responder-->
        <div class="am-a-responder" v-if="!notAResponder">

            <!-- Availability -->
            <div v-if="!isLoading">
                <a-alert v-if="amIAvailable" type="success" show-icon message="Currently available"></a-alert>
                <a-alert v-if="!amIAvailable" type="error" show-icon message="Currently not available"></a-alert>
            </div>
            <!-- / Availability -->

            <!-- Warning: If setting unavailable and somes skills only have one responder -->
            <div class="one-responder-warning" v-if="(!isLoading && amIAvailable && idsOfSkillsWithOnlyOneResponder.length)">
                <a-alert type="warning" show-icon :message="skillsWithOnlyOneResponderMessage"></a-alert>
            </div>
            <!-- / Warning -->

            <!-- Update Availability -->
            <div v-if="!isLoading" class="update-availability">

                <div class="title">
                    <b>Set {{ amIAvailable ? 'unavailable' : 'available' }} for the...</b>
                </div>

                <a-radio-group v-model="selectedNewAvailabilityValue">
                    <a-radio :style="radioStyle" :key="`availability-options-${i}`"
                        v-for="(option, i) in availabilityOptions" :value="option.value">
                        {{ option.name }}
                    </a-radio>
                </a-radio-group>

                <div class="update-availability-button">
                    <a-button :disabled="!selectedNewAvailabilityValue" @click.prevent="updateAvailability" type="primary">Update Availability</a-button>
                </div>

            </div>
            <!-- / Update Availability -->

        </div>
        <!-- / Am A Responder-->

        <!-- Not a responder -->
        <a-alert v-if="notAResponder" type="error" :message="('You are not a responder for ' + organisation.displayName)"></a-alert>
        <!-- / Not a responder -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require('lodash');
import presences from "../../api/presences";
import availability from "../../api/availability";
import organisations from "../../helpers/organisations";
import LoadingScreen from "../LoadingScreen.vue";

const AVAILABILITY_OPTIONS = [
    {
        name: "Next 30 minutes",
        value: 30
    },
    {
        name: "Next hour",
        value: 60
    },
    {
        name: "Next 2 hours",
        value: 120
    },
    {
        name: "Rest of the day",
        value: -1
    }
];

export default {
    props: ['organisation'],

    components: {LoadingScreen},

    data() {
        return {
            isLoading: false,

            isLoadingSkills: false,
            skills: [],

            isLoadingRespondersForAllSkills: false,
            skillResponders: {}, // keyed by skill id

            availabilityOptions: AVAILABILITY_OPTIONS,

            selectedNewAvailabilityValue: null,

            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },

            notAResponder: false,

            isSaving: false
        }
    },

    created() {
        this.loadAvailability();
    },

    computed: {
        ...mapGetters('missionControl', {
            presences: 'presences'
        }),

        timezone() {
            return "Europe/London";
        },

        responderPresences() {
            return _.filter(this.presences, presence => {
                return presence.isResponder
            });
        },

        responderPresenceForSelectedOrg() {
            return _.find(this.responderPresences, {
                ownerId: this.organisation.id
            });
        },

        tenantId() {
            if (this.organisation) {
                return organisations.getOrganisationTenantId(this.organisation);
            }
            return null;
        },

        idsOfSkillsWithOnlyOneResponder() {
            let ids = [];
            _.each(this.skillResponders, (responders, skillId) => {
                if (responders && responders.length == 1) {
                    ids.push(skillId);
                }
            });
            return ids;
        },

        skillsWithOnlyOneResponderMessage() {
            let skillsAtRisk = _.filter(this.skills, skill => {
                return this.idsOfSkillsWithOnlyOneResponder.includes(skill.id);
            });
            return 'The following response groups are in danger of having no responders: ' + skillsAtRisk.map(s => s.displayName).join(', ');
        },

        amIAvailable() {
            if (!this.skillResponders || this.isLoading) {
                return false;
            }

            let amIAvailable = true;
            _.each(this.skillResponders, (results) => {
                _.each(results, result => {
                    if (result.presence && result.presence.id && result.presence.id == this.responderPresenceForSelectedOrg.id) {
                        if (!result['available']) {
                            amIAvailable = false;
                        }
                    }
                });
            });
            return amIAvailable;
        }
    },

    watch: {
        organisation() {
            this.loadAvailability();
        }
    },

    methods: {
        async loadAvailability() {
            let vm = this;

            vm.notAResponder = false;
            if (!this.responderPresenceForSelectedOrg) {
                vm.notAResponder = true;
                return false;
            }

            vm.isLoading = true;

            await this.loadSkills();
            await this.loadRespondersForAllSkills();

            vm.isLoading = false;
        },

        async loadSkills() {
            let vm = this;

            vm.skills = [];
            vm.isLoadingSkills = true;

            return new Promise((resolve, reject) => {
                return presences.getSkillsForPresence(this.tenantId, this.responderPresenceForSelectedOrg.id).then(r => {
                    vm.isLoadingSkills = false;
                    vm.skills = r.data;
                    resolve(r.data);
                }).catch(e => {
                    console.log(e);
                    vm.$message.error('Error loading skills for presence');
                    reject(e);
                });
            });
        },

        async loadRespondersForAllSkills() {
            let vm = this;
            vm.skillResponders = {};
            vm.isLoadingRespondersForAllSkills = true;

            for (let i = 0; i < this.skills.length; i++) {
                let skill = this.skills[i];
                this.skillResponders[skill.id] = await availability.searchRespondersBySkillAndAvailableTime(this.tenantId, {
                    ResponderSearchType: 1,
                    SkillId: skill.id,
                    AvailableAt: window.moment().format() + ' ' + this.timezone,
                    OrgId: this.organisation.id
                }).then(r => r.data).then(r => _.map(r, 'result'));
                // }).then(r => r.data).then(r => _.map(r, 'result')).then(r => _.map(r, 'presence')).then(r => _.map(r, 'id'))
            }

            vm.isLoadingRespondersForAllSkills = false;
        },

        updateAvailability() {
            if (this.selectedNewAvailabilityValue == null) {
                return false;
            }

            let vm = this;
            vm.isSaving = true;

            // Determine moments
            let startMoment = window.moment();
            let endMoment;
            if (this.selectedNewAvailabilityValue == -1) {
                endMoment = startMoment.clone().startOf('day').add(1, 'days');
            } else {
                endMoment = startMoment.clone().add(this.selectedNewAvailabilityValue, 'minutes');
            }

            // Determine instants from moments
            let startInstant = startMoment.format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]");
            let endInstant = endMoment.format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]");

            let params = {
                OwnerId: this.organisation.id,
                PresenceId: this.responderPresenceForSelectedOrg.id,
                StartInstant: startInstant,
                EndInstant: endInstant,
                Available: !this.amIAvailable
            };

            availability.addOverride(this.tenantId, params).then(() => {
                vm.isSaving = false;
                vm.$message.success('Availability updated successfully');
                this.$emit('close-modal');
                // vm.loadAvailability();
            }).catch(e => {
                console.log(e);
                vm.$message.error('Error updating availability');
                vm.isSaving = false;
            })
        }
    }
}
</script>

<style lang="scss">
.organisation-personal-availability-inner {
    padding-top: 25px;

    .is-loading {
        text-align: center;
        padding-top: 30px;
    }

    .one-responder-warning {
        margin-top: 15px;
    }

    .update-availability {
        margin-top: 20px;

        .title {
            margin-bottom: 10px;
        }
    }

    .update-availability-button {
        margin-top: 25px;
    }
}
</style>
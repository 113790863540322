<template>
  <div class="landing">
    <div class="logo">
      <img src="/logo-new.png" alt="Logo" />
    </div>

    <!-- Not mobile -->
    <div class="bottom-wrapper">
      <a class="bottom-button" @click.prevent="login">Click Here To Login</a>
    </div>
    <!-- / Not mobile -->
  </div>
</template>

<script>
// const _ = require('lodash');
export default {
  name: "LoggedOut",
  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    // If signed in redirect to dashboard
    if (this.$oidc.isAuthenticated) {
      if (window.isLoggingOut) {
        window.isLoggingOut = false;
      } else {
        this.$router.push("/dashboard");
      }
    }
  },
  methods: {
    login() {
      this.$oidc.signIn();
      // this.$router.push("/dashboard");
    },
  },
};
</script>
<style scoped lang="scss">
.landing {
  // background: #5155ea;
  background: #3A0BA3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex-direction: column;

  .logo {
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .logo img {
    width: 80%;
    max-width: 400px;
  }

  .bottom-wrapper {
    margin-top: 70px;
  }

  .bottom-button {
    // margin-top: 70px;
    background: #fff;
    color: #000;
    padding: 20px 80px;
    font-weight: 500;
    font-size: 17px;
    border-radius: 50px;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    transition: background-color 0.2s ease;
    display: block;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;

    &:hover {
      background: #eee;
    }
  }
}
</style>

import self from '../../api/self';
import incidents from "../../api/incidents";
let _ = require('lodash');
import organisations from "../../helpers/organisations";
import incidentHelpers from '../../helpers/incidents';
import presences from '../../api/presences';
// import router from "../../router";

export const state = {
    isLoading: false,
    isPatching: false,
    serverErrors: [],
    incidents: [],

    searchQuery: ''
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    START_PATCHING(state) {
        state.isPatching = true
    },

    STOP_PATCHING(state) {
        state.isPatching = false
    },

    SET_INCIDENTS(state, incidents) {
        state.incidents = incidents
    },

    SET_ERRORS(state, serverErrors) {
        state.serverErrors = serverErrors
    },

    SET_SEARCH_QUERY(state, q) {
        state.searchQuery = q
    },

    START_LOADING_MEMBERSHIP_FOR_INCIDENT(state, incidentId) {
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    isLoadingMembership: true
                };
            }
            return incident;
        })
    },

    STOP_LOADING_MEMBERSHIP_FOR_INCIDENT(state, incidentId) {
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    isLoadingMembership: false
                };
            }
            return incident;
        })
    },

    SET_MEMBERSHIP_FOR_INCIDENT(state, params) {
        let { incidentId, membership } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    membership: _.uniqBy(membership, 'id')
                };
            }
            return incident;
        })
    },

    ADD_PRESENCE_TO_INCIDENT(state, params) {
        let { incidentId, presence } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id === incidentId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.membership.length) {
                    incidentCopy.membership.push(presence);
                } else {
                    incidentCopy.membership = [presence];
                }
                incidentCopy.membership = _.uniqBy(incidentCopy.membership, 'id');
                return incidentCopy;
            }
            return incident;
        });
    },

    START_LOADING_GROUP_MEMBERS_FOR_INCIDENT(state, incidentId) {
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    isLoadingGroupMembers: true
                };
            }
            return incident;
        })
    },

    STOP_LOADING_GROUP_MEMBERS_FOR_INCIDENT(state, incidentId) {
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    isLoadingGroupMembers: false
                };
            }
            return incident;
        })
    },

    SET_GROUP_MEMBERS_FOR_INCIDENT(state, params) {
        let { incidentId, groupMembers } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    groupMembers: groupMembers
                };
            }
            return incident;
        })
    },

    ADD_GROUP_MEMBER_TO_INCIDENT(state, params) {
        let { incidentId, groupMemberDetails } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id === incidentId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.groupMembers.length) {
                    incidentCopy.groupMembers.push(groupMemberDetails);
                } else {
                    incidentCopy.groupMembers = [groupMemberDetails];
                }
                incidentCopy.groupMembers = _.uniqBy(incidentCopy.groupMembers, 'presenceId');
                return incidentCopy;
            }
            return incident;
        });
    },

    UPDATE_INCIDENT_GROUP_MEMBER(state, params) {
        let { presenceId, groupId, groupMemberDetails } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == groupId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.groupMembers && incidentCopy.groupMembers.length) {
                    incidentCopy.groupMembers = _.map(incidentCopy.groupMembers, groupMember => {
                        if (groupMember.presenceId == presenceId) {
                            return {
                                ...groupMember,
                                ...groupMemberDetails
                            };
                        }
                        return groupMember;
                    });
                }
                return incidentCopy;
            }
            return incident;
        });
    },

    SET_PRESENCE_ACTIVE_FOR_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                if (!incidentCopy.activePresences) {
                    incidentCopy.activePresences = [presenceId];
                } else {
                    incidentCopy.activePresences.push(presenceId);
                    incidentCopy.activePresences = _.uniq(incidentCopy.activePresences);
                }
                return incidentCopy;
            }
            return incident;
        });
    },

    SET_PRESENCE_INACTIVE_FOR_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.activePresences) {
                    incidentCopy.activePresences = _.without(incidentCopy.activePresences, presenceId);
                } else {
                    incidentCopy.activePresences = [];
                }
                return incidentCopy;
            }
            return incident;
        });
    },

    UPDATE_INCIDENT_LAST_ACTIVITY(state, params) {
        let { incidentId, lastActivity } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                incidentCopy.lastActivity = lastActivity;
                return incidentCopy;
            }
            return incident;
        });
    },

    UPDATE_INCIDENT_LAST_HUMAN_ACTIVITY(state, params) {
        let { incidentId, lastHumanActivity } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                incidentCopy.lastHumanActivity = lastHumanActivity;
                return incidentCopy;
            }
            return incident;
        });
    },

    SET_FIRST_ARRIVE_FOR_INCIDENT_GROUP_MEMBER_IF_NOT_SET(state, params) {
        let { incidentId, presenceId } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.groupMembers && incidentCopy.groupMembers.length) {
                    incidentCopy.groupMembers = _.map(incidentCopy.groupMembers, groupMember => {
                        if (groupMember.presenceId == presenceId) {
                            let groupMemberCopy = { ...groupMember };
                            if (!groupMemberCopy.firstArrived) {
                                groupMemberCopy.firstArrived = window.moment()
                            }
                            return groupMemberCopy;
                        }
                        return groupMember;
                    });
                }
                return incidentCopy;
            }
            return incident;
        });
    },

    DISPOSE_OF_INCIDENT(state, incidentId) {
        state.incidents = _.filter(state.incidents, incident => {
            return incident.id !== incidentId
        });
    },

    UPDATE_INCIDENT_CONTENT(state, params) {
        let { incidentId, details } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };

                incidentCopy['content']['$v'] = {
                    ...incidentCopy['content']['$v'],
                    ...details
                };

                return incidentCopy;
            }
            return incident;
        });
    },

    REMOVE_GROUP_MEMBER_FROM_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.groupMembers) {
                    incidentCopy.groupMembers = _.filter(incidentCopy.groupMembers, groupMember => {
                        return groupMember.presenceId !== presenceId;
                    });
                }
                return incidentCopy;
            }
            return incident;
        });
    },

    REMOVE_PRESENCE_FROM_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                let incidentCopy = { ...incident };
                if (incidentCopy.membership) {
                    incidentCopy.membership = _.filter(incidentCopy.membership, member => {
                        return member.id !== presenceId;
                    });
                }
                return incidentCopy;
            }
            return incident;
        });
    },

    HANDLE_INCIDENT_UPDATED(state, params) {
        let { incidentId, newDetails } = params;
        state.incidents = _.map(state.incidents, incident => {
            if (incident.id == incidentId) {
                return {
                    ...incident,
                    ...newDetails
                };
            }
            return incident;
        });
    },

    PUSH_INCIDENT(state, incident) {
        state.incidents.push(incident);
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    incidents: (state) => {
        return state.incidents
    },

    incidentsOrdered: (state, getters) => {
        let incidents = getters.incidents;

        let liveIncidents = [];
        let urgentIncidents = [];
        let demoIncidents = [];
        let drillIncidents = [];
        let otherIncidents = [];

        _.each(incidents, incident => {
            let isIncidentLive = incidentHelpers.isIncidentLive(incident);
            let isIncidentUrgent = incidentHelpers.isIncidentUrgent(incident);
            let isIncidentDemo = incidentHelpers.isIncidentDemo(incident);
            let isIncidentDrill = incidentHelpers.isIncidentDrill(incident);

            if (isIncidentLive) {
                liveIncidents.push(incident);
            } else if (isIncidentUrgent) {
                urgentIncidents.push(incident);
            } else if (isIncidentDemo) {
                demoIncidents.push(incident);
            } else if (isIncidentDrill) {
                drillIncidents.push(incident);
            } else {
                otherIncidents.push(incident);
            }
        });

        let liveIncidentsSorted = incidentHelpers.sortByLastActivity(liveIncidents);
        let urgentIncidentsSorted = incidentHelpers.sortByLastActivity(urgentIncidents);
        let demoIncidentsSorted = incidentHelpers.sortByLastActivity(demoIncidents);
        let drillIncidentsSorted = incidentHelpers.sortByLastActivity(drillIncidents);
        let otherIncidentsSorted = incidentHelpers.sortByLastActivity(otherIncidents);

        let sorted = _.concat(
            liveIncidentsSorted.reverse(),
            urgentIncidentsSorted.reverse(),
            demoIncidentsSorted.reverse(),
            drillIncidentsSorted.reverse(),
            otherIncidentsSorted.reverse()
        );

        return sorted;
    },

    incidentsToShow: (state) => {
        let searchQuery = state.searchQuery;
        return _.filter(state.incidents, incident => {
            if (searchQuery.trim().length) {
                let displayNameMatches = false;
                if (incident.displayName) {
                    displayNameMatches = incident.displayName
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase());
                }

                let membersMatches = false;
                let membership = incident.membership ? incident.membership : [];
                _.each(membership, (m) => {
                    let memberDisplayNameMatches = m.displayName
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase());
                    if (memberDisplayNameMatches) {
                        membersMatches = true;
                    }
                });

                if (displayNameMatches || membersMatches) {
                    return true;
                }
                return false;
            }
            return true;
        })
    },

    pinnedIncidentsToShow: (state, getters, rootState, rootGetters) => {
        return _.filter(getters.incidentsToShow, incident => {
            return rootGetters['pinned/pinnedIds'].includes(incident.id);
        });
    },

    unpinnedIncidentsToShow: (state, getters, rootState, rootGetters) => {
        return _.filter(getters.incidentsToShow, incident => {
            return !rootGetters['pinned/pinnedIds'].includes(incident.id);
        });
    },

    pinnedIncidentsToShowOrdered: (state, getters) => {
        let incidents = getters.pinnedIncidentsToShow;

        let liveIncidents = [];
        let urgentIncidents = [];
        let demoIncidents = [];
        let drillIncidents = [];
        let otherIncidents = [];

        _.each(incidents, incident => {
            let isIncidentLive = incidentHelpers.isIncidentLive(incident);
            let isIncidentUrgent = incidentHelpers.isIncidentUrgent(incident);
            let isIncidentDemo = incidentHelpers.isIncidentDemo(incident);
            let isIncidentDrill = incidentHelpers.isIncidentDrill(incident);

            if (isIncidentLive) {
                liveIncidents.push(incident);
            } else if (isIncidentUrgent) {
                urgentIncidents.push(incident);
            } else if (isIncidentDemo) {
                demoIncidents.push(incident);
            } else if (isIncidentDrill) {
                drillIncidents.push(incident);
            } else {
                otherIncidents.push(incident);
            }
        });

        let liveIncidentsSorted = incidentHelpers.sortByLastActivity(liveIncidents);
        let urgentIncidentsSorted = incidentHelpers.sortByLastActivity(urgentIncidents);
        let demoIncidentsSorted = incidentHelpers.sortByLastActivity(demoIncidents);
        let drillIncidentsSorted = incidentHelpers.sortByLastActivity(drillIncidents);
        let otherIncidentsSorted = incidentHelpers.sortByLastActivity(otherIncidents);

        let sorted = _.concat(
            liveIncidentsSorted.reverse(),
            urgentIncidentsSorted.reverse(),
            demoIncidentsSorted.reverse(),
            drillIncidentsSorted.reverse(),
            otherIncidentsSorted.reverse()
        );

        return sorted;
    },

    unpinnedIncidentsToShowOrdered: (state, getters) => {
        let incidents = getters.unpinnedIncidentsToShow;

        let liveIncidents = [];
        let urgentIncidents = [];
        let demoIncidents = [];
        let drillIncidents = [];
        let otherIncidents = [];

        _.each(incidents, incident => {
            let isIncidentLive = incidentHelpers.isIncidentLive(incident);
            let isIncidentUrgent = incidentHelpers.isIncidentUrgent(incident);
            let isIncidentDemo = incidentHelpers.isIncidentDemo(incident);
            let isIncidentDrill = incidentHelpers.isIncidentDrill(incident);

            if (isIncidentLive) {
                liveIncidents.push(incident);
            } else if (isIncidentUrgent) {
                urgentIncidents.push(incident);
            } else if (isIncidentDemo) {
                demoIncidents.push(incident);
            } else if (isIncidentDrill) {
                drillIncidents.push(incident);
            } else {
                otherIncidents.push(incident);
            }
        });

        let liveIncidentsSorted = incidentHelpers.sortByLastActivity(liveIncidents);
        let urgentIncidentsSorted = incidentHelpers.sortByLastActivity(urgentIncidents);
        let demoIncidentsSorted = incidentHelpers.sortByLastActivity(demoIncidents);
        let drillIncidentsSorted = incidentHelpers.sortByLastActivity(drillIncidents);
        let otherIncidentsSorted = incidentHelpers.sortByLastActivity(otherIncidents);

        let sorted = _.concat(
            liveIncidentsSorted.reverse(),
            urgentIncidentsSorted.reverse(),
            demoIncidentsSorted.reverse(),
            drillIncidentsSorted.reverse(),
            otherIncidentsSorted.reverse()
        );

        return sorted;
    },

    incidentsToShowOrdered: (state, getters) => {
        return _.flattenDeep([getters.pinnedIncidentsToShowOrdered, getters.unpinnedIncidentsToShowOrdered]);
    },

    unreadIncidentCount: (state, getters) => {
        return getters.unreadIncidents.length
    },

    unreadIncidents: (state, getters, rootState, rootGetters) => {
        let presences = rootGetters['missionControl/presences'];
        let myPresenceIds = _.map(presences, 'id');
        return _.filter(state.incidents, incident => {

            // Get my group member
            let myGroupMember = _.find(incident.groupMembers, groupMember => {
                return myPresenceIds.includes(groupMember.presenceId)
            });

            // If has group member...
            if (myGroupMember) {
                if (!myGroupMember.lastReadPointer) {
                    return true;
                }
                let lastReadMoment = window.moment(myGroupMember.lastReadPointer);
                let lastActivityMoment = window.moment(incident.lastActivity);

                return lastActivityMoment.isAfter(lastReadMoment);
            }

            return false;
        })
    },

    searchQuery: (state) => {
        return state.searchQuery
    }
};

export const actions = {
    loadIncidents({ commit, dispatch }) {
        commit('START_LOADING');

        self.getIncidents().then(r => {
            commit('STOP_LOADING');
            commit('SET_INCIDENTS', r.data);

            // These were commented out before - performance?
            dispatch('loadGroupMembers');
            // dispatch('loadMemberships');
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_INCIDENTS', []);
            // this._vm.$message.error('Error loading incidents');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    async loadGroupMembers({ commit, getters, dispatch, rootGetters }, incidentId = null) {
        // If in admin incident viewer mode, reload group members in admin IV
        let adminIncidentViewerIncidentId = rootGetters['adminIncidentViewer/incidentId'];
        if (adminIncidentViewerIncidentId && adminIncidentViewerIncidentId == incidentId) {
            dispatch('adminIncidentViewer/loadGroupMembers', {}, { root: true });
        }

        // let vm = this;
        _.each(getters.incidents, async (incident) => {
            if (incidentId == null || incident.id == incidentId) {
                let organisation = await dispatch('missionControl/getOrganisationById', incident.ownerId, { root: true });
                let tenantId = organisations.getOrganisationTenantId(organisation)

                commit('START_LOADING_GROUP_MEMBERS_FOR_INCIDENT', incident.id);
                incidents.getGroupMembersForIncident(tenantId, incident.id).then(r => {
                    let groupMembers = r.data;
                    commit('STOP_LOADING_GROUP_MEMBERS_FOR_INCIDENT', incident.id);
                    commit('SET_GROUP_MEMBERS_FOR_INCIDENT', {
                        incidentId: incident.id,
                        groupMembers: groupMembers
                    });
                    _.each(groupMembers, groupMember => {
                        if (groupMember.isActive) {
                            dispatch('setPresenceActiveForIncident', {
                                incidentId: incident.id,
                                presenceId: groupMember.presenceId
                            });
                        }
                    });
                    dispatch('resolveMembershipForIncident', incident.id);
                }).catch(e => {
                    console.log(e);
                    // vm._vm.$message.error('Error loading group member for incident');
                })
            }
        });
    },

    async resolveMembershipForIncident({ commit, getters, rootGetters, dispatch }, incidentId) {
        console.log('Resolving membership for incident: ' + incidentId);

        // If in admin incident viewer mode, reload group members in admin IV
        let adminIncidentViewerIncidentId = rootGetters['adminIncidentViewer/incidentId'];
        if (adminIncidentViewerIncidentId && adminIncidentViewerIncidentId == incidentId) {
            // dispatch('adminIncidentViewer/loadMembership', {}, { root: true });
            dispatch('adminIncidentViewer/resolveMembership', {}, { root: true });
        }

        commit('START_LOADING_MEMBERSHIP_FOR_INCIDENT', incidentId);
        let incident = _.find(getters.incidents, incident => {
            return incident.id == incidentId;
        });
     
        if (incident && incident.groupMembers) {
            let organisation = await dispatch('missionControl/getOrganisationById', incident.ownerId, { root: true });
            let tenantId = organisations.getOrganisationTenantId(organisation)

            let presenceIds = _.map(incident.groupMembers, 'presenceId');
            // let membership = _.filter(rootGetters['allPresences/presences'], presence => {
            //     return presenceIds.includes(presence.id);
            // });

            let membership = await dispatch('allPresences/resolvePresences', {
                tenantId: tenantId,
                presenceIds: presenceIds
            }, { root: true });

            commit('SET_MEMBERSHIP_FOR_INCIDENT', {
                incidentId: incidentId,
                membership: membership
            });
        }
        commit('STOP_LOADING_MEMBERSHIP_FOR_INCIDENT', incidentId);
    },

    async loadMemberships({ commit, getters, dispatch, rootGetters }, incidentId = null) {
        // If in admin incident viewer mode, reload group members in admin IV
        let adminIncidentViewerIncidentId = rootGetters['adminIncidentViewer/incidentId'];
        if (adminIncidentViewerIncidentId && adminIncidentViewerIncidentId == incidentId) {
            dispatch('adminIncidentViewer/loadMembership', {}, { root: true });
        }

        // let vm = this;
        _.each(getters.incidents, async (incident) => {
            if (incidentId == null || incident.id == incidentId) {
                let organisation = await dispatch('missionControl/getOrganisationById', incident.ownerId, { root: true });
                let tenantId = organisations.getOrganisationTenantId(organisation)

                commit('START_LOADING_MEMBERSHIP_FOR_INCIDENT', incident.id);
                incidents.getMembershipForIncident(tenantId, incident.id).then(r => {
                    commit('STOP_LOADING_MEMBERSHIP_FOR_INCIDENT', incident.id);
                    commit('SET_MEMBERSHIP_FOR_INCIDENT', {
                        incidentId: incident.id,
                        membership: r.data
                    });
                }).catch(e => {
                    console.log(e);
                    // vm._vm.$message.error('Error loading memberships for incident');
                })
            }
        });
    },

    setSearchQuery({ commit }, query) {
        commit('SET_SEARCH_QUERY', query);
    },

    updateIncidentGroupMember({ commit }, params) {
        commit('UPDATE_INCIDENT_GROUP_MEMBER', params);
    },

    updateIncidentLastActivity({ commit }, params) {
        commit('UPDATE_INCIDENT_LAST_ACTIVITY', params);
    },

    updateIncidentLastHumanActivity({ commit }, params) {
        commit('UPDATE_INCIDENT_LAST_HUMAN_ACTIVITY', params);
    },

    handleGroupMemberAddedToIncident({ commit, dispatch, getters }, params) {
        let incident = _.find(getters.incidents, incident => {
            return incident.id == params.incidentId;
        });
        if (incident) {
            let shouldAdd = true;
            // We don't want to add a group member if they already exist
            if (incident.groupMembers) {
                let groupMember = _.find(incident.groupMembers, gm => {
                    return gm.presenceId == params.groupMemberDetails.presenceId;
                });
                if (groupMember) {
                    shouldAdd = false;
                }
            }

            if (shouldAdd) {
                commit('ADD_GROUP_MEMBER_TO_INCIDENT', params);
                dispatch('fetchAndAddMembershipForIncident', {
                    incidentId: params.incidentId,
                    tenantId: params.tenantId,
                    presenceId: params.groupMemberDetails.presenceId
                });
            }
        }
    },

    fetchAndAddMembershipForIncident({ commit }, params) {
        let { incidentId, tenantId, presenceId } = params;
        commit('START_PATCHING');
        presences.getPresence(tenantId, presenceId).then(r => {
            commit('STOP_PATCHING');
            commit('ADD_PRESENCE_TO_INCIDENT', {
                incidentId: incidentId,
                presence: r.data
            });
        }).catch(e => {
            commit('STOP_PATCHING');
            console.log(e);
            this._vm.$message.error('Error fetching membership for incident');
        });
    },

    setPresenceActiveForIncident({ commit }, params) {
        commit('SET_PRESENCE_ACTIVE_FOR_INCIDENT', params);
    },

    setPresenceInactiveForIncident({ commit }, params) {
        commit('SET_PRESENCE_INACTIVE_FOR_INCIDENT', params);
    },

    setFirstArriveForIncidentGroupMemberIfNotSet({ commit }, params) {
        commit('SET_FIRST_ARRIVE_FOR_INCIDENT_GROUP_MEMBER_IF_NOT_SET', params);
    },

    disposeOfIncidents({ commit }, incidentIds) {
        _.each(incidentIds, incidentId => {
            commit('DISPOSE_OF_INCIDENT', incidentId);
        })
    },

    disposeOfIncident({ commit }, incidentId) {
        commit('DISPOSE_OF_INCIDENT', incidentId);
    },

    updateIncidentContent({ commit }, params) {
        commit('UPDATE_INCIDENT_CONTENT', params);
    },

    removeGroupMemberFromIncident({ commit }, params) {
        commit('REMOVE_GROUP_MEMBER_FROM_INCIDENT', params);
        commit('REMOVE_PRESENCE_FROM_INCIDENT', params);
    },

    handleIncidentUpdated({ commit }, params) {
        commit('HANDLE_INCIDENT_UPDATED', params);
    },

    reloadIncidentMembership({ dispatch }, params) {
        let { incidentId } = params;
        dispatch('loadGroupMembers', incidentId);
        // dispatch('loadMemberships', incidentId);
    },

    pushIncident({ commit, dispatch }, incident) {
        commit('PUSH_INCIDENT', {
            ...incident,
            isLoadingGroupMembers: false,
            isLoadingMembership: false,
            groupMembers: [],
            membership: []
        });
        dispatch('loadGroupMembers', incident.id);
        // dispatch('loadMemberships', incident.id);
    },

    async fetchAndAddIncident({ commit, dispatch, getters }, params) {
        let { tenantId, incidentId } = params;
        // let vm = this;
        commit('START_PATCHING');
        incidents.getIncident(tenantId, incidentId).then(r => {
            commit('STOP_PATCHING');

            if (_.find(getters.incidents, { id: incidentId })) {
                // This has been added by something else in the time that we've been fetching
                // Let's not add it
                return false;
            }

            let incident = r.data;
            dispatch('pushIncident', incident);
            dispatch('alertNewIncidentOnceMembershipResolved', {
                tenantId: tenantId,
                incident: incident
            });
        }).catch(e => {
            console.log(e);
            commit('STOP_PATCHING');
            this._vm.$message.error('Error fetching incident');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    resolveIncidentOnceMembershipLoaded({ getters }, incidentId) {
        console.log('Starting: resolveIncidentOnceMembershipLoaded');
        console.log('incidentId: ' + incidentId);
        return new Promise((resolve, reject) => {
            let numberOfTries = 0;
            function getIncidentWithMembership() {
                let incident = _.find(getters['incidents'], { id: incidentId });
                if (!incident.isLoadingMembership && incident.membership && incident.membership.length) {
                    resolve(incident);
                } else {
                    numberOfTries + 1;
                    if (numberOfTries > 10) {
                        reject('Membership not loaded');
                    } else {
                        window.setTimeout(getIncidentWithMembership, 500);
                    }
                }
            }
            getIncidentWithMembership();
        });
    },

    alertNewIncidentOnceMembershipResolved({ dispatch }, params) {
        let { tenantId, incident } = params;
        dispatch('resolveIncidentOnceMembershipLoaded', incident.id).then(incidentWithMembership => {
            let createdBy = _.find(incidentWithMembership.membership, { id: incident.createdBy });
            dispatch('alertNewIncident', {
                tenantId: tenantId,
                incident: incident,
                createdBy: (createdBy && createdBy.displayName) ? createdBy.displayName : 'Unknown',
                createdByPresenceId: incident.createdBy
            });
        }).catch(e => {
            console.log(e);
            dispatch('alertNewIncident', {
                tenantId: tenantId,
                incident: incident,
                createdBy: 'Unknown',
                createdByPresenceId: null
            });
        });
    },

    async alertNewIncident({ dispatch }, params) {
        let { incident, createdBy, tenantId, createdByPresenceId } = params;
        // let vm = this;

        let isSos = incident.content['$v']['incidentPurpose'] == 1;
        let body = 'New ' + (isSos ? 'SOS' : 'incident') + ' created by ' + createdBy;

        // Get icon
        let icon = null;
        if (createdByPresenceId) {
            try {
                let getPresenceThumbProtectedUrlResponse = await presences.getPresenceThumbProtectedUrl(tenantId, createdByPresenceId);
                if (getPresenceThumbProtectedUrlResponse && getPresenceThumbProtectedUrlResponse.data && getPresenceThumbProtectedUrlResponse.data.url) {
                    icon = getPresenceThumbProtectedUrlResponse.data.url;
                }
            } catch (e) {
                console.log(e);
            }
        }

        dispatch('notifier/globalNotify', {
            title: incident.displayName,
            body: body,
            tag: incident.id,
            icon: icon,
            navigateTo: '/incidents/' + tenantId + '/' + incident.id
        }, { root: true });

        // vm._vm.$notification.show(incident.displayName, {
        //     body: body,
        //     tag: incident.id,
        //     icon: icon
        // }, {
        //     onclick: function () {
        //         router.push('/incidents/' + tenantId + '/' + incident.id);
        //     }
        // })
    }
};
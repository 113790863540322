<template>
  <div>
    <!-- Loading -->
    <div v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Checklist -->
    <div v-if="!isLoading">
      <!-- Task -->
      <div v-for="task in tasksOrdered" :key="task.id" class="task">
        <b>{{ task['displayName'] }}</b>
        <div>
          {{ task['description'] }}
        </div>
      </div>
      <!-- / Task -->
    </div>
    <!-- / Checklist -->
  </div>
</template>

<script>
import checklists from "../../../../api/checklists";
const _ = require('lodash');
export default {
  props: ["details", "tenantId"],
  computed: {
    checklistId() {
      return this.details.checklistId;
    },

    isLoading() {
      return (this.isLoadingChecklist || this.isLoadingChecklistTasks);
    },

    tasksOrdered() {
      return _.orderBy(this.checklistTasks, 'order')
    }
  },
  data() {
    return {
      isLoadingChecklist: false,
      checklist: null,

      isLoadingChecklistTasks: false,
      checklistTasks: []
    };
  },
  created() {
    this.loadChecklist();
    this.loadChecklistTasks();
  },
  methods: {
    loadChecklist() {
      let vm = this;
      vm.isLoadingChecklist = true;
      checklists
        .getChecklist(this.tenantId, this.checklistId)
        .then((r) => {
          vm.isLoadingChecklist = false;
          vm.checklist = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingChecklist = false;
          // vm.$message.error("Error loading checklist");
        });
    },

    loadChecklistTasks() {
      let vm = this;
      vm.isLoadingChecklistTasks = true;
      checklists
        .getChecklistTasks(this.tenantId, this.checklistId)
        .then((r) => {
          vm.isLoadingChecklistTasks = false;
          vm.checklistTasks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingChecklistTasks = false;
          // vm.$message.error("Error loading checklist tasks");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.task {
  background: #fff;
  border-radius: 6px;
  margin-bottom: 15px; 
  padding: 15px;
}
</style>
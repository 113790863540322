import chats from "../../api/chats";
const _ = require('lodash')
import axios from 'axios'
import eventBus from '../../event-bus';

export const state = {
    serverErrors: [],
    isLoading: false,
    isSaving: false,
    tenantId: null,
    chat: null,

    isLoadingMembership: false,
    membership: null,

    isLoadingGroupMembers: false,
    groupMembers: [],

    groupMemberDetails: {},

    activePresences: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    START_SAVING(state) {
        state.isSaving = true
    },

    STOP_SAVING(state) {
        state.isSaving = false
    },

    SET_CHAT(state, chat) {
        state.chat = chat
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    },

    START_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = true
    },

    STOP_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = false
    },

    SET_MEMBERSHIP(state, membership) {
        state.membership = membership
    },

    START_LOADING_GROUP_MEMBERS(state) {
        state.isLoadingGroupMembers = true
    },

    STOP_LOADING_GROUP_MEMBERS(state) {
        state.isLoadingGroupMembers = false
    },

    SET_GROUP_MEMBERS(state, groupMembers) {
        state.groupMembers = groupMembers
    },

    SET_GROUP_MEMBER_DETAILS(state, groupMemberDetails) {
        state.groupMemberDetails = groupMemberDetails
    },

    SET_DETAILS_FOR_GROUP_MEMBER(state, params) {
        let { presenceId, details } = params
        state.groupMemberDetails[presenceId] = details
    },

    SET_ACTIVE_PRESENCES(state, activePresences) {
        state.activePresences = activePresences;
    },

    SET_PRESENCE_ACTIVE(state, presenceId) {
        state.activePresences.push(presenceId);
        state.activePresences = _.uniq(state.activePresences);
    },

    SET_PRESENCE_INACTIVE(state, presenceId) {
        state.activePresences = _.without(state.activePresences, presenceId);
    },

    CHAT_NAME_UPDATED(state, newName) {
        state.chat.displayName = newName;
    },

    UPDATE_CHAT_GROUP_MEMBER(state, groupMemberDetails) {
        state.groupMembers = _.map(state.groupMembers, groupMember => {
            if (groupMember.presenceId == groupMemberDetails.presenceId) {
                return groupMemberDetails;
            } else {
                return groupMember;
            }
        })
    }
};

export const getters = {
    isLoading: (state) => {
        return (state.isLoading || state.isLoadingMembership || state.isLoadingGroupMembers)
    },

    isLoadingMembership: (state) => {
        return state.isLoadingMembership
    },

    isSaving: (state) => {
        return state.isSaving
    },

    chat: (state) => {
        return state.chat
    },

    tenantId: (state) => {
        return state.tenantId
    },

    membership: (state) => {
        return state.membership
    },

    activeMembers: (state, getters) => {
        return _.filter(state.membership, m => {
            if (getters.myPresenceId === m.id) {
                return true;
            }
            return _.includes(state.activePresences, m.id);
        });
    },

    inactiveMembers: (state, getters) => {
        return _.filter(state.membership, m => {
            if (getters.myPresenceId === m.id) {
                return false;
            }
            return !_.includes(state.activePresences, m.id);
        });
    },

    groupMembers: (state) => {
        return state.groupMembers
    },

    groupMemberDetails: (state) => {
        return state.groupMemberDetails
    },

    myPresenceId: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInChat = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInChat);
        if (intersection.length) {
            return _.first(intersection)
        }
        return null;
    },

    activePresences: (state) => {
        return state.activePresences
    },

    isManagedGroup: (state) => {
        return state.chat ? state.chat.flags == 1 : false;
    },

    canEditChat: (state, getters) => {
        return getters.isManagedGroup ? false : true;
    }
};

export const actions = {
    reset({ commit }) {
        commit('SET_CHAT', null);
        commit('SET_MEMBERSHIP', null);
        commit('SET_GROUP_MEMBERS', []);
        commit('SET_GROUP_MEMBER_DETAILS', {});
    },

    setChat({ commit, dispatch, getters }, chat) {
        dispatch('reset');
        commit('SET_CHAT', chat);
        dispatch('timeline/reset', {}, { root: true });
        dispatch('loadMembership');
        dispatch('loadGroupMembers');
        dispatch('timeline/loadTimeline', {
            model: 'chat',
            tenantId: getters.tenantId,
            guid: getters.chat.id,
            ownerType: 2,
            ownerId: getters.chat.id,
            organisationId: getters.chat.ownerId,
            behaviour: 0
        }, { root: true });
        dispatch('timeline/loadQuickMessages', {}, { root: true });
        dispatch('metadata/setMetadataIds', getters.chat.metadataIds ? getters.chat.metadataIds : [], { root: true });
        eventBus.$emit('chatLoaded');
    },

    loadChat({ commit, dispatch, getters }, params) {
        let { id, tenantId } = params
        commit('START_LOADING');
        commit('SET_TENANT_ID', tenantId);
        dispatch('reset');
        dispatch('timeline/reset', {}, { root: true });
        chats.getChat(tenantId, id).then(r => {
            commit('STOP_LOADING');
            commit('SET_CHAT', r.data)
            dispatch('loadMembership');
            dispatch('loadGroupMembers');
            dispatch('timeline/loadTimeline', {
                model: 'timeline',
                tenantId: getters.tenantId,
                guid: getters.chat.id,
                ownerType: 2,
                ownerId: getters.chat.id,
                organisationId: getters.chat.ownerId,
                behaviour: 0
            }, { root: true });
            dispatch('timeline/loadQuickMessages', {}, { root: true });
            dispatch('metadata/setMetadataIds', getters.chat.metadataIds ? getters.chat.metadataIds : [], { root: true });
            eventBus.$emit('chatLoaded');
        }).catch(e => {
            commit('STOP_LOADING');
            this._vm.$message.error('Error loading chat');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadMembership({ commit, getters }) {
        let tenantId = getters.tenantId;
        let chatId = getters.chat.id
        commit('START_LOADING_MEMBERSHIP');
        commit('SET_MEMBERSHIP', null);
        chats.getMembershipForChat(tenantId, chatId).then(r => {
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', r.data)
        }).catch(e => {
            commit('STOP_LOADING');
            this._vm.$message.error('Error loading membership');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadGroupMembers({ commit, getters }) {
        let tenantId = getters.tenantId;
        let chatId = getters.chat.id
        commit('START_LOADING_GROUP_MEMBERS');
        commit('SET_GROUP_MEMBERS', null);
        chats.getGroupMembersForChat(tenantId, chatId).then(r => {
            commit('STOP_LOADING_GROUP_MEMBERS');
            commit('SET_GROUP_MEMBERS', r.data);
            let activePresences = _.map(_.filter(r.data, m => (m.isActive === true)), 'presenceId');
            commit('SET_ACTIVE_PRESENCES', activePresences);
            // dispatch('loadAllGroupMemberDetails');
        }).catch(e => {
            commit('STOP_LOADING');
            this._vm.$message.error('Error loading group members');
            console.log(e);
            let errors;
            if (e.response && e.response.data && typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    handleParticipantAdded({ dispatch }, groupMemberDetails) {
        console.log('Group member added:');
        console.log(groupMemberDetails);
        dispatch('loadMembership', { silent: true });
        dispatch('loadGroupMembers', { silent: true });
    },

    loadAllGroupMemberDetails({ getters, commit }) {
        let tenantId = getters.tenantId;
        let chatId = getters.chat.id

        let groupMembers = getters.groupMembers
        let groupMemberPresenceIds = _.map(groupMembers, 'presenceId')

        chats.getAllGroupMemberDetailsForChat(tenantId, chatId, groupMemberPresenceIds).then(axios.spread((...responses) => {
            _.each(responses, (response, i) => {
                console.log(response);
                console.log(i);
            })
        })).catch(e => {
            this._vm.$message.error('Error loading group member details');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    setActivePresences({ commit }, activePresences) {
        commit('SET_ACTIVE_PRESENCES', activePresences);
    },

    setPresenceActive({ commit }, presenceId) {
        commit('SET_PRESENCE_ACTIVE', presenceId);
    },

    setPresenceInactive({ commit }, presenceId) {
        commit('SET_PRESENCE_INACTIVE', presenceId);
    },

    chatNameUpdated({ commit }, newName) {
        commit('CHAT_NAME_UPDATED', newName);
    },

    updateChatGroupMember({ commit }, groupMemberDetails) {
        commit('UPDATE_CHAT_GROUP_MEMBER', groupMemberDetails);
    }
};
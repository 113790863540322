import axios from 'axios';

export default {
    getUserImportsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/UserImport');
    },

    addUserImport(tenantId, userImportObjectFile, userImportUploadFile) {
        let userImportObjectFileRenamed = new File([userImportObjectFile], 'userimportobject', {
            type: 'application/json',
            lastModified: userImportObjectFile.lastModified
        });

        let userImportUploadFileRenamed = new File([userImportUploadFile], 'userimportupload', {
            type: userImportUploadFile.type
            // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const formData = new FormData();
        formData.append('file', userImportObjectFileRenamed);
        formData.append('file', userImportUploadFileRenamed); 

        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/UserImport',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }
}
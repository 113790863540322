<template>
    <div class="tsos-home-header">

        <img src="@/assets/tsos-widget/logo-full.png" class="top-logo" alt="">

        <h3 style="margin-bottom: 0px; padding-bottom: 0px;">
            What would you like help with today?
        </h3>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.tsos-home-header {
    padding-top: 35px;
    padding-bottom: 35px;
    // padding-left: 25px;
    // padding-right: 25px;

    padding-left: 35px;
    padding-right: 35px;

    .top-logo {
        width: 110px;
        height: auto;
        margin-bottom: 60px;
    }

    h3 {
        // letter-spacing: -0.5px;
        letter-spacing: -0.4px;
        // font-size: 18px;

        // font-size: 22px;
        font-size: 25px;
        line-height: 1.3;
    }
}
</style>
import apiClients from "../../api/api-clients";
import apiGrants from "../../api/api-grants";
import organisationHelpers from "../../helpers/organisations";
const _ = require('lodash');

export const state = {
    isLoadingApiClients: false,
    apiClients: [],
    isLoadingApiGrants: false,
    apiGrants: [],
    serverErrors: [],

    apiClientsAddedLocally: [],

    isLoadingApiGrantPermissionOptions: false,
    apiGrantPermissionOptions: []
};

export const mutations = {
    START_LOADING_API_CLIENTS(state) {
        state.isLoadingApiClients = true
    },

    STOP_LOADING_API_CLIENTS(state) {
        state.isLoadingApiClients = false
    },

    START_LOADING_API_GRANTS(state) {
        state.isLoadingApiGrants = true
    },

    STOP_LOADING_API_GRANTS(state) {
        state.isLoadingApiGrants = false
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_API_CLIENTS(state, apiClients) {
        state.apiClients = apiClients
    },

    SET_API_GRANTS(state, apiGrants) {
        state.apiGrants = apiGrants
    },

    PUSH_TO_API_CLIENTS_ADDED_LOCALLY(state, apiClient) {
        state.apiClientsAddedLocally.push(apiClient);
    },

    REMOVE_API_CLIENT_ADDED_LOCALLY_BY_ID(state, apiClientId) {
        state.apiClientsAddedLocally = _.filter(state.apiClientsAddedLocally, apiClient => {
            return apiClient.id !== apiClientId
        });
    },

    START_LOADING_API_GRANT_PERMISSION_OPTIONS(state) {
        state.isLoadingApiGrantPermissionOptions = true;
    },

    STOP_LOADING_API_GRANT_PERMISSION_OPTIONS(state) {
        state.isLoadingApiGrantPermissionOptions = false;
    },

    SET_API_GRANT_PERMISSION_OPTIONS(state, options) {
        state.apiGrantPermissionOptions = options;
    }
};

export const getters = {
    apiGrants: (state) => {
        return state.apiGrants
    },

    apiClients: (state, getters, rootState, rootGetters) => {
        let selectedOrg = rootGetters['admin/selectedOrganisation']
        let apiClients = [];
        if (selectedOrg) {
            apiClients = state.apiClients
        }
        return apiClients;
    },

    grantedApiClients: (state, getters, rootState, rootGetters) => {
        let apiClients = _.map(getters.apiGrants, apiGrant => {
            return _.find(getters.apiClients, { id: apiGrant.apiClientId })
        });

        let selectedOrg = rootGetters['admin/selectedOrganisation']

        if (selectedOrg) {
            apiClients = _.filter(apiClients, function (apiClient) {
                if (apiClient) {
                    return apiClient.ownerId == selectedOrg.id;
                } else {
                    return false;
                }
            });
        }

        return apiClients;
    },

    apiClientsAddedLocally: (state) => {
        return state.apiClientsAddedLocally
    },

    apiClientsAddedLocallyToShow: (state, getters) => {
        let idsOfGrantedApiClients = _.map(getters.grantedApiClients, 'id');
        return _.filter(getters.apiClientsAddedLocally, apiClientAddedLocally => {
            return !idsOfGrantedApiClients.includes(apiClientAddedLocally.id);
        });
    },

    partners: (state, getters, rootState, rootGetters) => {
        let partners = [];

        let selectedOrg = rootGetters['admin/selectedOrganisation']

        // Partners are api clients that don't belong to our org
        if (selectedOrg) {
            partners = _.filter(state.apiGrants, function (apiGrant) {
                let apiClient = _.find(state.apiClients, { id: apiGrant.apiClientId });
                if (apiClient) {
                    return apiClient.ownerId !== selectedOrg.id;
                } else {
                    return false;
                }
            });
        }

        return partners;
    },

    partnersToReview: (state, getters, rootState, rootGetters) => {
        let partners;

        let selectedOrg = rootGetters['admin/selectedOrganisation']

        // Partners are api clients that don't belong to our org
        if (selectedOrg) {
            partners = _.filter(state.apiClients, function (apiClient) {
                return apiClient.ownerId !== selectedOrg.id;
            });
        } else {
            partners = state.apiClients
        }

        // We only care about the apiClients that haven't been granted
        let idsOfApiClientsGranted = _.map(state.apiGrants, 'apiClientId');
        partners = _.filter(partners, partner => {
            return !_.includes(idsOfApiClientsGranted, partner.id);
        });

        return partners;
    },

    isLoading: (state) => {
        return state.isLoadingApiClients || state.isLoadingApiGrants || state.isLoadingApiGrantPermissionOptions
    },

    apiGrantPermissionOptions: (state) => {
        return _.map(state.apiGrantPermissionOptions, (val, key) => {
            return {
                description: val,
                value: Number(key)
            }
        })
    },

    isLoadingApiGrantPermissionOptions: (state) => {
        return state.isLoadingApiGrantPermissionOptions
    }
};

export const actions = {
    loadApiGrants({ commit, rootGetters }, loadSilenty = false) {
        return new Promise((resolve, reject) => {
            let org = rootGetters['admin/selectedOrganisation'];
            if (!org) {
                commit('SET_API_GRANTS', []);
                return false;
            }

            let tenantId = organisationHelpers.getOrganisationTenantId(org);

            if (!loadSilenty) {
                commit('START_LOADING_API_GRANTS');
            }

            apiGrants.getApiGrantsForOrg(tenantId, org.id).then(r => {
                commit('STOP_LOADING_API_GRANTS');
                commit('SET_API_GRANTS', r.data);
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING_API_GRANTS');
                commit('SET_API_GRANTS', []);

                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            })
        });
    },

    loadApiClients({ commit, rootGetters }) {
        return new Promise((resolve, reject) => {
            let org = rootGetters['admin/selectedOrganisation'];
            if (!org) {
                commit('SET_API_CLIENTS', []);
                return false;
            }
    
            let tenantId = organisationHelpers.getOrganisationTenantId(org);
    
            commit('START_LOADING_API_CLIENTS');
            apiClients.getApiClients(tenantId, org.id).then(r => {
                commit('STOP_LOADING_API_CLIENTS');
                commit('SET_API_CLIENTS', r.data);
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING_API_CLIENTS');
                commit('SET_API_CLIENTS', []);
    
                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            })
        });
    },

    pushToApiClientsAddedLocally({ commit }, apiClient) {
        commit('PUSH_TO_API_CLIENTS_ADDED_LOCALLY', apiClient);
    },

    loadApiGrantPermissionOptions({commit}) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING_API_GRANT_PERMISSION_OPTIONS');
            return apiClients.getPermissions().then(r => {
                commit('SET_API_GRANT_PERMISSION_OPTIONS', r.data);
                commit('STOP_LOADING_API_GRANT_PERMISSION_OPTIONS');
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                commit('STOP_LOADING_API_GRANT_PERMISSION_OPTIONS');
                reject(e);
            })
        });
    }
};
import axios from 'axios';

export default {
    getQuickMessages(tenantId, orgId, includeHidden = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/QuickMessages';
        if (includeHidden) {
            query = query + '?includeHidden=true'
        }

        return axios.get(query);
    },

    getQuickMessagesForAnnouncements(tenantId, orgId, includeHidden = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/QuickMessages';
        if (includeHidden) {
            query = query + '?includeHidden=true&type=announcement'
        } else {
            query = query + '?type=announcement'
        }

        return axios.get(query); 
    },

    getQuickMessage(tenantId, quickMessageId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/QuickMessages/' + quickMessageId);
    },

    addQuickMessage(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/QuickMessages/', params);
    },

    updateQuickMessage(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/QuickMessages', params);
    },

    deleteQuickMessage(tenantId, id) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/QuickMessages/' + id);

        // return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/QuickMessages', { data: params }, {
        //     headers: {
        //         "content-type": "application/json"
        //     }
        // });
    }
}
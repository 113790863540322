import axios from 'axios';
const _ = require('lodash');

export default {
    createIncident(tenantId, incidentDetails) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Incidents', incidentDetails);
    },

    getIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId);
    },

    getIncidentThumbProtectedUrl(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + incidentId + '/Thumb');
    },

    renameIncident(tenantId, incidentId, assigningPresenceId, newName) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Name/' + assigningPresenceId, newName, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    archiveIncident(tenantId, closeIncidentRequest) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/Archive', {data: closeIncidentRequest}, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    getMembershipForIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Presences')
    },

    getResponseTeamsForIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/ResponseTeams')
    },

    getGroupMembersForIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Members')
    },

    getGroupMemberDetailsForIncident(tenantId, incidentId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Members/' + presenceId)
    },

    getAllGroupMemberDetailsForIncident(tenantId, incidentId, presenceIds) {
        let requests = [];
        _.each(presenceIds, presenceId => {
            requests.push(
                axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Members/' + presenceId)
            )
        })
        return axios.all(requests)
    },

    addChecklistToIncident(tenantId, incidentId, presenceId, checklistId) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Checklists/' + checklistId + '/Presences/' + presenceId);
    },

    addMembersToIncident(tenantId, incidentId, presenceIds, assigningPresenceId) {
        let requests = [];
        _.each(presenceIds, presenceId => {
            requests.push(
                axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Members/' + presenceId + '/Presences/' + assigningPresenceId)
            )
        });
        return axios.all(requests)
    },

    addResponseTeamToIncident(tenantId, incidentId, assignFromSkillId, assignFromOrgId, assigningPresenceId, zonedDateTime) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/ResponseTeams', {
            IncidentId: incidentId,
            AssignFromSkillId: assignFromSkillId,
            AssignFromOrgId: assignFromOrgId,
            AssigningPresenceId: assigningPresenceId,
            ZonedDateTime: zonedDateTime
        });
    },

    getAssignmentsForIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Assignments');
    },

    searchIncidents(tenantId, searchCriteria) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Search/Incidents', searchCriteria);
    },

    removeMemberFromIncident(tenantId, incidentId, presenceId, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Members/' + presenceId + '/Presences/' + assigningPresenceId);
    }
}
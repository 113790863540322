<template>
  <div class="incident-tags-wrapper">
    <a-tag v-if="isDrill" class="mode-tag" color="#6D7278">Drill</a-tag>
    <a-tag v-if="isDemo" class="mode-tag" color="#6D7278">Demo</a-tag>
    <a-tag v-if="isUrgent" class="mode-tag" color="#00C0B2">URGENT</a-tag>
    <a-tag v-if="isLive" class="mode-tag" color="#00C0B2">Live</a-tag>
  </div>
</template>

<script>
import incidents from "../../helpers/incidents";
export default {
  props: ["incident"],
  computed: {
    isDrill() {
      return incidents.isIncidentDrill(this.incident);
    },
    isDemo() {
      return incidents.isIncidentDemo(this.incident);
    },
    isUrgent() {
        return incidents.isIncidentUrgent(this.incident);
    },
    isLive() {
        return incidents.isIncidentLive(this.incident);
    }
  },
};
</script>

<style scoped>
.incident-tags-wrapper {
  display: inline-block;
}
</style>
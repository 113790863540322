export default {
    data() {
        return {
            chatAvatars: []
        }
    },

    methods: {
        getChatAvatar(chat) {
            console.log(chat);
            return 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        } 
    }
}
<template>
  <div class="admin-org-indicator" @click.prevent="$router.push('/admin')">
      <organisation-thumbnail :tenant-id="tenantId" :org-id="selectedOrganisation.id"></organisation-thumbnail>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import OrganisationThumbnail from '../Organisations/OrganisationThumbnail.vue';
export default {
  components: { OrganisationThumbnail },
    computed: {
        ...mapGetters('admin', {
            tenantId: 'tenantId',
            selectedOrganisation: 'selectedOrganisation'
        })
    }
}
</script>

<style lang="scss">
.admin-org-indicator {
    margin-right: 15px;
    cursor: pointer;
}
</style>
import axios from 'axios'

export default {
    addHelpVideo(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos', params);
    },

    addHelpVideoThumbnail(tenantId, helpVideoId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Thumb',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    getHelpVideoThumbProtectedUrl(tenantId, helpVideoId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Thumb');
    },

    deleteHelpVideoThumb(tenantId, helpVideoId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Thumb');
    },

    getHelpVideos(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/HelpVideos')
    },

    getHelpVideo(tenantId, helpVideoId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId);
    },

    updateHelpVideo(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos', params);
    },

    deleteHelpVideo(tenantId, helpVideoId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId);
    },

    attachHelpVideoMediaStreamed(tenantId, helpVideoId, file, orientation) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Media/Stream/' + orientation,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    attachHelpVideoMediaReference(tenantId, helpVideoId, referenceUrl, orientation) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Media/Reference/' + orientation, referenceUrl, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    attachHelpVideoMediaDirect(tenantId, helpVideoId, directUrl, orientation) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Media/Direct/' + orientation, directUrl, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    attachHelpVideoMediaEmbed(tenantId, helpVideoId, embedUrl, orientation) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Media/Embed/' + orientation, embedUrl, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    deleteHelpVideoMedia(tenantId, helpVideoId, attachmentId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + helpVideoId + '/Media/' + attachmentId);
    }
}
import organisationHelpers from "../../helpers/organisations";
import availability from "../../api/availability";
const _ = require("lodash");

export const state = {
    isLoading: false,

    date: null,
    time: null,
    skillId: null,

    hasSearchBeenPerformed: false,
    searchResults: [],

    isLoadingTimetableWeekNumber: false,
    timetableWeekNumber: null,
    dateTimetableWeekNumberIsFor: null
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_DATE(state, date) {
        state.date = date
    },

    SET_TIME(state, time) {
        state.time = time
    },

    SET_SKILL_ID(state, skillId) {
        state.skillId = skillId
    },

    SET_SEARCH_HAS_BEEN_PERFORMED(state, boolVal) {
        state.hasSearchBeenPerformed = boolVal
    },

    SET_SEARCH_RESULTS(state, searchResults) {
        state.searchResults = searchResults
    },

    START_LOADING_TIMETABLE_WEEK_NUMBER(state) {
        state.isLoadingTimetableWeekNumber = true
    },

    STOP_LOADING_TIMETABLE_WEEK_NUMBER(state) {
        state.isLoadingTimetableWeekNumber = false
    },

    SET_TIMETABLE_WEEK_NUMBER(state, weekNumber) {
        state.timetableWeekNumber = weekNumber
    },

    SET_DATE_TIMETABLE_WEEK_NUMBER_IS_FOR(state, date) {
        state.dateTimetableWeekNumberIsFor = date;
    }
}

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    date: (state) => {
        return state.date
    },

    time: (state) => {
        return state.time
    },

    skillId: (state) => {
        return state.skillId
    },

    hasSearchBeenPerformed: (state) => {
        return state.hasSearchBeenPerformed
    },

    searchResults: (state) => {
        return state.searchResults
    },

    isLoadingTimetableWeekNumber: (state) => {
        return state.isLoadingTimetableWeekNumber
    },

    timetableWeekNumber: (state) => {
        return state.timetableWeekNumber
    },

    dateTimetableWeekNumberIsFor: (state) => {
        return state.dateTimetableWeekNumberIsFor
    }
}

export const actions = {
    reset({ commit }, skills = []) {
        commit('SET_DATE', window.moment().endOf('day'));
        commit('SET_TIME', Number(window.moment().format('HH') * 60) + Number(window.moment().format('mm')));
        if (skills && skills.length) {
            commit('SET_SKILL_ID', _.first(skills).id);
        }
        commit('SET_SEARCH_HAS_BEEN_PERFORMED', false);
        commit('SET_SEARCH_RESULTS', []);
        commit('SET_TIMETABLE_WEEK_NUMBER', null);
        commit('SET_DATE_TIMETABLE_WEEK_NUMBER_IS_FOR', null);
    },

    setDate({ commit }, date) {
        commit('SET_DATE', date);
    },

    setTime({ commit }, time) {
        commit('SET_TIME', time);
    },

    setSkillId({ commit }, skillId) {
        commit('SET_SKILL_ID', skillId);
    },

    getTimetableWeekNumber({ commit, rootGetters, getters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_SEARCH_RESULTS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        let hour = Math.floor(getters.time / 60);

        let minutes = getters.time % 60;

        let zonedDateTimeMoment = getters.date.clone().set('hour', hour).set('minute', minutes);

        let timezone = rootGetters['admin/timezone'];
        let zonedDateTime = zonedDateTimeMoment.format() + ' ' + timezone;

        commit('START_LOADING_TIMETABLE_WEEK_NUMBER');
        availability.getTimetableWeekNumberForOrg(tenantId, org.id, zonedDateTime).then(r => {
            commit('STOP_LOADING_TIMETABLE_WEEK_NUMBER');
            commit('SET_TIMETABLE_WEEK_NUMBER', r.data);
            commit('SET_DATE_TIMETABLE_WEEK_NUMBER_IS_FOR', getters.date);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_TIMETABLE_WEEK_NUMBER');
            commit('SET_TIMETABLE_WEEK_NUMBER', null);
            commit('SET_DATE_TIMETABLE_WEEK_NUMBER_IS_FOR', null);
        });
    },

    searchAvailability({ commit, rootGetters, getters, dispatch }) {
        dispatch('getTimetableWeekNumber');

        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_SEARCH_RESULTS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        // let dateFormatted = getters.date.format('YYYY-MM-DD');

        let hour = Math.floor(getters.time / 60);
        // let hourPadded = String(hour).padStart(2, '0');

        let minutes = getters.time % 60;
        // let minutesPadded = String(minutes).padStart(2, '0');

        let zonedDateTimeMoment = getters.date.clone().set('hour', hour).set('minute', minutes);

        let timezone = rootGetters['admin/timezone'];
        let zonedDateTime = zonedDateTimeMoment.format() + ' ' + timezone;

        // let zonedDateTime = `${dateFormatted}T${hourPadded}:${minutesPadded}:00.0000000Z Europe/London`;
        // let zonedDateTime = '2021-11-26T13:22:12.0345404Z Europe/London';

        let params = {
            ResponderSearchType: 1, //BySkillAndAvailableTime
            SkillId: getters.skillId,
            AvailableAt: zonedDateTime,
            OrgId: org.id
        };

        commit('START_LOADING');
        availability.searchRespondersBySkillAndAvailableTime(tenantId, params).then(r => {
            commit('STOP_LOADING');
            commit('SET_SEARCH_HAS_BEEN_PERFORMED', true);
            commit('SET_SEARCH_RESULTS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_SEARCH_RESULTS', []);
            console.log(e);
            this._vm.$message.error('Error searching availability');
        });
    }
}
export const state = {
    externalIdProvider: '',
    externalId: ''
};

export const mutations = {
    SET_EXTERNAL_ID_PROVIDER(state, externalIdProvider) {
        state.externalIdProvider = externalIdProvider;
    },

    SET_EXTERNAL_ID(state, externalId) {
        state.externalId = externalId;
    }
};

export const getters = {
    externalIdProvider: (state) => {
        return state.externalIdProvider;
    },

    externalId: (state) => {
        return state.externalId;
    },

    isTestingOrganisation: (state) => {
        let testIds = [
            '823-2999',
            '838-3342',
            '823-4321',
            '674-1234',
            '703-9522'
        ];
        
        return testIds.includes(state.externalId);
    }
};

export const actions = {
    setExternalIdProvider({ commit }, provider) {
        commit('SET_EXTERNAL_ID_PROVIDER', provider);
    },

    setExternalId({ commit }, externalId) {
        commit('SET_EXTERNAL_ID', externalId);
    }
};
<template>
  <div>
    <!-- Header -->
    <div class="content-type-header">
      <div class="left">
        <a-button
          @click.prevent="() => selectContentType(null)"
          type="link"
          icon="left"
        ></a-button>
      </div>
      <div class="right">
        <div class="name">
          {{ selectedContentType.name }}
        </div>
      </div>
    </div>
    <!-- / Header -->

    <!-- Body -->
    <div>
      <guidance-pack-topic-card
        @selected="() => handleTopicSelected(topic)"
        v-for="topic in contentForSelectedContentTypeSorted"
         @send="(params) => $emit('send', params)"
        :show-send-button="showSendButton"
        :topic="topic"
        :key="topic['$v']['id']"
      ></guidance-pack-topic-card>
    </div>
    <!-- / Body -->
  </div>
</template>

<script>
import GuidancePackTopicCard from "./GuidancePackTopicCard.vue";
const _ = require('lodash');
import { mapActions, mapGetters } from "vuex";
export default {
  props: ['showSendButton'],
  methods: {
    ...mapActions("guidancePackViewer", {
      selectContentType: "selectContentType",
      selectTopic: "selectTopic",
      markTopicAsViewed: "markTopicAsViewed"
    }),

    handleTopicSelected(topic) {
      if (topic["$t"] == "Soteria.Models.ExternalLinkDetails, Soteria.Models") {
        this.markTopicAsViewed(topic);
        window.open(topic["$v"]["url"], "_blank");
      } else {
        this.selectTopic(topic["$v"]["id"]);
      }
    },
  },
  components: { GuidancePackTopicCard },
  computed: {
    ...mapGetters("guidancePackViewer", {
      selectedContentType: "selectedContentType",
      contentForSelectedContentType: "contentForSelectedContentType"
    }),

    contentForSelectedContentTypeSorted() {
      return _.orderBy(this.contentForSelectedContentType, ["$v.rank", "$v.displayName"], ['desc', 'asc']);
    }
  },
};
</script>

<style scoped lang="scss">
.guidance-pack-topic-card {
  margin-bottom: 15px;
}

.content-type-header {
  background: #fff;
  border-radius: 10px;
  padding: 13px 10px;
  margin-bottom: 15px;

  display: flex;

  .left {
    flex-shrink: 1;
    padding-right: 10px;
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    .name {
      font-weight: 500;
    }
  }
}
</style>
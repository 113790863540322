import axios from "axios"

const ASSIGNMENT_NONE_STATUS = 0;
const ASSIGNMENT_NOT_APPLICABLE_STATUS = 1;
const ASSIGNMENT_COMPLETED_STATUS = 2;

export default {
    getMembershipForAssignment(tenantId, assignmentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Assignments/' + assignmentId + '/Presences');
    },

    getMembershipForArchivedAssignment(tenantId, assignmentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ArchivedAssignments/' + assignmentId + '/Presences');
    },

    getGroupMembersForAssignment(tenantId, assignmentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Assignments/' + assignmentId + '/Members');
    },

    setAssignmentStatus(tenantId, assignmentId, presenceId, status) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Assignments/' + assignmentId + '/Presences/' + presenceId + '/' + status);
    },

    getAssignmentNoneStatusEnumValue() {
        return ASSIGNMENT_NONE_STATUS;
    },

    getAssignmentNotApplicableStatusEnumValue() {
        return ASSIGNMENT_NOT_APPLICABLE_STATUS;
    },

    getAssignmentCompletedStatusEnumValue() {
        return ASSIGNMENT_COMPLETED_STATUS;
    }
}
<template>
  <div class="tsos-widget-organisation-selector" :class="{'hidden': onlyOneOrg}">
    <a-select size="large" option-filter-prop="children" show-search :filter-option="filterOption"
      style="width: 100%"
      v-model="selectedOrganisationId">
      <a-select-option v-for="(org, i) in organisations" :value="org.id"
        :label="getOrganisationDisplayName(org)" :key="i">
        <span role="img">
          <organisation-thumbnail :tenant-id="getOrgTenantId(org)" :org-id="org.id"
            style="display: inline; margin-right: 13px" size="small"
            :key="selectedOrganisation ? selectedOrganisation.id : 100"></organisation-thumbnail>
        </span>

        {{ getOrganisationDisplayName(org) }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrganisationThumbnail from '../../../Organisations/OrganisationThumbnail.vue';
import Organisations from '../../../../mixins/Organisations';
import _ from 'lodash';
import organisationHelpers from '../../../../helpers/organisations';

export default {
  mixins: [Organisations],

  components: {
    OrganisationThumbnail
  },

  computed: {
    ...mapGetters('missionControl', {
      organisations: 'organisations'
    }),

    ...mapGetters("helpCaller", {
      selectedOrganisation: "selectedOrganisation",
    }),

    onlyOneOrg() {
      return this.organisations.length == 1;
    },

    selected_organisation: {
      get() {
        return this.selectedOrganisation;
      },
      set(val) {
        this.selectOrganisation(val);
      }
    },

    selectedOrganisationId: {
      get() {
        return this.selectedOrganisation ? this.selectedOrganisation.id : null;
      },
      set(val) {
        this.selectOrganisation(
          _.find(this.organisations, { id: val })
        );
      }
    }
  },

  created() {
    if (this.organisations.length && !this.selectedOrganisation) {
          this.selectOrganisation(
              _.first(this.organisations)
          )
      }
  },

  methods: {
    ...mapActions("helpCaller", {
      selectOrganisation: "selectOrganisation",
    }),

    isOrganisationSelected(org) {
      return org && this.selectedOrganisation
        ? org.id === this.selectedOrganisation.id
        : false;
    },

    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
}
</script>

<style scoped lang="scss">
.tsos-widget-organisation-selector {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 15px;

  &:hidden {
    display: none;
  }
}
</style>
import axios from "axios"

export default {
    getPreviousNTimelineEntries(tenantId, guid, beforeTicks, n) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Timelines/' + guid + '/Previous/' + beforeTicks + '/' + n)
    },

    postTimelineEntry(tenantId, timelineMessage) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Timelines', timelineMessage)
    },

    addTimelineEntryMedia(timelineEntryId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/Timelines/Media/' + timelineEntryId,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    getTimelineEntryThumbProtectedUrl(tenantId, groupId, timelineEntryId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Timelines/' + groupId + '/Thumb/' + timelineEntryId);
    },

    getTimelineEntryMediaProtectedUrl(tenantId, groupId, timelineEntryId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Timelines/' + groupId + '/Media/' + timelineEntryId);
    },

    getAudio(tenantId, groupId, sessionId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Audio/' + groupId + '/' + sessionId);
    },

    getQuickMessages(tenantId, organisationId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + organisationId + '/QuickMessages');
    },

    updateLastReadPointer(tenantId, presenceId, groupId, lastReadInstant) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Timelines/' + groupId + '/Presences/' + presenceId + '/' + lastReadInstant);
    }
}
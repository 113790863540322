const _ = require('lodash');

export const state = {
    pinnedIds: []
};

export const mutations = {
    TOGGLE_PINNED(state, id) {
        state.pinnedIds = _.xor(state.pinnedIds, [id]);
    }
};

export const getters = {
    pinnedIds: (state) => {
        return state.pinnedIds;
    }
};

export const actions = {
    togglePinned({commit}, id) {
        commit('TOGGLE_PINNED', id);
    }
};
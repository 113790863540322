import { render, staticRenderFns } from "./IncidentThumbnail.vue?vue&type=template&id=446a4730&scoped=true&"
import script from "./IncidentThumbnail.vue?vue&type=script&lang=js&"
export * from "./IncidentThumbnail.vue?vue&type=script&lang=js&"
import style0 from "./IncidentThumbnail.vue?vue&type=style&index=0&id=446a4730&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446a4730",
  null
  
)

export default component.exports
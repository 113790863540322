<template>
  <div class="chat-thumbnail-wrapper">
    <loading-screen :is-loading="isUploading || isDeleting"></loading-screen>

    <organisation-thumbnail class="org-thumbnail" v-if="userBelongsToMultipleOrganisations" :size="18"
      :tenant-id="tenantId" :org-id="chatOwnerId"></organisation-thumbnail>

    <!-- Avatar -->
    <div :class="{ 'can-edit': canEdit }" @click.prevent="openModal">
      <a-avatar :size="size ? size : 'default'" v-if="(hasCustomAvatarCombined || multipleMembers) &&
        thumbnail &&
        !isLoadingThumbnail
        " :src="thumbnail.url" />

      <a-avatar :size="size ? size : 'default'" icon="loading" v-if="isLoadingThumbnail" />

      <presence-thumbnail v-if="!hasCustomAvatarCombined && !multipleMembers && !isLoadingThumbnail"
        :presenceId="firstPresenceId" :size="size" :ownerId="firstPresenceOwnerId"></presence-thumbnail>

      <div v-if="!thumbnail && !isLoadingThumbnail">No thumbnail</div>
    </div>
    <!-- / Avatar -->

    <!-- Modal -->
    <a-modal title="Change Avatar" :footer="null" v-model="modalVisible">
      <!-- Upload avatar -->
      <input @change="uploadFile" type="file" ref="file" class="file-input" />
      <!-- / Upload avatar -->

      <a-button @click.prevent="promptForFile" size="large" block type="primary">
        Upload New Avatar
      </a-button>

      <a-button v-if="hasCustomAvatarCombined" @click.prevent="removeThumbnail" size="large" block type="danger"
        style="margin-top: 15px">
        Remove Thumbnail
      </a-button>
    </a-modal>
    <!-- / Modal -->
  </div>
</template>

<script>
import chats from "../../api/chats";
import LoadingScreen from '../LoadingScreen.vue';
import OrganisationThumbnail from '../Organisations/OrganisationThumbnail.vue';
import PresenceThumbnail from "../Presences/PresenceThumbnail.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { PresenceThumbnail, LoadingScreen, OrganisationThumbnail },
  props: [
    "size",
    "tenantId",
    "chatId",
    "chatOwnerId",
    "hasCustomAvatar",
    "multipleMembers",
    "firstPresenceId",
    "firstPresenceOwnerId",
    "canEdit",
    "myPresenceId"
  ],
  computed: {
    ...mapGetters('missionControl', {
      userBelongsToMultipleOrganisations: 'userBelongsToMultipleOrganisations'
    }),

    hasCustomAvatarCombined() {
      if (this.hasCustomAvatarLocal !== null) {
        return this.hasCustomAvatarLocal;
      }
      return this.hasCustomAvatar;
    }
  },
  data() {
    return {
      isLoadingThumbnail: false,
      thumbnail: null,

      modalVisible: false,

      isUploading: false,
      hasCustomAvatarLocal: null,

      isDeleting: false
    };
  },
  created() {
    // this.getThumbnailUrl();
    this.attemptGetFromCacheOrLoad();
  },
  methods: {
    ...mapActions("temporaryImages", {
      getTemporaryImageByKey: "getTemporaryImageByKey",
      addTemporaryImageToCache: "addTemporaryImageToCache",
      removeFromCache: "removeFromCache"
    }),

    attemptGetFromCacheOrLoad() {
      let vm = this;
      this.getTemporaryImageByKey("chat-thumbnail-" + this.chatId)
        .then((r) => {
          vm.thumbnail = r;
        })
        .catch(() => {
          vm.getThumbnailUrl();
        });
    },

    getThumbnailUrl() {
      let vm = this;
      vm.isLoadingThumbnail = true;
      chats
        .getChatThumbProtectedUrl(this.tenantId, this.chatId)
        .then((r) => {
          vm.isLoadingThumbnail = false;
          vm.thumbnail = r.data;
          vm.addTemporaryImageToCache({
            key: "chat-thumbnail-" + this.chatId,
            value: vm.thumbnail,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openModal() {
      if (this.canEdit) {
        this.modalVisible = true;
      }
    },

    closeModal() {
      this.modalVisible = false;
    },

    promptForFile() {
      this.$refs.file.click();
    },

    uploadFile(e) {
      let vm = this;
      vm.isUploading = true;

      let file = e.target.files[0];
      chats.addChatThumb(this.tenantId, this.chatId, this.myPresenceId, file).then(r => {
        vm.isUploading = false;
        vm.modalVisible = false;
        vm.thumbnail = r.data;
        vm.hasCustomAvatarLocal = true;
        vm.removeFromCache('chat-thumbnail-' + vm.chatId);
      }).catch(e => {
        vm.isUploading = false;
        console.log(e);
        vm.$message.error('Error uploading media');
      });
    },

    removeThumbnail() {
      let vm = this;
      vm.isDeleting = true;
      chats.deleteChatThumb(this.tenantId, this.chatId, this.myPresenceId).then(() => {
        vm.thumbnail = {};
        vm.hasCustomAvatarLocal = false;
        vm.isDeleting = false;
        vm.modalVisible = false;
        vm.removeFromCache('chat-thumbnail-' + vm.chatId);
      }).catch(e => {
        vm.isDeleting = false;
        console.log(e);
        vm.$message.error('Error removing thumbnail');
      });
    }
  },
};
</script>

<style scoped lang="scss">
.chat-thumbnail-wrapper {
  position: relative;
}

.org-thumbnail {
  position: absolute;
  bottom: -4px;
  right: -4px;
  z-index: 1000;
}

.can-edit {
  cursor: pointer;
}

.file-input {
  display: none;
}
</style>
<template>
  <div class="chat-list-item" :class="{ unread: isUnread }" @click.prevent="navigateToChat">
    <div class="left">
      <a-avatar size="large" icon="loading" v-if="isLoadingMembership" />
      <chat-thumbnail size="large" v-if="!isLoadingMembership" :has-custom-avatar="chat.hasCustomAvatar"
        :multiple-members="groupMembers && groupMembers.length > 2" :first-presence-id="membershipNotMe && membershipNotMe.length ? membershipNotMe[0].id : null
          " :first-presence-owner-id="membershipNotMe && membershipNotMe.length ? membershipNotMe[0].ownerId : null
    " :chat-id="chat.id" :chat-owner-id="chat.ownerId" :tenant-id="tenantId"></chat-thumbnail>
    </div>
    <div class="right" :key="timeAgoRefreshKey">
      <div class="name">
        {{ displayName }}

        <a-badge v-if="shouldShowArchivingBadge" class="archive-badge" :count="`Archiving in ${numberOfDaysUntilArchiving} ${numberOfDaysUntilArchiving == 1 ? 'day' : 'days'}`"></a-badge>
      </div>
      {{ getLastActivityHuman(chat) }}
    </div>
    <div class="super-right" v-if="!hidePin">
      <pin-button :guid="chat.id"></pin-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChatThumbnail from "../../components/Chats/ChatThumbnail.vue";
import organisations from "../../helpers/organisations";
import Chats from "./../../mixins/Chats";
import Dates from "./../../mixins/Dates";
import TimeAgoRefresher from "./../../mixins/TimeAgoRefresher";
const _ = require("lodash");
import chats from "../../api/chats";
import PinButton from '../../components/PinButton.vue';
export default {
  components: { ChatThumbnail, PinButton },
  props: ["chat", "hidePin"],
  mixins: [Chats, Dates, TimeAgoRefresher],
  watch: {
    chat() {
      //  console.log('THE CHAT HAS CHANGED');
      //  console.log(newChat);
      this.$forceUpdate();
    }
  },
  data() {
    return {
      // membership: [],
      // isLoadingMembership: false,
      // groupMembers: [],
      // isLoadingGroupMembers: false,
    };
  },
  created() {
    // this.getMembership();
    // this.getGroupMembers();
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      presences: "presences",
    }),

    ...mapGetters("chats", {
      searchQuery: "searchQuery",
      unreadChats: "unreadChats"
    }),

    isLoadingMembership() {
      return this.chat.isLoadingMembership !== false;
    },

    membership() {
      return this.chat.membership ? this.chat.membership : [];
    },

    membershipNotMe() {
      let vm = this;
      return _.filter(this.membership, m => {
        return m.id !== vm.myMembership;
      });
    },

    isLoadingGroupMembers() {
      return this.chat.isLoadingGroupMembers !== false;
    },

    groupMembers() {
      return this.chat.groupMembers ? this.chat.groupMembers : [];
    },

    isUnread() {
      return _.filter(this.unreadChats, { id: this.chat.id }).length
    },

    organisation() {
      return this.getOrganisationById(this.chat.ownerId);
    },

    deleteArchivedChatDays() {
      if (this.organisation) {
        return this.organisation['content']['$v']['deleteArchivedChatDays'] ? this.organisation['content']['$v']['deleteArchivedChatDays'] : 180;
      }
      return 180;
    },

    lastActivityMoment() {
      return window.moment(this.chat.lastActivity);
    },

    chatWillBeArchivedOnMoment() {
      return this.lastActivityMoment.clone().add(this.deleteArchivedChatDays, 'days');
    },

    numberOfDaysUntilArchiving() {
      let today = window.moment();
      return this.chatWillBeArchivedOnMoment.diff(today, 'days');
    },

    shouldShowArchivingBadge() {
      return (this.numberOfDaysUntilArchiving <= 10 && this.numberOfDaysUntilArchiving >= 0);
    },

    tenantId() {
      let organisation = this.getOrganisationById(this.chat.ownerId);
      return organisations.getOrganisationTenantId(organisation);
    },

    myMembership() {
      if (!this.membership) {
        return null;
      }

      let myPresenceIds = _.map(this.presences, "id");
      let intersection = _.intersection(
        myPresenceIds,
        _.map(this.membership, "id")
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    myGroupMember() {
      if (this.myMembership) {
        return _.find(this.groupMembers, (groupMember) => {
          return groupMember.presenceId == this.myMembership;
        });
      }
      return null;
    },

    displayName() {
      if (this.chat.displayName && this.chat.displayName.length) {
        return this.chat.displayName;
      }
      if (this.isLoadingMembership) {
        return "Loading";
      }
      if (this.membership.length) {
        let myPresenceIds = _.map(this.presences, "id");
        let membersNotMe = _.filter(this.membership, (member) => {
          return !myPresenceIds.includes(member.id);
        });
        let displayNames = _.map(membersNotMe, "displayName");
        let numberOfExtraPeople = null;
        if (displayNames.length > 2) {
          numberOfExtraPeople = displayNames.length - 2;
        }
        if (displayNames.length) {
          if (numberOfExtraPeople) {
            return (
              displayNames.splice(0, 2).join(", ") +
              " and " +
              numberOfExtraPeople +
              " " +
              (numberOfExtraPeople > 1 ? "others" : "other")
            );
          } else {
            return displayNames.join(", ");
          }
        } else {
          return "Unknown";
        }
      }
      return "Unknown";
    },
  },
  methods: {
    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getChatUrl(chat) {
      return "/chats/" + this.tenantId + "/" + chat.id;
    },

    navigateToChat() {
      this.$router.push(this.getChatUrl(this.chat));
    },

    getMembership() {
      let vm = this;
      vm.isLoadingMembership = true;
      chats
        .getMembershipForChat(this.tenantId, this.chat.id)
        .then((r) => {
          vm.isLoadingMembership = false;
          vm.membership = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error loading membership");
        });
    },

    getGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;
      chats
        .getGroupMembersForChat(this.tenantId, this.chat.id)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.groupMembers = r.data;
        })
        .catch((e) => {
          console.loog(e);
          vm.$message.error("Error loading group members");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.chat-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  .super-right {
    padding-left: 5px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  &.unread {
    .name {
      font-weight: 700 !important;
      color: #000;
    }

    font-weight: 500;
    color: #000;
  }

  .archive-badge {
    margin-left: 10px;
  }
}
</style>
import policies from "../../api/guidance/policies";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");

export const state = {
    isLoading: false,
    downloads: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_DOWNLOADS(state, downloads) {
        state.downloads = downloads
    },

    UPDATE_DOWNLOAD_LOCALLY(state, updatedDownload) {
        state.downloads = _.map(state.downloads, download => {
            if (updatedDownload['$v']['id'] == download['$v']['id']) {
                return updatedDownload;
            } else {
                return download;
            }
        });
    }
}

export const getters = {
    isLoading: (state) => (state.isLoading),
    downloads: (state) => (state.downloads),
    downloadsToShow: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.downloads, download => {
                return download['$v']['ownerId'] == ownerIdFilter
            });
        }
        return state.downloads;
    }
}

export const actions = {
    reset({commit}) {
        commit('SET_DOWNLOADS', []);
    },

    startLoading({commit}) {
        commit('START_LOADING');
    },

    stopLoading({commit}) {
        commit('STOP_LOADING');
    },

    loadDownloads({commit, rootGetters}) {
        commit('START_LOADING');

        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_DOWNLOADS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        policies.getPolicies(tenantId, org.id).then(r => {
            commit('STOP_LOADING');
            commit('SET_DOWNLOADS', r.data);
        }).catch(e => {
            console.log(e);
            this._vm.$message.error('Error loading downloads');
        })
    },

    updateDownloadLocally({ commit }, download) {
        commit('UPDATE_DOWNLOAD_LOCALLY', download);
    }
}
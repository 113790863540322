import { v4 as uuidv4 } from 'uuid';

export const state = {
    guid: null
};

export const mutations = {
    SET_GUID(state, guid) {
        state.guid = guid;
    }
};

export const getters = {
    guid: (state) => {
        return state.guid
    }
};

export const actions = {
    launch({ dispatch, getters }) {
        if (!getters.guid)  {
            dispatch('generateGuid');
        }
    },

    generateGuid({ commit }) {
        let guid = uuidv4();
        commit('SET_GUID', guid);
    }
};
const _ = require('lodash')
// import eventBus from '../../event-bus';

export const state = {
    serverErrors: [],

    tenantId: null,
    chatId: null,

    activePresences: []
};

export const mutations = {
    SET_CHAT_ID(state, chatId) {
        state.chatId = chatId
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    }
};

export const getters = {
    isLoading: (state, getters, rootState, rootGetters) => {
        if (rootGetters['chats/isLoading']) {
            return true;
        }
        if (getters.chat) {
            return getters.chat.isLoadingMembership || getters.chat.isLoadingGroupMembers;
        }
        return false;
    },

    isLoadingMembership: (state, getters) => {
        if (getters.chat) {
            return getters.chat.isLoadingMembership;
        }
        return false;
    },

    chatId: (state) => {
        return state.chatId
    },

    chat: (state, getters, rootState, rootGetters) => {
        return _.find(rootGetters['chats/chats'], { id: getters.chatId });
    },

    tenantId: (state) => {
        return state.tenantId
    },

    membership: (state, getters) => {
        if (getters.chat && getters.chat.membership) {
            return getters.chat.membership;
        }
        return [];
    },

    activeMembers: (state, getters) => {
        return _.filter(getters.membership, m => {
            if (getters.myPresenceId === m.id) {
                return true;
            }
            return _.includes(getters.activePresences, m.id);
        });
    },

    inactiveMembers: (state, getters) => {
        return _.filter(getters.membership, m => {
            if (getters.myPresenceId === m.id) {
                return false;
            }
            return !_.includes(getters.activePresences, m.id);
        });
    },

    groupMembers: (state, getters) => {
        if (getters.chat) {
            return getters.chat.groupMembers;
        }
        return [];
    },

    myPresenceId: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInChat = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInChat);
        if (intersection.length) {
            return _.first(intersection)
        }
        return null;
    },

    activePresences: (state, getters) => {
        if (getters.chat && getters.chat.activePresences) {
            return getters.chat.activePresences;
        }
        return [];
    },

    isManagedGroup: (state, getters) => {
        return getters.chat ? getters.chat.flags == 1 : false;
    },

    canEditChat: (state, getters) => {
        return getters.isManagedGroup ? false : true;
    }
};

export const actions = {
    clearChatViewer({dispatch}) {
        dispatch('reset');
    },

    reset({ commit }) {
        commit('SET_CHAT_ID', null);
    },

    loadChat({ commit, dispatch }, params) {
        let { id, tenantId } = params
        commit('SET_TENANT_ID', tenantId);
        dispatch('reset');
        dispatch('timeline/reset', {}, { root: true });
        commit('SET_CHAT_ID', id);
        // dispatch('timeline/loadTimeline', {
        //     model: 'timeline',
        //     tenantId: getters.tenantId,
        //     guid: id,
        //     ownerType: 2,
        //     ownerId: id,
        //     organisationId: getters.chat.ownerId,
        //     behaviour: 0
        // }, { root: true });
        // dispatch('timeline/loadQuickMessages', {}, { root: true });
        // dispatch('metadata/setMetadataIds', getters.chat.metadataIds ? getters.chat.metadataIds : [], { root: true });
        // eventBus.$emit('chatLoaded');
    },

    setActivePresences({ commit }, activePresences) {
        commit('SET_ACTIVE_PRESENCES', activePresences);
    },

    setPresenceActive({ commit }, presenceId) {
        commit('SET_PRESENCE_ACTIVE', presenceId);
    },

    setPresenceInactive({ commit }, presenceId) {
        commit('SET_PRESENCE_INACTIVE', presenceId);
    },

    chatNameUpdated({ commit }, newName) {
        commit('CHAT_NAME_UPDATED', newName);
    }
};
import axios from 'axios';

export default {
    getActiveApiGrant(tenantId, orgId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiGrants/Now');
    },

    revokeApiGrant(tenantId, apiGrantId, presenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/ApiGrants/' + apiGrantId + '/' + presenceId);
    },

    getApiGrantsForApiClient(tenantId, orgId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiGrants');
    },

    addApiGrant(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/ApiGrants', params);
    },

    getApiGrantsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiGrants');
    },

    getApiGrantSecretStatus(tenantId, orgId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiSecrets');
    },

    setApiGrantSecret(tenantId, orgId, apiClientId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiSecrets', params);
    },

    getApiGrantSettings(tenantId, orgId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiSettings');
    },

    setApiGrantSettings(tenantId, orgId, apiClientId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiSettings', params);
    }
}
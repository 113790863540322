import Vue from 'vue'

// import LogRocket from 'logrocket';

let profiles = {
  testing: {
    api_base: 'https://api.testing.teamsos.co.uk'
  },
  staging: {
    api_base: 'https://api.teamsos.co.uk'
  },
  live: {
    api_base: 'https://api.teamsos.co.uk'
  },
  alex: {
    api_base: 'https://id4api.alexfelton.com'
  },
  martin: {
    api_base: 'https://id4api.thirks.com'
  }
}
let activeProfile = 'testing';
// let activeProfile = 'alex';

if (window.location.host == 'app.teamsos.co.uk') {
  activeProfile = 'live';
}

if (window.location.host == 'app.staging.teamsos.co.uk') {
  activeProfile = 'staging';
}

// alex
// activeProfile = 'alex';

window.activeProfile = activeProfile

// switch (window.location.host) {
//   case 'app.teamsos.co.uk':
//     activeProfile = 'live';
//     break;
//   default:
//     activeProfile = 'testing';
//     break;
// }

window.API_BASE = profiles[activeProfile].api_base;
window.POSTHOG_INITIALISED = false;
window.LOGROCKET_ENABLED = false;

import posthog from 'posthog-js'
if (activeProfile == 'live') {
  posthog.init('phc_In0encTTt5kbYw0jnOzfSFL1vCS7roDlvX2UFHAYT5B', { api_host: 'https://eu.posthog.com' })
  window.POSTHOG_INITIALISED = true;

  // LogRocket.init('tfw9se/teamsos');
  // window.LOGROCKET_ENABLED = true;
}

import App from './App.vue'
import router from './router'
import store from './store'
import idsrvAuth from './idsrvAuth'
import axios from 'axios';
import axiosThrottle from 'axios-request-throttle';
axiosThrottle.use(axios, { requestsPerSecond: 200 });

import VueIntercom from 'vue-intercom';
Vue.use(VueIntercom, { appId: 'q5w32ilq' });

import signalR from './signalR'
Vue.use(signalR)

import VueWaypoint from 'vue-waypoint'
Vue.use(VueWaypoint);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  position: POSITION.TOP_CENTER
});

// window.moment = require('moment')
window.moment = require('moment-timezone')

Vue.config.productionTip = false

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

import VueNativeNotification from 'vue-native-notification';
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})
// Vue.notification.requestPermission()
//   .then((r) => {
//     if (r == 'denied') {
//       Vue.$message.warning('Please enable browser notifications');
//     }
//   }); // Prints "granted", "denied" or "default"

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDeB2tUw10SCOH45IiSkGtjzTmVtSycAhQ',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

require('./assets/scss/main.scss')

Vue.component('admin-org-indicator', require('./components/Admin/AdminOrgIndicator.vue').default);

idsrvAuth.startup().then(ok => {
  if (ok) {
    // Axios Interceptor
    axios.interceptors.request.use(
      config => {
        const token = idsrvAuth.accessToken;
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      error => {
        Promise.reject(error)
      });

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  } else {
    console.log('Startup was not ok')
  }
});
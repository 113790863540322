<template>
  <a-modal
    class="fullscreen-modal select-guidance-modal"
    centered
    v-model="showModal"
    title="Select Guidance"
    :footer="null"
  >
    <div class="outer-wrapper">
      <div class="inner-wrapper">
        <div class="loader" v-if="isLoadingCombined">
          <a-spin></a-spin>
        </div>

        <!-- Guidance pack list (No guidance pack selected) -->
        <a-row :gutter="20" v-if="!isLoadingCombined && !selectedGuidancePack">
          <a-col
            :span="12"
            v-for="guidancePack in guidancePacksSorted"
            :key="guidancePack.id"
          >
            <guidance-pack-card
              @selected="() => selectGuidancePack(guidancePack)"
              @send="(params) => sendGuidance(params)"
              :show-send-button="true"
              :guidance-pack="guidancePack"
            ></guidance-pack-card>
          </a-col>
        </a-row>
        <!-- / Guidance pack list (No guidance pack selected) -->

        <!-- Selected guidance pack -->
        <guidance-pack
          v-if="selectedGuidancePack"
          :tenant-id="tenantId"
          :show-send-button="true"
          @go-back="() => selectGuidancePack(null)"
          @send="(params) => sendGuidance(params)"
          :guidance-pack-id="selectedGuidancePack['$v']['id']"
        ></guidance-pack>
        <!-- / Selected guidance pack -->
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GuidancePackCard from "../../views/Guidance/GuidancePackCard.vue";
import GuidancePack from "../SendMessageActions/SendGuidanceModal/GuidancePack.vue";

export default {
  props: ["visible", "tenantId", "orgId", "myPresenceId"],

  components: { GuidancePackCard, GuidancePack },

  data() {
    return {
      selectedGuidancePack: null,
    };
  },

  watch: {
    showModal(isVisible) {
      if (isVisible) {
        this.selectedGuidancePack = null;
        this.loadGuidancePacks();
      }
    },
  },

  methods: {
    ...mapActions("guidance", {
      loadGuidancePacks: "loadGuidancePacks",
    }),

    selectGuidancePack(guidancePack) {
      this.selectedGuidancePack = guidancePack;
    },

    sendGuidance(params) {
      this.$emit("set-guidance", params);
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
      guidancePacksSorted: "guidancePacksSorted",
      isLoading: "isLoading",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },
  },
};
</script>

<style lang="scss">
.select-guidance-modal {
  .outer-wrapper {
    background: rgb(247, 248, 250);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    overflow-y: hidden;

    .inner-wrapper {
      padding-left: 10px;
      padding-right: 10px;
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  .guidance-pack-card-wrapper {
    margin-bottom: 20px;
  }
}
</style>
export const state = {};

export const mutations = {};

export const getters = {
    canEditFaqs: (state, getters, rootState, rootGetters) => {
        let userPresence = rootGetters['missionControl/userPresence'];
        let allowedEmails = ['harvey@enhanceable.io', 'harvey+reseller@enhanceable.io', 'mel-platform@teamsos.co.uk', 'mel@teamsos.co.uk', 'support@teamsos.co.uk', 'toni-platform@teamsos.co.uk', 'toni@teamsos.co.uk', 'sophie@teamsos.co.uk', 'sophie-platform@teamsos.co.uk', 'bridget@teamsos.co.uk', 'bridget-platform@teamsos.co.uk'];
        if (userPresence && allowedEmails.includes(userPresence.email)) {
            return true;
        }
    },
};

export const actions = {};
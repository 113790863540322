import organisationHelpers from "../../helpers/organisations";
import organisations from "../../api/organisations";

export const state = {
    startDate: null,
    endDate: null,

    isLoadingStats: false,
    stats: [],

    isLoadingMetrics: false,
    metrics: []
}

export const mutations = {
    SET_START_DATE(state, startDate) {
        state.startDate = startDate
    },

    SET_END_DATE(state, endDate) {
        state.endDate = endDate
    },

    START_LOADING_STATS(state) {
        state.isLoadingStats = true
    },

    STOP_LOADING_STATS(state) {
        state.isLoadingStats = false
    },

    SET_STATS(state, stats) {
        state.stats = stats;
    },

    START_LOADING_METRICS(state) {
        state.isLoadingMetrics = true
    },

    STOP_LOADING_METRICS(state) {
        state.isLoadingMetrics = false
    },

    SET_METRICS(state, metrics) {
        state.metrics = metrics;
    },
}

export const getters = {
    isLoading: (state) => {
        return state.isLoadingMetrics || state.isLoadingStats
    },

    startDate: (state) => {
        return state.startDate
    },

    endDate: (state) => {
        return state.endDate
    },

    stats: (state) => {
        return state.stats
    },

    metrics: (state) => {
        return state.metrics
    }
}

export const actions = {
    reset({ commit, rootGetters }) {
        let selectedOrganisation = rootGetters['admin/selectedOrganisation'];
        let demoModeFlag = null;
        if (selectedOrganisation) {
            demoModeFlag = selectedOrganisation['content']['$v']['demoMode']
        }
        // 1 = demo mode optional
        // 2 = demo mode always
        if (demoModeFlag == 1 || demoModeFlag == 2) {
            // set to last week when in demo mode
            let beginningOfWeek = window.moment().startOf("week");
            let endOfWeek = window.moment().endOf("week");
            commit('SET_START_DATE', beginningOfWeek.subtract(1, "week"));
            commit('SET_END_DATE', endOfWeek.subtract(1, "week"));
        } else {
            commit('SET_START_DATE', window.moment().startOf('day'));
            commit('SET_END_DATE', window.moment().endOf('day'));
        }
        commit('SET_STATS', []);
    },

    setStartDate({ commit, dispatch }, startDate) {
        commit('SET_START_DATE', startDate);
        dispatch('fetch');
    },

    setEndDate({ commit, dispatch }, endDate) {
        commit('SET_END_DATE', endDate);
        dispatch('fetch');
    },

    fetch({ dispatch }) {
        dispatch('getStats');
        dispatch('getMetrics');
    },

    getStats({ commit, rootGetters, getters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_STATS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        let fromInstant = getters.startDate.clone().startOf('day').format('x') * 10000;
        let toInstant = getters.endDate.clone().endOf('day').format('x') * 10000;

        commit('START_LOADING_STATS');
        organisations.getStatisticsForOrg(tenantId, org.id, fromInstant, toInstant).then(r => {
            commit('STOP_LOADING_STATS');
            commit('SET_STATS', r.data);
        }).catch(e => {
            commit('SET_STATS', []);
            console.log(e);
            this._vm.$message.error('Error loading stats');
        });
    },

    getMetrics({ commit, rootGetters, getters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_METRICS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        let fromInstant = getters.startDate.clone().startOf('day').format('x') * 10000;
        let toInstant = getters.endDate.clone().endOf('day').format('x') * 10000;

        commit('START_LOADING_METRICS');
        organisations.getMetricsForOrg(tenantId, org.id, fromInstant, toInstant).then(r => {
            commit('STOP_LOADING_METRICS');
            commit('SET_METRICS', r.data);
        }).catch(e => {
            commit('SET_METRICS', []);
            console.log(e);
            this._vm.$message.error('Error loading metrics');
        });
    }
}
const _ = require('lodash');
import organisations from '../../api/organisations';
import organisationHelpers from '../../helpers/organisations';
import presences from '../../api/presences';

export const state = {
    organisationsLoadingPresencesFor: [],
    presences: [],
    presencesLoadingThumbnailsFor: []
};

export const mutations = {
    START_LOADING_FOR_ORGANISATION(state, orgId) {
        state.organisationsLoadingPresencesFor.push(orgId);
        state.organisationsLoadingPresencesFor = _.uniq(state.organisationsLoadingPresencesFor);
    },

    STOP_LOADING_FOR_ORGANISATION(state, orgId) {
        state.organisationsLoadingPresencesFor = _.without(state.organisationsLoadingPresencesFor, orgId);
    },

    PUSH_PRESENCES(state, presences) {
        state.presences = _.flattenDeep([state.presences, presences]);
    },

    START_LOADING_THUMBNAIL_FOR_PRESENCE(state, presenceId) {
        state.presencesLoadingThumbnailsFor.push(presenceId);
        state.presencesLoadingThumbnailsFor = _.uniq(state.presencesLoadingThumbnailsFor);
    },

    STOP_LOADING_THUMBNAIL_FOR_PRESENCE(state, presenceId) {
        state.presencesLoadingThumbnailsFor = _.without(state.presencesLoadingThumbnailsFor, presenceId);
    }
};

export const getters = {
    isLoading: (state) => {
        return (state.organisationsLoadingPresencesFor.length > 0) || (state.presencesLoadingThumbnailsFor.length > 0)
    },

    organisationsLoadingPresencesFor: (state) => (state.organisationsLoadingPresencesFor),

    presences: (state) => (state.presences)
};

export const actions = {
    async loadPresencesForAllOrganisations({ rootGetters, dispatch }) {
        return new Promise((resolve) => {
            let organisations = rootGetters['missionControl/organisations'];
            // _.each(organisations, organisation => {
            //     dispatch('loadPresencesForOrganisation', organisation);
            // })
            organisations.forEach(async (organisation) => {
                await dispatch('loadPresencesForOrganisation', organisation);
            })
            resolve();
        });
    },

    async loadPresencesForOrganisation({ commit, dispatch }, organisation) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING_FOR_ORGANISATION', organisation.id);
            let tenantId = organisationHelpers.getOrganisationTenantId(organisation);
            organisations.getMembershipForOrg(tenantId, organisation.id).then(r => {
                commit('STOP_LOADING_FOR_ORGANISATION', organisation.id);
                commit('PUSH_PRESENCES', r.data);
                dispatch('loadThumbnailsForPresences', {
                    tenantId: tenantId,
                    presenceIds: _.map(r.data, 'id')
                });
                resolve(r.data);
            }).catch(e => {
                console.log('Error loading presences for org: ' + organisation.id);
                console.log(e);
                reject(e);
            });
        });
    },

    loadThumbnailsForPresences({ dispatch }, params) {
        let { tenantId, presenceIds } = params;
        _.each(presenceIds, presenceId => {
            dispatch('checkIfNeedsToLoadThumbnailForPresence', {
                tenantId: tenantId,
                presenceId: presenceId
            });
        });
    },

    checkIfNeedsToLoadThumbnailForPresence({ dispatch }, params) {
        let { presenceId } = params;

        dispatch(
            'temporaryImages/getTemporaryImageByKey',
            'presence-thumbnail-' + presenceId,
            { root: true }
        ).then(() => { }).catch(() => {
            dispatch('loadThumbnailForPresence', params);
        })
    },

    loadThumbnailForPresence({ commit, dispatch }, params) {
        let { tenantId, presenceId } = params;
        commit('START_LOADING_THUMBNAIL_FOR_PRESENCE', presenceId);
        presences.getPresenceThumbProtectedUrl(tenantId, presenceId).then(r => {
            dispatch('temporaryImages/addTemporaryImageToCache', {
                key: "presence-thumbnail-" + presenceId,
                value: r.data
            }, { root: true });
            commit('STOP_LOADING_THUMBNAIL_FOR_PRESENCE', presenceId);
        }).catch((e) => {
            console.log(e);
            commit('STOP_LOADING_THUMBNAIL_FOR_PRESENCE', presenceId);
        });
    },

    async resolvePresences({ dispatch }, params) {
        // return new Promise(async (resolve) => {
        //     let { tenantId, presenceIds } = params;
        //     let membership = [];

        //     // presenceIds.forEach(async (presenceId) => {
        //     //     let presence = await dispatch('resolvePresence', {
        //     //         tenantId: tenantId,
        //     //         presenceId: presenceId
        //     //     });
        //     //     membership.push(presence);
        //     // });

        //     await Promise.all(presenceIds.map(async (presenceId) => {
        //         let presence = await dispatch('resolvePresence', {
        //             tenantId: tenantId,
        //             presenceId: presenceId
        //         });
        //         membership.push(presence);
        //     }));

        //     resolve(membership);
        // });

        return new Promise((resolve) => {
            let { tenantId, presenceIds } = params;
            let membership = [];
    
            // Use map to create an array of promises and then wait for all of them to resolve
            Promise.all(presenceIds.map(async (presenceId) => {
                let presence = await dispatch('resolvePresence', {
                    tenantId: tenantId,
                    presenceId: presenceId
                });
                membership.push(presence);
            })).then(() => {
                resolve(membership);
            }).catch((error) => {
                // Handle errors here if needed
                console.error("Error resolving presences:", error);
                resolve(membership); // Resolve with an empty array or handle the error appropriately
            });
        });
    },

    async resolvePresence({ commit, getters }, params) {
        return new Promise((resolve, reject) => {
            let { tenantId, presenceId } = params;
            let presence = _.find(getters.presences, { id: presenceId });
            if (presence) {
                resolve(presence);
            } else {
                presences.getPresence(tenantId, presenceId).then(r => {
                    commit('PUSH_PRESENCES', [r.data]);
                    resolve(r.data);
                }).catch(e => {
                    console.log(e);
                    reject(e);
                });
            }
        });
    }
};
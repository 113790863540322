<template>
  <div>
    <a-spin v-if="isLoading"></a-spin>
    <a-avatar
      :size="size ? size : 'default'"
      v-if="!isLoading && !imageSrc"
      icon="user"
    />
    <a-avatar
      :size="size ? size : 'default'"
      v-if="!isLoading && imageSrc"
      :src="imageSrc"
    />
  </div>
</template>

<script>
const _ = require("lodash");
import presences from "../../api/presences";
import organisations from "../../helpers/organisations";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["presenceId", "ownerId", "size"],
  data() {
    return {
      isLoading: false,
      temporaryImage: null,
      // imageSrc: null,
      // expiresAt: null
    };
  },
  created() {
    this.attemptGetFromCacheOrLoad();
    // this.loadImageSrc();
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
    }),

    organisation() {
      let vm = this;
      return _.find(this.organisations, {
        id: vm.ownerId,
      });
    },

    tenantId() {
      if (this.organisation) {
        return organisations.getOrganisationTenantId(this.organisation);
      }
      return this.ownerId;
    },

    imageSrc() {
      if (this.temporaryImage) {
        return this.temporaryImage.url;
      }
      return null;
    },
  },
  methods: {
    ...mapActions("temporaryImages", {
      getTemporaryImageByKey: "getTemporaryImageByKey",
      addTemporaryImageToCache: "addTemporaryImageToCache",
    }),

    attemptGetFromCacheOrLoad() {
      let vm = this;
      this.getTemporaryImageByKey("presence-thumbnail-" + this.presenceId)
        .then((r) => {
          vm.temporaryImage = r;
        })
        .catch(() => {
          vm.loadImageSrc();
        });
    },

    loadImageSrc() {
      let vm = this;
      vm.isLoading = true;
      presences
        .getPresenceThumbProtectedUrl(this.tenantId, this.presenceId)
        .then((r) => {
          vm.isLoading = false;
          vm.temporaryImage = r.data;
          vm.addTemporaryImageToCache({
            key: "presence-thumbnail-" + this.presenceId,
            value: vm.temporaryImage,
          });
          // vm.imageSrc = r.data.url;
          // vm.expiresAt = r.data.expiresAt;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error('Error loading thumbnail');
        });
    },
  },
};
</script>

<style>
</style>
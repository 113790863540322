import topics from "../../api/guidance/topics";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");

export const state = {
    isLoading: false,
    topics: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_TOPICS(state, topics) {
        state.topics = topics
    },

    UPDATE_TOPIC_LOCALLY(state, updatedTopic) {
        state.topics = _.map(state.topics, topic => {
            if (updatedTopic['$v']['id'] == topic['$v']['id']) {
                return updatedTopic;
            } else {
                return topic;
            }
        });
    }
}

export const getters = {
    isLoading: (state) => (state.isLoading),
    topics: (state) => (state.topics),
    topicsToShow: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.topics, topic => {
                return topic['$v']['ownerId'] == ownerIdFilter
            });
        }
        return state.topics;
    }
}

export const actions = {
    reset({ commit }) {
        commit('SET_TOPICS', []);
    },

    startLoading({ commit }) {
        commit('START_LOADING');
    },

    stopLoading({ commit }) {
        commit('STOP_LOADING');
    },

    loadTopics({ commit, rootGetters }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING');

            let org = rootGetters['admin/selectedOrganisation'];
            if (!org) {
                commit('SET_TOPICS', []);
                return false;
            }
            let tenantId = organisationHelpers.getOrganisationTenantId(org);
    
            topics.getTopics(tenantId, org.id, true).then(r => {
                commit('STOP_LOADING');
                commit('SET_TOPICS', r.data);
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                this._vm.$message.error('Error loading topics');
                reject(e);
            })
        });
    },

    updateTopicLocally({ commit }, topic) {
        commit('UPDATE_TOPIC_LOCALLY', topic);
    }
}
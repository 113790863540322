<template>
  <div class="announcement-thumbnail-wrapper">
    <organisation-thumbnail
      class="org-thumbnail"
      v-if="userBelongsToMultipleOrganisations"
      :size="18"
      :tenant-id="tenantId"
      :org-id="ownerId"
    ></organisation-thumbnail>

    <!-- <a-avatar :size="size ? size : 'default'" icon="info" /> -->
    <presence-thumbnail
      :size="size ? size : 'default'"
      :presenceId="createdBy"
      :ownerId="ownerId"
    ></presence-thumbnail>
  </div>
</template>

<script>
import PresenceThumbnail from "../Presences/PresenceThumbnail.vue";
import OrganisationThumbnail from "../Organisations/OrganisationThumbnail.vue";
import {mapGetters} from "vuex";
export default {
  components: { PresenceThumbnail, OrganisationThumbnail },
  props: ["size", "tenantId", "incidentId", "createdBy", "ownerId"],
  computed: {
    ...mapGetters('missionControl', {
      userBelongsToMultipleOrganisations: 'userBelongsToMultipleOrganisations'
    })
  }
};
</script>

<style scoped lang="scss">
.announcement-thumbnail-wrapper {
  position: relative;
}

.org-thumbnail {
  position: absolute;
  bottom: -4px;
  right: -4px;
  z-index: 1000;
}
</style>
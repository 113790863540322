<template>
  <div class="signal-r-disconnected-screen" :class="{ 'show': shouldShow }">
    <div>
        <a-spin size="large"></a-spin>
        <h1>Connecting...</h1>
    </div>
  </div>
</template>

<script>
import eventBus from "../event-bus";
export default {
  data() {
    return {
      connected: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$oidc.isAuthenticated;
    },

    shouldShow() {
      return this.isAuthenticated && !this.connected;
    },
  },
  created() {
    eventBus.$on("signal-r-connected", () => {
      this.connected = true;
    });

    eventBus.$on("signal-r-disconnected", () => {
      this.connected = false;
    });
  },
};
</script>

<style scoped lang="scss">
.signal-r-disconnected-screen {
    background: rgba(0,0,0,0.9);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900000;

    display: none;
    &.show {
        display: flex;
    }

    align-items: center;
    justify-items: center;
    justify-content: center;

    div {
        text-align: center;

        h1 {
            color: #fff;
            margin-top: 20px;
            font-weight: 300;
        }
    }
}
</style>
<template>
  <div class="h100">
      <!-- Soteria.Models.Attachments.WebAttachment, Soteria.Models -->
      <div class="h100" v-if="type == 'Soteria.Models.Attachments.WebAttachment, Soteria.Models'">
          <!-- Content type text/html -->
          <div class="h100" v-if="mediaDetails['contentType'] == 'text/html'">
            <iframe width="100%" :height="minHeight ? minHeight + 'px' : '100%'" :src="mediaDetailsSource['$v']['sourceUrl']" frameborder="0"></iframe>
          </div>
          <!-- / Content type text/html -->
      </div>
      <!-- / Soteria.Models.Attachments.WebAttachment, Soteria.Models -->
  </div>
</template>

<script>
export default {
    props: ['media', 'minHeight'],
    computed: {
        type() {
            return this.media['$t'];
        },
        mediaDetails() {
            return this.media['$v']
        },
        mediaDetailsSource() {
            return this.mediaDetails['source']
        }
    }
}
</script>

<style scoped lang="scss">
.h100 {
    height: 100%;
}
</style>
<template>
  <div class="latest-chats-wrapper">
    <create-chat-modal
      v-if="createChatModalInitialised"
      :visible="createChatModalVisible"
      @close-modal="closeCreateChatModal"
    ></create-chat-modal>
    <div class="title-wrapper">
      <div class="left">
        <h2 class="title">Chats <tooltip :tooltip="newChatTooltip" v-if="newChatTooltip"></tooltip></h2>
      </div>
      <div class="right">
        <a-button
          class="button-margin-left btn-rounded"
          icon="plus"
          type="primary"
          @click.prevent="openCreateChatModal"
        >
          New
        </a-button>
      </div>
    </div>
    <div class="latest-chats">
      <a-spin v-if="isLoading"></a-spin>

      <!-- Loaded -->
      <div v-else>
        <chat-list-item
          v-for="chat in latestChats"
          :chat="chat"
          :key="chat.id"
          :hide-pin="true"
        ></chat-list-item>

        <div class="no-results" v-if="latestChats.length == 0">
          No chats to show
        </div>
      </div>
      <!-- / Loaded -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChatListItem from "../Chats/ChatListItem.vue";
import CreateChatModal from "../../components/Chats/CreateChatModal.vue";
import Tooltip from '../../components/Tooltip.vue';
import tooltips from '../../tooltips.json';

export default {
  components: { ChatListItem, CreateChatModal, Tooltip },
  data() {
    return {
      createChatModalInitialised: false,
      createChatModalVisible: false,
      newChatTooltip: tooltips.Home.Chats,
    };
  },
  computed: {
    ...mapGetters("chats", {
      isLoading: "isLoading",
      chatsOrdered: "chatsOrdered",
    }),

    latestChats() {
      return this.chatsOrdered.slice(0, 3);
    },
  },
  methods: {
    openCreateChatModal() {
      if (!this.createChatModalInitialised) {
        this.createChatModalInitialised = true;
      }
      this.$nextTick(() => {
        this.createChatModalVisible = true;
      });
    },

    closeCreateChatModal() {
      this.createChatModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.latest-chats-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-wrapper {
    flex-shrink: 1;
    display: flex;
    .left {
      flex-grow: 1;
    }
    .right {
      flex-shrink: 1;
    }
  }

  .ant-btn-primary {
        background: #3A0BA3;

        &:hover {
            background: #6031C9;
        }
  }

  .latest-chats {
    flex-grow: 1;
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 15px;
  }

  .no-results {
    padding: 15px;
  }
}
</style>
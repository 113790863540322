import guidancePacks from "../../api/guidance-packs";
import entityViews from "../../api/entity-views";
import self from "../../api/self";
const _ = require('lodash')
import router from "../../router";

export const state = {
    serverErrors: [],
    isSaving: false,

    guidancePackId: null,
    tenantId: null,

    isLoadingGuidancePacks: false,
    guidancePacks: [],

    isLoadingGuidancePackContents: false,
    guidancePackContents: null,

    selectedParentId: null,
    selectedTopicId: null,
    topicIdStack: [],
    selectedContentType: null
};

export const mutations = {
    START_LOADING(state) {
        state.isLoadingGuidancePackContents = true
    },

    STOP_LOADING(state) {
        state.isLoadingGuidancePackContents = false
    },

    START_LOADING_GUIDANCE_PACKS(state) {
        state.isLoadingGuidancePacks = true
    },

    STOP_LOADING_GUIDANCE_PACKS(state) {
        state.isLoadingGuidancePacks = false
    },

    START_SAVING(state) {
        state.isSaving = true
    },

    STOP_SAVING(state) {
        state.isSaving = false
    },

    SET_GUIDANCE_PACK_ID(state, guidancePackId) {
        state.guidancePackId = guidancePackId
    },

    SET_GUIDANCE_PACKS(state, guidancePacks) {
        state.guidancePacks = guidancePacks
    },

    SET_GUIDANCE_PACK_CONTENTS(state, guidancePackContents) {
        state.guidancePackContents = guidancePackContents
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    },

    SET_SELECTED_PARENT_ID(state, parentId) {
        state.selectedParentId = parentId
    },

    SET_SELECTED_TOPIC_ID(state, selectedTopicId) {
        state.selectedTopicId = selectedTopicId
    },

    SET_SELECTED_CONTENT_TYPE(state, contentType) {
        state.selectedContentType = contentType
    },

    CLEAR_TOPIC_ID_STACK(state) {
        state.topicIdStack = []
    },

    PUSH_TO_TOPIC_ID_STACK(state, topicId) {
        state.topicIdStack.push(topicId)
    },

    POP_TOPIC_ID_STACK(state) {
        state.topicIdStack.pop()
    }
};

export const getters = {
    isLoading: (state) => {
        return (state.isLoadingGuidancePackContents || state.isLoadingGuidancePacks)
    },

    isSaving: (state) => {
        return state.isSaving
    },

    guidancePacks: (state) => {
        return state.guidancePacks
    },

    guidancePackId: (state) => {
        return state.guidancePackId;
    },

    guidancePack: (state) => {
        return _.first(
            _.filter(state.guidancePacks, guidancePack => {
                return guidancePack['$v']['id'] === state.guidancePackId
            })
        );
    },

    guidancePackContents: (state) => {
        return state.guidancePackContents
    },

    tenantId: (state) => {
        return state.tenantId
    },

    topics: (state) => {
        return _.filter(state.guidancePackContents, gpc => {
            return gpc['$t'] == 'Soteria.Models.TopicDetails, Soteria.Models';
        });
    },

    selectedTopicId: (state) => {
        return state.selectedTopicId
    },

    selectedTopic: (state) => {
        return _.first(
            _.filter(state.guidancePackContents, guidancePackContent => {
                return guidancePackContent['$v']['id'] === state.selectedTopicId
            })
        );
    },

    selectedTopicShouldBeDisplayedInModal: (state, getters) => {
        if (getters.selectedTopic) {
            let modalTypes = [
                "Soteria.Models.HelpVideoDetails, Soteria.Models",
                "Soteria.Models.ArticleDetails, Soteria.Models",
                "Soteria.Models.PolicyDetails, Soteria.Models"
            ];
            return modalTypes.includes(getters.selectedTopic['$t']);
        }
        return false;
    },

    selectedParentId: (state) => {
        return state.selectedParentId
    },

    selectedParent: (state) => {
        return _.first(
            _.filter(state.guidancePackContents, guidancePackContent => {
                return guidancePackContent['$v']['id'] === state.selectedParentId
            })
        );
    },

    contentLinksForSelectedParent: (state, getters) => {
        if (getters.guidancePack && getters.selectedParentId) {
            return _.filter(getters.guidancePack['$v']['contentLinks'], cl => {
                return cl.parentEntityId == getters.selectedParentId;
            });
        }
        return [];
    },

    idsOfContentLinkChildrenForSelectedParent: (state, getters) => {
        return _.map(getters.contentLinksForSelectedParent, 'childEntityId')
    },

    selectedParentChildren: (state, getters) => {
        return _.filter(state.guidancePackContents, guidancePackContent => {
            return getters.idsOfContentLinkChildrenForSelectedParent.includes(guidancePackContent['$v']['id'])
        });
    },

    contentLinksForSelectedTopic: (state, getters) => {
        if (getters.guidancePack && getters.selectedTopicId) {
            return _.filter(getters.guidancePack['$v']['contentLinks'], cl => {
                return cl.parentEntityId == getters.selectedTopicId;
            });
        }
        return [];
    },

    idsOfContentLinkChildrenForSelectedTopic: (state, getters) => {
        return _.map(getters.contentLinksForSelectedTopic, 'childEntityId')
    },

    selectedTopicChildren: (state, getters) => {
        return _.filter(state.guidancePackContents, guidancePackContent => {
            return getters.idsOfContentLinkChildrenForSelectedTopic.includes(guidancePackContent['$v']['id'])
        });
    },

    selectedContentType: (state) => {
        return state.selectedContentType
    },

    contentForSelectedContentType: (state) => {
        if (!state.selectedContentType) {
            return [];
        }
        return _.filter(state.guidancePackContents, gpc => {
            return gpc['$t'] == state.selectedContentType.model;
        });
    },

    topicIdStack: (state) => {
        return state.topicIdStack
    },

    contentTypeGroups: (state, getters) => {
        let getContentTypeGroupCount = (modelType) => {
            let filtered = _.filter(getters.guidancePackContents, (g) => {
                return g["$t"] == modelType;
            });
            return filtered.length;
        };

        return [
            {
                name: "Articles",
                model: "Soteria.Models.ArticleDetails, Soteria.Models",
                icon: "book",
                count: getContentTypeGroupCount(
                    "Soteria.Models.ArticleDetails, Soteria.Models"
                )
            },
            {
                name: "Help Videos",
                model: "Soteria.Models.HelpVideoDetails, Soteria.Models",
                icon: "play-circle",
                count: getContentTypeGroupCount(
                    "Soteria.Models.HelpVideoDetails, Soteria.Models"
                )
            },
            {
                name: "Downloads",
                model: "Soteria.Models.PolicyDetails, Soteria.Models",
                icon: "copy",
                count: getContentTypeGroupCount(
                    "Soteria.Models.PolicyDetails, Soteria.Models"
                )
            },
            {
                name: "External Links",
                model: "Soteria.Models.ExternalLinkDetails, Soteria.Models",
                icon: "link",
                count: getContentTypeGroupCount(
                    "Soteria.Models.ExternalLinkDetails, Soteria.Models"
                )
            },
            {
                name: "Playbooks",
                model: "Soteria.Models.ChecklistContentDetails, Soteria.Models",
                icon: "unordered-list",
                count: getContentTypeGroupCount(
                    "Soteria.Models.ChecklistContentDetails, Soteria.Models"
                )
            }
        ];
    },

    noParentTopicsOrOtherContentTypes: (state, getters) => {
        // Condition 1
        let hasNoParentTopics = getters['topics'].length == 0;
        // console.log(hasNoParentTopics);

        let contentTypeGroups = getters['contentTypeGroups'];

        let contentTypeGroupsWithCountOfZero = _.filter(contentTypeGroups, group => {
            return group.count == 0;
        }).length

        let totalNumberOfContentTypeGroups = contentTypeGroups.length;

        // Condition 2
        let onlyOneContentType = (totalNumberOfContentTypeGroups == (contentTypeGroupsWithCountOfZero + 1));

        if (
            hasNoParentTopics
            && onlyOneContentType
        ) {
            return true;
        }

        return false;
    }
};

export const actions = {
    reset({ commit }) {
        commit('SET_GUIDANCE_PACK_CONTENTS', null);
        commit('SET_SELECTED_TOPIC_ID', null);
        commit('SET_SELECTED_CONTENT_TYPE', null);
        commit('CLEAR_TOPIC_ID_STACK');
    },

    loadAllGuidancePacks({ commit }) {
        commit('START_LOADING_GUIDANCE_PACKS');
        self.getGuidancePacks().then(r => {
            commit('STOP_LOADING_GUIDANCE_PACKS');
            commit('SET_GUIDANCE_PACKS', r.data);
        }).catch(e => {
            commit('STOP_LOADING_GUIDANCE_PACKS');
            commit('SET_GUIDANCE_PACKS', []);
            // this._vm.$message.error('Error loading guidance packs');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadGuidancePack({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            let { id, tenantId } = params
            commit('START_LOADING');
            commit('SET_GUIDANCE_PACK_ID', id);
            commit('SET_TENANT_ID', tenantId);
            dispatch('reset');

            dispatch('loadAllGuidancePacks');

            guidancePacks.getGuidancePackContents(id).then(r => {
                commit('STOP_LOADING');
                commit('SET_GUIDANCE_PACK_CONTENTS', r.data)
                dispatch('selectFirstContentTypeIfNoParentTopicsOrOtherContentTypes');
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING');
                // this._vm.$message.error('Error loading guidance pack contents');
                console.log(e);
                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            })
        });
    },

    selectFirstContentTypeIfNoParentTopicsOrOtherContentTypes({ getters, dispatch }) {
        if (
            getters['noParentTopicsOrOtherContentTypes'] == true
        ) {
            // Get 
            let theOnlyContentType = _.find(getters['contentTypeGroups'], group => {
                return group.count > 0;
            });
            dispatch('selectContentType', theOnlyContentType);
        }
    },

    selectParent({ commit }, parentId) {
        commit('SET_SELECTED_PARENT_ID', parentId);
    },

    selectTopic({ commit, dispatch }, topicId) {
        commit('SET_SELECTED_TOPIC_ID', topicId);
        if (topicId) {
            commit('PUSH_TO_TOPIC_ID_STACK', topicId);
            dispatch('markCurrentTopicAsViewed');
        } else {
            commit('POP_TOPIC_ID_STACK');
        }
    },

    backToPreviousTopic({ commit, getters }) {
        commit('POP_TOPIC_ID_STACK');
        if (getters.topicIdStack.length) {
            let topicId = getters.topicIdStack[getters.topicIdStack.length - 1];
            commit('SET_SELECTED_TOPIC_ID', topicId);
        } else {
            commit('SET_SELECTED_TOPIC_ID', null);
        }
    },

    selectContentType({ commit, getters }, contentType) {
        commit('SET_SELECTED_CONTENT_TYPE', contentType);
        if (
            contentType == null
            && getters['noParentTopicsOrOtherContentTypes'] == true
        ) {
            router.push('/guidance');
        }
    },

    markTopicAsViewed({ rootGetters, dispatch }, topic) {
        let myPresenceSubjectIds = _.uniq(_.map(rootGetters['missionControl/presences'], 'subjectId'));
        if (topic && myPresenceSubjectIds.length) {
            let { id, ownerId } = topic['$v'];

            let entityTypeEnums = [
                'Unknown', // 0
                'Soteria.Models.HelpVideoDetails, Soteria.Models', // 1
                'Soteria.Models.PolicyDetails, Soteria.Models', // 2
                'Soteria.Models.ArticleDetails, Soteria.Models', // 3
                'Soteria.Models.ExternalLinkDetails, Soteria.Models', // 4
                'Soteria.Models.TopicDetails, Soteria.Models', // 5
                'Soteria.Models.GuidancePack, Soteria.Models' // 6
            ];
            let entityTypeEnum = entityTypeEnums.indexOf(topic['$v']);
            if (entityTypeEnum == -1) {
                entityTypeEnum = 0; // Unknown
            }

            _.each(myPresenceSubjectIds, subjectId => {
                dispatch('markTopicAsViewedForSubjectId', {
                    subjectId: subjectId,
                    entityOwnerId: ownerId,
                    viewedEntityId: id,
                    viewedEntityType: entityTypeEnum
                });
            });
        }
    },

    // markCurrentTopicAsViewed({ getters, dispatch }) {
    markCurrentTopicAsViewed({ getters, dispatch }) {
        let selectedTopic = getters.selectedTopic;
        dispatch('markTopicAsViewed', selectedTopic);
    },

    markTopicAsViewedForSubjectId({ dispatch }, params) {
        let { entityOwnerId, viewedEntityId, viewedEntityType, subjectId } = params;
        let entityViewRequest = {
            SubjectId: subjectId, // TODO: send for each subject id from presences
            EntityOwnerId: entityOwnerId,
            ViewedEntityId: viewedEntityId,
            ViewedEntityType: viewedEntityType,
            ViewSource: 6 // enum doesn't exist yet - TIM!
        };
        entityViews.addEntityView(entityViewRequest).then((r) => {
            let entityView = r.data;
            dispatch('guidance/addEntityView', entityView, { root: true });
        }).catch(e => {
            console.log(e);
            this._vm.$message.error('Error marking content as viewed');
        });
    }
};
import axios from 'axios'

export default {
    getSubjectPreferencesForSelf() {
        return axios.get(window.API_BASE + '/api/v1/self/Preferences');
    },

    markSubjectPreferencesLastOrgForSelf(lastOrgId) {
        return axios.put(window.API_BASE + '/api/v1/self/Preferences/LastOrg/' + lastOrgId);
    }
}
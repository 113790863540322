<template>
    <div>
        <div class="footer-navigation">
            <a @click.prevent="navigateToRoute('call-for-help')" :class="{ 'active': route == 'call-for-help' }" href="#">
                <div class="icon">
                    <a-icon style="filled" type="home" v-if="route == 'home'" />
                    <a-icon type="home" v-else />
                </div>
                <div class="text">
                    Call For Help
                </div>
            </a>
            <!-- <a @click.prevent="navigateToRoute('conversations')" :class="{ 'active': route == 'conversations' }" href="#">
                <div class="icon">
                    <MessageFilled v-if="route == 'conversations'" />
                    <MessageOutlined v-else />
                </div>
                <div class="text">
                    Messages
                </div>
            </a> -->
            <a @click.prevent="navigateToRoute('incidents')" :class="{ 'active': route == 'incidents' }" href="#">
                <div class="icon">
                    <a-icon style="filled" type="warning" v-if="route == 'incidents'" />
                    <a-icon type="warning" v-else /> 
                </div>
                <div class="text">
                    Incidents
                </div>
            </a>
        </div>
        <!-- <div class="powered-by-tsos"> -->
            <!-- <a href="https://teamsos.co.uk" target="_blank">Powered by teamSOS</a> -->
        <!-- </div> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import { HomeFilled, HomeOutlined, MessageFilled, MessageOutlined, WarningOutlined, WarningFilled } from '@ant-design/icons-vue'
export default {
    components: {},
    computed: {
        ...mapGetters('tsosWidget', {
            route: 'route'
        })
    },
    methods: {
        ...mapActions('tsosWidget', {
            navigateToRoute: 'navigateToRoute'
        })
    }
}
</script>

<style scoped lang="scss">
.footer-navigation {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;

    a {
        display: flex;
        flex: 1;
        text-align: center;
        // padding-top: 17px;
        // padding-bottom: 17px;
        padding-top: 13px;
        padding-bottom: 11px;

        // padding-top: 14px;
        // padding-bottom: 14px;

        flex-direction: column;
        align-items: center;
        text-decoration: none;
        font-size: 14px;

        color: #333;

        &.active {
            font-weight: 600;
            color: var(--tsos);
        }

        .anticon {
            font-size: 20px;
            margin-bottom: 8px;
        }
    }
}

.powered-by-tsos {
    background: #f5f5f5;
    font-size: 11px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;

    a {
        color: rgba(0,0,0,.6);
        text-decoration: none !important;
        transition: all .2s ease-in-out;

        &:hover {
            color: rgba(0,0,0,.9);
        }
    }
}
</style>
import axios from 'axios';

export default {
    searchArchivedIncidents(tenantId, searchCriteria) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Search/ArchivedIncidents', searchCriteria);
    },

    getMembershipForArchivedIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ArchivedIncidents/' + incidentId + '/Presences')
    },

    getGroupMembersForArchivedIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ArchivedIncidents/' + incidentId + '/Members')
    },

    getArchivedAssignmentsForArchivedIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ArchivedIncidents/' + incidentId + '/ArchivedAssignments')
    },

    getMembershipForArchivedAssignment(tenantId, assignmentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + ' /ArchivedAssignments/' + assignmentId + '/Presences');
    },

    renameArchivedIncident(tenantId, incidentId, assigningPresenceId, newName) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/ArchivedIncidents/' + incidentId + '/Name/' + assigningPresenceId, newName, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    deleteArchivedIncident(tenantId, incidentId, deletedByPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/ArchivedIncidents/' + incidentId + '/Presences/' + deletedByPresenceId);
    },

    getResponseTeamsForArchivedIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + ' /ArchivedIncidents/' + incidentId + '/ResponseTeams');
    }
}
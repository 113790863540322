var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h100"},_vm._l((_vm.mediaToShow),function(m,i){return _c('div',{key:i,staticClass:"h100"},[(
        m['$v']['source']['$t'] ==
        'Soteria.Models.Attachments.LinkAttachmentSource, Soteria.Models'
      )?_c('div',{staticClass:"h100",style:(_vm.wrapperStyle)},[_c('iframe',{attrs:{"width":"100%","src":m['$v']['source']['$v']['sourceUrl'],"frameborder":"0"}})]):_vm._e(),(
        m['$v']['source']['$t'] ==
        'Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models'
      )?_c('div',{staticClass:"h100",style:(_vm.wrapperStyle)},[_c('video',{staticClass:"video-viewer",attrs:{"controls":"","width":"100%","autoplay":"","loop":""}},[_c('source',{attrs:{"src":m['$v']['source']['$v']['sourceUrl'],"type":m['$v']['source']['$v']['contentType']}}),_vm._v(" Sorry, your browser doesn't support embedded videos. ")])]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
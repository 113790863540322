import self from '../../api/self'
let _ = require('lodash');
import organisations from "../../helpers/organisations";
import announcements from "../../api/announcements";
import chatHelpers from "../../helpers/chats";
// import router from "../../router";
import presences from '../../api/presences';

export const state = {
    isLoading: false,
    isPatching: false,
    serverErrors: [],

    announcements: [],
    searchQuery: '',

    expectedNumberOfGroupMembers: null // {announcementId: X, expectedNumberOfGroupMembers: Y}
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    START_PATCHING(state) {
        state.isPatching = true
    },

    STOP_PATCHING(state) {
        state.isPatching = false
    },

    SET_ANNOUNCEMENTS(state, announcements) {
        state.announcements = announcements
    },

    SET_ERRORS(self, serverErrors) {
        state.serverErrors = serverErrors
    },

    SET_SEARCH_QUERY(state, q) {
        state.searchQuery = q
    },

    UPDATE_ANNOUNCEMENT_LAST_ACTIVITY(state, params) {
        let { announcementId, lastActivity } = params;
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                let announcementCopy = { ...announcement };
                announcementCopy.lastActivity = lastActivity;
                return announcementCopy;
            }
            return announcement;
        });
    },

    UPDATE_ANNOUNCEMENT_LAST_HUMAN_ACTIVITY(state, params) {
        let { announcementId, lastHumanActivity } = params;
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                let announcementCopy = { ...announcement };
                announcementCopy.lastHumanActivity = lastHumanActivity;
                return announcementCopy;
            }
            return announcement;
        });
    },

    UPDATE_ANNOUNCEMENT(state, newAnnouncement) {
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id === newAnnouncement.id) {
                return {
                    ...announcement,
                    ...newAnnouncement
                };
            }
            return announcement;
        });
    },

    START_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT(state, announcementId) {
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                return {
                    ...announcement,
                    isLoadingGroupMembers: true
                };
            }
            return announcement;
        })
    },

    STOP_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT(state, announcementId) {
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                return {
                    ...announcement,
                    isLoadingGroupMembers: false
                };
            }
            return announcement;
        })
    },

    SET_GROUP_MEMBERS_FOR_ANNOUNCEMENT(state, params) {
        let { announcementId, groupMembers } = params;
        state.announcements = _.map(state.announcements, announcement => {
            if (announcement.id == announcementId) {
                return {
                    ...announcement,
                    groupMembers: groupMembers
                };
            }
            return announcement;
        });
    },

    UPDATE_ANNOUNCEMENT_GROUP_MEMBER(state, params) {
        let { announcementId, groupMember } = params;
        state.announcements = _.map(state.announcements, announcement => {
            let announcementLocal = { ...announcement };

            if (announcementLocal.id == announcementId) {

                let isGroupMemberInGroupMembers = _.map(announcementLocal.groupMembers, 'presenceId').includes(groupMember.presenceId);
                if (isGroupMemberInGroupMembers) {
                    announcementLocal.groupMembers = _.map(announcementLocal.groupMembers, gm => {
                        if (gm.presenceId === groupMember.presenceId) {
                            return {
                                ...gm,
                                ...groupMember
                            };
                        }
                        return gm;
                    });
                } else {
                    if (announcementLocal.groupMembers && announcement.groupMembers.length) {
                        announcementLocal.groupMembers.push(groupMember);
                    } else {
                        announcementLocal.groupMembers = [groupMember];
                    }
                }

            }
            return announcementLocal;
        });
    },

    PUSH_ANNOUNCEMENT(state, announcement) {
        state.announcements.push(announcement);
    },

    SET_EXPECTED_NUMBER_OF_GROUP_MEMBERS(state, params) {
        state.expectedNumberOfGroupMembers = params;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    announcements: (state) => {
        return state.announcements
    },

    announcementsOrdered: (state, getters) => {
        return chatHelpers.sortByLastActivity(getters.announcements).reverse();
    },

    announcementsToShow: (state) => {
        let searchQuery = state.searchQuery;
        return _.filter(state.announcements, announcement => {
            if (searchQuery.trim().length) {
                let displayNameMatches = false;
                if (announcement.displayName) {
                    displayNameMatches = announcement.displayName
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase());
                }

                return displayNameMatches;
            }
            return true;
        })
    },

    pinnedAnnouncementsToShow: (state, getters, rootState, rootGetters) => {
        return _.filter(getters.announcementsToShow, announcement => {
            return rootGetters['pinned/pinnedIds'].includes(announcement.id);
        });
    },

    unpinnedAnnouncementsToShow: (state, getters, rootState, rootGetters) => {
        return _.filter(getters.announcementsToShow, announcement => {
            return !rootGetters['pinned/pinnedIds'].includes(announcement.id);
        });
    },

    announcementsToShowOrdered: (state, getters) => {
        let pinnedAnnouncementsOrdered = chatHelpers.sortByLastActivity(getters.pinnedAnnouncementsToShow).reverse();
        let unpinnedAnnouncementsOrdered = chatHelpers.sortByLastActivity(getters.unpinnedAnnouncementsToShow).reverse();
        return _.flattenDeep([pinnedAnnouncementsOrdered, unpinnedAnnouncementsOrdered]);
        // return chatHelpers.sortByLastActivity(getters.announcementsToShow).reverse();
    },

    unreadAnnouncementCount: (state, getters) => {
        return getters.unreadAnnouncements.length
    },

    unreadAnnouncements: (state, getters, rootState, rootGetters) => {
        return _.filter(state.announcements, announcement => {
            let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');

            // Let's then use this to get the group members
            let myGroupMember = null;
            if (announcement.groupMembers) {
                myGroupMember = _.find(announcement.groupMembers, groupMember => {
                    return myPresenceIds.includes(groupMember.presenceId);
                });
            }

            // If I don't have a group member, then this is unread
            if (!myGroupMember) {
                return true;
            }

            // THe unread logic for a grup member for an announcement is:
            // Base condition:
            // 0. gm.LastReadPointer < LastActivity;
            // There are 3 other condition for being unread
            // 1. No content
            // 2. Content object exists and doesn't have a response
            // 3. IsResponder && LastReadPointer < (Content.LastResponseAt ?? Insant.MinValue)

            // Condition 0
            if (myGroupMember) {
                if (!myGroupMember.lastReadPointer) {
                    return true;
                }
                let lastReadMoment = window.moment(myGroupMember.lastReadPointer);
                let lastActivityMoment = window.moment(announcement.lastActivity);

                if (lastActivityMoment.isAfter(lastReadMoment)) {
                    return true;
                }
            }

            // Condition 1 - No content
            let isNoContent = !myGroupMember.content;
            if (isNoContent) {
                return true;
            }

            // Condition 2 - Content object exists and doesn't have a response
            if (myGroupMember.content && !myGroupMember['content']['$v']['response']) {
                return true;
            }

            // Condition 3 - IsResponder && LastReadPointer < (Content.LastResponseAt ?? Insant.MinValue)
            if (myGroupMember.isResponder) {
                let lastReadMoment = window.moment(myGroupMember.lastReadPointer);

                if (announcement['content']['$v']['lastResponseAt']) {
                    let lastResponseAtMoment = window.moment(announcement['content']['$v']['lastResponseAt']);
                    let isLastResponseAfterLastReadPointer = lastResponseAtMoment.isAfter(lastReadMoment);
                    if (isLastResponseAfterLastReadPointer) {
                        return true;
                    }
                }

            }

            return false;
        });
    },

    searchQuery: (state) => {
        return state.searchQuery
    },

    expectedNumberOfGroupMembers: (state) => {
        return state.expectedNumberOfGroupMembers
    }
};

export const actions = {
    loadAnnouncements({ commit, dispatch }) {
        commit('START_LOADING');

        self.getAnnouncements().then(r => {
            commit('STOP_LOADING');
            commit('SET_ANNOUNCEMENTS', r.data);
            dispatch('loadGroupMembers');
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_ANNOUNCEMENTS', []);
            // this._vm.$message.error('Error loading announcements');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    setSearchQuery({ commit }, query) {
        commit('SET_SEARCH_QUERY', query);
    },

    async loadGroupMembers({ commit, getters, dispatch }, announcementId = null) {
        // let vm = this;
        _.each(getters.announcements, async (announcement) => {
            if (announcementId == null || announcement.id == announcementId) {
                let organisation = await dispatch('missionControl/getOrganisationById', announcement.ownerId, { root: true });
                let tenantId = organisations.getOrganisationTenantId(organisation)

                commit('START_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT', announcement.id);
                announcements.getGroupMembersForAnnouncement(tenantId, announcement.id).then(r => {
                    commit('STOP_LOADING_GROUP_MEMBERS_FOR_ANNOUNCEMENT', announcement.id);
                    commit('SET_GROUP_MEMBERS_FOR_ANNOUNCEMENT', {
                        announcementId: announcement.id,
                        groupMembers: r.data
                    });

                    // If we have an expected number of group members for this group
                    // AND 
                    // the number of group members returned doesn't match
                    // then let's try again...
                    if (
                        getters.expectedNumberOfGroupMembers
                        && getters.expectedNumberOfGroupMembers.announcementId == announcementId
                        && getters.expectedNumberOfGroupMembers.expectedNumberOfGroupMembers !== r.data.length
                    ) {
                        window.setTimeout(() => {
                            dispatch('setExpectedNumberOfGroupMembers', null);
                            dispatch('loadGroupMembers', announcementId);
                        }, 1500);
                    }
                }).catch(e => {
                    console.log(e);
                    // vm._vm.$message.error('Error loading group member for announcement');
                })
            }
        });
    },

    updateAnnouncementGroupMember({ commit }, params) {
        commit('UPDATE_ANNOUNCEMENT_GROUP_MEMBER', params);
    },

    updateAnnouncementLastActivity({ commit }, params) {
        commit('UPDATE_ANNOUNCEMENT_LAST_ACTIVITY', params);
    },

    updateAnnouncementLastHumanActivity({ commit }, params) {
        commit('UPDATE_ANNOUNCEMENT_LAST_HUMAN_ACTIVITY', params);
    },

    updateAnnouncement({ commit }, announcement) {
        commit('UPDATE_ANNOUNCEMENT', announcement);
    },

    pushAnnouncement({ commit, dispatch }, announcement) {
        commit('PUSH_ANNOUNCEMENT', {
            ...announcement,
            isLoadingGroupMembers: false,
            groupMembers: []
        });
        dispatch('loadGroupMembers', announcement.id);
    },

    reloadAnnouncementMembership({ dispatch }, announcementId) {
        dispatch('loadMemberships', announcementId);
    },

    async fetchAndAddAnnouncement({ commit, dispatch, getters }, params) {
        let { tenantId, announcementId } = params;
        // let vm = this;
        commit('START_PATCHING');
        announcements.getAnnouncement(tenantId, announcementId).then(r => {
            commit('STOP_PATCHING');

            if (_.find(getters.announcements, { id: announcementId })) {
                // This has been added by something else in the time that we've been fetching
                // Let's not add it
                return false;
            }

            let announcement = r.data;
            dispatch('pushAnnouncement', announcement);
            dispatch('getCreatedByPresenceAndAlertNewAnnouncement', {
                tenantId: tenantId,
                announcement: announcement
            });

            // Get icon
            // let icon = null;
            // if (createdByPresenceId) {
            //     try {
            //         let getPresenceThumbProtectedUrlResponse = await presences.getPresenceThumbProtectedUrl(tenantId, createdByPresenceId);
            //         if (getPresenceThumbProtectedUrlResponse && getPresenceThumbProtectedUrlResponse.data && getPresenceThumbProtectedUrlResponse.data.url) {
            //             icon = getPresenceThumbProtectedUrlResponse.data.url;
            //         }
            //     } catch (e) {
            //         console.log(e);
            //     }
            // }

            // vm._vm.$notification.show('New Announcement', {
            //     body: r.data.displayName,
            //     tag: announcementId,
            //     icon: icon
            // }, {
            //     onclick: function () {
            //         router.push('/announcements/' + tenantId + '/' + announcementId);
            //     }
            // })

        }).catch(e => {
            console.log(e);
            commit('STOP_PATCHING');
            this._vm.$message.error('Error fetching announcement');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },


    async getCreatedByPresenceAndAlertNewAnnouncement({ dispatch }, params) {
        let { tenantId, announcement } = params;

        let presence = null;
        if (announcement.createdBy) {
            try {
                let presenceResponse = await presences.getPresence(tenantId, announcement.createdBy);
                if (presenceResponse.data) {
                    presence = presenceResponse.data;
                }
            } catch (e) {
                console.log(e);
            }
        }

        if (presence) {
            dispatch('alertNewAnnouncement', {
                tenantId: tenantId,
                announcement: announcement,
                createdBy: presence.displayName,
                createdByPresenceId: presence.id
            });
        } else {
            dispatch('alertNewAnnouncement', {
                tenantId: tenantId,
                announcement: announcement,
                createdBy: 'Unknown',
                createdByPresenceId: null
            });
        }
    },

    async alertNewAnnouncement({ dispatch }, params) {
        let { announcement, createdBy, tenantId, createdByPresenceId } = params;
        // let vm = this;

        let body = 'New announcement created by ' + createdBy;

        // Get icon
        let icon = null;
        if (createdByPresenceId) {
            try {
                let getPresenceThumbProtectedUrlResponse = await presences.getPresenceThumbProtectedUrl(tenantId, createdByPresenceId);
                if (getPresenceThumbProtectedUrlResponse && getPresenceThumbProtectedUrlResponse.data && getPresenceThumbProtectedUrlResponse.data.url) {
                    icon = getPresenceThumbProtectedUrlResponse.data.url;
                }
            } catch (e) {
                console.log(e);
            }
        }

        dispatch('notifier/globalNotify', {
            title: announcement.displayName,
            body: body,
            tag: announcement.id,
            icon: icon,
            navigateTo: '/announcements/' + tenantId + '/' + announcement.id
        }, { root: true });

        // vm._vm.$notification.show(announcement.displayName, {
        //     body: body,
        //     tag: announcement.id,
        //     icon: icon
        // }, {
        //     onclick: function () {
        //         router.push('/announcements/' + tenantId + '/' + announcement.id);
        //     }
        // })
    },

    setExpectedNumberOfGroupMembers({ commit }, params) {
        commit('SET_EXPECTED_NUMBER_OF_GROUP_MEMBERS', params);
    }
};
import axios from 'axios';

export default {
    getFaqs() {
        return axios.get(window.API_BASE + '/api/v1/FAQs');
    },

    addFaq(faq) {
        return axios.post(window.API_BASE + '/api/v1/FAQs', faq);
    },

    updateFaq(faq) {
        return axios.put(window.API_BASE + '/api/v1/FAQs/' + faq.id, faq);
    }
}
<template>
  <div class="home">
    <!-- <h1 v-if="userDisplayName" style="font-size: 30px">
      Welcome Back, {{ userDisplayName }}
    </h1> -->

    <!-- <div class="top-call-for-help">
      <a-button @click.prevent="$router.push('/call-for-help')" type="primary" size="large" class="btn-rounded">
        Call For Help
        <img src="@/assets/shield.png" height="25" style="margin-left: 15px;" alt="">
      </a-button>
    </div> -->

    <!-- Tiles Row 1 -->
    <a-row type="flex" :gutter="20" class="tiles-row-1">
      <a-col :span="8">
        <latest-chats></latest-chats>
      </a-col>
      <a-col :span="8">
        <latest-incidents></latest-incidents>
      </a-col>
       <a-col :span="8">
        <latest-announcements></latest-announcements>
      </a-col>
    </a-row>
    <!-- / Tiles Row 1 -->

    <!-- Tiles Row 2 -->
    <a-row type="flex" :gutter="30">
    <a-col :span="24">
        <latest-tasks></latest-tasks>
      </a-col>
    </a-row>
    <!-- / Tiles Row 2 -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LatestChats from "./Dashboard/LatestChats.vue";
import LatestIncidents from "./Dashboard/LatestIncidents.vue";
import LatestAnnouncements from './Dashboard/LatestAnnouncements.vue';
import LatestTasks from "./Dashboard/LatestTasks.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";
export default {
  name: "Home",
  components: {LatestChats, LatestIncidents, LatestAnnouncements, LatestTasks},
  mixins: [RequiresNonHiddenPresences],
  computed: {
    ...mapGetters("missionControl", {
      userDisplayName: "userDisplayName",
      isLoadingMissionControl: "isLoading"
    }),

    // ...mapGetters("incidents", {
    //   isLoadingIncidents: "isLoading"
    // }),

    //  ...mapGetters("incidents", {
    //   isLoadingTasks: "isLoading"
    // }),

    //   ...mapGetters("incidents", {
    //   isLoadingTasks: "isLoading"
    // }),

    // isLoading() {
    //   return (
    //     this.isLoadingMissionControl
    //     || this.isLoadingIncidents
    //     || this.isLoadingChats
    //     || this.isLoadingTasks
    //   );
    // }
  },
};
</script>

<style scoped lang="scss">
.tiles-row-1 {
  margin-bottom: 20px;

  .latest-chats, .latest-incidents {
    height: 100%;
  }
}

.top-call-for-help {
  margin-bottom: 25px;
  text-align: center;
}
</style>
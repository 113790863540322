<template>
  <div
    class="incident-list-item"
    :class="{ unread: isUnread, 'in-widget': inWidget }"
    @click.prevent="navigateToIncident"
  >
    <div class="left">
      <incident-thumbnail
        size="large"
        :incident-id="incident.id"
        :incident-owner-id="incident.ownerId"
        :created-by="incident.createdBy"
        :tenant-id="tenantId"
      ></incident-thumbnail>
    </div>
    <div class="right" :key="timeAgoRefreshKey">
      <div class="name">
        {{ displayName }}
      </div>

      <incident-tags :incident="incident"></incident-tags>{{ ucfirst(getLastActivityHuman(incident)) }}

      <div v-if="membership" class="first-responder-wrapper">
        <span v-if="firstResponderMembership">First responded by {{ getPresenceDisplayName(firstResponderMembership) }}</span>
        <span class="text-danger" v-else>❗ Not responded to</span>
      </div>
    </div>
    <div class="super-right" v-if="!hidePin">
      <mute-display-button v-if="isGroupMuted(incident)"></mute-display-button>
      <pin-button :guid="incident.id"></pin-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import organisations from "../../helpers/organisations";
import Dates from "./../../mixins/Dates";
import TimeAgoRefresher from "./../../mixins/TimeAgoRefresher";
import IncidentThumbnail from "../../components/Incidents/IncidentThumbnail.vue";
import IncidentTags from "../../components/Incidents/IncidentTags.vue";
import incidents from "../../api/incidents";
import PinButton from "../../components/PinButton.vue";
import MuteDisplayButton from "../../components/MuteDisplayButton.vue";
const _ = require("lodash");
import eventBus from "../../event-bus";

export default {
  components: { IncidentThumbnail, IncidentTags, PinButton, MuteDisplayButton },
  props: ["incident", "hidePin", "inWidget"],
  mixins: [Dates, TimeAgoRefresher],
  watch: {
    incident() {
      this.$forceUpdate();
    }
  },
  data() {
    return {
      // membership: [],
      // isLoadingMembership: false,
      // groupMembers: [],
      // isLoadingGroupMember: false,
    };
  },
  created() {
    // this.getMembership();
    // this.getGroupMembers();
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      presences: "presences",
    }),

    ...mapGetters("incidents", {
      searchQuery: "searchQuery",
      unreadIncidents: "unreadIncidents",
    }),

    ...mapGetters('mutedGroups', {
        mutedGroupIds: 'mutedGroupIds'
    }),

    isLoadingMembership() {
      return this.incident.isLoadingMembership !== false;
    },

    membership() {
      return this.incident.membership ? this.incident.membership : [];
    },

    isLoadingGroupMembers() {
      return this.incident.isLoadingGroupMembers !== false;
    },

    groupMembers() {
      return this.incident.groupMembers ? this.incident.groupMembers : [];
    },

    tenantId() {
      let organisation = this.getOrganisationById(this.incident.ownerId);
      return organisations.getOrganisationTenantId(organisation);
    },

    displayName() {
      if (this.incident.displayName && this.incident.displayName.length) {
        return this.incident.displayName;
      }
      return "Unknown";
    },

    isUnread() {
      return _.filter(this.unreadIncidents, { id: this.incident.id }).length;
    },

    myMembership() {
      if (!this.membership) {
        return null;
      }

      let myPresenceIds = _.map(this.presences, "id");
      let intersection = _.intersection(
        myPresenceIds,
        _.map(this.membership, "id")
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    myGroupMember() {
      if (this.myMembership) {
        return _.find(this.groupMembers, (groupMember) => {
          return groupMember.presenceId == this.myMembership;
        });
      }
      return null;
    },

    firstResponderMembership() {
      if (this.incident && this.incident['content']['$v']['firstResponder']) {
        return _.find(this.membership, (member) => {
              return member.id == this.incident['content']['$v']['firstResponder']
        });
      }
      return null;
    }
  },
  methods: {
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    isGroupMuted(incident) {
      return this.mutedGroupIds.includes(incident.id);
    },

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getIncidentUrl(incident) {
      return "/incidents/" + this.tenantId + "/" + incident.id;
    },

    navigateToIncident() {
      if (this.inWidget) {
        eventBus.$emit('widget-navigated-to-incident');
      }

      this.$router.push(this.getIncidentUrl(this.incident));
    },

    getPresenceDisplayName(presence) {
      if (presence.id === this.myMembership) {
        return "You (" + presence.displayName + ")";
      }
      return presence.displayName;
    },

    getMembership() {
      let vm = this;
      vm.isLoadingMembership = true;
      incidents
        .getMembershipForIncident(this.tenantId, this.incident.id)
        .then((r) => {
          vm.isLoadingMembership = false;
          vm.membership = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading membership");
        });
    },

    getGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;
      incidents
        .getGroupMembersForIncident(this.tenantId, this.incident.id)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.groupMembers = r.data;
        })
        .catch((e) => {
          console.loog(e);
          // vm.$message.error("Error loading group members");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.incident-list-item {
  background: #fff;
  padding: 20px 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  &.in-widget {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  display: flex;

  .pin-button {
    margin-left: 18px;
  }

  .muted-button {
    padding-left: 10px;
  }

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

   .super-right {
    padding-left: 5px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  &.unread {
    .name {
      font-weight: 700 !important;
      color: #000;
    }
    font-weight: 500;
    color: #000;
  }
}

.text-danger {
  color: #f5222d;
}

.first-responder-wrapper {
  margin-top: 1px;
}
</style>
import faqs from '../../api/faqs'
let _ = require('lodash');

export const state = {
    isLoading: false,
    serverErrors: [],

    faqs: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_FAQS(state, faqs) {
        state.faqs = faqs
    },

    SET_ERRORS(self, serverErrors) {
        state.serverErrors = serverErrors
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    faqs: (state) => {
        return state.faqs
    }
};

export const actions = {
    loadFaqs({ commit }) {
        commit('START_LOADING');

        faqs.getFaqs().then(r => {
            commit('STOP_LOADING');
            commit('SET_FAQS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_FAQS', []);

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    }
};
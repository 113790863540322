import axios from "axios"

export default {
    getMembershipForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Presences');
    },

    getGroupMembersForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Members');
    },

    getSkillsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills');
    },

    getOrgThumbProtectedUrl(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Thumb');
    },

    getOrgBannerImageProtectedUrl(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Banner');
    },

    getLocationsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Locations');
    },

    getManagedChatsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Chats');
    },

    getAnnouncementsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Announcements');
    },

    getGuidancePacksForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/GuidancePacks');
    },

    getStatisticsForOrg(tenantId, orgId, startDate, endDate) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Statistics/' + orgId + '/' + startDate + '/' + endDate);
    },

    getMetricsForOrg(tenantId, orgId, startDate, endDate) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/StatisticTiles/' + orgId + '/' + startDate + '/' + endDate);
    }
}
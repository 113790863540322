import organisationHelpers from "../../helpers/organisations";
import archivedIncidents from "../../api/archived-incidents";

export const state = {
    isLoading: false,

    startDate: null,
    endDate: null,
    incidentType: undefined,
  
    hasSearchBeenPerformed: false,
    searchResults: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_START_DATE(state, startDate) {
        state.startDate = startDate
    },

    SET_END_DATE(state, endDate) {
        state.endDate = endDate
    },

    SET_INCIDENT_TYPE(state, incidentType) {
        state.incidentType = incidentType
    },

    SET_SEARCH_HAS_BEEN_PERFORMED(state, boolVal) {
        state.hasSearchBeenPerformed = boolVal
    },

    SET_SEARCH_RESULTS(state, searchResults) {
        state.searchResults = searchResults
    }
}

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    startDate: (state) => {
        return state.startDate
    },

    endDate: (state) => {
        return state.endDate
    },

    incidentType: (state) => {
        return state.incidentType
    },

    hasSearchBeenPerformed: (state) => {
        return state.hasSearchBeenPerformed
    },

    searchResults: (state) => {
        return state.searchResults
    }
}

export const actions = {
    reset({commit}) {
        commit('SET_START_DATE', window.moment().startOf('day').subtract(1, 'month'));
        commit('SET_END_DATE', window.moment().endOf('day'));
        commit('SET_INCIDENT_TYPE', undefined);
        commit('SET_SEARCH_HAS_BEEN_PERFORMED', false);
        commit('SET_SEARCH_RESULTS', []);
    },

    setStartDate({commit}, startDate) {
        commit('SET_START_DATE', startDate);
    },

    setEndDate({commit}, endDate) {
        commit('SET_END_DATE', endDate);
    },

    setIncidentType({commit}, incidentType) {
        commit('SET_INCIDENT_TYPE', incidentType);
    },

    searchIncidents({commit, rootGetters, getters}) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_SEARCH_RESULTS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        let searchCriteria = {
            OrgId: org.id,
            SearchType: 1,
            CreatedAfter: getters.startDate.clone().startOf('day').format() + ' Europe/London',
            CreatedBefore: getters.endDate.clone().endOf('day').format() + ' Europe/London'
        };

        if (getters.incidentType) {
            searchCriteria['IncidentTypeId'] = getters.incidentType
        }

        commit('START_LOADING');
        archivedIncidents.searchArchivedIncidents(tenantId, searchCriteria).then(r => {
            commit('STOP_LOADING');
            commit('SET_SEARCH_HAS_BEEN_PERFORMED', true);
            commit('SET_SEARCH_RESULTS', r.data);
        }).catch(e => {
            commit('SET_SEARCH_RESULTS', []);
            console.log(e);
            this._vm.$message.error('Error searching archived incidents');
        });
    }
}
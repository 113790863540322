import axios from 'axios';

export default {
    getPermissions() {
        return axios.get(window.API_BASE + '/api/v1/ApiGrants');
    },

    getApiClients(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients');
    },

    getApiClientForOrg(tenantId, orgId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId);
    },

    getApiClientLinkTarget(apiClientLinkTargetId) {
        return axios.get(window.API_BASE + '/api/v1/ApiClientLinkTargets/' + apiClientLinkTargetId);
    },

    getApiClientLookupsForOrg(tenantId, orgId, apiClientId, lookupScenario) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ApiClients/' + apiClientId + '/ApiLookups/' + lookupScenario);
    },

    getApiClientThumbProtectedUrl(tenantId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ApiClients/' + apiClientId + '/Thumb');
    },

    getApiClientGroupLinksForIncident(tenantId, orgId, groupId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Incidents/' + groupId + '/ApiLinks');
    },

    addApiClientGroupLink(tenantId, apiClientGroupLinkRequest) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/ApiLinks', apiClientGroupLinkRequest);
    },

    addApiClient(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/ApiClients', params);
    },

    updateApiClient(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/ApiClients', params); 
    },

    addApiClientThumb(tenantId, apiClientId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/ApiClients/' + apiClientId + '/Thumb',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    deleteApiClientThumb(tenantId, apiClientId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/ApiClients/' + apiClientId + '/Thumb');
    },

    getApiClientLogoProtectedUrl(tenantId, apiClientId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ApiClients/' + apiClientId + '/Logo');
    },

    addApiClientLogo(tenantId, apiClientId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/ApiClients/' + apiClientId + '/Logo',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    deleteApiClientLogo(tenantId, apiClientId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/ApiClients/' + apiClientId + '/Logo');
    }
}
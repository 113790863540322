import axios from 'axios'

export default {
    addArticle(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Articles', params);
    },

    getArticles(tenantId, orgId, includeHidden = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Articles';
        if (includeHidden) {
            query = query + '?includeHidden=true'
        }
        return axios.get(query);
    },

    getArticle(tenantId, articleId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId);
    },

    updateArticle(tenantId, articleId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId, params);
    },

    deleteArticle(tenantId, articleId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId);
    },

    attachArticleMediaStreamed(tenantId, articleId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId + '/Media/Stream',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ); 
    },

    addArticleButtonStrip(tenantId, articleId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId + '/ButtonStrip',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    getArticleButtonStripProtectedUrl(tenantId, articleId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId + '/ButtonStrip');
    },

    deleteArticleButtonStrip(tenantId, articleId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + articleId + '/ButtonStrip');
    }
}
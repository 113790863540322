import externalTriggers from "../../api/external-triggers";
import organisationHelpers from "../../helpers/organisations";
const _ = require('lodash');

export const state = {
    isLoadingTriggerSources: false,
    triggerSources: [],

    isLoadingTriggerFormFactors: false,
    triggerFormFactors: []
};

export const mutations = {
    START_LOADING_TRIGGER_SOURCES(state) {
        state.isLoadingTriggerSources = true
    },

    STOP_LOADING_TRIGGER_SOURCES(state) {
        state.isLoadingTriggerSources = false
    },

    SET_TRIGGER_SOURCES(state, triggerSources) {
        state.triggerSources = triggerSources
    },

    START_LOADING_TRIGGER_FORM_FACTORS(state) {
        state.isLoadingTriggerFormFactors = true
    },

    STOP_LOADING_TRIGGER_FORM_FACTORS(state) {
        state.isLoadingTriggerFormFactors = false
    },

    SET_TRIGGER_FORM_FACTORS(state, triggerFormFactors) {
        state.triggerFormFactors = triggerFormFactors
    },

    UPDATE_SMART_BUTTON_LOCALLY(state, updatedSmartButton) {
        state.triggerSources = _.map(state.triggerSources, triggerSource => {
            if (updatedSmartButton.id == triggerSource.id) {
                return updatedSmartButton;
            } else {
                return triggerSource;
            }
        });
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoadingTriggerSources || state.isLoadingTriggerFormFactors
    },

    isLoadingTriggerFormFactors: (state) => {
        return state.isLoadingTriggerFormFactors;
    },

    triggerSources: (state) => {
        return state.triggerSources
    },

    triggerFormFactors: (state) => {
        return state.triggerFormFactors
    }
};

export const actions = {
    load({ dispatch }) {
        dispatch('loadTriggerSources');
        dispatch('loadTriggerFormFactors');
    },

    loadTriggerSources({ commit, rootGetters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_TRIGGER_SOURCES', []);
            return false;
        }

        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        commit('START_LOADING_TRIGGER_SOURCES');
        externalTriggers.getTriggerSourcesForOrg(tenantId, org.id).then(r => {
            commit('SET_TRIGGER_SOURCES', r.data);
            commit('STOP_LOADING_TRIGGER_SOURCES');
        }).catch(e => {
            console.log(e);
        });
    },

    loadTriggerFormFactors({ commit }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING_TRIGGER_FORM_FACTORS');
            externalTriggers.getTriggerFormFactors().then(r => {
                commit('SET_TRIGGER_FORM_FACTORS', r.data);
                commit('STOP_LOADING_TRIGGER_FORM_FACTORS');
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                reject(e);
            });
        });
    },

    updateSmartButtonLocally({ commit }, smartButton) {
        commit('UPDATE_SMART_BUTTON_LOCALLY', smartButton);
    }
};


import axios from 'axios';

export default {
    getTriggerFormFactorThumb(triggerFormFactorId) {
        return axios.get(window.API_BASE + '/api/v1/TriggerFormFactor/' + triggerFormFactorId + '/Thumb');
    },

    getTriggerSource(triggerUniqueId) {
        return axios.get(window.API_BASE + '/api/v1/TriggerSource/' + triggerUniqueId);
    },

    addTriggerSource(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/TriggerSource', params);
    },

    updateTriggerSource(triggerUniqueId, params) {
        return axios.put(window.API_BASE + '/api/v1/TriggerSource/' + triggerUniqueId, params);
    },

    getTriggerFormFactors() {
        return axios.get(window.API_BASE + '/api/v1/TriggerFormFactor');
    },

    getTriggerFormFactorEvents(triggerFormFactorId) {
        return axios.get(window.API_BASE + '/api/v1/TriggerFormFactor/' + triggerFormFactorId);
    },

    getTriggerSourcesForSelf() {
        return axios.get(window.API_BASE + '/api/v1/self/TriggerSource');
    },

    getTriggerSourcesForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/TriggerSource');
    },

    getActionsForTriggerSource(tenantId, triggerSourceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/TriggerSource/' + triggerSourceId + '/TriggerAction');
    },

    deleteTriggerSource(triggerUniqueId) {
        return axios.delete(window.API_BASE + '/api/v1/TriggerSource/' + triggerUniqueId);
    }
}
<template>
  <div class="parent-children-page">
    <!-- Header -->
    <div class="topic-header">
      <div class="left">
        <a-button
          @click.prevent="() => selectParent(null)"
          type="link"
          icon="left"
        ></a-button>
      </div>
      <div class="right">
        <div class="name">
          {{ selectedParent["$v"]["displayName"] }}
        </div>
      </div>
    </div>
    <!-- / Header -->

    <!-- Topic -->
    <guidance-pack-topic-card
      @selected="() => handleTopicSelected(topic)"
      @send="(params) => $emit('send', params)"
      :show-send-button="showSendButton"
      v-for="topic in selectedParentChildrenSorted"
      :topic="topic"
      :key="topic['$v']['id']"
    ></guidance-pack-topic-card>
    <!-- / Topic -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GuidancePackTopicCard from "./GuidancePackTopicCard";
const _ = require('lodash');
export default {
  props: ['showSendButton'],
  computed: {
    ...mapGetters("guidancePackViewer", {
      selectedParent: "selectedParent",
      selectedParentChildren: "selectedParentChildren",
    }),

    selectedParentChildrenSorted() {
      return _.orderBy(this.selectedParentChildren, ["$v.rank", "$v.displayName"], ['desc', 'asc']);
    }
  },
  components: { GuidancePackTopicCard },
  methods: {
    ...mapActions("guidancePackViewer", {
      selectParent: "selectParent",
      selectTopic: "selectTopic",
    }),

    handleTopicSelected(topic) {
      if (topic["$t"] == "Soteria.Models.ExternalLinkDetails, Soteria.Models") {
        window.open(topic["$v"]["url"], "_blank");
      } else {
        this.selectTopic(topic["$v"]["id"]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.parent-children-page {
  .topic-header {
    background: #fff;
    border-radius: 10px;
    padding: 13px 10px;
    margin-bottom: 15px;
    display: flex;

    .left {
      flex-shrink: 1;
      padding-right: 10px;
    }

    .right {
      flex-grow: 1;
      display: flex;
      align-items: center;
      .name {
        font-weight: 500;
      }
    }
  }

  .guidance-pack-topic-card {
    margin-bottom: 15px;
  }
}
</style>
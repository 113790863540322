import self from '../../api/self'
let _ = require('lodash');
import announcementHelpers from "../../helpers/announcements";

export const state = {
    isLoading: false,
    serverErrors: [],

    tasks: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_TASKS(state, tasks) {
        state.tasks = tasks
    },

    SET_ERRORS(self, serverErrors) {
        state.serverErrors = serverErrors
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    tasks: (state) => {
        return state.tasks
    },

    tasksOrdered: (state, getters) => {
        return announcementHelpers.sortByCreated(getters.tasks);
    },

    incompleteTaskCount: (state) => {
        return _.filter(state.tasks, task => {
            return (!task['content']['$v']['status']) || task['content']['$v']['status'] == 0;
        }).length
    }
};

export const actions = {
    loadTasks({ commit }) {
        commit('START_LOADING');

        self.getAssignments().then(r => {
            commit('STOP_LOADING');
            commit('SET_TASKS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_TASKS', []);
            // this._vm.$message.error('Error loading tasks');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    }
};
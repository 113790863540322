import moment from "moment"
const humanizeDuration = require("humanize-duration");

export default {
    methods: {
        getLastActivityHuman(chat, allowDatesInFuture = false) {
            let m = window.moment(chat.lastActivity)
            if (m.isValid()) {
                let str = m.fromNow();
                if (allowDatesInFuture) {
                    return str;
                } else {
                    return str.replace('in a few seconds', 'a few seconds ago');
                }
            }
            return '-'
        },

        displayTimeAgo(datetime, allowDatesInFuture=false) {
            let str = moment(datetime).fromNow();
            if (allowDatesInFuture) {
                return str;
            } else {
                return str.replace('in a few seconds', 'a few seconds ago');
            }
        }, 

        getTimeDifference(time1, time2) {
            let t1moment = moment(time1);
            let t2moment = moment(time2);
            if (t1moment.isValid() && t2moment.isValid()) {
                return humanizeDuration(t1moment.diff(t2moment), {
                    units: ['y', 'mo', 'w', 'd', 'h', 'm', 's'],
                    maxDecimalPoints: 0
                });
            }
            return null;
        }
    }
}
<template>
  <div class="tsos-popup-conversations-view">

    <!-- Organisation Selector -->
     <organisation-selector></organisation-selector>
    <!-- / Organisation Selector -->

    <div v-if="selectedOrganisation" class="tsos-widget-incident-types-wrapper">
      <incident-type-selector></incident-type-selector>
    </div>

    <!-- Incident Types -->
    <!-- <div class="incident-types">
      <div v-for="incidentType in incidentTypes" 
      :key="incidentType.name" 
      @click.prevent="handleConversationSelected(incidentType)"
      class="incident-type">
        <div class="left">
          {{ incidentType.name }}
        </div>
        <div class="right">
          {{ incidentType.type }}
        </div>
      </div>
    </div> -->
    <!-- / Incident Types -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrganisationSelector from './CallForHelpView/OrganisationSelector.vue';
import IncidentTypeSelector from './CallForHelpView/IncidentTypeSelector.vue';

export default {
  data() {
    return {
      incidentTypes: [
        {
          name: 'Violence',
          type: 'SOS'
        },
        {
          name: 'Medical emergency',
          type: 'SOS'
        },
        {
          name: 'Site safety',
          type: 'SOS'
        },

        {
          name: 'Wellbeing at work',
          type: '1 to 1'
        },
        {
          name: 'Minor injury',
          type: 'Medical'
        }
      ]
    }
  },

  components: {OrganisationSelector, IncidentTypeSelector},

  computed: {
    ...mapGetters('helpCaller', {
      selectedOrganisation: 'selectedOrganisation'
    })
  },

  created() {},

  methods: {
    ...mapActions('tsosWidget', {
      navigateToRoute: 'navigateToRoute'
    }),

    handleConversationSelected() {
      this.navigateToRoute('chat');
    },

    getInitials(name) {
      const initials = name.match(/\b\w/g) || []
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    },
  }
}
</script>

<style scoped lang="scss">
.tsos-popup-conversations-view {
  // padding: 10px 15px !important;
  padding: 15px 15px !important;
  min-height: 250px;

  max-height: calc(100vh - 80px - 115px - 60px - 50px);
  overflow-y: scroll;

  background: #fff;

  .loading-wrapper {
    padding: 15px 10px;
  }

  .no-conversations-wrapper {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .incident-types {
    .incident-type {
      display: flex;

      background: rgb(255, 255, 255);
      padding: 16px 20px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;
      border: 1px solid rgb(255, 255, 255);

      &:hover {
        color: rgb(81, 85, 234);
        border: 1px solid rgb(81, 85, 234);
      }

      .left {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }

      .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
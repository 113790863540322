import axios from 'axios';

export default {
    searchRespondersBySkillAndAvailableTime(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Search/Schedules', params);
    },

    addSchedule(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Schedules', params);
    },

    updateSchedule(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Schedules', params);
    },

    deleteSchedule(tenantId, scheduleId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Schedules/' + scheduleId);
    },

    getSchedulesForPresence(tenantId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId + '/Schedules');
    },

    addOverride(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Overrides', params);
    },

    deleteOverride(tenantId, overrideId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Overrides/' + overrideId);
    },

    getScheduleOverridesForPresence(tenantId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId + '/Overrides');
    },

    getTimetableWeekNumberForOrg(tenantId, orgId, zonedDateTime) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/TimetableDates/Now', zonedDateTime,
            {
                headers: {
                    "content-type": "application/json"
                }
            }
        );
    }
}
<template>
  <div
    class="guidance-pack-topic-card"
    @click.prevent="$emit('selected', topic)"
  >
    <div class="left" v-if="shouldShowLeft" :style="leftStyle"></div>
    <div class="right">
      <div class="name">
        <!-- {{ topic["$v"] }} -->
        {{ topic["$v"]["displayName"] }}
      </div>
      <div>
        {{ topic["$v"]["contextQuote"] }}
      </div>
      <div v-if="showSendButton" class="send-button">
        <a-button type="primary" @click.prevent.stop="emitSend">Share</a-button>
      </div>
      <div class="last-viewed"
      :key="timeAgoRefreshKey"
       v-if="shouldShowLastViewed">
        {{ lastViewed }}
      </div>
      <div
        v-if="shouldShowProgressBar"
        class="progress-bar"
        :style="progressBarStyle"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import guidancePacks from "../../../api/guidance-packs";
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
const _ = require("lodash");
export default {
  props: ["topic", "showSendButton"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      isLoadingButtonStripUrl: false,
      buttonStripUrl: null,
    };
  },
  computed: {
    ...mapGetters("guidancePackViewer", {
      tenantId: "tenantId",
      guidancePack: "guidancePack",
      guidancePackContents: "guidancePackContents",
    }),

    ...mapGetters("guidance", {
      lastViews: "lastViews",
    }),

    shouldShowLastViewed() {
      return this.topic["$t"] !== "Soteria.Models.TopicDetails, Soteria.Models";
    },

    shouldShowLeft() {
      let showFor = [
        "Soteria.Models.TopicDetails, Soteria.Models",
        "Soteria.Models.ArticleDetails, Soteria.Models",
        "Soteria.Models.HelpVideoDetails, Soteria.Models",
      ];
      return showFor.includes(this.topic["$t"]);
    },

    leftStyle() {
      if (this.buttonStripUrl) {
        return {
          backgroundImage: "url(" + this.buttonStripUrl.url + ")",
        };
      }
      return {
        backgroundColor: "rgba(81, 85, 234, 0.1)",
      };
    },

    isTopic() {
      return this.topic["$t"] == "Soteria.Models.TopicDetails, Soteria.Models";
    },

    shouldShowProgressBar() {
      return this.isTopic;
    },

    contentLinksForSelectedTopic() {
      if (!this.isTopic) {
        return null;
      }
      return _.filter(this.guidancePack["$v"]["contentLinks"], (cl) => {
        return cl.parentEntityId == this.topic["$v"]["id"];
      });
    },

    idsOfContentLinkChildrenForSelectedTopic() {
      if (!this.isTopic) {
        return null;
      }
      return _.map(this.contentLinksForSelectedTopic, "childEntityId");
    },

    percentageOfTopicRead() {
      if (!this.isTopic) {
        return null;
      }

      let viewedEntityIds = _.map(this.lastViews, "viewedEntityId");

      let intersection = _.intersection(
        this.idsOfContentLinkChildrenForSelectedTopic,
        viewedEntityIds
      );

      // Last major change content > last read at - don't include in read
      let validIntersection = [];
      _.each(intersection, (entityId) => {
        let entity = _.find(this.guidancePackContents, (content) => {
          return content["$v"]["id"] == entityId;
        });
        if (entity) {
          if (entity["$v"]["lastMajorChange"]) {
            let lastMajorChangeMoment = window.moment(
              entity["$v"]["lastMajorChange"]
            );

            let lastView = _.find(this.lastViews, (lv) => {
              return lv["viewedEntityId"] == entityId;
            });
            let viewedAtMoment = window.moment(lastView["viewedAt"]);
            if (viewedAtMoment.isAfter(lastMajorChangeMoment)) {
              validIntersection.push(entityId);
            }
          } else {
            validIntersection.push(entityId);
          }
        } else {
          validIntersection.push(entityId);
        }
      });

      if (validIntersection == 0) {
        return 3; // so some progress
      } else {
        return (
          (100 * validIntersection.length) /
          this.idsOfContentLinkChildrenForSelectedTopic.length
        );
      }
    },

    progressBarStyle() {
      return {
        width: this.percentageOfTopicRead + "%",
      };
    },

    lastViewsOrderedByViewedAt() {
      return _.orderBy(this.lastViews, "viewedAt", "desc");
    },

    lastViewed() {
      let lastView = _.find(this.lastViewsOrderedByViewedAt, {
        viewedEntityId: this.topic["$v"]["id"],
      });
      if (lastView) {
        let lastViewMoment = window.moment(lastView.viewedAt);

        if (
          this.topic["$v"]["lastMajorChange"] &&
          window
            .moment(this.topic["$v"]["lastMajorChange"])
            .isAfter(lastViewMoment)
        ) {
          return "This has changed since you last viewed it";
        }

        return "You viewed this " + this.displayTimeAgo(lastView.viewedAt);
      }
      return "";
    },
  },
  created() {
    this.getButtonStripUrl();
  },
  methods: {
    emitSend() {
      this.$emit("send", {
        guidancePackId: this.guidancePack["$v"]["id"],
        contentId: this.topic["$v"]["id"],
        name:
          this.guidancePack["$v"]["displayName"] +
          "\n" +
          this.topic["$v"]["displayName"],
          guidancePackName: this.guidancePack['$v']['displayName'],
          contentName: this.topic['$v']['displayName']
      });
    },

    getButtonStripUrl() {
      if (this.topic["$t"] == "Soteria.Models.TopicDetails, Soteria.Models") {
        this.getTopicButtonStripUrl();
      }
      if (this.topic["$t"] == "Soteria.Models.ArticleDetails, Soteria.Models") {
        this.getArticleButtonStripUrl();
      }
      if (
        this.topic["$t"] == "Soteria.Models.HelpVideoDetails, Soteria.Models"
      ) {
        this.getHelpVideoButtonStripUrl();
      }
    },

    getTopicButtonStripUrl() {
      let vm = this;
      vm.isLoadingButtonStripUrl = true;
      guidancePacks
        .getTopicButtonStripProtectedUrl(this.tenantId, this.topic["$v"]["id"])
        .then((r) => {
          vm.isLoadingButtonStripUrl = false;
          vm.buttonStripUrl = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading image");
        });
    },

    getArticleButtonStripUrl() {
      let vm = this;
      vm.isLoadingButtonStripUrl = true;
      guidancePacks
        .getArticleButtonStripProtectedUrl(
          this.tenantId,
          this.topic["$v"]["id"]
        )
        .then((r) => {
          vm.isLoadingButtonStripUrl = false;
          vm.buttonStripUrl = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading image");
        });
    },

    getHelpVideoButtonStripUrl() {
      let vm = this;
      vm.isLoadingButtonStripUrl = true;
      guidancePacks
        .getHelpVideoButtonStripProtectedUrl(
          this.tenantId,
          this.topic["$v"]["id"]
        )
        .then((r) => {
          vm.isLoadingButtonStripUrl = false;
          vm.buttonStripUrl = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading image");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.guidance-pack-topic-card {
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;

  .last-viewed {
    text-align: right;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    background-size: cover;
    background-position: center;
  }
  .right {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    .name {
      margin-bottom: 5px;
      font-weight: 500;
    }
    position: relative;
    .progress-bar {
      background: #87d13e;
      display: block;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
.send-button {
  margin-top: 10px;
}
</style>
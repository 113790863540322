<template>
  <div>
    <a-avatar
      :size="size ? size : 'default'"
      v-if="thumbnail"
      :src="thumbnail.url"
    />
    <a-avatar :size="size ? size : 'default'" icon="loading" v-else />
  </div>
</template>

<script>
import organisations from "../../api/organisations";
import { mapActions } from "vuex";
export default {
  props: ["size", "tenantId", "orgId"],
  data() {
    return {
      isLoadingThumbnail: false,
      thumbnail: null,
    };
  },
  created() {
    this.attemptGetFromCacheOrLoad();
  },
  methods: {
    ...mapActions("temporaryImages", {
      getTemporaryImageByKey: "getTemporaryImageByKey",
      addTemporaryImageToCache: "addTemporaryImageToCache",
    }),

    attemptGetFromCacheOrLoad() {
      let vm = this;
      this.getTemporaryImageByKey("organisation-thumbnail-" + this.orgId)
        .then((r) => {
          vm.thumbnail = r;
        })
        .catch(() => {
          vm.getThumbnailUrl();
        });
    },

    getThumbnailUrl() {
      let vm = this;
      vm.isLoadingThumbnail = true;
      organisations
        .getOrgThumbProtectedUrl(this.tenantId, this.orgId)
        .then((r) => {
          vm.isLoadingThumbnail = false;
          vm.thumbnail = r.data;
          vm.addTemporaryImageToCache({
            key: "organisation-thumbnail-" + this.orgId,
            value: vm.thumbnail,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style>
</style>
import playbooks from "../../api/guidance/playbooks";
import checklists from "../../api/checklists";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");

export const state = {
    isLoadingPlaybooks: false,
    playbooks: [],

    isLoadingChecklistContents: false,
    checklistContents: []
}

export const mutations = {
    START_LOADING_PLAYBOOKS(state) {
        state.isLoadingPlaybooks = true
    },

    STOP_LOADING_PLAYBOOKS(state) {
        state.isLoadingPlaybooks = false
    },

    SET_PLAYBOOKS(state, playbooks) {
        state.playbooks = playbooks
    },

    START_LOADING_CHECKLIST_CONTENTS(state) {
        state.isLoadingChecklistContents = true
    },

    STOP_LOADING_CHECKLIST_CONTENTS(state) {
        state.isLoadingChecklistContents = false
    },

    SET_CHECKLIST_CONTENTS(state, checklistContents) {
        state.checklistContents = checklistContents
    },

    UPDATE_CHECKLIST_CONTENT_LOCALLY(state, updatedChecklistContent) {
        state.checklistContents = _.map(state.checklistContents, checklistContent => {
            if (checklistContent['$v']['id'] == updatedChecklistContent['$v']['id']) {
                return updatedChecklistContent;
            } else {
                return checklistContent;
            }
        });
    }
}

export const getters = {
    isLoading: (state) => (state.isLoadingPlaybooks || state.isLoadingChecklistContents),
    isLoadingPlaybooks: (state) => state.isLoadingPlaybooks,
    isLoadingChecklistContents: (state) => state.isLoadingChecklistContents,
    playbooks: (state) => (state.playbooks),
    checklistContents: (state) => (state.checklistContents),
    playbooksForSelectedOwnerId: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.playbooks, playbook => {
                return playbook['ownerId'] == ownerIdFilter
            });
        }
        return state.playbooks;
    },
    checklistContentsForSelectedOwnerId: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.checklistContents, checklistContent => {
                return checklistContent['$v']['ownerId'] == ownerIdFilter
            });
        }
        return state.checklistContents; 
    },
    checklistContentsChecklistIds: (state, getters) => {
        return _.map(getters.checklistContentsForSelectedOwnerId, '$v.checklistId');
    },
    playbooksToShow: (state, getters) => {
        return _.filter(getters.playbooksForSelectedOwnerId, playbook => {
            return getters.checklistContentsChecklistIds.includes(playbook.id)
        });
    },
    playbooksToLink: (state, getters) => {
        return _.filter(getters.playbooksForSelectedOwnerId, playbook => {
            return !getters.checklistContentsChecklistIds.includes(playbook.id)
        }); 
    }
}

export const actions = {
    reset({ commit }) {
        commit('SET_PLAYBOOKS', []);
        commit('SET_CHECKLIST_CONTENTS', []);
    },

    loadPlaybooks({ dispatch }) {
        dispatch('loadChecklists');
        dispatch('loadChecklistContents');
    },

    loadChecklists({ commit, rootGetters }) {
        commit('START_LOADING_PLAYBOOKS');

        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_PLAYBOOKS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        playbooks.getPlaybooksForOrg(tenantId, org.id, true, false).then(r => {
            commit('STOP_LOADING_PLAYBOOKS');
            commit('SET_PLAYBOOKS', r.data);
        }).catch(e => {
            console.log(e);
            this._vm.$message.error('Error loading playbooks');
        })
    },

    loadChecklistContents({ commit, rootGetters }) {
        commit('START_LOADING_CHECKLIST_CONTENTS');

        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_PLAYBOOKS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        checklists.getChecklistContents(tenantId, org.id, true).then(r => {
            commit('STOP_LOADING_CHECKLIST_CONTENTS');
            commit('SET_CHECKLIST_CONTENTS', r.data);
        }).catch(e => {
            console.log(e);
            this._vm.$message.error('Error loading checklist contents');
        })
    },

    updateChecklistContentLocally({ commit }, checklistContent) {
        commit('UPDATE_CHECKLIST_CONTENT_LOCALLY', checklistContent);
    }
}
<template>
  <div class="create-chat-modal-topbar">
    <div class="left">
      <div class="topbar-avatars">
        <presence-thumbnail
          v-for="member in allMembers"
          :key="member.id"
          :presence-id="member.id"
          :owner-id="member.ownerId"
        ></presence-thumbnail>
      </div>
    </div>
    <div class="right">
      <a-button
        v-if="selectedMembers.length <= 1"
        :disabled="selectedMembers.length === 0"
        key="submit"
        type="primary"
        :loading="isSaving"
        @click.prevent="createChat"
      >
        Chat
      </a-button>
      <a-button
        v-if="selectedMembers.length > 1"
        key="submit"
        type="primary"
        @click.prevent="goToNextStage"
      >
        Next
      </a-button>
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
const _ = require("lodash");
export default {
  props: ["myMembership", "members", "selectedMembers", "isSaving"],
  components: { PresenceThumbnail },
  computed: {
    allPresenceIds() {
      return _.flattenDeep([this.myMembership.id, this.selectedMembers]);
    },
    allMembers() {
      let vm = this;
      let selectedMemberDetails = _.filter(this.members, (member) => {
        return vm.selectedMembers.includes(member.id);
      });
      return _.flattenDeep([this.myMembership, selectedMemberDetails]);
    },
  },
  methods: {
    createChat() {
        this.$emit('create-chat');
    },
    goToNextStage() {
        this.$emit('go-to-next-stage');
    },
  },
};
</script>

<style scoped lang="scss">
.create-chat-modal-topbar {
  margin-bottom: 15px;
  background: rgb(247, 248, 250);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  .left {
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
  
  .topbar-avatars {
      div {
          display: inline;

          &:first-child {
              margin-left: 0;
          }

          margin-left: -12px;
      }
  }
}
</style>
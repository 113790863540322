<template>
  <div class="h100">
    <div class="h100" v-for="(m, i) in mediaToShow" :key="i">
      <!-- Soteria.Models.Attachments.LinkAttachmentSource, Soteria.Models -->
      <div
        class="h100"
         :style="wrapperStyle"
        v-if="
          m['$v']['source']['$t'] ==
          'Soteria.Models.Attachments.LinkAttachmentSource, Soteria.Models'
        "
      >
        <iframe
          width="100%"
          :src="m['$v']['source']['$v']['sourceUrl']"
          frameborder="0"
        ></iframe>
      </div>
      <!-- / Soteria.Models.Attachments.LinkAttachmentSource, Soteria.Models -->

      <!-- Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models -->
      <div
        class="h100"
        :style="wrapperStyle"
        v-if="
          m['$v']['source']['$t'] ==
          'Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models'
        "
      >
        <video controls width="100%" class="video-viewer" autoplay loop>
          <source
            :src="m['$v']['source']['$v']['sourceUrl']"
            :type="m['$v']['source']['$v']['contentType']"
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <!-- / Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models -->
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  props: ["media", "maxHeight"],
  computed: {
    wrapperStyle() {
      if (this.maxHeight) {
        return {
          maxHeight: this.maxHeight + 'px',
          height: this.maxHeight + 'px'
        }
      }
      return [];
    },
    numberOfMedia() {
      return this.media.length;
    },
    orientationToShow() {
      // 1 is portrait
      // 2 is landscape

      let showPortrait = window.matchMedia("(orientation: portrait)").matches;
      return showPortrait ? 1 : 2;
      //   return 2;
    },
    mediaToShow() {
      let vm = this;
      if (this.numberOfMedia > 1) {
        return _.filter(this.media, (m) => {
          if (m["$v"] && m["$v"]["orientation"]) {
            return m["$v"]["orientation"] == vm.orientationToShow;
          }
          return true;
        });
      }
      return this.media;
    },
  },
};
</script>

<style scoped>
.h100 {
  height: 100%;
}

iframe {
  height: calc(100% - 100px);
  width: 100%;
  margin: 0 auto;
}

video {
  width: auto;
  height: calc(100% - 100px);
  margin: 0 auto;
}
</style>
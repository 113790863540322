import moment from "moment";
const _ = require('lodash');

export default {
    sortByLastActivity(incidents) {
        let withLastActivityMoments = _.map(incidents, i => {
            return {
                ...i,
                unix: moment(i.lastActivity).unix()
            }
        });
        return _.sortBy(withLastActivityMoments, 'unix')
    }
}
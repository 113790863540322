<template>
  <div class="incident-thumbnail-wrapper">
    <organisation-thumbnail
      class="org-thumbnail"
      v-if="userBelongsToMultipleOrganisations"
      :size="18"
      :tenant-id="tenantId"
      :org-id="incidentOwnerId"
    ></organisation-thumbnail>

    <a-avatar
      :size="size ? size : 'default'"
      v-if="thumbnail"
      :src="thumbnail.url"
    />
    <a-avatar :size="size ? size : 'default'" icon="loading" v-else />
    <!-- <a-avatar :size="size ? size : 'default'" icon="info" /> -->
  </div>
</template>

<script>
import presences from "../../api/presences";
import OrganisationThumbnail from "../../components/Organisations/OrganisationThumbnail.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["size", "tenantId", "incidentId", "incidentOwnerId", "createdBy"],
  components: { OrganisationThumbnail },
  data() {
    return {
      isLoadingThumbnail: false,
      thumbnail: null,
    };
  },
  created() {
    this.attemptGetFromCacheOrLoad();
  },
  methods: {
    ...mapActions("temporaryImages", {
      getTemporaryImageByKey: "getTemporaryImageByKey",
      addTemporaryImageToCache: "addTemporaryImageToCache",
    }),

    attemptGetFromCacheOrLoad() {
      let vm = this;
      this.getTemporaryImageByKey("presence-thumbnail-" + this.createdBy)
        .then((r) => {
          vm.thumbnail = r;
        })
        .catch(() => {
          vm.getThumbnailUrl();
        });
    },

    getThumbnailUrl() {
      let vm = this;
      vm.isLoadingThumbnail = true;
      presences
        .getPresenceThumbProtectedUrl(this.tenantId, this.createdBy)
        .then((r) => {
          vm.isLoadingThumbnail = false;
          vm.thumbnail = r.data;
          vm.addTemporaryImageToCache({
            key: "presence-thumbnail-" + this.createdBy,
            value: vm.thumbnail,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      userBelongsToMultipleOrganisations: "userBelongsToMultipleOrganisations",
    }),
  },
};
</script>

<style scoped lang="scss">
.incident-thumbnail-wrapper {
  position: relative;
}

.org-thumbnail {
  position: absolute;
  bottom: -4px;
  right: -4px;
  z-index: 1000;
}
</style>
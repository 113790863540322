import timelines from "../../api/timelines";
const _ = require('lodash')
import moment from "moment";
import eventBus from "../../event-bus";

const TIMELINE_PER_PAGE = 30;

export const state = {
    serverErrors: [],
    waypointEnabled: false,

    isLoadingTimeline: false,
    timeline: [],

    loadedEarliest: false,

    model: null,

    guid: null,
    tenantId: null,
    ownerType: null,
    ownerId: null,
    behaviour: null,
    organisationId: null,

    isLoadingQuickMessages: false,
    quickMessages: [],

    typingPresenceIds: [],

    idOfEarliestMessageBeforeLoadingMoreMessages: null
};

export const mutations = {
    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    ENABLE_WAYPOINT(state) {
        state.waypointEnabled = true
    },

    DISABLE_WAYPOINT(state) {
        state.waypointEnabled = false
    },

    SET_LOADED_EARLIEST(state, loadedEarliest) {
        state.loadedEarliest = loadedEarliest;
    },

    SET_MODEL(state, model) {
        state.model = model;
    },

    START_LOADING_TIMELINE(state) {
        state.isLoadingTimeline = true
    },

    STOP_LOADING_TIMELINE(state) {
        state.isLoadingTimeline = false
    },

    SET_TIMELINE(state, timeline) {
        state.timeline = timeline
    },

    ADD_TO_TIMELINE(state, entries) {
        state.timeline = _.unionBy(state.timeline, entries, 'id')
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId;
    },

    SET_GUID(state, guid) {
        state.guid = guid;
    },

    SET_OWNER_TYPE(state, ownerType) {
        state.ownerType = ownerType;
    },

    SET_OWNER_ID(state, ownerId) {
        state.ownerId = ownerId;
    },

    SET_BEHAVIOUR(state, behaviour) {
        state.beforeTicks = behaviour;
    },

    SET_ORGANISATION_ID(state, organisationId) {
        state.organisationId = organisationId;
    },

    START_LOADING_QUICK_MESSAGES(state) {
        state.isLoadingQuickMessages = true
    },

    STOP_LOADING_QUICK_MESSAGES(state) {
        state.isLoadingQuickMessages = false
    },

    SET_QUICK_MESSAGES(state, quickMessages) {
        state.quickMessages = quickMessages
    },

    SET_TYPING_PRESENCE_IDS(state, presenceIds) {
        state.typingPresenceIds = presenceIds
    },

    ADD_TYPING_PRESENCE_ID(state, presenceId) {
        // if (!state.typingPresenceIds.includes(presenceId)) {
        state.typingPresenceIds.push(presenceId);
        // }
        // state.typingPresenceIds = _.uniq(state.typingPresenceIds);
    },

    REMOVE_TYPING_PRESENCE_ID(state, presenceId) {
        // state.typingPresenceIds = _.without(state.typingPresenceIds, presenceId)
        for (let i = 0; i < state.typingPresenceIds.length; i++) {
            if (state.typingPresenceIds[i] == presenceId) {
                state.typingPresenceIds.splice(i, 1);
                break;
            }
        }
    },

    SET_ID_OF_EARLIEST_MESSAGE_BEFORE_LOADING_MORE_MESSAGES(state, id) {
        state.idOfEarliestMessageBeforeLoadingMoreMessages = id;
    }
};

export const getters = {
    isLoading: (state) => {
        return (state.isLoadingTimeline || state.isLoadingQuickMessages);
    },

    waypointEnabled: (state) => {
        return state.waypointEnabled
    },

    loadedEarliest: (state) => {
        return state.loadedEarliest
    },

    model: (state) => {
        return state.model
    },

    timeline: (state) => {
        return state.timeline
    },

    timelineWithMomentsSorted: (state) => {
        let timelineWithMoments = _.map(state.timeline, entry => {
            return {
                ...entry,
                moment: moment(entry.whenCreated),
                unix: moment(entry.whenCreated).unix()
            }
        })
        let sorted = _.sortBy(timelineWithMoments, 'unix');
        return sorted;
    },

    timelineGroupedByDate: (state, getters) => {
        let sorted = getters.timelineWithMomentsSorted;
        return _.groupBy(sorted, r => r.moment.format('YYYY-MM-DD'));
    },

    ticksOfEarliestMessage: (state, getters) => {
        let firstMessage = _.first(getters.timelineWithMomentsSorted);
        if (getters.timelineWithMomentsSorted) {
            return firstMessage.moment.format('x') * 10000
        }
        return null;
    },

    guid: (state) => {
        return state.guid;
    },

    tenantId: (state) => {
        return state.tenantId;
    },

    ownerType: (state) => {
        return state.ownerType;
    },

    ownerId: (state) => {
        return state.ownerId;
    },

    behaviour: (state) => {
        return state.behaviour;
    },

    organisationId: (state) => {
        return state.organisationId;
    },

    quickMessages: (state) => {
        return state.quickMessages
    },

    typingPresenceIds: (state) => {
        return state.typingPresenceIds
    },

    idOfEarliestMessageBeforeLoadingMoreMessages: (state) => {
        return state.idOfEarliestMessageBeforeLoadingMoreMessages
    }
};

export const actions = {
    reset({ commit }) {
        commit('SET_TIMELINE', []);
        commit('SET_QUICK_MESSAGES', []);
        commit('DISABLE_WAYPOINT');
        commit('SET_ID_OF_EARLIEST_MESSAGE_BEFORE_LOADING_MORE_MESSAGES', null);
    },

    loadTimeline({ commit }, params = null) {
        return new Promise((resolve, reject) => {
            let model = params['model'];
            let tenantId = params['tenantId'];
            let ownerType = params['ownerType'];
            let ownerId = params['ownerId'];
            let behaviour = params['behaviour'];
            let organisationId = params['organisationId'];
            let guid = params['guid'];

            commit('SET_MODEL', model);
            commit('SET_LOADED_EARLIEST', false);
            commit('SET_TENANT_ID', tenantId);
            commit('SET_OWNER_TYPE', ownerType);
            commit('SET_OWNER_ID', ownerId);
            commit('SET_BEHAVIOUR', behaviour);
            commit('SET_ORGANISATION_ID', organisationId);
            commit('SET_GUID', guid);
            commit('DISABLE_WAYPOINT');

            let beforeTicks;
            if (params && params.beforeTicks) {
                beforeTicks = params.beforeTicks;
            } else {
                beforeTicks = window.moment().format('x') * 10000
            }
            commit('START_LOADING_TIMELINE');
            timelines.getPreviousNTimelineEntries(tenantId, guid, beforeTicks, TIMELINE_PER_PAGE).then(r => {
                // console.log(r.data);
                commit('ADD_TO_TIMELINE', r.data);
                commit('STOP_LOADING_TIMELINE');
                setTimeout(() => {
                    commit('ENABLE_WAYPOINT');
                }, 1000);
                resolve(r.data);
            }).catch(e => {
                commit('STOP_LOADING_TIMELINE');
                commit('ENABLE_WAYPOINT');
                // this._vm.$message.error('Error loading timeline');
                console.log(e);
                let errors;
                if (e.response && e.response.data && typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            });
        });
    },

    loadEarlierMessages({ commit, getters }) {
        let beforeTicks = getters.ticksOfEarliestMessage;
        let guid = getters.guid;
        let tenantId = getters.tenantId;

        // Get earliest message
        let earlistMessageSoFar = _.first(getters.timelineWithMomentsSorted);
        if (earlistMessageSoFar) {
            commit('SET_ID_OF_EARLIEST_MESSAGE_BEFORE_LOADING_MORE_MESSAGES', earlistMessageSoFar.id);
        } else {
            commit('SET_ID_OF_EARLIEST_MESSAGE_BEFORE_LOADING_MORE_MESSAGES', null);
        }

        commit('START_LOADING_TIMELINE');
        timelines.getPreviousNTimelineEntries(tenantId, guid, beforeTicks, TIMELINE_PER_PAGE).then(r => {
            // console.log(r.data);
            if (r.data.length < TIMELINE_PER_PAGE) {
                commit('SET_LOADED_EARLIEST', true);
            }
            commit('ADD_TO_TIMELINE', r.data);
            this._vm.$nextTick(() => {
                if (getters.idOfEarliestMessageBeforeLoadingMoreMessages) {
                    eventBus.$emit('scroll-timeline-to-entry-with-id', getters.idOfEarliestMessageBeforeLoadingMoreMessages);
                }
            });
            commit('STOP_LOADING_TIMELINE');
        }).catch(e => {
            commit('STOP_LOADING_TIMELINE');
            // this._vm.$message.error('Error loading timeline');
            console.log(e);
            let errors;
            if (e.response && e.response.data && typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    addTimelineEntry({ commit }, entry) {
        commit('ADD_TO_TIMELINE', [entry]);
    },

    loadQuickMessages({ getters, commit }) {
        let tenantId = getters.tenantId;
        let organisationId = getters.organisationId;

        commit('START_LOADING_QUICK_MESSAGES');
        commit('SET_QUICK_MESSAGES', null);
        timelines.getQuickMessages(tenantId, organisationId).then(r => {
            commit('STOP_LOADING_QUICK_MESSAGES');
            commit('SET_QUICK_MESSAGES', r.data);
        }).catch(e => {
            commit('STOP_LOADING_QUICK_MESSAGES');
            // this._vm.$message.error('Error loading quick messages');
            console.log(e);
            let errors;
            if (e.response && e.response.data && typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    handlePresenceTyping({ commit }, presenceId) {
        commit('ADD_TYPING_PRESENCE_ID', presenceId);

        // window.setTimeout(() => commit('REMOVE_TYPING_PRESENCE_ID', presenceId), 2500);
        window.setTimeout(() => commit('REMOVE_TYPING_PRESENCE_ID', presenceId), 2500);
    },

    removeTypingPresenceId({ commit }, presenceId) {
        commit('REMOVE_TYPING_PRESENCE_ID', presenceId);
    }
};
import organisationHelpers from "../../helpers/organisations";
import userImports from "../../api/user-imports";

export const state = {
    isLoading: false,
    imports: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_IMPORTS(state, imports) {
        state.imports = imports
    },

    PUSH_IMPORT(state, userImport) {
        state.imports.push(userImport)
    }
}

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    imports: (state) => {
        return state.imports
    }
}

export const actions = {
    reset({ commit }) {
        commit('SET_IMPORTS', []);
    },

    pushImport({commit}, userImport) {
        commit('PUSH_IMPORT', userImport);
    },

    loadImports({ commit, rootGetters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_IMPORTS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        commit('START_LOADING');
        userImports.getUserImportsForOrg(tenantId, org.id).then(r => {
            commit('STOP_LOADING');
            commit('SET_IMPORTS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_IMPORTS', []);
            console.log(e);
            this._vm.$message.error('Error loading user imports');
        });
    }
}
<template>
  <div class="latest-tasks-wrapper">
    <div class="title-wrapper">
      <h2 class="title">Tasks</h2>
    </div>
    <div class="latest-tasks" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <div class="latest-tasks" v-if="!isLoading">

      <incident-task-group
        v-for="(tasks, incidentId) in latestTasksGroupedByOwnerId"
        :key="incidentId"
        :incident-id="incidentId"
        :tasks="tasks"
      >
      </incident-task-group>

      <div class="no-results" v-if="latestTasks.length == 0">
        No tasks to show
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IncidentTaskGroup from "../Tasks/IncidentTaskGroup.vue";
const _ = require('lodash');
export default {
  components: {IncidentTaskGroup},

  computed: {
    ...mapGetters("tasks", {
      isLoadingTasks: "isLoading",
      tasksOrdered: "tasksOrdered",
    }),

    ...mapGetters("incidents", {
      isLoadingIncidents: "isLoading"
    }),

    isLoading() {
      return (this.isLoadingTasks || this.isLoadingIncidents);
    },

    latestTasks() {
      return this.tasksOrdered.slice(0, 3);
    },

    latestTasksGroupedByOwnerId() {
      return _.groupBy(this.tasksOrdered, "ownerId");
    },
  },
};
</script>

<style lang="scss" scoped>
.latest-tasks-wrapper {
  display: flex;
  flex-direction: column;

  .title-wrapper {
    flex-shrink: 1;
  }

  .latest-tasks {
    flex-grow: 1;
    // background: #fff;
    // padding: 5px;
    border-radius: 6px;
    font-weight: 500;
    // border: 1px solid #fff;
    margin-bottom: 15px;
  }

  .no-results {
    background: #fff;
    padding: 15px;
    border-radius: 6px;
  }
}
</style>
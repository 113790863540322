import axios from 'axios'

export default {
    getChecklist(tenantId, checklistId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Checklists/' + checklistId)
    },

    addChecklist(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Checklists', params);
    },

    updateChecklist(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Checklists', params);
    },

    deleteChecklist(tenantId, checklistId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Checklists/' + checklistId);
    },

    getChecklistTasks(tenantId, checklistId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Checklists/' + checklistId + '/Tasks')
    },

    addChecklistContent(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/ChecklistContents', params);
    },

    getChecklistContents(tenantId, orgId, includeHidden = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ChecklistContents';
        if (includeHidden) {
            query = query + '?includeHidden=true'
        }
        return axios.get(query);
    },

    getChecklistContent(tenantId, checklistContentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ChecklistContents/' + checklistContentId);
    },

    updateChecklistContent(tenantId, checklistContentId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/ChecklistContents/' + checklistContentId, params);
    },

    deleteChecklistContent(tenantId, checklistContentId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/ChecklistContents/' + checklistContentId);
    }
}
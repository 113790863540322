<template>
  <div class="loading-context-screen">
      <div class="inner">
        <a-spin size="large"></a-spin>
        <div class="loading-text">
            Loading...
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.loading-context-screen {
    // background: #5155ea;
    background: #3A0BA3;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
        text-align: center;
        margin-top: -50px;

        .loading-text {
            color: #fff;
            margin-top: 20px;
            font-size: 20px;
        }
    }
}
</style>
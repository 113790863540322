import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('missionControl', {
            userPresence: 'userPresence',
            hasNonHiddenPresences: 'hasNonHiddenPresences'
        })
    },

    created() {
        if (this.userPresence && !this.hasNonHiddenPresences) {
            this.$router.push('/admin');
        }
    },

    watch: {
        userPresence(newVal) {
            if (newVal && !this.hasNonHiddenPresences) {
                this.$router.push('/admin');
            }
        },

        hasNonHiddenPresences(newVal) {
            if (this.userPresence && !newVal) {
                this.$router.push('/admin');
            }
        }
    }
}
import self from '../../api/self';
import chats from "../../api/chats";
import presences from "../../api/presences";
import chatHelpers from "../../helpers/chats";
import organisations from "../../helpers/organisations";
// import router from "../../router";
let _ = require('lodash');

export const state = {
    isLoading: false,
    isPatching: false,
    serverErrors: [],
    chats: [],

    searchQuery: ''
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    START_PATCHING(state) {
        state.isPatching = true
    },

    STOP_PATCHING(state) {
        state.isPatching = false
    },

    SET_CHATS(state, chats) {
        state.chats = chats
    },

    SET_ERRORS(state, serverErrors) {
        state.serverErrors = serverErrors
    },

    SET_SEARCH_QUERY(state, q) {
        state.searchQuery = q
    },

    START_LOADING_MEMBERSHIP_FOR_CHAT(state, chatId) {
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    isLoadingMembership: true
                };
            }
            return chat;
        })
    },

    STOP_LOADING_MEMBERSHIP_FOR_CHAT(state, chatId) {
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    isLoadingMembership: false
                };
            }
            return chat;
        })
    },

    SET_MEMBERSHIP_FOR_CHAT(state, params) {
        let { chatId, membership } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    membership: _.uniqBy(membership, 'id')
                };
            }
            return chat;
        })
    },

    ADD_PRESENCE_TO_CHAT(state, params) {
        let { chatId, presence } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id === chatId) {
                let chatCopy = { ...chat };
                if (chatCopy.membership.length) {
                    chatCopy.membership.push(presence);
                } else {
                    chatCopy.membership = [presence];
                }
                chatCopy.membership = _.uniqBy(chatCopy.membership, 'id');
                return chatCopy;
            }
            return chat;
        });
    },

    START_LOADING_GROUP_MEMBERS_FOR_CHAT(state, chatId) {
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    isLoadingGroupMembers: true
                };
            }
            return chat;
        })
    },

    STOP_LOADING_GROUP_MEMBERS_FOR_CHAT(state, chatId) {
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    isLoadingGroupMembers: false
                };
            }
            return chat;
        })
    },

    SET_GROUP_MEMBERS_FOR_CHAT(state, params) {
        let { chatId, groupMembers } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    groupMembers: groupMembers
                };
            }
            return chat;
        })
    },

    ADD_GROUP_MEMBER_TO_CHAT(state, params) {
        let { chatId, groupMemberDetails } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id === chatId) {
                let chatCopy = { ...chat };
                if (chatCopy.groupMembers.length) {
                    chatCopy.groupMembers.push(groupMemberDetails);
                } else {
                    chatCopy.groupMembers = [groupMemberDetails];
                }
                chatCopy.groupMembers = _.uniqBy(chatCopy.groupMembers, 'presenceId');
                return chatCopy;
            }
            return chat;
        });
    },

    UPDATE_CHAT_GROUP_MEMBER(state, params) {
        let { presenceId, groupId, groupMemberDetails } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == groupId) {
                let chatCopy = { ...chat };
                if (chatCopy.groupMembers && chatCopy.groupMembers.length) {
                    chatCopy.groupMembers = _.map(chatCopy.groupMembers, groupMember => {
                        if (groupMember.presenceId == presenceId) {
                            return {
                                ...groupMember,
                                ...groupMemberDetails
                            }
                        }
                        return groupMember;
                    });
                }
                return chatCopy;
            }
            return chat;
        });
    },

    SET_ACTIVE_PRESENCES_FOR_CHAT(state, params) {
        let { chatId, activePresences } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    activePresences: activePresences
                }
            }
            return chat;
        });
    },

    SET_PRESENCE_ACTIVE_FOR_CHAT(state, params) {
        let { chatId, presenceId } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                if (!chatCopy.activePresences) {
                    chatCopy.activePresences = [presenceId];
                } else {
                    chatCopy.activePresences.push(presenceId);
                    chatCopy.activePresences = _.uniq(chatCopy.activePresences);
                }
                return chatCopy;
            }
            return chat;
        });
    },

    SET_PRESENCE_INACTIVE_FOR_CHAT(state, params) {
        let { chatId, presenceId } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                if (chatCopy.activePresences) {
                    chatCopy.activePresences = _.without(chatCopy.activePresences, presenceId);
                } else {
                    chatCopy.activePresences = [];
                }
                return chatCopy;
            }
            return chat;
        });
    },

    UPDATE_CHAT_LAST_ACTIVITY(state, params) {
        let { chatId, lastActivity } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                chatCopy.lastActivity = lastActivity;
                return chatCopy;
            }
            return chat;
        });
    },

    UPDATE_CHAT_LAST_HUMAN_ACTIVITY(state, params) {
        let { chatId, lastHumanActivity } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                chatCopy.lastHumanActivity = lastHumanActivity;
                return chatCopy;
            }
            return chat;
        });
    },

    SET_FIRST_ARRIVE_FOR_CHAT_GROUP_MEMBER_IF_NOT_SET(state, params) {
        let { chatId, presenceId } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                if (chatCopy.groupMembers && chatCopy.groupMembers.length) {
                    chatCopy.groupMembers = _.map(chatCopy.groupMembers, groupMember => {
                        if (groupMember.presenceId == presenceId) {
                            let groupMemberCopy = { ...groupMember };
                            if (!groupMemberCopy.firstArrived) {
                                groupMemberCopy.firstArrived = window.moment()
                            }
                            return groupMemberCopy;
                        }
                        return groupMember;
                    });
                }
                return chatCopy;
            }
            return chat;
        });
    },

    DISPOSE_OF_CHAT(state, chatId) {
        state.chats = _.filter(state.chats, chat => {
            return chat.id !== chatId;
        });
    },

    REMOVE_GROUP_MEMBER_FROM_CHAT(state, params) {
        let { chatId, presenceId } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                if (chatCopy.groupMembers) {
                    chatCopy.groupMembers = _.filter(chatCopy.groupMembers, groupMember => {
                        return groupMember.presenceId !== presenceId;
                    });
                }
                return chatCopy;
            }
            return chat;
        });
    },

    REMOVE_PRESENCE_FROM_CHAT(state, params) {
        let { chatId, presenceId } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                let chatCopy = { ...chat };
                if (chatCopy.membership) {
                    chatCopy.membership = _.filter(chatCopy.membership, presence => {
                        return presence.id !== presenceId;
                    });
                }
                return chatCopy;
            }
            return chat;
        });
    },

    HANDLE_CHAT_UPDATED(state, params) {
        let { chatId, newDetails } = params;
        state.chats = _.map(state.chats, chat => {
            if (chat.id == chatId) {
                return {
                    ...chat,
                    ...newDetails
                };
            }
            return chat;
        });
    },

    PUSH_CHAT(state, chat) {
        state.chats.push(chat);
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    chats: (state) => {
        return state.chats
    },

    chatsOrdered: (state, getters) => {
        return chatHelpers.sortByLastActivity(getters.chats).reverse()
    },

    unreadChatCount: (state, getters) => {
        return getters.unreadChats.length;
    },

    chatsToShow: (state, getters, rootState, rootGetters) => {
        let searchQuery = state.searchQuery;
        let filteredByQuery = _.filter(state.chats, chat => {
            if (searchQuery.trim().length) {
                let displayNameMatches = false;
                if (chat.displayName) {
                    displayNameMatches = chat.displayName
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase());
                }

                let membersMatches = false;
                let membership = chat.membership ? chat.membership : [];
                _.each(membership, (m) => {
                    let memberDisplayNameMatches = m.displayName
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase());
                    if (memberDisplayNameMatches) {
                        membersMatches = true;
                    }
                });

                if (displayNameMatches || membersMatches) {
                    return true;
                }
                return false;
            }
            return true;
        })

        let chatsForOrganisationsWithoutChatDisabled = _.filter(filteredByQuery, chat => {
            return !_.includes(rootGetters['missionControl/idsOfOrganisationsWithChatDisabled'], chat.ownerId);
        });

        return chatsForOrganisationsWithoutChatDisabled;
    },

    pinnedChatsToShow: (state, getters, rootState, rootGetters) => {
        return _.filter(getters.chatsToShow, chat => {
            return rootGetters['pinned/pinnedIds'].includes(chat.id);
        });
    },

    unpinnedChatsToShow: (state, getters, rootState, rootGetters) => {
        return _.filter(getters.chatsToShow, chat => {
            return !rootGetters['pinned/pinnedIds'].includes(chat.id);
        });
    },

    chatsToShowOrdered: (state, getters) => {
        let pinnedChatsOrdered = chatHelpers.sortByLastActivity(getters.pinnedChatsToShow).reverse();
        let unpinnedChatsOrdered = chatHelpers.sortByLastActivity(getters.unpinnedChatsToShow).reverse();
        return _.flattenDeep([pinnedChatsOrdered, unpinnedChatsOrdered]);
        // return chatHelpers.sortByLastActivity(getters.chatsToShow).reverse()
    },

    unreadChats: (state, getters, rootState, rootGetters) => {
        let presences = rootGetters['missionControl/presences'];
        let myPresenceIds = _.map(presences, 'id');
        return _.filter(state.chats, chat => {

            // Get my group member
            let myGroupMember = _.find(chat.groupMembers, groupMember => {
                return myPresenceIds.includes(groupMember.presenceId)
            });

            // If has group member...
            if (myGroupMember) {
                if (!myGroupMember.lastReadPointer) {
                    return true;
                }
                let lastReadMoment = window.moment(myGroupMember.lastReadPointer);
                let lastActivityMoment = window.moment(chat.lastActivity);

                return lastActivityMoment.isAfter(lastReadMoment);
            }

            return false;
        })
    },

    searchQuery: (state) => {
        return state.searchQuery
    }
};

export const actions = {
    loadChats({ commit, dispatch }) {
        commit('START_LOADING');

        self.getChats().then(r => {
            commit('STOP_LOADING');
            commit('SET_CHATS', r.data);
            dispatch('loadGroupMembers');
            // dispatch('loadMemberships');
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_CHATS', []);
            // this._vm.$message.error('Error loading chats');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    async loadGroupMembers({ commit, getters, dispatch }, chatId = null) {
        // let vm = this;
        _.each(getters.chats, async (chat) => {
            if (chatId == null || chat.id == chatId) {
                let organisation = await dispatch('missionControl/getOrganisationById', chat.ownerId, { root: true });
                let tenantId = organisations.getOrganisationTenantId(organisation)

                commit('START_LOADING_GROUP_MEMBERS_FOR_CHAT', chat.id);
                chats.getGroupMembersForChat(tenantId, chat.id).then(r => {
                    commit('STOP_LOADING_GROUP_MEMBERS_FOR_CHAT', chat.id);
                    commit('SET_GROUP_MEMBERS_FOR_CHAT', {
                        chatId: chat.id,
                        groupMembers: r.data
                    });
                    let activePresences = _.map(_.filter(r.data, m => (m.isActive === true)), 'presenceId');
                    commit('SET_ACTIVE_PRESENCES_FOR_CHAT', {
                        chatId: chat.id,
                        activePresences: activePresences
                    });
                    dispatch('resolveMembershipForChat', chat.id);
                }).catch(e => {
                    console.log(e);
                    // vm._vm.$message.error('Error loading group member for chat');
                })
            }
        });
    },

    async resolveMembershipForChat({ commit, getters, dispatch }, chatId) {
        commit('START_LOADING_MEMBERSHIP_FOR_CHAT', chatId);
        let chat = _.find(getters.chats, chat => {
            return chat.id == chatId;
        });

        if (chat && chat.groupMembers) {
            let organisation = await dispatch('missionControl/getOrganisationById', chat.ownerId, { root: true });
            let tenantId = organisations.getOrganisationTenantId(organisation)

            let presenceIds = _.map(chat.groupMembers, 'presenceId');
            // let membership = _.filter(rootGetters['allPresences/presences'], presence => {
            //     return presenceIds.includes(presence.id);
            // });

            let membership = await dispatch('allPresences/resolvePresences', {
                tenantId: tenantId,
                presenceIds: presenceIds
            }, { root: true });

            commit('SET_MEMBERSHIP_FOR_CHAT', {
                chatId: chatId,
                membership: membership
            });
            console.log('membership resolved');
            console.log(membership);
        }
        commit('STOP_LOADING_MEMBERSHIP_FOR_CHAT', chatId);
    },

    async loadMemberships({ commit, getters, dispatch }, chatId = null) {
        // let vm = this;
        _.each(getters.chats, async (chat) => {
            if (chatId == null || chat.id == chatId) {
                let organisation = await dispatch('missionControl/getOrganisationById', chat.ownerId, { root: true });
                let tenantId = organisations.getOrganisationTenantId(organisation)

                commit('START_LOADING_MEMBERSHIP_FOR_CHAT', chat.id);
                chats.getMembershipForChat(tenantId, chat.id).then(r => {
                    commit('STOP_LOADING_MEMBERSHIP_FOR_CHAT', chat.id);
                    commit('SET_MEMBERSHIP_FOR_CHAT', {
                        chatId: chat.id,
                        membership: r.data
                    });
                }).catch(e => {
                    console.log(e);
                    // vm._vm.$message.error('Error loading membership for chat');
                })
            }
        });
    },

    setSearchQuery({ commit }, query) {
        commit('SET_SEARCH_QUERY', query);
    },

    updateChatGroupMember({ commit }, params) {
        commit('UPDATE_CHAT_GROUP_MEMBER', params);
    },

    updateChatLastActivity({ commit }, params) {
        commit('UPDATE_CHAT_LAST_ACTIVITY', params);
    },

    updateChatLastHumanActivity({ commit }, params) {
        commit('UPDATE_CHAT_LAST_HUMAN_ACTIVITY', params);
    },

    handleGroupMemberAddedToChat({ commit, dispatch, getters }, params) {
        let chat = _.find(getters.chats, chat => {
            return chat.id == params.chatId;
        });
        if (chat) {
            let shouldAdd = true;
            // We don't want to add a group member if they already exist
            if (chat.groupMembers) {
                let groupMember = _.find(chat.groupMembers, gm => {
                    return gm.presenceId == params.groupMemberDetails.presenceId;
                });
                if (groupMember) {
                    shouldAdd = false;
                }
            }

            if (shouldAdd) {
                commit('ADD_GROUP_MEMBER_TO_CHAT', params);
                dispatch('fetchAndAddMembershipForChat', {
                    chatId: params.chatId,
                    tenantId: params.tenantId,
                    presenceId: params.groupMemberDetails.presenceId
                });
            }
        }
    },

    fetchAndAddMembershipForChat({ commit }, params) {
        let { chatId, tenantId, presenceId } = params;
        commit('START_PATCHING');
        presences.getPresence(tenantId, presenceId).then(r => {
            commit('STOP_PATCHING');
            commit('ADD_PRESENCE_TO_CHAT', {
                chatId: chatId,
                presence: r.data
            });
        }).catch(e => {
            commit('STOP_PATCHING');
            console.log(e);
            this._vm.$message.error('Error fetching membership for chat');
        });
    },

    setPresenceActiveForChat({ commit }, params) {
        commit('SET_PRESENCE_ACTIVE_FOR_CHAT', params);
    },

    setPresenceInactiveForChat({ commit }, params) {
        commit('SET_PRESENCE_INACTIVE_FOR_CHAT', params);
    },

    setFirstArriveForChatGroupMemberIfNotSet({ commit }, params) {
        commit('SET_FIRST_ARRIVE_FOR_CHAT_GROUP_MEMBER_IF_NOT_SET', params);
    },

    disposeOfChat({ commit }, chatId) {
        commit('DISPOSE_OF_CHAT', chatId);
    },

    removeGroupMemberFromChat({ commit }, params) {
        commit('REMOVE_GROUP_MEMBER_FROM_CHAT', params);
        commit('REMOVE_PRESENCE_FROM_CHAT', params);
    },

    handleChatUpdated({ commit }, params) {
        commit('HANDLE_CHAT_UPDATED', params);
    },

    reloadChatMembership({ dispatch }, params) {
        let { chatId } = params;
        dispatch('loadGroupMembers', chatId);
        // dispatch('loadMemberships', chatId);
    },

    pushChat({ commit, dispatch }, chat) {
        commit('PUSH_CHAT', {
            ...chat,
            isLoadingGroupMembers: false,
            isLoadingMembership: false,
            groupMembers: [],
            membership: []
        });
        dispatch('loadGroupMembers', chat.id);
        // dispatch('loadMemberships', chat.id);
    },

    fetchAndAddChat({ commit, dispatch }, params) {
        let { tenantId, chatId } = params;
        // let vm = this;
        commit('START_PATCHING');
        chats.getChat(tenantId, chatId).then(r => {
            commit('STOP_PATCHING');
            dispatch('pushChat', r.data);

            // vm._vm.$notification.show('New Chat', {
            //     body: r.data.displayName
            // }, {
            //     onclick: function () {
            //         router.push('/chats/' + tenantId + '/' + chatId);
            //     }
            // })
        }).catch(e => {
            console.log(e);
            commit('STOP_PATCHING');
            this._vm.$message.error('Error fetching chat');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    }
};
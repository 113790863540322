<template>
    <div class="tsos-incidents-header">
        <h3 style="margin-bottom: 0px; padding-bottom: 0px;">
            Incidents
        </h3>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.tsos-incidents-header {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;

    h3 {
        // letter-spacing: -0.5px;
        letter-spacing: -0.4px;
        font-size: 19px;
    }
}
</style>
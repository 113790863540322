<template>
  <div class="presence-badge">
    <presence-thumbnail
      style="display: inline"
      v-if="presenceId"
      :presence-id="presenceId"
      :owner-id="ownerId"
      size="small"
    ></presence-thumbnail>
    <span class="presence-name">
    {{ nameToShow }}
    </span>
  </div>
</template>

<script>
const _ = require('lodash');
import {mapGetters} from 'vuex';
import PresenceThumbnail from "./PresenceThumbnail.vue";
export default {
  components: { PresenceThumbnail },
  props: ["name", "presenceId", "ownerId"],
  computed: {
    ...mapGetters('missionControl', {
      presences: 'presences'
    }),

    nameToShow() {
      if (_.map(this.presences, 'id').includes(this.presenceId)) {
        return 'You (' + this.name + ')'
      }
      return this.name;
    }
  }
};
</script>

<style lang="scss">
.presence-badge {
  border: 1px solid rgb(81, 85, 234);
  color: rgb(81, 85, 234);
  border-radius: 13px;
  display: inline-block;
  font-size: 10px;
  padding: 2px 2px;

  .ant-avatar {
    width: 18px;
    height: 18px;
    line-height: 18px;
  }

  .presence-name {
      padding-left: 3px;
      padding-right: 4px;
  }
}
</style>
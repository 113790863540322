<template>
  <div>
    <h3 class="incident-title" @click.prevent="goToIncident">{{ incident ? incident.displayName : "Unknown" }}</h3>

    <!-- Loading Memberships -->
    <div class="loader" v-if="isLoadingMembership">
      <a-spin></a-spin>
    </div>
    <!-- / Loading Memberships -->

    <!-- Tasks -->
    <div class="tasks">
        <task
        v-for="task in tasks"
        :key="task.id"
        :tenant-id="tenantId"
        :incident-id="incident.id"
        :task="task"
        :membership="membership"
        :my-presence-id="myPresenceId"
      ></task>
    </div>
    <!-- / Tasks -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import organisations from "../../helpers/organisations";
// import incidents from "../../api/incidents";
import Task from "../Incidents/Show/Tabs/Tasks/Task.vue"

export default {
  props: ["incidentId", "tasks"],
  components: {Task},
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      presences: "presences",
    }),

    ...mapGetters("incidents", {
      incidents: "incidents",
    }),

    incident() {
      return _.find(this.incidents, { id: this.incidentId });
    },

    membership() {
      if (this.incident && this.incident.membership) {
        return this.incident.membership;
      }
      return [];
    },

    isLoadingMembership() {
      if (this.incident) {
        return this.incident.isLoadingMembership;
      }
      return true;
    },

    organisation() {
      return this.getOrganisationById(this.incident.ownerId);
    },

    tenantId() {
      return organisations.getOrganisationTenantId(this.organisation);
    },

    myPresenceId() {
      let myPresenceIds = _.map(this.presences, "id");
      let presenceIdsInIncident = _.map(this.membership, "id");
      let intersection = _.intersection(myPresenceIds, presenceIdsInIncident);
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },
  },
  created() {
    // this.loadMembership();
  },
  methods: {
    goToIncident() {
      if (this.incident) {
        this.$router.push('/incidents/' + this.tenantId + '/' + this.incident.id);
      }
    },

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    // loadMembership() {
    //   let vm = this;
    //   vm.isLoadingMembership = true;
    //   incidents
    //     .getMembershipForIncident(this.tenantId, this.incidentId)
    //     .then((r) => {
    //       vm.isLoadingMembership = false;
    //       vm.membership = r.data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       vm.isLoadingMembership = false;
    //       vm.$message.error("Error loading incident members");
    //     });
    // },
  },
};
</script>

<style scoped>
.incident-title {
  cursor: pointer;
}
</style>
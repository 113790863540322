const _ = require('lodash')
import organisationHelpers from "../../helpers/organisations";
import organisations from "../../api/organisations";
import incidentTypes from "../../api/incident-types";

export const state = {
    selectedOrganisation: null,
    recentOrganisations: [],

    isLoadingGroupMembers: false,
    groupMembers: [],

    isLoadingMembership: false,
    membership: [],

    isLoadingIncidentTypes: false,
    incidentTypes: [],

    isLoadingAllIncidentTypes: false,
    allIncidentTypes: [],

    isLoadingLocations: false,
    locations: [],

    isLoadingSkills: false,
    skills: []
};

export const mutations = {
    PUSH_ORG_TO_RECENT_ORGANISATIONS(state, orgId) {
        if (state.recentOrganisations.includes(orgId)) {
            state.recentOrganisations = _.without(state.recentOrganisations, orgId);
        }
        state.recentOrganisations.push(orgId);
    },

    SET_SELECTED_ORGANISATION(state, organisation) {
        state.selectedOrganisation = organisation
    },

    START_LOADING_GROUP_MEMBERS(state) {
        state.isLoadingGroupMembers = true
    },

    STOP_LOADING_GROUP_MEMBERS(state) {
        state.isLoadingGroupMembers = false
    },

    SET_GROUP_MEMBERS(state, groupMembers) {
        state.groupMembers = groupMembers
    },

    START_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = true
    },

    STOP_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = false
    },

    SET_MEMBERSHIP(state, membership) {
        state.membership = membership
    },

    START_LOADING_INCIDENT_TYPES(state) {
        state.isLoadingIncidentTypes = true
    },

    STOP_LOADING_INCIDENT_TYPES(state) {
        state.isLoadingIncidentTypes = false
    },

    SET_INCIDENT_TYPES(state, incidentTypes) {
        state.incidentTypes = incidentTypes
    },
    
    START_LOADING_ALL_INCIDENT_TYPES(state) {
        state.isLoadingAllIncidentTypes = true
    },

    STOP_LOADING_ALL_INCIDENT_TYPES(state) {
        state.isLoadingAllIncidentTypes = false
    },

    SET_ALL_INCIDENT_TYPES(state, incidentTypes) {
        state.allIncidentTypes = incidentTypes
    },

    // START_LOADING_ALL_INCIDENT_TYPES(state) {
    //     state.isLoadingAllIncidentTypes = true
    // },

    // STOP_LOADING_ALL_INCIDENT_TYPES(state) {
    //     state.isLoadingAllIncidentTypes = false
    // },

    // SET_ALL_INCIDENT_TYPES(state, incidentTypes) {
    //     state.allIncidentTypes = incidentTypes
    // },

    START_LOADING_LOCATIONS(state) {
        state.isLoadingLocations = true;
    },

    STOP_LOADING_LOCATIONS(state) {
        state.isLoadingLocations = false;
    },

    SET_LOCATIONS(state, locations) {
        state.locations = locations;
    },

    START_LOADING_SKILLS(state) {
        state.isLoadingSkills = true;
    },

    STOP_LOADING_SKILLS(state) {
        state.isLoadingSkills = false;
    },

    SET_SKILLS(state, skills) {
        state.skills = skills;
    }
};

export const getters = {
    recentOrganisations: (state) => (state.recentOrganisations),

    selectedOrganisation: (state) => {
        return state.selectedOrganisation
    },

    timezone: (state) => {
        if (
            state.selectedOrganisation 
            && state.selectedOrganisation.content
            && state.selectedOrganisation.content['$v']
            && state.selectedOrganisation.content['$v']['timeZone']
        ) {
            return state.selectedOrganisation.content['$v']['timeZone'];
        }
        return "Europe/London";
    },

    tenantId: (state) => {
        if (state.selectedOrganisation) {
            return organisationHelpers.getOrganisationTenantId(state.selectedOrganisation);
        }
        return null;
    },

    isLoading: (state) => {
        return (state.isLoadingGroupMembers || state.isLoadingMembership || state.isLoadingAllIncidentTypes || state.isLoadingIncidentTypes || state.isLoadingLocations || state.isLoadingSkills);
    },

    membership: (state) => {
        return state.membership
    },

    groupMembers: (state) => {
        return state.groupMembers
    },

    incidentTypes: (state) => {
        return state.incidentTypes
    },

    allIncidentTypes: (state) => {
        return state.allIncidentTypes
    },

    locations: (state) => {
        return state.locations
    },

    skills: (state) => {
        return state.skills
    },

    myPresenceId: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInIncident = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInIncident);
        if (intersection.length) {
            return _.first(intersection)
        }

        // For hidden presences, the presence won't show in membership for orgs
        // Let's try and find the right presence for the selected org through
        // the ownerId of the presences
        if (getters.selectedOrganisation) {
            let presence = _.find(rootGetters['missionControl/presences'], presence => {
                return presence.ownerId == getters.selectedOrganisation['id']
            });
            if (presence) {
                return presence.id;
            }
        }

        return null;
    },

    isMyPresenceHidden: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInIncident = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInIncident);

        let presenceId;
        let presence;

        presenceId = null;
        if (intersection.length) {
            presenceId = _.first(intersection)
        }
        if (presenceId) {
            presence = _.find(getters.membership, { id: presenceId });
            if (presence && presence.isHiddenPresence) {
                return true
            }
        }

        // For hidden presences, the presence won't show in membership for orgs
        // Let's try and find the right presence for the selected org through
        // the ownerId of the presences
        if (getters.selectedOrganisation) {
            presence = _.find(rootGetters['missionControl/presences'], presence => {
                return presence.ownerId == getters.selectedOrganisation['id']
            });
            if (presence && presence.isHiddenPresence) {
                return true
            }
        }

        return false;
    }
};

export const actions = {
    selectOrganisation({ commit, dispatch, getters, rootGetters }, organisation) {
        if (
            getters.selectedOrganisation
        ) {
            commit('PUSH_ORG_TO_RECENT_ORGANISATIONS', getters.selectedOrganisation.id);
        }

        dispatch('adminGuidance/resetOwnerIdFilter', {}, { root: true });

        commit('SET_GROUP_MEMBERS', []);
        commit('SET_MEMBERSHIP', []);
        commit('SET_LOCATIONS', []);
        commit('SET_SKILLS', []);
        commit('SET_SELECTED_ORGANISATION', organisation)
        if (organisation) {
            dispatch('loadGroupMembers');
            dispatch('loadMembership');
            dispatch('loadIncidentTypes');
            dispatch('loadAllIncidentTypes');
            dispatch('loadLocations');
            dispatch('loadSkills');

            dispatch('adminGuidance/loadGuidancePacksForOrg', {
                tenantId: organisationHelpers.getOrganisationTenantId(organisation),
                orgId: organisation.id
            }, { root: true });

            if (organisation.id !== rootGetters['subjectPreferences/lastOrgId']) {
                dispatch('subjectPreferences/setLastOrgId', organisation.id, { root: true });
            }

            // Load some guidance stuff for admin
            dispatch('adminTopics/loadTopics', {}, { root: true });
            dispatch('adminArticles/loadArticles', {}, { root: true });
            dispatch('adminHelpVideos/loadHelpVideos', {}, { root: true });
            dispatch('adminPlaybooks/loadPlaybooks', {}, { root: true });
            dispatch('adminDownloads/loadDownloads', {}, { root: true });
            dispatch('adminExternalLinks/loadExternalLinks', {}, { root: true });
        }
    },

    loadGroupMembers({ commit, getters }) {
        commit('START_LOADING_GROUP_MEMBERS');
        organisations.getGroupMembersForOrg(getters.tenantId, getters.selectedOrganisation.id).then(r => {
            commit('STOP_LOADING_GROUP_MEMBERS');
            commit('SET_GROUP_MEMBERS', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_GROUP_MEMBERS');
            commit('SET_GROUP_MEMBERS', []);
            this._vm.$message.error('Error loading group members');
        })
    },

    loadMembership({ commit, getters }) {
        commit('START_LOADING_MEMBERSHIP');
        organisations.getMembershipForOrg(getters.tenantId, getters.selectedOrganisation.id).then(r => {
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', []);
            this._vm.$message.error('Error loading membership');
        })
    },

    loadIncidentTypes({ commit, getters }) {
        commit('START_LOADING_INCIDENT_TYPES');
        incidentTypes.getIncidentTypes(
            getters.tenantId, getters.selectedOrganisation.id
        ).then(r => {
            commit('STOP_LOADING_INCIDENT_TYPES');
            commit('SET_INCIDENT_TYPES', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_INCIDENT_TYPES');
            commit('SET_INCIDENT_TYPES', []);
            this._vm.$message.error('Error loading incident types');
        });
    },

    loadAllIncidentTypes({ commit, getters }) {
        commit('START_LOADING_ALL_INCIDENT_TYPES');
        incidentTypes.getIncidentTypes(
            getters.tenantId, getters.selectedOrganisation.id,
            true
        ).then(r => {
            commit('STOP_LOADING_ALL_INCIDENT_TYPES');
            commit('SET_ALL_INCIDENT_TYPES', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_ALL_INCIDENT_TYPES');
            commit('SET_ALL_INCIDENT_TYPES', []);
            this._vm.$message.error('Error loading incident types');
        });
    },

    loadLocations({ commit, getters }) {
        commit('START_LOADING_LOCATIONS');
        organisations.getLocationsForOrg(getters.tenantId, getters.selectedOrganisation.id).then(r => {
            commit('SET_LOCATIONS', r.data);
            commit('STOP_LOADING_LOCATIONS');
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_LOCATIONS');
            commit('SET_LOCATIONS', []);
            this._vm.$message.error('Error loading locations');
        })
    },

    loadSkills({ commit, getters }) {
        commit('START_LOADING_SKILLS');
        organisations.getSkillsForOrg(getters.tenantId, getters.selectedOrganisation.id).then(r => {
            commit('SET_SKILLS', r.data);
            commit('STOP_LOADING_SKILLS');
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_SKILLS');
            commit('SET_SKILLS', []);
            this._vm.$message.error('Error loading skills');
        });
    },

    loadSkillsSilently({ commit, getters }) {
        // commit('START_LOADING_SKILLS');
        organisations.getSkillsForOrg(getters.tenantId, getters.selectedOrganisation.id).then(r => {
            commit('SET_SKILLS', r.data);
            // commit('STOP_LOADING_SKILLS');
        }).catch(e => {
            console.log(e);
            // commit('STOP_LOADING_SKILLS');
            // commit('SET_SKILLS', []);
            // this._vm.$message.error('Error loading skills');
        });
    }
};
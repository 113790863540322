import articles from "../../api/guidance/articles";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");

export const state = {
    isLoading: false,
    articles: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_ARTICLES(state, articles) {
        state.articles = articles
    },

    UPDATE_ARTICLE_LOCALLY(state, updatedArticle) {
        state.articles = _.map(state.articles, article => {
            if (updatedArticle['$v']['id'] == article['$v']['id']) {
                return updatedArticle;
            } else {
                return article;
            }
        });
    }
}

export const getters = {
    isLoading: (state) => (state.isLoading),
    articles: (state) => (state.articles),
    articlesToShow: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.articles, article => {
                return article['$v']['ownerId'] == ownerIdFilter
            });
        }
        return state.articles;
    }
}

export const actions = {
    reset({commit}) {
        commit('SET_ARTICLES', []);
    },

    startLoading({commit}) {
        commit('START_LOADING');
    },

    stopLoading({commit}) {
        commit('STOP_LOADING');
    },

    loadArticles({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING');

            let org = rootGetters['admin/selectedOrganisation'];
            if (!org) {
                commit('SET_ARTICLES', []);
                return false;
            }
            let tenantId = organisationHelpers.getOrganisationTenantId(org);
    
            articles.getArticles(tenantId, org.id, true).then(r => {
                commit('STOP_LOADING');
                commit('SET_ARTICLES', r.data);
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                this._vm.$message.error('Error loading articles');
                reject(e);
            })
        });
    },

    updateArticleLocally({ commit }, article) {
        commit('UPDATE_ARTICLE_LOCALLY', article);
    }
}
let _ = require('lodash');
// import axios from 'axios';
import organisationHelpers from "../../helpers/organisations";
import organisations from "../../api/organisations";
import onboarding from "../../api/onboarding";

const STAGE_HUMAN_MAPPING = [
    'Technical Setup',
    'Import Users',
    'Users',
    'Response Groups',
    'Emergency Types',
    'Incident Types',
    'Review'
];

export const state = {
    // onboardingStages: {},
    // isLoadingOnboardingStagesForOrganisations: [],
    isSavingOnboardingStage: false,

    organisation: null,
    isLoadingMembership: false,
    membership: null,
    stage: 0,

    idsOfOrganisationsToHide: [] // Used to hide organisations from the list - for when onboarding complete
};

export const mutations = {
    // SET_ONBOARDING_STAGES(state, stages) {
    //     state.onboardingStages = stages;
    // },

    // SET_ONBOARDING_STAGE_FOR_ORGANISATION(state, params) {
    //     let { organisationId, onboardingStage } = params;
    //     state.onboardingStages[organisationId] = onboardingStage;
    // },

    // START_LOADING_ONBOARDING_STAGE_FOR_ORGANISATION(state, organisationId) {
    //     state.isLoadingOnboardingStagesForOrganisations.push(organisationId);
    // },

    // STOP_LOADING_ONBOARDING_STAGE_FOR_ORGANISATION(state, organisationId) {
    //     state.isLoadingOnboardingStagesForOrganisations = state.isLoadingOnboardingStagesForOrganisations.filter(id => id !== organisationId);
    // },

    SET_ORGANISATION(state, organisation) {
        state.organisation = organisation;
    },

    START_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = true;
    },

    STOP_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = false;
    },

    SET_MEMBERSHIP(state, membership) {
        state.membership = membership;
    },

    SET_STAGE(state, stage) {
        state.stage = stage;
    },

    INCREMENT_STAGE(state) {
        state.stage++;
    },

    DECREMENT_STAGE(state) {
        state.stage--;
    },

    START_SAVING_ONBOARDING_STAGE(state) {
        state.isSavingOnboardingStage = true;
    },

    STOP_SAVING_ONBOARDING_STAGE(state) {
        state.isSavingOnboardingStage = false;
    },

    HIDE_ORGANISATION(state, organisationId) {
        state.idsOfOrganisationsToHide.push(organisationId);
    },

    SHOW_ORGANISATION(state, organisationId) {
        state.idsOfOrganisationsToHide = state.idsOfOrganisationsToHide.filter(id => id !== organisationId);
    }
};

export const getters = {
    organisation: (state) => {
        return state.organisation;
    },

    selectedOrganisation: (state) => {
        return state.organisation;
    },

    stage: (state) => {
        return state.stage;
    },

    stageHuman: (state) => {
        return STAGE_HUMAN_MAPPING[state.stage];
    },

    tenantId: (state) => {
        if (state.organisation) {
            return organisationHelpers.getOrganisationTenantId(state.organisation);
        }
        return null;
    },

    isLoadingMembership: (state) => {
        return state.isLoadingMembership;
    },

    membership: (state) => {
        return state.membership;
    },

    idsOfOrganisationsToHide: (state) => {
        return state.idsOfOrganisationsToHide;
    },

    onboardingOrgsToShow: (state, getters, rootState, rootGetters) => {
        let orgs = rootGetters['missionControl/organisationsIAmAnAdminForWithIncompleteOnboarding'];
        let idsOfOrganisationsToHide = getters.idsOfOrganisationsToHide;
        return orgs.filter(org => !idsOfOrganisationsToHide.includes(org.id));
    }
};

export const actions = {
    // loadOnboardingStatusesForAllOrganisations({ commit, dispatch, rootGetters }) {
    //     let organisationsIAmAnAdminFor = rootGetters['missionControl/organisationsIAmAnAdminFor'];

    //     organisationsIAmAnAdminFor.forEach(organisation => {
    //         commit('START_LOADING_ONBOARDING_STAGE_FOR_ORGANISATION', organisation.id);
    //         onboarding.getOnboardingStage(organisation.id).then(r => {
    //             commit('STOP_LOADING_ONBOARDING_STAGE_FOR_ORGANISATION', organisation.id);
    //             commit('SET_ONBOARDING_STAGE_FOR_ORGANISATION', { organisationId: organisation.id, onboardingStage: r.data });
    //         });
    //     });
    // },

    startOnboardingForOrganisation({ commit, dispatch, rootGetters }, organisation) {
        // Let's get the organisation from mission control to ensure we have the latest version
        let updatedOrganisation = _.find(rootGetters['missionControl/organisations'], {id: organisation.id});
        if (!updatedOrganisation) {
            updatedOrganisation = {...organisation};
        }

        commit('SET_ORGANISATION', updatedOrganisation);
        dispatch('loadMembership');

        if (organisation.id !== rootGetters['subjectPreferences/lastOrgId']) {
            dispatch('subjectPreferences/setLastOrgId', organisation.id, { root: true });
        }

        if (updatedOrganisation.content['$v']['onboardingStage']) {
            // Find the index of the stage in the human readable mapping
            let stageIndex = STAGE_HUMAN_MAPPING.indexOf(updatedOrganisation.content['$v']['onboardingStage']);
            if (stageIndex !== -1) {
                commit('SET_STAGE', stageIndex);
                return;
            } else {
                commit('SET_STAGE', 0);
            }
        } else {
            commit('SET_STAGE', 0);
        }
    },

    setOrganisation({ commit }, organisation) {
        commit('SET_ORGANISATION', organisation);
    },

    selectOrganisation({ commit }, organisation) {
        commit('SET_ORGANISATION', organisation);
    },

    loadMembership({ commit, getters }) {
        commit('START_LOADING_MEMBERSHIP');
        organisations.getMembershipForOrg(getters.tenantId, getters.organisation.id).then(r => {
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', []);
            this._vm.$message.error('Error loading membership');
        });
    },

    updateOnboardingMembership({commit}, membership) {
        commit('SET_MEMBERSHIP', membership);
    },

    setStage({ commit, dispatch }, stage) {
        commit('SET_STAGE', stage);
        dispatch('persistStage');
    },

    persistStage({ commit, getters, dispatch }) {
        commit('START_SAVING_ONBOARDING_STAGE');
        onboarding.setOnboardingStage(getters.organisation.id, getters.stageHuman).then((r) => {
            commit('STOP_SAVING_ONBOARDING_STAGE');
            dispatch('missionControl/updateOrganisationInline', r.data, { root: true })
        }).catch(e => {
            console.log(e);
            commit('STOP_SAVING_ONBOARDING_STAGE');
            this._vm.$message.error('Error saving onboarding stage');
        });
    },

    incrementStage({ commit }) {
        commit('INCREMENT_STAGE');
    },

    decrementStage({ commit }) {
        commit('DECREMENT_STAGE');
    },

    hideOrganisation({ commit }, organisationId) {
        commit('HIDE_ORGANISATION', organisationId);
    },

    showOrganisation({ commit }, organisationId) {
        commit('SHOW_ORGANISATION', organisationId);
    }
};
<template>
  <a-modal class="help-and-support-modal" v-model="shouldShowModal" title="Help and Support" width="900px" :footer="null">
    <a-row :gutter="20" type="flex">
      <a-col :span="6">
        <div class="help-and-support-button" @click.prevent="emailSupport">
          <a-icon type="mail" />
          <span>Email</span>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="help-and-support-button" @click.prevent="bookTrainingSession">
          <a-icon type="calendar" />
          <span>Book Training Session</span>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="help-and-support-button" @click.prevent="goToFaqs">
          <a-icon type="question-circle" />
          <span>FAQs</span>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="help-and-support-button" @click.prevent="goToStatusPage">
          <a-icon type="warning" />
          <span>Service Status</span>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  props: ["visible"],

  methods: {
    emailSupport() {
      window.open('mailto:help@teamsos.co.uk', '_blank');
    },

    bookTrainingSession() {
      window.open('http://calendly.com/teamsos', '_blank');
    },

    goToFaqs() {
      this.$router.push('/faqs');
      this.shouldShowModal = false;
    },

    goToStatusPage() {
      window.open('https://status.teamsos.co.uk/', '_blank');
    }
  },

  computed: {
    shouldShowModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style lang="scss">
.help-and-support-modal {
  .help-and-support-button {
    // color: #5155ea;
    // border: 1px solid #5155ea;
    border-radius: 7px;
    text-align: center;
    padding: 30px;
    background: rgb(247, 248, 250);
    border: 1px solid #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;

    &:hover {
      cursor: pointer;
      color: #5155ea;
      border: 1px solid #5155ea;
    }

    .anticon {
      font-size: 30px;
      margin-bottom: 25px;
    }

    span {
      font-size: 20px;
    }
  }
}
</style>
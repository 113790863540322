import guidancePacks from "../../api/guidance/guidance-packs";
const _ = require("lodash");

export const state = {
    isLoading: false,
    guidancePacks: [],

    ownerIdFilter: null
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_GUIDANCE_PACKS(state, guidancePacks) {
        state.guidancePacks = guidancePacks
    },

    SET_OWNER_ID_FILTER(state, ownerId) {
        state.ownerIdFilter = ownerId;
    }
}

export const getters = {
    isLoadingGuidancePack: (state) => (state.isLoading),
    isLoadingGuidancePacks: (state) => (state.isLoading),
    guidancePacks: (state) => (state.guidancePacks),
    ownerIdFilter: (state, getters, rootState, rootGetters) => {
        if (state.ownerIdFilter) {
            return state.ownerIdFilter;
        }
        let selectedOrganisation = rootGetters['admin/selectedOrganisation'];
        if (selectedOrganisation) {
            return selectedOrganisation.id;
        }
        return null;
    },
    ownerIdFilterOptions: (state, getters, rootState, rootGetters) => {
        let options = [];

        let selectedOrganisation = rootGetters['admin/selectedOrganisation'];
        if (selectedOrganisation) {
            options.push({
                // label: selectedOrganisation.displayName,
                label: "My organisation",
                value: selectedOrganisation.id,
                type: 'organisation'
            });

            if (
                selectedOrganisation.ownerId
                && _.find(rootGetters['missionControl/organisations'], {id: selectedOrganisation.ownerId})
            ) {
                let parentOrg = _.find(rootGetters['missionControl/organisations'], {id: selectedOrganisation.ownerId});
                options.push({
                    // label: parentOrg.displayName,
                    label: "My parent organisation",
                    value: parentOrg.id,
                    type: 'organisation'
                });
            }
        }

        if (state.guidancePacks && !state.isLoading) {
            _.each(state.guidancePacks, guidancePack => {
                options.push({
                    label: guidancePack['$v']['displayName'],
                    value: guidancePack['$v']['id'],
                    type: 'guidance-pack'
                });
            });
        }

        return options;
    }
}

export const actions = {
    resetOwnerIdFilter({ commit }) {
        commit('SET_OWNER_ID_FILTER', null);
    },

    setOwnerIdFilter({ commit }, ownerId) {
        commit('SET_OWNER_ID_FILTER', ownerId);
    },

    loadGuidancePacksForOrg({ commit }, params) {
        return new Promise((resolve, reject) => {
            let {tenantId, orgId} = params;
            commit('START_LOADING');
            
            guidancePacks.getGuidancePacksForOrg(tenantId, orgId).then(r => {
                commit('STOP_LOADING');
                commit('SET_GUIDANCE_PACKS', r.data);
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                commit('STOP_LOADING');
                commit('SET_GUIDANCE_PACKS', []);
                reject(e);
            });
        }); 
    }
}
import organisationHelpers from "../../helpers/organisations";
import organisations from "../../api/organisations";
import chats from "../../api/chats";
const _ = require('lodash');

export const state = {
    isLoading: false,
    managedGroups: [],
    serverErrors: [],

    searchQuery: ''
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_MANAGED_GROUPS(state, managedGroups) {
        state.managedGroups = managedGroups
    },

    SET_SEARCH_QUERY(state, searchQuery) {
        state.searchQuery = searchQuery
    },

    START_LOADING_MEMBERSHIP_FOR_MANAGED_GROUP(state, managedGroupId) {
        state.managedGroups = _.map(state.managedGroups, managedGroup => {
            if (managedGroup.id == managedGroupId) {
                return {
                    ...managedGroup,
                    isLoadingMembership: true
                };
            }
            return managedGroup;
        })
    },

    STOP_LOADING_MEMBERSHIP_FOR_MANAGED_GROUP(state, managedGroupId) {
        state.managedGroups = _.map(state.managedGroups, managedGroup => {
            if (managedGroup.id == managedGroupId) {
                return {
                    ...managedGroup,
                    isLoadingMembership: false
                };
            }
            return managedGroup;
        })
    },

    SET_MEMBERSHIP_FOR_MANAGED_GROUP(state, params) {
        let { managedGroupId, membership } = params;
        state.managedGroups = _.map(state.managedGroups, managedGroup => {
            if (managedGroup.id == managedGroupId) {
                return {
                    ...managedGroup,
                    membership: _.uniqBy(membership, 'id')
                };
            }
            return managedGroup;
        })
    },

    START_LOADING_GROUP_MEMBERS_FOR_MANAGED_GROUP(state, managedGroupId) {
        state.managedGroups = _.map(state.managedGroups, managedGroup => {
            if (managedGroup.id == managedGroupId) {
                return {
                    ...managedGroup,
                    isLoadingGroupMembers: true
                };
            }
            return managedGroup;
        })
    },

    STOP_LOADING_GROUP_MEMBERS_FOR_MANAGED_GROUP(state, managedGroupId) {
        state.managedGroups = _.map(state.managedGroups, managedGroup => {
            if (managedGroup.id == managedGroupId) {
                return {
                    ...managedGroup,
                    isLoadingGroupMembers: false
                };
            }
            return managedGroup;
        })
    },

    SET_GROUP_MEMBERS_FOR_MANAGED_GROUP(state, params) {
        let { managedGroupId, groupMembers } = params;
        state.managedGroups = _.map(state.managedGroups, managedGroup => {
            if (managedGroup.id == managedGroupId) {
                return {
                    ...managedGroup,
                    groupMembers: _.uniqBy(groupMembers, 'id')
                };
            }
            return managedGroup;
        })
    },
};

export const getters = {
    managedGroups: (state) => {
        return state.managedGroups
    },

    managedGroupsToShow: (state) => {
        let searchQuery = state.searchQuery;
        let filtered = _.filter(state.managedGroups, managedGroup => {
            if (searchQuery.trim().length) {
                return managedGroup.displayName
                    .toLowerCase()
                    .includes(searchQuery.trim().toLowerCase());
            } else {
                return true;
            }
        });
        return _.orderBy(filtered, [group => group.displayName.toLowerCase()]);
    },

    isLoading: (state) => {
        return state.isLoading
    },

    searchQuery: (state) => {
        return state.searchQuery
    }
};

export const actions = {
    loadManagedGroups({ commit, dispatch, rootGetters }) {
        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_MANAGED_GROUPS', []);
            return false;
        }

        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        commit('START_LOADING');
        organisations.getManagedChatsForOrg(tenantId, org.id).then(r => {
            commit('STOP_LOADING');
            commit('SET_MANAGED_GROUPS', r.data);
            dispatch('loadGroupMembers');
            dispatch('loadMemberships');
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_MANAGED_GROUPS', []);

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    async loadGroupMembers({ commit, getters, rootGetters }, managedGroupId = null) {
        _.each(getters.managedGroups, async (managedGroup) => {
            if (managedGroupId == null || managedGroup.id == managedGroupId) {
                let tenantId = rootGetters['admin/tenantId'];

                commit('START_LOADING_GROUP_MEMBERS_FOR_MANAGED_GROUP', managedGroup.id);
                chats.getGroupMembersForChat(tenantId, managedGroup.id).then(r => {
                    commit('STOP_LOADING_GROUP_MEMBERS_FOR_MANAGED_GROUP');
                    commit('SET_GROUP_MEMBERS_FOR_MANAGED_GROUP', {
                        managedGroupId: managedGroup.id,
                        groupMembers: r.data
                    });
                }).catch(e => {
                    console.log(e);
                });
            }
        });
    },

    async loadMemberships({ commit, getters, rootGetters }, managedGroupId = null) {
        _.each(getters.managedGroups, async (managedGroup) => {
            if (managedGroupId == null || managedGroup.id == managedGroupId) {
                let tenantId = rootGetters['admin/tenantId'];

                commit('START_LOADING_MEMBERSHIP_FOR_MANAGED_GROUP', managedGroup.id);
                chats.getMembershipForChat(tenantId, managedGroup.id).then(r => {
                    commit('STOP_LOADING_MEMBERSHIP_FOR_MANAGED_GROUP');
                    commit('SET_MEMBERSHIP_FOR_MANAGED_GROUP', {
                        managedGroupId: managedGroup.id,
                        membership: r.data
                    });
                }).catch(e => {
                    console.log(e);
                });
            }
        });
    },

    setSearchQuery({ commit }, query) {
        commit('SET_SEARCH_QUERY', query);
    },
};
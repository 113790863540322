import incidents from "../../api/incidents";
import archivedIncidents from "../../api/archived-incidents";
import organisations from "../../api/organisations";
import apiClients from "../../api/api-clients";
const _ = require('lodash')
// import axios from 'axios'
import moment from "moment";

export const state = {
    adminMode: false,

    archivedMode: false,
    archivedIncident: null,

    serverErrors: [],
    tenantId: null,
    incidentId: null,

    isLoadingResponseTeams: false,
    responseTeams: [],

    isLoadingApiClientGroupLinks: false,
    apiClientGroupLinks: [],

    isLoadingAssignments: false,
    assignments: [],

    isLoadingSkills: false,
    skills: []
};

export const mutations = {
    ENABLE_ADMIN_MODE(state) {
        state.adminMode = true;
    },

    DISABLE_ADMIN_MODE(state) {
        state.adminMode = false;
    },

    ENABLE_ARCHIVED_MODE(state) {
        state.archivedMode = true;
    },

    DISABLE_ARCHIVED_MODE(state) {
        state.archivedMode = false;
    },

    SET_ARCHIVED_INCIDENT(state, incident) {
        state.archivedIncident = incident;
    },

    SET_INCIDENT_ID(state, incidentId) {
        state.incidentId = incidentId
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    },

    START_LOADING_RESPONSE_TEAMS(state) {
        state.isLoadingResponseTeams = true
    },

    STOP_LOADING_RESPONSE_TEAMS(state) {
        state.isLoadingResponseTeams = false
    },

    SET_RESPONSE_TEAMS(state, responseTeams) {
        state.responseTeams = responseTeams
    },

    START_LOADING_API_CLIENT_GROUP_LINKS(state) {
        state.isLoadingApiClientGroupLinks = true;
    },

    STOP_LOADING_API_CLIENT_GROUP_LINKS(state) {
        state.isLoadingApiClientGroupLinks = false;
    },

    SET_API_CLIENT_GROUP_LINKS(state, apiClientGroupLinks) {
        state.apiClientGroupLinks = apiClientGroupLinks
    },

    START_LOADING_ASSIGNMENTS(state) {
        state.isLoadingAssignments = true
    },

    STOP_LOADING_ASSIGNMENTS(state) {
        state.isLoadingAssignments = false
    },

    SET_ASSIGNMENTS(state, assignments) {
        state.assignments = assignments
    },

    START_LOADING_SKILLS(state) {
        state.isLoadingSkills = true
    },

    STOP_LOADING_SKILLS(state) {
        state.isLoadingSkills = false
    },

    SET_SKILLS(state, skills) {
        state.skills = skills
    },

    // Archived

    START_LOADING_ARCHIVED_INCIDENT_MEMBERSHIP(state) {
        if (state.archivedIncident) {
            state.archivedIncident = {
                ...state.archivedIncident,
                isLoadingMembership: true
            }
        }
    },

    STOP_LOADING_ARCHIVED_INCIDENT_MEMBERSHIP(state) {
        if (state.archivedIncident) {
            state.archivedIncident = {
                ...state.archivedIncident,
                isLoadingMembership: false
            }
        }
    },

    SET_ARCHIVED_INCIDENT_MEMBERSHIP(state, membership) {
        if (state.archivedIncident) {
            state.archivedIncident = {
                ...state.archivedIncident,
                membership: _.uniqBy(membership, 'id')
            }
        }
    },

    START_LOADING_ARCHIVED_INCIDENT_GROUP_MEMBERS(state) {
        if (state.archivedIncident) {
            state.archivedIncident = {
                ...state.archivedIncident,
                isLoadingGroupMembers: true
            }
        }
    },

    STOP_LOADING_ARCHIVED_INCIDENT_GROUP_MEMBERS(state) {
        if (state.archivedIncident) {
            state.archivedIncident = {
                ...state.archivedIncident,
                isLoadingGroupMembers: false
            }
        }
    },

    SET_ARCHIVED_INCIDENT_GROUP_MEMBERS(state, groupMembers) {
        if (state.archivedIncident) {
            state.archivedIncident = {
                ...state.archivedIncident,
                groupMembers: groupMembers
            };
        }
    }
};

export const getters = {
    adminMode: (state) => {
        return state.adminMode;
    },

    archivedMode: (state) => {
        return state.archivedMode;
    },

    isArchived: (state, getters, rootState, rootGetters) => {
        if (getters.adminMode) {
            return rootGetters['adminIncidentViewer/isArchived'];
        }
        if (getters.archivedMode) {
            return getters.archivedMode;
        }
        return false;
    },

    isLoading: (state, getters, rootState, rootGetters) => {
        if (rootGetters['incidents/isLoading']) {
            return true;
        }
        if (getters.incident) {
            return getters.incident.isLoadingMembership || getters.incident.isLoadingGroupMembers;
        }
        if (state.isLoadingResponseTeams) {
            return true;
        }
        return false;
    },

    incidentId: (state) => {
        return state.incidentId
    },

    incident: (state, getters, rootState, rootGetters) => {
        if (getters.adminMode) {
            let adminIncident = rootGetters['adminIncidentViewer/incident'];
            if (adminIncident && adminIncident.id == getters.incidentId) {
                return adminIncident;
            }
        } else if (getters.archivedMode) {
            return state.archivedIncident;
        } else {
            return _.find(rootGetters['incidents/incidents'], { id: getters.incidentId });
        }
        return undefined;
    },

    tenantId: (state) => {
        return state.tenantId
    },

    membership: (state, getters) => {
        if (getters.incident && getters.incident.membership) {
            return getters.incident.membership;
        }
        return [];
    },

    activeMembers: (state, getters) => {
        return _.filter(getters.membership, m => {
            if (getters.myPresenceId === m.id) {
                return true;
            }
            return _.includes(getters.activePresences, m.id);
        });
    },

    inactiveMembers: (state, getters) => {
        return _.filter(getters.membership, m => {
            if (getters.myPresenceId === m.id) {
                return false;
            }
            return !_.includes(getters.activePresences, m.id);
        });
    },

    groupMembers: (state, getters) => {
        if (getters.incident) {
            return getters.incident.groupMembers;
        }
        return [];
    },

    responseTeams: (state) => {
        return state.responseTeams
    },

    myPresenceId: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInIncident = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInIncident);
        if (intersection.length) {
            return _.first(intersection)
        }
        if (getters.adminMode) {
            return rootGetters['admin/myPresenceId'];
        }
        return null;
    },

    amIPresentInGroup: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInIncident = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInIncident);
        if (intersection.length) {
            return true;
        }
        return false;
    },

    activePresences: (state, getters) => {
        if (getters.incident && getters.incident.activePresences) {
            return getters.incident.activePresences;
        }
        return [];
    },

    isLoadingApiClientGroupLinks: (state) => {
        return state.isLoadingApiClientGroupLinks
    },

    apiClientGroupLinks: (state) => {
        return state.apiClientGroupLinks
    },

    sharedWithApiClientIds: (state) => {
        return _.map(state.apiClientGroupLinks, 'apiClientId');
    },

    firstArrivalGroupMember: (state, getters) => {
        let createdByPresenceId = getters.incident.createdBy;
        let arrived = _.filter(getters.incident.groupMembers, member => {
            return member.firstArrival && (member.presenceId !== createdByPresenceId);
        });
        if (arrived.length) {
            let withArrivalMoments = _.map(arrived, m => {
                return {
                    ...m,
                    unix: moment(m.firstArrived).unix()
                }
            });
            return _.first(_.sortBy(withArrivalMoments, 'unix'));
        }
        return null;
    },

    firstArrivalMembership: (state, getters) => {
        if (getters.firstArrivalGroupMember) {
            return _.find(getters.membership, { id: getters.firstArrivalGroupMember.presenceId });
        }
        return null;
    },

    firstResponderMembership: (state, getters) => {
        if (
            getters.incident
            && getters.incident['content']['$v']['firstResponder']
        ) {
            // return _.find(getters.membership, {id: getters.incident['content']['$v']['firstResponder']});
            // return  getters.incident['content']['$v']['firstResponder'];
            return _.find(getters.membership, (member) => {
                return member.id == getters.incident['content']['$v']['firstResponder']
            });
        }
        return null;
    },

    firstRespondedAt: (state, getters) => {
        if (
            getters.incident
            && getters.incident['content']['$v']['firstResponded']
        ) {
            return window.moment(getters.incident['content']['$v']['firstResponded']);
        }
        return null;
    },

    isWaitingForFirstResponder: (state, getters) => {
        if (getters.incident) {
            return !(getters.incident['content']['$v']['firstResponded'] !== undefined);
        }
        return false;
    },

    isLoadingAssignments: (state) => {
        return state.isLoadingAssignments
    },

    assignments: (state) => {
        return state.assignments
    },

    isLoadingSkills: (state) => {
        return state.isLoadingSkills
    },

    skills: (state) => {
        return state.skills
    }
};

export const actions = {
    enableAdminMode({ commit }) {
        commit('ENABLE_ADMIN_MODE');
    },

    disableAdminMode({ commit }) {
        commit('DISABLE_ADMIN_MODE');
    },

    clearIncidentViewer({ dispatch }) {
        dispatch('reset');
    },

    reset({ commit }) {
        commit('SET_INCIDENT_ID', null);
        commit('SET_RESPONSE_TEAMS', []);
        commit('SET_API_CLIENT_GROUP_LINKS', []);
        commit('SET_ASSIGNMENTS', []);
        commit('SET_SKILLS', []);
        commit('SET_ARCHIVED_INCIDENT', null);
        commit('DISABLE_ARCHIVED_MODE');
    },

    setIncident({ commit, dispatch }, params) {
        let { incident, tenantId } = params;
        dispatch('reset');
        commit('DISABLE_ARCHIVED_MODE');
        commit('SET_TENANT_ID', tenantId);
        commit('SET_INCIDENT_ID', incident.id);
        dispatch('timeline/reset', {}, { root: true });
        // dispatch('loadAssignments');
        // dispatch('loadMembership');
        // dispatch('loadGroupMembers');
        // dispatch('loadResponseTeams');
        // dispatch('loadApiClientGroupLinks');
        // dispatch('timeline/loadTimeline', {
        //     model: 'incident',
        //     tenantId: getters.tenantId,
        //     guid: getters.incidentId,
        //     ownerType: 3,
        //     ownerId: getters.incidentId,
        //     organisationId: getters.incident.ownerId,
        //     behaviour: 0
        // }, { root: true });
        // dispatch('timeline/loadQuickMessages', {}, { root: true });
        // dispatch('metadata/setMetadataIds', getters.incident.metadataIds ? getters.incident.metadataIds : [], { root: true });
    },

    setArchivedIncident({ commit, dispatch }, params) {
        let { incident, tenantId } = params;
        dispatch('reset');
        commit('ENABLE_ARCHIVED_MODE');
        commit('SET_TENANT_ID', tenantId);
        commit('SET_INCIDENT_ID', incident.id);
        commit('SET_ARCHIVED_INCIDENT', incident);
        dispatch('loadGroupMembersForArchivedIncident');
        dispatch('loadMembershipForArchivedIncident');
        dispatch('timeline/reset', {}, { root: true });
    },

    loadIncident({ commit, dispatch }, params) {
        let { id, tenantId } = params
        commit('SET_TENANT_ID', tenantId);
        dispatch('reset');
        commit('DISABLE_ARCHIVED_MODE');
        dispatch('timeline/reset', {}, { root: true });
        commit('SET_INCIDENT_ID', id);
        dispatch('loadAssignments');
        // dispatch('loadResponseTeams');
        // dispatch('loadApiClientGroupLinks');
        // dispatch('timeline/loadTimeline', {
        //     model: 'incident',
        //     tenantId: getters.tenantId,
        //     guid: id,
        //     ownerType: 3,
        //     ownerId: id,
        //     organisationId: getters.incident.ownerId,
        //     behaviour: 0
        // }, { root: true });
        // dispatch('timeline/loadQuickMessages', {}, { root: true });
        // dispatch('metadata/setMetadataIds', getters.incident.metadataIds ? getters.incident.metadataIds : [], { root: true });

        // incidents.getIncident(tenantId, id).then(r => {
        //     commit('STOP_LOADING');
        //     commit('SET_INCIDENT', r.data)
        //     dispatch('loadMembership');
        //     dispatch('loadGroupMembers');
        //     dispatch('loadResponseTeams');
        //     dispatch('loadApiClientGroupLinks');
        //     dispatch('timeline/loadTimeline', {
        //         model: 'incident',
        //         tenantId: getters.tenantId,
        //         guid: getters.incident.id,
        //         ownerType: 3,
        //         ownerId: getters.incident.id,
        //         organisationId: getters.incident.ownerId,
        //         behaviour: 0
        //     }, { root: true });
        //     dispatch('timeline/loadQuickMessages', {}, { root: true });
        //     dispatch('metadata/setMetadataIds', getters.incident.metadataIds ? getters.incident.metadataIds : [], { root: true });
        // }).catch(e => {
        //     commit('STOP_LOADING');
        //     this._vm.$message.error('Error loading incident');

        //     let errors;
        //     if (typeof e.response.data === 'object') {
        //         errors = _.flatten(_.toArray(e.response.data.errors));
        //     } else {
        //         errors = ['Something went wrong. Please try again.'];
        //     }
        //     commit('SET_ERRORS', errors);
        // })
    },

    loadResponseTeams({ commit, getters }) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incidentId
        commit('START_LOADING_RESPONSE_TEAMS');
        commit('SET_RESPONSE_TEAMS', []);

        let req;
        if (getters.isArchived) {
            req = archivedIncidents.getResponseTeamsForArchivedIncident(tenantId, incidentId);
        } else {
            req = incidents.getResponseTeamsForIncident(tenantId, incidentId);
        }

        req.then(r => {
            commit('STOP_LOADING_RESPONSE_TEAMS');
            commit('SET_RESPONSE_TEAMS', r.data);
            // dispatch('loadAllGroupMemberDetails');
        }).catch(e => {
            commit('STOP_LOADING_RESPONSE_TEAMS');
            this._vm.$message.error('Error loading response teams');
            console.log(e);
            let errors;
            if (e.response && e.response.data && typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    loadApiClientGroupLinks({ commit, getters }) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incidentId;
        let organisationId = getters.incident.ownerId;

        commit('START_LOADING_API_CLIENT_GROUP_LINKS');
        apiClients.getApiClientGroupLinksForIncident(tenantId, organisationId, incidentId).then(r => {
            commit('STOP_LOADING_API_CLIENT_GROUP_LINKS');
            commit('SET_API_CLIENT_GROUP_LINKS', r.data);
        }).catch(e => {
            commit('STOP_LOADING_API_CLIENT_GROUP_LINKS');
            this._vm.$message.error('Error loading previously shared with');
            console.log(e);
        })
    },

    loadAssignments({ commit, getters }) {
        let tenantId = getters.tenantId;
        let incidentId = getters.incidentId;

        commit('START_LOADING_ASSIGNMENTS');

        let req;
        if (getters.isArchived) {
            req = archivedIncidents.getArchivedAssignmentsForArchivedIncident(tenantId, incidentId);
        } else {
            req = incidents.getAssignmentsForIncident(tenantId, incidentId);
        }

        req.then(r => {
            commit('STOP_LOADING_ASSIGNMENTS');
            commit('SET_ASSIGNMENTS', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_ASSIGNMENTS');
        });
    },

    loadSkills({ commit, getters }) {
        let tenantId = getters.tenantId;
        let incident = getters.incident;

        commit('START_LOADING_SKILLS');
        organisations.getSkillsForOrg(tenantId, incident.ownerId).then(r => {
            commit('STOP_LOADING_SKILLS');
            commit('SET_SKILLS', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING_SKILLS');
        });
    },

    // Archived Incidents

    loadGroupMembersForArchivedIncident({ commit, getters }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_ARCHIVED_INCIDENT_GROUP_MEMBERS');
        archivedIncidents.getGroupMembersForArchivedIncident(getters.tenantId, incident.id).then(r => {
            commit('STOP_LOADING_ARCHIVED_INCIDENT_GROUP_MEMBERS');
            commit('SET_ARCHIVED_INCIDENT_GROUP_MEMBERS', r.data);
        }).catch(e => {
            console.log(e);
        });
    },

    loadMembershipForArchivedIncident({ commit, getters }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_ARCHIVED_INCIDENT_MEMBERSHIP');
        archivedIncidents.getMembershipForArchivedIncident(getters.tenantId, incident.id).then(r => {
            commit('STOP_LOADING_ARCHIVED_INCIDENT_MEMBERSHIP');
            commit('SET_ARCHIVED_INCIDENT_MEMBERSHIP', r.data);
        }).catch(e => {
            console.log(e);
        });
    },
};
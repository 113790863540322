import incidents from "../../api/incidents";
import presences from "../../api/presences";
import archivedIncidents from "../../api/archived-incidents";
import organisations from "../../helpers/organisations";
const _ = require('lodash');

export const state = {
    isLoading: false,
    isArchived: false,

    tenantId: null,
    incidentId: null,

    incident: null
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_IS_ARCHIVED(state, isArchived) {
        state.isArchived = isArchived
    },

    SET_SEARCH_RESULTS(state, searchResults) {
        state.searchResults = searchResults
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    },

    SET_INCIDENT_ID(state, incidentId) {
        state.incidentId = incidentId
    },

    SET_INCIDENT(state, incident) {
        state.incident = incident;
    },

    START_LOADING_MEMBERSHIP(state) {
        if (state.incident) {
            state.incident = {
                ...state.incident,
                isLoadingMembership: true
            };
        }
    },

    STOP_LOADING_MEMBERSHIP(state) {
        if (state.incident) {
            state.incident = {
                ...state.incident,
                isLoadingMembership: false
            };
        }
    },

    SET_MEMBERSHIP(state, membership) {
        if (state.incident) {
            state.incident = {
                ...state.incident,
                membership: _.uniqBy(membership, 'id')
            };
        }
    },

    START_LOADING_GROUP_MEMBERS(state) {
        if (state.incident) {
            state.incident = {
                ...state.incident,
                isLoadingGroupMembers: true
            };
        }
    },

    STOP_LOADING_GROUP_MEMBERS(state) {
        if (state.incident) {
            state.incident = {
                ...state.incident,
                isLoadingGroupMembers: false
            };
        }
    },

    SET_GROUP_MEMBERS(state, groupMembers) {
        if (state.incident) {
            state.incident = {
                ...state.incident,
                groupMembers: groupMembers
            };
        }
    },

    ADD_PRESENCE_TO_ADMIN_INCIDENT(state, params) {
        let { incidentId, presence } = params;
        if (state.incident && state.incident.id === incidentId) {
            if (state.incident.membership.length) {
                state.incident.membership.push(presence)
            } else {
                state.incident.membership = [presence];
            }
            state.incident.membership = _.uniqBy(state.incident.membership, 'id')
        }
    },

    ADD_GROUP_MEMBER_TO_ADMIN_INCIDENT(state, params) {
        let { incidentId, groupMemberDetails } = params;
        if (state.incident && state.incident.id === incidentId) {
            if (state.incident.groupMembers.length) {
                state.incident.groupMembers.push(groupMemberDetails);
            } else {
                state.incident.groupMembers = [groupMemberDetails];
            }
            state.incident.groupMembers = _.uniqBy(state.incident.groupMembers, 'presenceId');
        }
    },

    UPDATE_ADMIN_INCIDENT_GROUP_MEMBER(state, params) {
        let { presenceId, groupId, groupMemberDetails } = params;
        if (state.incident && state.incident.id === groupId) {
            if (state.incident.groupMembers && state.incident.groupMembers.length) {
                state.incident.groupMembers = _.map(state.incident.groupMembers, groupMember => {
                    if (groupMember.presenceId == presenceId) {
                        return {
                            ...groupMember,
                            ...groupMemberDetails
                        };
                    }
                    return groupMember;
                });
            }
        }
    },

    SET_PRESENCE_ACTIVE_FOR_ADMIN_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        if (state.incident && state.incident.id === incidentId) {
            if (!state.incident.activePresences) {
                state.incident.activePresences = [presenceId];
            } else {
                state.incident.activePresences.push(presenceId);
                state.incident.activePresences = _.uniq(state.incident.activePresences)
            }
        }
    },

    SET_PRESENCE_INACTIVE_FOR_ADMIN_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        if (state.incident && state.incident.id === incidentId) {
            if (state.incident.activePresences) {
                state.incident.activePresences = _.without(state.incident.activePresences, presenceId);
            } else {
                state.incident.activePresences = [];
            }
        }
    },

    UPDATE_ADMIN_INCIDENT_LAST_ACTIVITY(state, params) {
        let { incidentId, lastActivity } = params;
        if (state.incident && state.incident.id === incidentId) {
            state.incident.lastActivity = lastActivity
        }
    },

    UPDATE_ADMIN_INCIDENT_LAST_HUMAN_ACTIVITY(state, params) {
        let { incidentId, lastHumanActivity } = params;
        if (state.incident && state.incident.id === incidentId) {
            state.incident.lastHumanActivity = lastHumanActivity
        }
    },

    SET_FIRST_ARRIVE_FOR_ADMIN_INCIDENT_GROUP_MEMBER_IF_NOT_SET(state, params) {
        let { incidentId, presenceId } = params;
        if (state.incident && state.incident.id === incidentId) {
            if (state.incident.groupMembers && state.incident.groupMembers.length) {
                state.incident.groupMembers = _.map(state.incident.groupMembers, groupMember => {
                    if (groupMember.presenceId == presenceId) {
                        let groupMemberCopy = { ...groupMember };
                        if (!groupMemberCopy.firstArrived) {
                            groupMemberCopy.firstArrived = window.moment()
                        }
                        return groupMemberCopy;
                    }
                    return groupMember;
                })
            }
        }
    },

    UPDATE_ADMIN_INCIDENT_CONTENT(state, params) {
        let { incidentId, details } = params;
        if (state.incident && state.incident.id === incidentId) {
            state.incident['content']['$v'] = {
                ...state.incident['content']['$v'],
                ...details
            };
        }
    },

    REMOVE_GROUP_MEMBER_FROM_ADMIN_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        if (state.incident && state.incident.id == incidentId) {
            if (state.incident.groupMembers) {
                state.incident.groupMembers = _.filter(state.incident.groupMembers, groupMember => {
                    return groupMember.presenceId !== presenceId
                })
            }
        }
    },

    REMOVE_PRESENCE_FROM_ADMIN_INCIDENT(state, params) {
        let { incidentId, presenceId } = params;
        if (state.incident && state.incident.id == incidentId) {
            if (state.incident.membership) {
                state.incident.membership = _.filter(state.incident.membership, member => {
                    return member.id !== presenceId
                })
            }
        }
    },

    HANDLE_ADMIN_INCIDENT_UPDATED(state, params) {
        let { incidentId, newDetails } = params;
        if (state.incident && state.incident.id == incidentId) {
            state.incident = {
                ...state.incident,
                ...newDetails
            }
        }
    }
}

export const getters = {
    isLoading: (state) => {
        return state.isLoading
    },

    isArchived: (state) => {
        return state.isArchived
    },

    tenantId: (state) => {
        return state.tenantId
    },

    incidentId: (state) => {
        return state.incidentId
    },

    incident: (state) => {
        return state.incident
    },

    adminIncidentBeingViewed: (state) => {
        return state.incident
    }
}

export const actions = {
    reset({ commit }) {
        commit('SET_INCIDENT_ID', null);
        commit('SET_TENANT_ID', null);
        commit('SET_INCIDENT', null);
    },

    loadIncident({ commit, dispatch }, params) {
        let { tenantId, incidentId } = params;
        commit('SET_INCIDENT_ID', incidentId);
        commit('SET_TENANT_ID', tenantId);
        commit('SET_IS_ARCHIVED', false);

        return new Promise((resolve, reject) => {
            commit('START_LOADING');
            incidents.getIncident(tenantId, incidentId).then(r => {
                commit('SET_INCIDENT', r.data);
                dispatch('loadGroupMembers');
                // dispatch('loadMembership');
                resolve(r.data);
                commit('STOP_LOADING');
            }).catch(e => {
                commit('STOP_LOADING');
                console.log(e);
                reject(e);
            })
        });
    },

    loadGroupMembers({ commit, getters, dispatch }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_GROUP_MEMBERS');
        incidents.getGroupMembersForIncident(getters.tenantId, incident.id).then(r => {
            commit('STOP_LOADING_GROUP_MEMBERS');
            commit('SET_GROUP_MEMBERS', r.data);
            dispatch('resolveMembership');
            // TODO: Handle setting active presences
        }).catch(e => {
            console.log(e);
        });
    },

    loadMembership({ commit, getters }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_MEMBERSHIP');
        incidents.getMembershipForIncident(getters.tenantId, incident.id).then(r => {
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', r.data);
        }).catch(e => {
            console.log(e);
        });
    },

    async resolveMembership({ commit, dispatch, getters }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_MEMBERSHIP');
        if (incident && incident.groupMembers) {
            let organisation = await dispatch('missionControl/getOrganisationById', incident.ownerId, { root: true });
            let tenantId = organisations.getOrganisationTenantId(organisation)

            let presenceIds = _.map(incident.groupMembers, 'presenceId');

            let membership = await dispatch('allPresences/resolvePresences', {
                tenantId: tenantId,
                presenceIds: presenceIds
            }, { root: true });

            commit('SET_MEMBERSHIP', membership);
        }
        commit('STOP_LOADING_MEMBERSHIP');
    },

    setArchivedIncident({ commit, dispatch }, params) {
        let { tenantId, incidentId, incident } = params;
        commit('SET_IS_ARCHIVED', true);
        commit('SET_TENANT_ID', tenantId);
        commit('SET_INCIDENT_ID', incidentId);
        commit('SET_INCIDENT', incident);
        dispatch('loadGroupMembersForArchivedIncident');
        dispatch('loadMembershipForArchivedIncident');
    },

    loadGroupMembersForArchivedIncident({ commit, getters }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_GROUP_MEMBERS');
        archivedIncidents.getGroupMembersForArchivedIncident(getters.tenantId, incident.id).then(r => {
            commit('STOP_LOADING_GROUP_MEMBERS');
            commit('SET_GROUP_MEMBERS', r.data);
            // TODO: Handle setting active presences
        }).catch(e => {
            console.log(e);
        });
    },

    loadMembershipForArchivedIncident({ commit, getters }) {
        let incident = getters.incident;
        if (!incident) {
            return false;
        }

        commit('START_LOADING_MEMBERSHIP');
        archivedIncidents.getMembershipForArchivedIncident(getters.tenantId, incident.id).then(r => {
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', r.data);
        }).catch(e => {
            console.log(e);
        });
    },

    updateAdminIncidentGroupMember({ commit }, params) {
        commit('UPDATE_ADMIN_INCIDENT_GROUP_MEMBER', params);
    },

    updateAdminIncidentLastActivity({ commit }, params) {
        commit('UPDATE_ADMIN_INCIDENT_LAST_ACTIVITY', params);
    },

    updateAdminIncidentLastHumanActivity({ commit }, params) {
        commit('UPDATE_ADMIN_INCIDENT_LAST_HUMAN_ACTIVITY', params);
    },

    handleGroupMemberAddedToAdminIncident({ commit, dispatch, getters }, params) {
        let incident = getters.incident;
        if (incident.id == params.incidentId) {
            let shouldAdd = true;
            // We don't want to add a group member if they already exist
            if (incident.groupMembers) {
                let groupMember = _.find(incident.groupMembers, gm => {
                    return gm.presenceId == params.groupMemberDetails.presenceId;
                });
                if (groupMember) {
                    shouldAdd = false;
                }
            }

            if (shouldAdd) {
                commit('ADD_GROUP_MEMBER_TO_ADMIN_INCIDENT', params);
                dispatch('fetchAndAddMembershipForAdminIncident', {
                    incidentId: params.incidentId,
                    tenantId: params.tenantId,
                    presenceId: params.groupMemberDetails.presenceId
                });
            }
        }
    },

    fetchAndAddMembershipForAdminIncident({ commit }, params) {
        let { incidentId, tenantId, presenceId } = params;
        commit('START_PATCHING');
        presences.getPresence(tenantId, presenceId).then(r => {
            commit('STOP_PATCHING');
            commit('ADD_PRESENCE_TO_ADMIN_INCIDENT', {
                incidentId: incidentId,
                presence: r.data
            });
        }).catch(e => {
            commit('STOP_PATCHING');
            console.log(e);
            this._vm.$message.error('Error fetching membership for incident');
        });
    },

    setPresenceActiveForAdminIncident({ commit }, params) {
        commit('SET_PRESENCE_ACTIVE_FOR_ADMIN_INCIDENT', params);
    },

    setPresenceInactiveForAdminIncident({ commit }, params) {
        commit('SET_PRESENCE_INACTIVE_FOR_ADMIN_INCIDENT', params);
    },

    setFirstArriveForAdminIncidentGroupMemberIfNotSet({ commit }, params) {
        commit('SET_FIRST_ARRIVE_FOR_ADMIN_INCIDENT_GROUP_MEMBER_IF_NOT_SET', params);
    },

    updateAdminIncidentContent({ commit }, params) {
        commit('UPDATE_ADMIN_INCIDENT_CONTENT', params);
    },

    removeGroupMemberFromAdminIncident({ commit }, params) {
        commit('REMOVE_GROUP_MEMBER_FROM_ADMIN_INCIDENT', params);
        commit('REMOVE_PRESENCE_FROM_ADMIN_INCIDENT', params);
    },

    handleAdminIncidentUpdated({ commit }, params) {
        commit('HANDLE_ADMIN_INCIDENT_UPDATED', params);
    },

    reloadAdminIncidentMembership({ dispatch, getters }, params) {
        let { incidentId } = params;
        if (getters.incident && getters.incident.id === incidentId) {
            dispatch('loadGroupMembers', incidentId);
            // dispatch('loadMembership', incidentId);
        }
    }
}
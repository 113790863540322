<template>
  <div class="tsos-widget">
    <FocusLoop :is-visible="true" :disabled="!show">
      <div ref="tsosPopup" class="tsos-popup">
        <transition name="scale">
          <div v-if="show" id="tsos-popup-box" class="tsos-popup-box">
            <div class="tsos-popup-header">
              <home-header v-if="route == 'home'"></home-header>
              <incidents-header v-if="route == 'incidents'"></incidents-header>
              <call-for-help-header v-if="route == 'call-for-help'"></call-for-help-header>
            </div>

            <div class="tsos-popup-body">
              <slot name="body">

                <!-- If we're loaded, let's route to the right screen -->
                <!-- <div class="tsos-popup-body-inner rounded"> -->
                <div class="tsos-popup-body-inner rounded">
                  <HomeView v-if="route == 'home'" :url-asset="urlAssets" :attendants="getAttendants" />
                  <IncidentsView v-if="route == 'incidents'" />
                  <CallForHelpView v-if="route == 'call-for-help'"></CallForHelpView>
                </div>
                <!-- / If we're loaded, let' route to the right screen -->
              </slot>
            </div>

            <div v-if="route !== 'chat'" class="tsos-popup-footer">
              <home-footer></home-footer>
            </div>
          </div>
        </transition>
        <button ref="tsosButton" class="tsos-popup-button tsos-popup-button--default"
           aria-haspopup="true" aria-controls="tsos-popup-box"
          :aria-expanded="show ? 'true' : 'false'" :aria-label="ariaLabelButton" :title="ariaLabelButton"
          @click="togglePopup">
          <span v-show="!show || !icon">
            <slot name="button" :open="show">
              <img src="@/assets/tsos-widget/tsos.svg" alt="chat icon" class="tsos-popup-logo" aria-hidden="true">
            </slot>
          </span>
          <img v-show="show && icon" src="@/assets/tsos-widget/close.svg" alt="close icon" aria-hidden="true">
        </button>
      </div>
    </FocusLoop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeView from './Views/HomeView.vue'
import IncidentsView from './Views/IncidentsView.vue'
import { FocusLoop } from '@vue-a11y/focus-loop'
import HomeHeader from './Headers/HomeHeader.vue'
import IncidentsHeader from './Headers/IncidentsHeader.vue'
import HomeFooter from './Footers/HomeFooter.vue'
import CallForHelpHeader from './Headers/CallForHelpHeader.vue'
import CallForHelpView from './Views/CallForHelpView.vue'
import eventBus from '../../event-bus';

export default {
  name: 'TsosWidget',

  components: {
    HomeHeader,
    IncidentsHeader,
    HomeFooter,
    HomeView,
    IncidentsView,
    CallForHelpHeader,
    CallForHelpView,
    FocusLoop
  },

  props: {
    config: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isInitialising: false,
      isInitialised: true,
      show: false,
      tsosPopup: null,
      tsosButton: null
    }
  },

  computed: {
    ...mapGetters('tsosWidget', {
      route: 'route'
    }),

    ariaLabelButton() {
      return `${this.show ? 'Close' : 'Open'} call for help popup`
    }
  },

  created() {
    // Event bus listen to incident-created and hide on event
    eventBus.$on('incident-created', () => {
      this.show = false
    })

    eventBus.$on('widget-navigated-to-incident', () => {
      this.show = false
    })

    this.$watch('show', (val) => {
      if (!val) return this.removeEventListeners()
      document.addEventListener('keydown', this.closeKeydownEsc)
    })
  },

  beforeDestroy() {
    this.removeEventListeners()
  },

  methods: {
    togglePopup() {
      this.show = !this.show
      setTimeout(() => this.tsosButton.blur(), 500)
      if (!this.show) {
        return this.$emit('close')
      }
      this.$emit('open')
    },

    closeKeydownEsc(event) {
      if (event.which === 27) this.togglePopup()
    },

    removeEventListeners() {
      document.removeEventListener('keydown', this.closeKeydownEsc)
    }
  }
}
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

.tsos-widget {
  .tsos-popup-header p {
    font-weight: 500;
    font-size: 17px;
  }

  #callScreenRef {
    height: 100% !important;
  }

  .ccccn .cc-bottom-buttons {
    justify-content: flex-start !important;
    padding-left: 10px !important;
  }

  .cc__callscreen__resize,
  .cc__callscreen__resize-button {
    display: none !important;
  }

  // .cc-callbuttons-buttons {
  //   display: grid !important;
  //   gap: 15px !important;
  // }

  // .tsos-popup * {
  //   box-sizing: border-box;
  //   padding: 0;
  //   margin: 0;
  //   font-family: 'Inter', sans-serif;
  // }

  .tsos-popup {
    position: fixed;
    // z-index: 999;
    z-index: 9999;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 380px;
    width: 100%;
    margin-left: 20px;
    -webkit-font-smoothing: antialiased;
  }

  .tsos-popup[dir="ltr"] {
    text-align: left;
  }

  .tsos-popup[dir="rtl"] {
    left: 20px;
    right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .tsos-popup[dir="rtl"] .tsos-popup-box {
    transform-origin: bottom left;
  }

  .tsos-popup[dir="rtl"] .tsos-popup-body .tsos-popup-body__link-info {
    padding-left: 0;
    padding-right: 10px;
  }

  .tsos-popup-box {
    // transition: transform 0.3s ease-in-out, opacity 0.2s, visibility 0.2s;
    transition: transform 0.2s ease-in-out, opacity 0.1s, visibility 0.1s;
    transform-origin: bottom right;
    transform: scale3d(1, 1, 1);
    // border-radius: 10px;

    // border-radius: 23px;
    // border-radius: 35px;
    border-radius: 25px;

    margin-bottom: 10px;
    box-shadow: 0 5px 25px -5px rgba(45, 62, 79, 0.15);
    // background-color: #fbfbfb;
    border: var(--tsos-border-default);
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: var(--tsos-bg-header);
  }

  .tsos-popup .scale-enter-active,
  .tsos-popup .scale-leave-active {
    transform: scale3d(0, 0, 0);
  }

  .tsos-popup-header {
    // padding: 25px 25px;
    // border-bottom: 5px solid var(--tsos-border-color-bottom-header);
    // background-color: var(--tsos-bg-header);
    color: var(--tsos-text-color-header) !important;

    h3 {
      color: var(--tsos-text-color-header) !important;
    }
  }

  .tsos-popup-body {
    // padding: 14px;
    // padding: 5px;
    // background-color: #fff;
    // background-color: var(--tsos-bg-header);
    // max-height: 300px;
    // min-height: 500px;
    // max-height: 500px;
    // min-height: calc(100vh - 250px);
    // min-height: 250px;
    height: 100%;
    max-height: calc(100vh - 250px);
    overflow: auto;
    // min-width: 350px;
    // min-width: 100vw;
    width: 100%;

    // on mobile, change max height
    @media (max-width: 768px) {
      // min-height: calc(100vh - 320px) !important;
      // max-height: calc(100vh - 320px) !important;
    }

    .tsos-popup-body-inner {
      background: #fff;
      width: 100%;
      height: 100%;

      &.rounded {
        // border-top-left-radius: 23px;
        // border-top-right-radius: 23px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        overflow: hidden;
      }
    }

    .call-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 18px;
    }

    .cc-outgoingcall__wrapper {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .tsos-popup-body__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  .tsos-popup-body__list-item {
    width: 100%;
  }

  .tsos-popup-body__list-item:not(:last-child) {
    border-bottom: 1px solid #fbfbfb;
  }

  .tsos-popup-body__link {
    outline: none;
    display: flex;
    padding: 10px 18px;
    text-decoration: none;
    color: #333;
    letter-spacing: 0.2px;
    margin: 1.5px 0;
    // border-radius: 10px;
    border-radius: 35px;
    text-decoration: none;
  }

  .tsos-popup-body__link:hover {
    background-color: #fbfbfb;
    cursor: pointer;
  }

  // .tsos-popup-body__link:hover,
  // .tsos-popup-body__link:focus {
  //   background-color: #fbfbfb;
  // }

  // .tsos-popup-body__link:focus {
  //   box-shadow: 0 0 0 3px var(--tsos-outline-color);
  // }

  .tsos-popup-body__link-avatar {
    position: relative;
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
  }

  .tsos-popup-body__link-avatar>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .tsos-popup-body__link-avatar>.avatar-initials {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.6);
  }

  .tsos-popup-body__link-avatar-brand {
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .tsos-popup-body__link-info {
    padding-top: 12px;
    padding-left: 12px;
  }

  .tsos-popup-body__link-info>span {
    display: block;
    line-height: 1rem;
  }

  .tsos-popup-body__link-info__label {
    font-size: 12px;
  }

  .tsos-popup-body__link-info__name {
    font-weight: bold;
    margin-top: 2px;
    font-size: 18px;
  }

  .tsos-popup-footer {
    // padding: 16px;
    border-top: 1px solid var(--tsos-border-color-top-footer);
    background-color: var(--tsos-bg-footer);
    color: var(--tsos-text-color-footer);

    // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 25px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 25px;
  }

  .tsos-popup-button {
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    color: var(--tsos-text-color-button);
    border: none;
    cursor: pointer;
    border-radius: 50%;

    // width: 60px;
    // height: 60px;

    width: 55px;
    height: 55px;

    display: inline-block;
    padding-top: 2px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    font-family: inherit;
    // transition: background-color 0.3s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.1s;
  }

  .tsos-popup-logo {
    width: 73%;
    height: 73%;
  }

  .tsos-popup-button--default {
    background-color: var(--tsos-bg-button);
  }

  .tsos-popup-button--no-icon {
    border-radius: 30px;
    height: auto;
    width: auto;
    padding: 14px 20px;
  }

  .tsos-popup-button:focus,
  .tsos-popup-button:hover {
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px var(--tsos-outline-color);
  }

  @media (prefers-reduced-motion: reduce) {
    .vsc-popup {
      transition: none;
    }
  }
}
</style>
<template>
  <a-modal
    v-model="shouldShowModal"
    title="Send Feedback to TeamSOS"
    ok-text="Send"
    @ok="sendFeedback"
    :ok-button-props="{
      props: { disabled: feedbackMessage.trim().length === 0 },
    }"
  >
    <loading-screen :is-loading="isSaving"></loading-screen>
    <a-textarea
      v-model="feedbackMessage"
      :rows="5"
      placeholder="What would you like us to know? We read everything you send!"
    ></a-textarea>
    <div style="margin-top: 10px">
      <small>App version {{ version }}</small>
    </div>
  </a-modal>
</template>

<script>
const _ = require('lodash');
import LoadingScreen from "../components/LoadingScreen.vue";
import feedback from "../api/feedback";
import {mapGetters} from 'vuex';
export default {
  props: ["visible", "version"],
  components: { LoadingScreen },
  data() {
    return {
      feedbackMessage: "",
      isSaving: false,
      logs: [],
    };
  },
  created() {
    let current_log = console.log;
    console.log = (msg) => {
      if (msg !== undefined) this.logs.push(msg);
      current_log.apply(null, arguments);
    };
  },
  computed: {
    ...mapGetters('missionControl', {
      presences: 'presences'
    }),

    shouldShowModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    sendFeedback() {
      let vm = this;
      vm.isSaving = true;

      let feedbackMessage = vm.feedbackMessage;
      feedbackMessage = feedbackMessage + '\n\nConsole Logs:\n' + '-----------------------';
      feedbackMessage = feedbackMessage + this.logs.join('\n');

      let presence = _.first(this.presences);

      let fbRequest = {
        Section: this.$route.fullPath,
        FeedbackMessage: feedbackMessage,
        Platform: 'Web',
        OSVersion: '-',
        AppVersion: 'API-' + this.version,
        PresenceId: presence ? presence.id : null,
        OrgId: presence ? presence.ownerId : null
      };

      feedback
        .addFeedback(fbRequest)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Feedback submitted successfully!");
          vm.feedbackMessage = "";
          vm.$emit("close");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error submitting feedback");
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style>
</style>
export const state = {};

export const mutations = {};

export const getters = {
    canEditApiClient(state, getters, rootState, rootGetters) {
        let isMyPresenceHidden = rootGetters['admin/isMyPresenceHidden'];

        let isContentOrg = false;
        if (
            rootGetters['admin/selectedOrganisation']
            && rootGetters['admin/selectedOrganisation']['content']['$v']['contentOrg']
        ) {
            isContentOrg = true;
        }

        // From discussion - any (admin) presence should be able to view the ApiClient, but the save option is only available to:
        // Unhidden admins in the org, where Content.ContentOrg is null or false Any admins in the org, where Content.ContentOrg is true
        if (!isMyPresenceHidden) {
            return !isContentOrg;
        } else {
            return isContentOrg;
        }
    }
};

export const actions = {};
<template>
    <a-layout-header v-show="hasNotification" class="verison-header" style="
      background: #000;
      color: #fff;
      text-align: center;
      font-weight: bold;
      height: 55px;
      line-height: 55px;
    ">
        <span style="margin-right: 20px">{{ notification ? notification.notification : '' }}</span>
        <a-button @click.prevent="goToNotification" class="btn-rounded" style="font-weight: 600">{{ notification ? notification.cta_text : '' }}</a-button>
    </a-layout-header>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            isLoading: false,
            notification: null,

            pollingInterval: null,
        };
    },
    computed: {
        hasNotification() {
            return this.notification;
        },
    },
    created() {
        let vm = this;
        vm.getNotification();
        vm.pollingInterval = setInterval(vm.getNotification, 30 * 60000); // every 30 mins
        // vm.pollingInterval = setInterval(vm.getVersion, 1 * 60000); // every 1 min
    },
    methods: {
        getNotification() {
            let vm = this;
            vm.isLoading = true;
            axios
                .get(
                    window.API_BASE +
                    "/api/v1/faqs/notification"
                )
                .then((r) => {
                    vm.notification = r.data;
                    vm.isLoading = false;
                    // eventBus.$emit("appVersion", vm.latestVersion);
                })
                .catch((e) => {
                    console.log(e);
                    vm.isLoading = false;
                });
        },
        goToNotification() {
            if (this.notification && this.notification.cta_link) {
                window.open(this.notification.cta_link);
            }
        },
    },
};
</script>

<style></style>
import moment from "moment";
const _ = require('lodash');

export default {
    isIncidentDrill(incident) {
        return incident['content']['$v']['mode'] == 1;
    },

    isIncidentDemo(incident) {
        return incident['content']['$v']['mode'] == 2;
    },

    isIncidentUrgent(incident) {
        return incident['content']['$v']['isUrgent'];
    },

 /* IsLive if:
   * Isn't a Drill or a Demo
   * The incident was created up to two hours ago and has not yet been responded
   * The last human activity on the incident was within a hour ago
   */
    isIncidentLive(incident) {
        // Is incident drill or demo
        let isIncidentDrillOrDemo = (this.isIncidentDrill(incident) || this.isIncidentDemo(incident));

        // Was created up to two hours ago
        let twoHoursAgo = window.moment().subtract(2, 'hours');
        let wasCreatedUpToTwoHoursAgo = window.moment(incident.created).isAfter(twoHoursAgo);

        //  Has been responded
        let hasBeenResponded = incident['content']['$v']['firstResponded'];

        // Last human activity was within an hour ago
        let anHourAgo = window.moment().subtract(1, 'hour');
        let wasLastHumanActivityWithinAnHour = window.moment(incident.lastHumanActivity).isAfter(anHourAgo);

        return !isIncidentDrillOrDemo && ((wasCreatedUpToTwoHoursAgo && !hasBeenResponded) || wasLastHumanActivityWithinAnHour);
    },

    sortByLastActivity(incidents) {
        let withLastActivityMoments = _.map(incidents, i => {
            return {
                ...i,
                unix: moment(i.lastActivity).unix()
            }
        });
        return _.sortBy(withLastActivityMoments, 'unix')
    }
}
<template>
  <div class="tsos-popup-home-view">
    <ul class="tsos-popup-body__list">
      <li class="tsos-popup-body__list-item">

        <div @click.prevent.stop="navigateToRoute('call-for-help')" class="tsos-popup-body__link" aria-label="Start a conversation">
          <div class="tsos-popup-body__link-avatar">
            <div class="avatar-initials">
              <span>
                <a-icon type="warning" />
              </span>
            </div>

          </div>
          <div class="tsos-popup-body__link-info">
            <span class="tsos-popup-body__link-info__name">Call For Help</span>
          </div>
        </div>

      </li>
    </ul>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
// import {WarningOutlined} from '@ant-design/icons-vue'

export default defineComponent({
  name: 'HomeView',

  data() {
    return {
      recipientUid: null,
      callSettings: null
    }
  },

  components: {},

  props: {
    urlAsset: String,
    attendants: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    ...mapActions('tsosWidget', {
      navigateToRoute: 'navigateToRoute'
    }),

    getInitials(name) {
      const initials = name.match(/\b\w/g) || []
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    },

    handleUserSelected() {
      this.navigateToRoute('chat');
    }
  }
})
</script>

<style scoped lang="scss">
.tsos-popup-home-view {
  padding: 15px !important;
  letter-spacing: -0.4px;

  min-height: 250px;

  .tsos-popup-body__link-info {
    padding-top: 0 !important;
    display: flex;
    align-items: center;

    .tsos-popup-body__link-info__name {
      margin-top: 0;
    }
  }
}
</style>
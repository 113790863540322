import axios from 'axios';

export default {
    addPolicy(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Policies', params);
    },

    getPolicies(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Policies');
    },

    getPolicy(tenantId, policyId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Policies/' + policyId);
    },

    getPreviousPolicies(tenantId, orgId, policyId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Policies/' + policyId);
    },

    updatePolicy(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Policies', params);
    },

    deletePolicy(tenantId, policyId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Policies/' + policyId);
    },

    attachPolicyMediaStreamed(tenantId, policyId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/Policies/' + policyId + '/Media/Stream',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    attachPolicyMediaReference(tenantId, policyId, referenceUrl) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Policies/' + policyId + '/Media/Reference', referenceUrl, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    attachPolicyMediaDirect(tenantId, policyId, directUrl) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Policies/' + policyId + '/Media/Direct', directUrl, {
            headers: {
                "content-type": "application/json"
            }
        });
    }
}
const _ = require('lodash');
import { mapActions, mapGetters } from 'vuex';
import organisations from "../helpers/organisations";
import presences from "../api/presences";
import eventBus from "../event-bus";
export default {
    data() {
        return {
            controlMessages: [
                'Unknown',                    // 0
                'ListenerCount',              // ControlMessageListener, 1
                'RefreshAssignments',         // ControlMessageRefreshAssignments, 2
                'GroupArrive',                // ControlMessagePresenceGroup, 3
                'GroupDepart',                // ControlMessagePresenceGroup, 4
                'GroupTyping',                // ControlMessagePresenceGroup, 5
                'GroupDispose',               // ControlMessagePresenceGroup, 6
                'GroupResponded',             // ControlMessagePresenceGroup, 7
                'GroupLeave',                 // ControlMessagePresenceGroup, 8
                'GroupChangedChat',           // ChatDetails, 9
                'GroupChangedIncident',       // IncidentDetails, 10
                'GroupChangedMembership',     // ControlMessageEntity, 11
                'UpdatedPersonalEmergency',   // ControlMessagePersonalEmergency, 12
                'GroupMemberUpdated'          // ControlMessageGroupMemberUpdated, 13
            ]
        }
    },

    computed: {
        ...mapGetters("incidents", {
            incidents: "incidents"
        }),

        ...mapGetters("chats", {
            chats: "chats"
        }),

        ...mapGetters("announcements", {
            announcements: "announcements"
        }),

        ...mapGetters("chatViewer", {
            chat: "chat"
        }),

        ...mapGetters("incidentViewer", {
            incident: "incident"
        }),

        ...mapGetters("adminIncidentViewer", {
            adminIncidentBeingViewed: "adminIncidentBeingViewed"
        }),

        ...mapGetters("announcementViewer", {
            announcement: "announcement"
        }),

        ...mapGetters("missionControl", {
            presences: "presences",
            organisations: "organisations"
        })
    },

    methods: {
        ...mapActions("notifier", {
            globalNotify: "globalNotify"
        }),

        ...mapActions("chats", {
            loadChats: "loadChats",
            updateChatGroupMember: "updateChatGroupMember",
            updateChatFirstArrivalIfNotSet: "updateChatFirstArrivalIfNotSet",
            updateChatLastActivity: "updateChatLastActivity",
            updateChatLastHumanActivity: "updateChatLastHumanActivity",
            handleGroupMemberAddedToChat: "handleGroupMemberAddedToChat",
            setPresenceActiveForChat: "setPresenceActiveForChat",
            setPresenceInactiveForChat: "setPresenceInactiveForChat",
            setFirstArriveForChatGroupMemberIfNotSet: "setFirstArriveForChatGroupMemberIfNotSet",
            disposeOfChat: "disposeOfChat",
            removeGroupMemberFromChat: "removeGroupMemberFromChat",
            handleChatUpdated: "handleChatUpdated",
            fetchAndAddChat: "fetchAndAddChat",
            reloadChatMembership: "reloadChatMembership"
        }),

        ...mapActions("incidents", {
            loadIncidents: "loadIncidents",
            updateIncidentGroupMember: "updateIncidentGroupMember",
            updateIncidentLastActivity: "updateIncidentLastActivity",
            updateIncidentLastHumanActivity: "updateIncidentLastHumanActivity",
            handleGroupMemberAddedToIncident: "handleGroupMemberAddedToIncident",
            setPresenceActiveForIncident: "setPresenceActiveForIncident",
            setPresenceInactiveForIncident: "setPresenceInactiveForIncident",
            setFirstArriveForIncidentGroupMemberIfNotSet: "setFirstArriveForIncidentGroupMemberIfNotSet",
            disposeOfIncident: "disposeOfIncident",
            updateIncidentContent: "updateIncidentContent",
            removeGroupMemberFromIncident: "removeGroupMemberFromIncident",
            handleIncidentUpdated: "handleIncidentUpdated",
            fetchAndAddIncident: "fetchAndAddIncident",
            reloadIncidentMembership: "reloadIncidentMembership"
        }),

        ...mapActions("adminIncidentViewer", {
            updateAdminIncidentGroupMember: "updateAdminIncidentGroupMember",
            updateAdminIncidentLastActivity: "updateAdminIncidentLastActivity",
            updateAdminIncidentLastHumanActivity: "updateAdminIncidentLastHumanActivity",
            handleGroupMemberAddedToAdminIncident: "handleGroupMemberToAdminIncident",
            setPresenceActiveForAdminIncident: "setPresenceActiveForAdminIncident",
            setPresenceInactiveForAdminIncident: "setPresenceInactiveForAdminIncident",
            setFirstArriveForAdminIncidentGroupMemberIfNotSet: "setFirstArriveForAdminIncidentGroupMemberIfNotSet",
            updateAdminIncidentContent: "updateAdminIncidentContent",
            removeGroupMemberFromAdminIncident: "removeGroupMemberFromAdminIncident",
            handleAdminIncidentUpdated: "handleAdminIncidentUpdated",
            reloadAdminIncidentMembership: "reloadAdminIncidentMembership"
        }),

        ...mapActions("announcements", {
            loadAnnouncements: "loadAnnouncements",
            updateAnnouncementGroupMember: "updateAnnouncementGroupMember",
            fetchAndAddAnnouncement: "fetchAndAddAnnouncement",
            updateAnnouncementLastActivity: "updateAnnouncementLastActivity",
            updateAnnouncementLastHumanActivity: "updateAnnouncementLastHumanActivity",
            updateAnnouncement: "updateAnnouncement",
            reloadAnnouncementMembership: "reloadAnnouncementMembership"
        }),

        ...mapActions("adminAnnouncements", {
            updateAdminAnnouncementGroupMember: "updateAdminAnnouncementGroupMember",
            updateAdminAnnouncementLastActivity: "updateAdminAnnouncementLastActivity",
            updateAdminAnnouncementLastHumanActivity: "updateAdminAnnouncementLastHumanActivity",
            updateAdminAnnouncement: "updateAdminAnnouncement",
            reloadAdminAnnouncementMembership: "reloadAdminAnnouncementMembership"
        }),

        ...mapActions("tasks", {
            loadTasks: "loadTasks"
        }),

        getIncidentDisplayNameForNotification(incident) {
            if (incident.displayName && incident.displayName.length) {
                return incident.displayName;
            }
            return "Unknown";
        },

        getChatDisplayNameForNotification(chat) {
            if (chat.displayName && chat.displayName.length) {
                return chat.displayName;
            }
            if (chat.isLoadingMembership) {
                return "Unknown";
            }
            if (chat.membership.length) {
                let myPresenceIds = _.map(this.presences, "id");
                let membersNotMe = _.filter(chat.membership, (member) => {
                    return !myPresenceIds.includes(member.id);
                });
                let displayNames = _.map(membersNotMe, "displayName");
                let numberOfExtraPeople = null;
                if (displayNames.length > 2) {
                    numberOfExtraPeople = displayNames.length - 2;
                }
                if (displayNames.length) {
                    if (numberOfExtraPeople) {
                        return (
                            displayNames.splice(0, 2).join(", ") +
                            " and " +
                            numberOfExtraPeople +
                            " " +
                            (numberOfExtraPeople > 1 ? "others" : "other")
                        );
                    } else {
                        return displayNames.join(", ");
                    }
                } else {
                    return "Unknown";
                }
            }
            return "Unknown";
        },

        getOrganisationById(id) {
            return _.find(this.organisations, { id });
        },

        getControlMessageIndex(val) {
            return this.controlMessages.indexOf(val)
        },

        getHumanReadableControlMessage(index) {
            return this.controlMessages[index]
        },

        listenToSignalRMessages() {
            this.listenToSignalRControlMessages();
            this.listenForNewChatEntry();
            this.listenForNotifyMemberAdded();
            this.listenForAnnouncementResponseMessage();
            this.listenForAddParticiantMessage();
            this.listenForNotifyAlertMessage();
        },

        listenToSignalRControlMessages() {
            let vm = this;
            this.$signalR.on("ControlMessage", (controlMessage) => {
                let messageType = this.getHumanReadableControlMessage(controlMessage.messageType);
                console.log('[Global] Control Message Received:');
                console.log(messageType);
                // console.log(controlMessage);

                eventBus.$emit('new-control-message-for-timeline', controlMessage);

                // Debug
                if (window.location.hostname == 'localhost' && window.secretDebug) {
                    vm.$message.info(messageType)
                }

                if (messageType == 'Unknown') {
                    console.log('Ignoring Unknown control message');
                }

                if (messageType == 'ListenerCount') {
                    console.log('Ignoring listener count control message');
                }

                if (messageType == 'RefreshAssignments') {
                    this.handleRefreshAssignmentsControlMessage(controlMessage);
                }

                if (messageType == 'GroupArrive') {
                    this.handleGroupArriveControlMessage(controlMessage);
                }

                if (messageType == 'GroupDepart') {
                    this.handleGroupDepartControlMessage(controlMessage);
                }

                if (messageType == 'GroupDispose') {
                    this.handleGroupDisposeControlMessage(controlMessage);
                }

                if (messageType == 'GroupResponded') {
                    this.handleGroupRespondedControlMessage(controlMessage);
                }

                if (messageType == 'GroupLeave') {
                    this.handleGroupLeaveControlMessage(controlMessage);
                }

                if (messageType == 'GroupChangedChat') {
                    this.handleGroupChangedChatControlMessage(controlMessage);
                }

                if (messageType == 'GroupChangedIncident') {
                    this.handleGroupChangedIncidentControlMessage(controlMessage);
                }

                if (messageType == 'GroupChangedMembership') {
                    this.handleGroupChangedMembershipControlMessage(controlMessage);
                }

                if (messageType == 'GroupMemberUpdated') {
                    this.handleGroupMemberUpdatedControlMessage(controlMessage);
                }

                // if (messageType == 'GroupChangedMembership') {
                //     console.log('Because received GroupChangedMessages');
                //     console.log('Reloading chats, incidents, announcements...');

                //     // TODO: Check payload
                //     vm.loadChats();
                //     vm.loadIncidents();
                //     vm.loadAnnouncements();
                // }
            });
        },

        handleRefreshAssignmentsControlMessage() {
            // this.loadChats();
            // this.loadIncidents();
            // this.loadAnnouncements();
            this.loadTasks();
        },

        handleGroupArriveControlMessage(controlMessage) {
            let messageBody = JSON.parse(controlMessage["messageBody"]);
            let groupId = messageBody["groupId"];
            let presenceId = messageBody["presenceId"];

            // Check chats
            _.each(this.chats, chat => {
                if (chat.id === groupId) {
                    this.setPresenceActiveForChat({
                        chatId: chat.id,
                        presenceId: presenceId
                    });
                    this.setFirstArriveForChatGroupMemberIfNotSet({
                        chatId: chat.id,
                        presenceId: presenceId
                    })
                }
            });

            // Check incidents
            _.each(this.incidents, incident => {
                if (incident.id === groupId) {
                    this.setPresenceActiveForIncident({
                        incidentId: incident.id,
                        presenceId: presenceId
                    });
                    this.setFirstArriveForIncidentGroupMemberIfNotSet({
                        incidentId: incident.id,
                        presenceId: presenceId
                    })
                }
            });

            // Check admin incident
            if (
                this.adminIncidentBeingViewed
                && this.adminIncidentBeingViewed.id == groupId
            ) {
                this.setPresenceActiveForAdminIncident({
                    incidentId: groupId,
                    presenceId: presenceId
                })
            }

            // Check announcements
            // TODO: Check - do we need to check announcements here?
        },

        handleGroupDepartControlMessage(controlMessage) {
            let messageBody = JSON.parse(controlMessage["messageBody"]);
            let groupId = messageBody["groupId"];
            let presenceId = messageBody["presenceId"];

            // Check chats
            _.each(this.chats, chat => {
                if (chat.id === groupId) {
                    this.setPresenceInactiveForChat({
                        chatId: chat.id,
                        presenceId: presenceId
                    });
                }
            });

            // Check incidents
            _.each(this.incidents, incident => {
                if (incident.id === groupId) {
                    this.setPresenceInactiveForIncident({
                        incidentId: incident.id,
                        presenceId: presenceId
                    });
                }
            });

            // Check admin incident
            if (
                this.adminIncidentBeingViewed
                && this.adminIncidentBeingViewed.id == groupId
            ) {
                this.setPresenceInactiveForAdminIncident({
                    incidentId: groupId,
                    presenceId: presenceId
                })
            }

            // Check announcements
            // TODO: Check - do we need to check announcements here?
        },

        handleGroupDisposeControlMessage(controlMessage) {
            let targetGroupId = controlMessage.targetGroupId;

            let vm = this;

            // If chat selected
            if (
                this.$route.name == 'View Chat'
                && this.chat
                && this.chat.id == targetGroupId
            ) {
                vm.$message.info("Chat has been closed");
                this.$router.push('/chats');
            }

            // If incident selected
            if (
                this.$route.name == 'View Incident'
                && this.incident
                && this.incident.id == targetGroupId
            ) {
                vm.$message.info("Incident has been closed");
                this.$router.push('/incidents');
            }

            // Remove from chats
            _.each(this.chats, chat => {
                if (chat.id == targetGroupId) {
                    this.disposeOfChat(targetGroupId);
                }
            });

            // Remove from incidents
            _.each(this.incidents, incident => {
                if (incident.id == targetGroupId) {
                    this.disposeOfIncident(targetGroupId);
                }
            });
        },

        handleGroupRespondedControlMessage(controlMessage) {
            console.log('Group responded control message:');
            console.log(controlMessage);
            // alert('group responded');

            let messageBody = JSON.parse(controlMessage["messageBody"]);
            let groupId = messageBody.groupId;
            let presenceId = messageBody.presenceId;
            let firstArrival = messageBody.firstArrival ? window.moment(messageBody.firstArrival) : window.moment();

            // Find incident
            let incident = _.find(this.incidents, incident => {
                return incident.id == groupId;
            });

            // Update first responded on incident
            if (incident && !incident['content']['$v']['firstResponded']) {
                this.updateIncidentContent({
                    incidentId: incident.id,
                    details: {
                        firstResponded: firstArrival,
                        firstResponder: presenceId
                    }
                });
            }

            // Update first arrival for group member if needed
            // if (incident && incident.groupMembers && firstArrival) {
            //     let groupMember = _.find(incident.groupMembers, groupMember => {
            //         return groupMember.presenceId == presenceId;
            //     });
            //     if (groupMember) {
            //         this.updateIncidentGroupMember({
            //             presenceId: presenceId,
            //             groupId: groupId,
            //             groupMemberDetails: {
            //                 firstArrival: firstArrival
            //             }
            //         });
            //     }
            // }

            // Check admin incident
            if (
                this.adminIncidentBeingViewed
                && this.adminIncidentBeingViewed.id == groupId
            ) {
                // Update first responded on incident
                if (!this.adminIncidentBeingViewed['content']['$v']['firstResponded']) {
                    this.updateAdminIncidentContent({
                        incidentId: this.adminIncidentBeingViewed.id,
                        details: {
                            firstResponded: firstArrival,
                            firstResponder: presenceId
                        }
                    });
                }

                // Update first arrival for group member if needed
                // if (!this.adminIncidentBeingViewed['content']['$v']['firstResponded']) {
                //     let groupMember = _.find(this.adminIncidentBeingViewed.groupMembers, groupMember => {
                //         return groupMember.presenceId == presenceId;
                //     });
                //     if (groupMember) {
                //         this.updateAdminIncidentGroupMember({
                //             presenceId: presenceId,
                //             groupId: groupId,
                //             groupMemberDetails: {
                //                 firstArrival: firstArrival
                //             }
                //         });
                //     }
                // }
            }
        },

        handleGroupLeaveControlMessage(controlMessage) {
            console.log('Group leave control message');
            console.log(controlMessage);

            let messageBody = JSON.parse(controlMessage["messageBody"]);
            let groupId = messageBody["groupId"];
            let presenceId = messageBody["presenceId"];

            let myPresenceIds = _.map(this.presences, 'id');

            // Check chats
            _.each(this.chats, chat => {
                if (chat.id == groupId) {
                    this.removeGroupMemberFromChat({
                        chatId: groupId,
                        presenceId: presenceId
                    });

                    // Remove chat if this is my group member
                    if (myPresenceIds.includes(presenceId)) {
                        this.disposeOfChat(groupId);
                    }
                }
            });

            // Check incidents
            _.each(this.incidents, incident => {
                if (incident.id == groupId) {
                    this.removeGroupMemberFromIncident({
                        incidentId: groupId,
                        presenceId: presenceId
                    });

                    // Remove incident if this is my group member
                    if (myPresenceIds.includes(presenceId)) {
                        this.disposeOfIncident(groupId);
                    }
                }
            });

            // Check admin incident
            if (
                this.adminIncidentBeingViewed
                && this.adminIncidentBeingViewed.id == groupId
            ) {
                this.removeGroupMemberFromAdminIncident({
                    incidentId: groupId,
                    presenceId: presenceId
                });
            }
        },

        handleGroupChangedChatControlMessage(controlMessage) {
            console.log('Group changed chat control message:');
            console.log(controlMessage);

            let groupId = controlMessage['targetGroupId'];
            let newDetails = JSON.parse(controlMessage["messageBody"]);

            this.handleChatUpdated({
                chatId: groupId,
                newDetails: newDetails
            });
        },

        handleGroupChangedIncidentControlMessage(controlMessage) {
            console.log('Group changed incident control message:');
            console.log(controlMessage);

            let groupId = controlMessage['targetGroupId'];
            let newDetails = JSON.parse(controlMessage["messageBody"]);

            this.handleIncidentUpdated({
                incidentId: groupId,
                newDetails: newDetails
            });

            if (
                this.adminIncidentBeingViewed
                && this.adminIncidentBeingViewed.id == groupId
            ) {
                this.handleAdminIncidentUpdated({
                    incidentId: groupId,
                    newDetails: newDetails
                })
            }
        },

        handleGroupChangedMembershipControlMessage(controlMessage) {
            console.log('GROUP CHANGED MEMBERSHIP IS HERE!!! :) :) :) :) :) HELLOOOOOOO HARVEY LOOK HEREEEEEEE!!!! :) :) :) :) HERE HERE HER HERE HERE');
            console.log('Group changed membership control message:');
            console.log(controlMessage);
            // alert('group changed membership');

            let { tenantId, entityId } = controlMessage;

            // Check chats
            _.each(this.chats, chat => {
                if (chat.id == entityId) {
                    this.reloadChatMembership({
                        tenantId: tenantId,
                        chatId: entityId
                    });
                }
            });

            // Check incidents
            _.each(this.incidents, incident => {
                if (incident.id == entityId) {
                    this.reloadIncidentMembership({
                        tenantId: tenantId,
                        incidentId: entityId
                    });
                }
            });

            // Chek admin incident
            if (
                this.adminIncidentBeingViewed
                && this.adminIncidentBeingViewed.id == entityId
            ) {
                this.reloadAdminIncidentMembership({
                    tenantId: tenantId,
                    incidentId: entityId
                })
            }

            // Check announcements
            _.each(this.announcements, announcement => {
                if (announcement.id == entityId) {
                    this.reloadAnnouncementMembership({
                        tenantId: tenantId,
                        announcementId: entityId
                    });
                }
            });

             // Check admin announcements
             _.each(this.adminAnnouncements, announcement => {
                if (announcement.id == entityId) {
                    this.reloadAdminAnnouncementMembership({
                        tenantId: tenantId,
                        announcementId: entityId
                    });
                }
            });
        },

        handleGroupMemberUpdatedControlMessage(controlMessage) {
            // We want to update the group member in two places
            // 1. Globally (Vuex incidents/chats)

            // First let's decode the message
            let messageBody = JSON.parse(controlMessage["messageBody"]);
            console.log('Message body:');
            console.log(messageBody);

            // Group member details
            let groupMemberDetails = messageBody.groupMemberDetails;

            // Group ID
            let groupId = groupMemberDetails.groupId;

            // Presence ID
            let presenceId = groupMemberDetails.presenceId;

            // Check chats
            _.each(this.chats, chat => {
                if (chat.groupMembers) {
                    _.each(chat.groupMembers, groupMember => {
                        if (
                            groupMember.presenceId == presenceId
                            && groupMember.groupId === groupId
                        ) {
                            // Update group member
                            this.updateChatGroupMember({
                                presenceId: presenceId,
                                groupId: groupId,
                                groupMemberDetails: groupMemberDetails
                            });
                        }
                    });
                }
            });

            // Check incidents
            _.each(this.incidents, incident => {
                if (incident.groupMembers) {
                    _.each(incident.groupMembers, groupMember => {
                        if (
                            groupMember.presenceId == presenceId
                            && groupMember.groupId === groupId
                        ) {
                            // Update group member
                            this.updateIncidentGroupMember({
                                presenceId: presenceId,
                                groupId: groupId,
                                groupMemberDetails: groupMemberDetails
                            });
                        }
                    });
                }
            });

            // Check admin incident
            if (
                this.adminIncidentBeingViewed
            ) {
                _.each(this.adminIncidentBeingViewed.groupMembers, groupMember => {
                    if (
                        groupMember.presenceId == presenceId
                        && groupMember.groupId === groupId
                    ) {
                        // Update group member
                        this.updateAdminIncidentGroupMember({
                            presenceId: presenceId,
                            groupId: groupId,
                            groupMemberDetails: groupMemberDetails
                        });
                    }
                });
            }
        },

        async listenForNewChatEntry() {
            let vm = this;
            this.$signalR.on("NewChatEntry", (newChatEntry) => {
                let { entry } = newChatEntry;
                let ownerId = entry.ownerId;
                let createdByPresenceId = entry.createdByPresenceId;
                let createdAt = entry.whenCreated;

                // Emit entry on event bus
                eventBus.$emit('new-chat-entry-for-timeline', newChatEntry);

                console.log("[Global] New Chat Entry");
                console.log(entry);

                // Check chats to update last activity
                _.each(this.chats, async chat => {
                    if (chat.id === ownerId) {
                        // alert('Update chat last activity');
                        vm.updateChatLastActivity({
                            chatId: ownerId,
                            lastActivity: createdAt
                        });
                        if (createdByPresenceId !== '00000000-0000-0000-0000-000000000000') {
                            vm.updateChatLastHumanActivity({
                                chatId: ownerId,
                                lastHumanActivity: createdAt
                            });
                        }

                        // Send browser notification
                        let shouldSendBrowserNotification = true;
                        let shouldSendOnlyIfInBackground = false;
                        if (vm.chat && vm.chat.id === chat.id) {
                            shouldSendOnlyIfInBackground = true;
                            // shouldSendBrowserNotification = false;
                        }
                        if (entry.behaviour !== undefined && entry.behaviour == 1) { // Suppress
                            shouldSendBrowserNotification = false;
                        }
                        if (shouldSendBrowserNotification) {
                            let organisation = this.getOrganisationById(chat.ownerId);
                            let tenantId = organisations.getOrganisationTenantId(organisation);

                            let messageTrimmed = entry.content['$v']['text'];
                            if (messageTrimmed.length > 30) {
                                messageTrimmed = messageTrimmed.substring(0, 30) + '...';
                            }

                            let chatDisplayName = this.getChatDisplayNameForNotification(chat);

                            let icon = null;
                            try {
                                let getPresenceThumbProtectedUrlResponse = await presences.getPresenceThumbProtectedUrl(tenantId, createdByPresenceId);
                                if (getPresenceThumbProtectedUrlResponse && getPresenceThumbProtectedUrlResponse.data && getPresenceThumbProtectedUrlResponse.data.url) {
                                    icon = getPresenceThumbProtectedUrlResponse.data.url;
                                }
                            } catch (e) {
                                console.log(e);
                            }

                            this.globalNotify({
                                title: '"' + messageTrimmed + '"',
                                body: chatDisplayName + ' • Chats',
                                tag: chat.id,
                                icon: icon,
                                navigateTo: '/chats/' + tenantId + '/' + chat.id,
                                onlyInBackground: shouldSendOnlyIfInBackground
                            });

                            // vm.$notification.show('"' + messageTrimmed + '"', {
                            //     body: chatDisplayName + ' • Chats',
                            //     tag: chat.id,
                            //     icon: icon
                            // }, {
                            //     onclick: function () {
                            //         vm.$router.push('/chats/' + tenantId + '/' + chat.id);
                            //     }
                            // })
                        }
                    }
                });

                // Check incidents to update last activity
                _.each(this.incidents, async incident => {
                    if (incident.id === ownerId) {
                        // alert('Update incident last activity');
                        vm.updateIncidentLastActivity({
                            incidentId: ownerId,
                            lastActivity: createdAt
                        });
                        if (createdByPresenceId !== '00000000-0000-0000-0000-000000000000') {
                            vm.updateIncidentLastHumanActivity({
                                incidentId: ownerId,
                                lastHumanActivity: createdAt
                            });
                        }

                        // Send browser notification
                        let shouldSendBrowserNotification = true;
                        let shouldSendOnlyIfInBackground = false;
                        if (vm.incident && vm.incident.id === incident.id) {
                            shouldSendOnlyIfInBackground = true;
                            // shouldSendBrowserNotification = false;
                        }
                        if (entry.behaviour !== undefined && entry.behaviour == 1) { // Suppress
                            shouldSendBrowserNotification = false;
                        }

                        if (shouldSendBrowserNotification) {
                            let organisation = this.getOrganisationById(incident.ownerId);
                            let tenantId = organisations.getOrganisationTenantId(organisation);

                            let messageTrimmed = entry.content['$v']['text'];
                            if (messageTrimmed.length > 30) {
                                messageTrimmed = messageTrimmed.substring(0, 30) + '...';
                            }

                            let incidentDisplayName = this.getIncidentDisplayNameForNotification(incident);

                            let icon = null;
                            try {
                                let getPresenceThumbProtectedUrlResponse = await presences.getPresenceThumbProtectedUrl(tenantId, createdByPresenceId);
                                if (getPresenceThumbProtectedUrlResponse && getPresenceThumbProtectedUrlResponse.data && getPresenceThumbProtectedUrlResponse.data.url) {
                                    icon = getPresenceThumbProtectedUrlResponse.data.url;
                                }
                            } catch (e) {
                                console.log(e);
                            }

                            this.globalNotify({
                                guid: incident.id,
                                title: '"' + messageTrimmed + '"',
                                body: incidentDisplayName + ' • Incidents',
                                tag: incident.id,
                                icon: icon,
                                navigateTo: '/incidents/' + tenantId + '/' + incident.id,
                                onlyInBackground: shouldSendOnlyIfInBackground
                            });
                        }
                    }
                });

                // Check admin incident
                if (
                    this.adminIncidentBeingViewed
                    && this.adminIncidentBeingViewed.id === ownerId
                ) {
                    // alert('Update incident last activity');
                    vm.updateAdminIncidentLastActivity({
                        incidentId: ownerId,
                        lastActivity: createdAt
                    });
                    if (createdByPresenceId !== '00000000-0000-0000-0000-000000000000') {
                        vm.updateAdminIncidentLastHumanActivity({
                            incidentId: ownerId,
                            lastHumanActivity: createdAt
                        });
                    }
                }
            });
        },

        listenForNotifyMemberAdded() {
            // let vm = this;
            this.$signalR.on("NotifyMemberAdded", (notifyMemberAdded) => {
                console.log('[Global] Notify member added:');
                console.log(notifyMemberAdded);

                // TODO: check if I'm in group
                // Add chat if not
            })
        },

        listenForAnnouncementResponseMessage() {
            this.$signalR.on("AnnouncementResponse",
                (announcementResponseMessage) => {
                    console.log('[Global] Received announcement response:');
                    console.log(announcementResponseMessage);
                    let announcementId = announcementResponseMessage.announcement.id;
                    let groupMember = announcementResponseMessage.groupMember;

                    // Update announcement
                    this.updateAnnouncement(announcementResponseMessage.announcement);
                    this.updateAnnouncementGroupMember({
                        announcementId: announcementId,
                        groupMember: groupMember
                    });

                    // Update admin announcement
                    this.updateAdminAnnouncement(announcementResponseMessage.announcement);
                    this.updateAdminAnnouncementGroupMember({
                        announcementId: announcementId,
                        groupMember: groupMember
                    });

                    // If announcement selected
                    if (
                        this.$route.name == 'View Announcement'
                        && this.announcement
                        && this.announcement.id == announcementId
                    ) {
                        // this.$message.info('Announcement response updated');
                        eventBus.$emit('update-current-announcement-last-read-pointer');
                    }
                });
        },

        async listenForAddParticiantMessage() {
            this.$signalR.on("AddParticiant", (particiantAddedMessage) => {
                console.log("[Global] Particiant Added");
                console.log(particiantAddedMessage);

                let tenantId = particiantAddedMessage.tenantId;
                let groupMemberDetails = particiantAddedMessage.groupMemberDetails;

                let groupId = groupMemberDetails.groupId;
                let groupType = groupMemberDetails.groupType;

                // Is Incident / Chat / Announcement
                let isChat = false;
                let chat = undefined;

                let isIncident = false;
                let incident = undefined;

                let isAnnouncement = false;
                let announcement = undefined;

                if (groupType == 2) {
                    isChat = true;
                    chat = _.find(this.chats, { id: groupId });
                }

                if (groupType == 3) {
                    isIncident = true;
                    incident = _.find(this.incidents, { id: groupId });
                }

                if (groupType == 7) {
                    isAnnouncement = true;
                    announcement = _.find(this.announcements, { id: groupId });
                }

                // If is incident
                // And we don't recognise it
                // Let's go fetch that thanggg
                // And then stop because we don't need to add member
                // As we'll fetch the full membership when loading incident
                if (isIncident && !incident) {
                    this.fetchAndAddIncident({
                        tenantId: tenantId,
                        incidentId: groupId
                    });
                    return false;
                }

                // If is chat
                // And we don't recognise it
                // Let's go fetch that chatty chatty chat chat
                // And then stop because we don't need to add member
                // As we'll fetch the full membership when loading chat
                if (isChat && !chat) {
                    this.fetchAndAddChat({
                        tenantId: tenantId,
                        chatId: groupId
                    });
                    return false;
                }

                // If is announcement
                // And we don't recognise it
                // Let's go fetch the announcementttt
                // And then stop because we don't need to add member
                // As we'll fetch full membershpi when loading announcement
                if (isAnnouncement && !announcement) {
                    this.fetchAndAddAnnouncement({
                        tenantId: tenantId,
                        announcementId: groupId
                    });
                    return false;
                }

                // Check chats
                _.each(this.chats, chat => {
                    if (chat.id === groupId) {
                        // TODO: uniqueness check in case echo back of my own member
                        this.handleGroupMemberAddedToChat({
                            tenantId: tenantId,
                            chatId: chat.id,
                            groupMemberDetails: groupMemberDetails
                        });
                    }
                });

                // Check incidents
                _.each(this.incidents, incident => {
                    if (incident.id === groupId) {
                        // TODO: uniqueness check in case echo back of my own member
                        this.handleGroupMemberAddedToIncident({
                            tenantId: tenantId,
                            incidentId: incident.id,
                            groupMemberDetails: groupMemberDetails
                        });
                    }
                });

                // TODO: Check announcements
            });
        },

        async listenForNotifyAlertMessage() {
            this.$signalR.on("NotifyAlert", (notifyAlertMessage) => {
                console.log("[Global] Notify Alert Message");
                console.log(notifyAlertMessage);
                this.globalNotify({
                    title: notifyAlertMessage.header,
                    body: notifyAlertMessage.body,
                    tag: notifyAlertMessage.groupId ? notifyAlertMessage.groupId : null
                });
            });
        }
    }
}
<template>
    <div class="describe-this-page-button-and-modal">
        <a-button @click.prevent="showModal" class="btn-rounded describe-this-page-button">
            About This Page</a-button>

        <a-modal width="1000px" title="About This Page" v-model="modalVisible" centered :footer="null">
            <div class="describe-this-page-modal-inner">
                <video width="95%" controls v-if="modalVisible">
                    <source src="/explainers/demo.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalVisible: false
        }
    },
    methods: {
        showModal() {
            this.modalVisible = true;
        },

        closeModal() {
            this.modalVisible = false;
        }
    }
}
</script>

<style lang="scss">
.describe-this-page-button-and-modal {
    display: inline-block;
    margin-right: 15px;

    .describe-this-page-button {
        font-weight: 500;
    }
}

.describe-this-page-modal-inner {
        display: flex;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
    }
</style>
// import announcements from "../../api/announcements";
const _ = require('lodash')

export const state = {
    adminMode: false,
    serverErrors: [],
    tenantId: null,
    announcementId: null
};

export const mutations = {
    ENABLE_ADMIN_MODE(state) {
        state.adminMode = true;
    },

    DISABLE_ADMIN_MODE(state) {
        state.adminMode = false;
    },

    SET_ANNOUNCEMENT_ID(state, announcementId) {
        state.announcementId = announcementId
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId
    }
};

export const getters = {
    adminMode: (state) => {
        return state.adminMode;
    },

    isLoading: (state, getters, rootState, rootGetters) => {
        if (!getters.adminMode && rootGetters['announcements/isLoading']) {
            return true;
        }
        if (getters.adminMode && rootGetters['adminAnnouncements/isLoading']) {
            return true;
        }
        if (getters.announcement) {
            return getters.announcement.isLoadingGroupMembers;
        }
        return false;
    },

    announcementId: (state) => {
        return state.announcementId
    },

    announcement: (state, getters, rootState, rootGetters) => {
        if (getters.adminMode) {
            return _.find(rootGetters['adminAnnouncements/announcements'], { id: getters.announcementId });
        }
        return _.find(rootGetters['announcements/announcements'], { id: getters.announcementId });
    },

    tenantId: (state) => {
        return state.tenantId
    },

    groupMembers: (state, getters) => {
        if (getters.announcement) {
            return getters.announcement.groupMembers;
        }
        return [];
    },

    myPresenceId: (state, getters, rootState, rootGetters) => {
        let myPresenceIds = _.map(rootGetters['missionControl/presences'], 'id');
        let presenceIdsInAnnouncement = _.map(getters.groupMembers, 'presenceId');
        let intersection = _.intersection(myPresenceIds, presenceIdsInAnnouncement);
        if (intersection.length) {
            return _.first(intersection)
        }
        if (getters.adminMode) {
            return rootGetters['admin/myPresenceId']; 
        }
        return null;
    },

    myGroupMember: (state, getters) => {
        if (!getters.myPresenceId) {
            return null;
        }
        return _.find(getters.groupMembers, groupMember => {
            return groupMember.presenceId === getters.myPresenceId;
        });
    },

    haveIResponded: (state, getters) => {
        if (getters.myGroupMember) {
            return getters.myGroupMember['content'];
        }
        return false;
    },

    myChoice: (state, getters) => {
        if (
            getters.haveIResponded
            && getters.announcement['content']['$v']['choices']
        ) {
            // return getters.myGroupMember['content'];
            return _.find(
                getters.announcement['content']['$v']['choices'],
                { id: getters.myGroupMember['content']['$v']['response'] }
            );
        }
        return null;
    },

    choices: (state, getters) => {
        if (getters.announcement) {
            return getters.announcement['content']['$v']['choices'];
        }
        return [];
    },

    responses: (state, getters) => {
        let responses = [];

        if (!getters.announcement || !getters.groupMembers) {
            return [];
        }

        responses.push({
            id: null,
            name: 'People have not responded',
            members: _.filter(getters.groupMembers, groupMember => {
                return !groupMember.content;
            })
        });

        _.each(getters.announcement['content']['$v']['choices'], choice => {
            let res = {
                ...choice
            };
            res.members = _.filter(getters.groupMembers, groupMember => {
                return (
                    groupMember['content']
                    && groupMember['content']['$v']['response']
                    && groupMember['content']['$v']['response'] == choice.id
                )
            });
            responses.push(res);
        });

        return responses;
    }
};

export const actions = {
    enableAdminMode({ commit }) {
        commit('ENABLE_ADMIN_MODE');
    },

    disableAdminMode({ commit }) {
        commit('DISABLE_ADMIN_MODE');
    },

    clearAnnouncementViewer({ dispatch }) {
        dispatch('reset');
    },

    reset({ commit }) {
        commit('SET_ANNOUNCEMENT_ID', null);
    },

    loadAnnouncement({ commit, dispatch }, params) {
        let { id, tenantId } = params
        // commit('START_LOADING');
        commit('SET_TENANT_ID', tenantId);
        dispatch('reset');
        dispatch('timeline/reset', {}, { root: true });
        commit('SET_ANNOUNCEMENT_ID', id);
        // announcements.getAnnouncement(tenantId, id).then(r => {
        //     commit('STOP_LOADING');
        //     commit('SET_ANNOUNCEMENT', r.data)
        //     dispatch('loadGroupMembers');
        //     dispatch('timeline/loadTimeline', {
        //         model: 'announcement',
        //         tenantId: getters.tenantId,
        //         guid: getters.announcement.id,
        //         ownerType: 3,
        //         ownerId: getters.announcement.id,
        //         organisationId: getters.announcement.ownerId,
        //         behaviour: 0
        //     }, { root: true });
        // }).catch(e => {
        //     commit('STOP_LOADING');
        //     this._vm.$message.error('Error loading announcement');
        //     console.log(e);
        //     let errors;
        //     if (typeof e.response.data === 'object') {
        //         errors = _.flatten(_.toArray(e.response.data.errors));
        //     } else {
        //         errors = ['Something went wrong. Please try again.'];
        //     }
        //     commit('SET_ERRORS', errors);
        // })
    }
};
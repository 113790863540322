<template>
  <a-modal title="Change Avatar" :footer="null" v-model="shouldShowModal">
    <loading-screen :is-loading="isUploading || isDeleting"></loading-screen>
    <div class="change-avatar-modal-inner">
      <div class="current-thumbnail">
        <presence-thumbnail
          v-if="userPresence"
          size="large"
          style="display: inline-block; margin-right: 8px"
          :presence-id="userPresence.id"
          :owner-id="userPresence.ownerId"
        ></presence-thumbnail>
      </div>

      <div class="actions">
        <input @change="uploadFile" type="file" ref="file" class="file-input" />

        <div>
          <a-button
          @click.prevent="attemptUploadFile"
            size="large"
            type="primary"
            >Upload New Image</a-button
          >
        </div>

        <div class="remove-thumbnail-button">
          <a-button @click.prevent="removeThumbnail" size="large" type="danger"
            >Remove Current Thumbnail</a-button
          >
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import organisations from "../helpers/organisations";
import presences from "../api/presences";
import PresenceThumbnail from "../components/Presences/PresenceThumbnail.vue";
import LoadingScreen from "./LoadingScreen.vue";
const _ = require("lodash");
export default {
  props: ["visible"],

  data() {
    return {
      isUploading: false,
      isDeleting: false,
    };
  },

  components: { PresenceThumbnail, LoadingScreen },

  methods: {
    attemptUploadFile() {
      this.$refs.file.click();
    },

    uploadFile(e) {
      let vm = this;
      let file = e.target.files[0];
      vm.isUploading = true;
      presences
        .addPresenceThumb(this.tenantId, this.userPresence.id, file)
        .then(() => {
          vm.isUploading = false;
          vm.$emit("presence-updated");
          vm.$emit("close");
        })
        .catch((e) => {
          console.log(e);
          vm.isUploading = false;
          vm.$message.error("Error updating presence");
        });
    },

    removeThumbnail() {
      let vm = this;
      vm.isDeleting = true;
      presences
        .deletePresenceThumb(this.tenantId, this.userPresence.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$emit("presence-updated");
          vm.$emit("close");
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error("Error removing thumbnail");
        });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      userPresence: "userPresence",
      organisations: "organisations",
    }),

    shouldShowModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close");
        }
      },
    },

    organisation() {
      let vm = this;
      return _.find(this.organisations, {
        id: vm.userPresence.ownerId,
      });
    },

    tenantId() {
      if (this.organisation) {
        return organisations.getOrganisationTenantId(this.organisation);
      }
      return this.ownerId;
    },
  },
};
</script>

<style lang="scss">
.change-avatar-modal-inner {
  .file-input {
    display: none;
  }

  .current-thumbnail {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;

    .ant-avatar {
      width: 100px;
      height: 100px;
      line-height: 100px;
    }
  }

  .actions {
    text-align: center;

    .remove-thumbnail-button {
      margin-top: 20px;
    }
  }
}
</style>
import axios from 'axios'

export default {
    getPresence(tenantId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId)
    },

    getPresenceThumbProtectedUrl(tenantId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId + '/Thumb')
    },

    addPresence(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Presences', params);
    },

    updatePresence(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Presences', params);
    },

    addPresenceThumb(tenantId, presenceId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId + '/Thumb/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    deletePresenceThumb(tenantId, presenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId + '/Thumb');
    },

    getLastViewsForPresence(tenantId, orgId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Presences/' + presenceId + '/EntityViews');
    },

    getSkillsForPresence(tenantId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId + '/Skills');
    },

    addSkillAssignment(tenantId, orgId, skillId, presenceId) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills/' + skillId + '/Presences/' + presenceId);
    },

    deleteSkillAssignment(tenantId, orgId, skillId, presenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills/' + skillId + '/Presences/' + presenceId);
    },

    deletePresence(tenantId, presenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Presences/' + presenceId);
    },

    sendNewPresenceEmails(tenantId, orgId, presenceIds, after=null) {
        let resource = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Presences/New';
        if (after) {
            resource = resource + '?after=' + after
        }

        return axios.post(resource, presenceIds, {
            headers: {
                "content-type": "application/json"
            }
        });
    }
}
<template>
  <div class="h100">
    <!-- Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models -->
    <div
      class="h100"
      v-if="
        media['$v']['source']['$t'] ==
        'Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models'
      "
    >
      <iframe
        width="100%" 
        :src="media['$v']['source']['$v']['sourceUrl']"
        frameborder="0"
      ></iframe>
    </div>
    <!-- / Soteria.Models.Attachments.BlobAttachmentSource, Soteria.Models -->
  </div>
</template>

<script>
export default {
  props: ["media"],
};
</script>

<style scoped>
.h100 {
    height: 100%;
}

iframe {
  height: calc(100% - 100px);
}
</style>
import incidentTypes from "../../api/incident-types";
const _ = require('lodash')
import organisations from "../../helpers/organisations";
import organisationsApi from "../../api/organisations";
import incidents from "../../api/incidents";
import timelines from "../../api/timelines";
import router from '../../router';

export const state = {
    serverErrors: [],
    isSaving: false,
    selectedOrganisation: null,

    isLoadingMembership: false,
    membership: [],

    isLoadingIncidentTypes: false,
    incidentTypes: [],

    selectedMembers: [],

    stage: 0
};

export const mutations = {
    SET_SELECTED_ORGANISATION(state, organisation) {
        state.selectedOrganisation = organisation
    },

    START_LOADING_INCIDENT_TYPES(state) {
        state.isLoadingIncidentTypes = true
    },

    STOP_LOADING_INCIDENT_TYPES(state) {
        state.isLoadingIncidentTypes = false
    },

    SET_INCIDENT_TYPES(state, incidentTypes) {
        state.incidentTypes = incidentTypes
    },

    START_SAVING(state) {
        state.isSaving = true
    },

    STOP_SAVING(state) {
        state.isSaving = false
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    START_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = true
    },

    STOP_LOADING_MEMBERSHIP(state) {
        state.isLoadingMembership = false
    },

    SET_MEMBERSHIP(state, membership) {
        state.membership = membership
    },

    SET_SELECTED_MEMBERS(state, selectedMembers) {
        state.selectedMembers = selectedMembers
    },

    TOGGLE_MEMBER_SELECTED(state, member) {
        state.selectedMembers = _.xor(state.selectedMembers, [member.id])
    },

    INCREMENT_STAGE(state) {
        state.stage = state.stage + 1
    },

    DECREMENT_STAGE(state) {
        state.stage = state.stage - 1
    },

    SET_STAGE(state, stage) {
        state.stage = stage
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoadingIncidentTypes
    },

    isLoadingIncidentTypes: (state) => {
        return state.isLoadingIncidentTypes
    },

    isSaving: (state) => {
        return state.isSaving
    },

    selectedOrganisation: (state) => {
        return state.selectedOrganisation
    },

    incidentTypes: (state) => {
        return state.incidentTypes
    },

    sosIncidentTypes: (state) => {
        return _.filter(state.incidentTypes, incidentType => {
            return incidentType.purpose === 1;
        })
    },

    otherIncidentTypes: (state) => {
        return _.filter(state.incidentTypes, incidentType => {
            return incidentType.purpose !== 1;
        })
    },

    isLoadingMembership: (state) => {
        return state.isLoadingMembership
    },

    membership: (state) => {
        return state.membership
    },

    selectedMembers: (state) => {
        return state.selectedMembers
    },

    stage: (state) => {
        return state.stage
    }
};

export const actions = {
    reset({ commit }) {
        commit('SET_SELECTED_ORGANISATION', null);
        commit('SET_STAGE', 0);
        commit('SET_SELECTED_MEMBERS', []);
    },

    selectOrganisation({ commit, dispatch }, organisation) {
        commit('SET_SELECTED_ORGANISATION', organisation);
        dispatch('loadMembership');
        dispatch('loadIncidentTypes');
    },

    loadMembership({ commit, getters }) {
        commit('START_LOADING_MEMBERSHIP');
        // let vm = this;
        let tenantId = organisations.getOrganisationTenantId(getters.selectedOrganisation);
        organisationsApi.getMembershipForOrg(tenantId, getters.selectedOrganisation.id).then((r) => {
            commit('STOP_LOADING_MEMBERSHIP');
            commit('SET_MEMBERSHIP', r.data);
        }).catch(e => {
            console.log(e);
            // vm.$message.error('Error loading membershp');
        });
    },

    loadIncidentTypes({ commit, getters }) {
        commit('START_LOADING_INCIDENT_TYPES');
        incidentTypes.getIncidentTypes(
            organisations.getOrganisationTenantId(getters.selectedOrganisation),
            getters.selectedOrganisation.id
        ).then(r => {
            commit('STOP_LOADING_INCIDENT_TYPES');
            commit('SET_INCIDENT_TYPES', r.data)
        }).catch(e => {
            commit('STOP_LOADING_INCIDENT_TYPES');
            // this._vm.$message.error('Error loading incident types');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    async startDrill({ commit, getters, dispatch }, params) {
        commit('START_SAVING');

        let { description, incidentType } = params;
        let presence = await dispatch('missionControl/getPresenceForOrganisationId', getters.selectedOrganisation.id, { root: true })

        // let timestamp = window.moment().format()

        let incidentDetails = {
            OwnerId: getters.selectedOrganisation.id,
            CreatedBy: presence.id,
            DisplayName: incidentType.displayName,
            Content: {
                '$t': "Soteria.Models.IncidentContent, Soteria.Models",
                '$v': {
                    IncidentDescription: description,
                    IncidentPurpose: incidentType.purpose,
                    IncidentTypeId: incidentType.id,
                    Mode: 1,
                }

            },
            // IncidentTimestamp: timestamp.substring(0,3)
            ChosenResponders: getters.selectedMembers,
            IncidentTimestamp: '2021-11-26T13:22:12.0345404Z Europe/London'
        }
        // TODO: DETERMINE REAL TIMESTAMP

        incidents.createIncident(
            organisations.getOrganisationTenantId(getters.selectedOrganisation),
            incidentDetails
        ).then(async (r) => {
            let incident = r.data;
            let organisation = await dispatch('missionControl/getOrganisationById', incident.ownerId, { root: true });
            let tenantId = organisations.getOrganisationTenantId(organisation)

            timelines.postTimelineEntry(tenantId, {
                OwnerType: 3,
                OwnerId: r.data.id,
                CreatedByPresenceId: presence.id,
                Content: {
                    $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
                    $v: {
                        Text: description
                    }
                }
            }).then(() => { }).catch(e => {
                console.log(e);
                this._vm.$message.error('Error posting description onto timeline');
            });

            dispatch('incidents/pushIncident', incident, { root: true });
            dispatch('incidentViewer/setIncident', {
                incident,
                tenantId
            }, { root: true });

            router.push('/incidents/' + tenantId + '/' + incident.id);
            commit('STOP_SAVING');
        }).catch(e => {
            commit('STOP_SAVING');
            this._vm.$message.error('Error creating incident');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    setSelectedMembers({ commit }, selectedMembers) {
        commit('SET_SELECTED_MEMEBRS', selectedMembers);
    },

    toggleMemberSelected({ commit }, member) {
        commit('TOGGLE_MEMBER_SELECTED', member);
    },

    decrementStage({ commit }) {
        commit('DECREMENT_STAGE');
    },

    incrementStage({ commit }) {
        commit('INCREMENT_STAGE');
    },

    setStage({ commit }, stage) {
        commit('SET_STAGE', stage);
    }
};
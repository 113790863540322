<template>
  <div class="tsos-popup-conversations-view">

    <div class="search-wrapper">
      <!-- Search -->
      <a-input-search size="large" placeholder="Search incidents" v-model="search_query" />
      <!-- / Search -->
    </div>

    <!-- Loading -->
    <div v-if="isLoadingCombined" class="loading-wrapper">
      <Skeleton active />
    </div>
    <!-- / Loading -->

    <!-- Empty -->
    <div v-if="!isLoadingCombined && incidentsToShow.length == 0" class="no-conversations-wrapper">
      <Empty description="No incidents" />
    </div>
    <!-- / Empty -->

    <!-- Incidents -->
     <div class="tsos-widget-incidents">
      <incident-list-item v-for="incident in incidentsToShowOrdered"
      :hide-pin="true"
      :in-widget="true"
       :key="`tsos-widget-incidents-${incident.id}`"
       :incident="incident"
       ></incident-list-item>
     </div>
    <!-- / Incidents -->
  </div>
</template>

<script>
import { Empty, Skeleton } from 'ant-design-vue';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import organisations from '@/helpers/organisations';
import IncidentListItem from '../../../views/Incidents/IncidentListItem.vue';

export default {
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters("missionControl", {
      organisations: 'organisations',
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters('incidents', {
      incidentsToShow: "incidentsToShow",
      incidentsToShowOrdered: "incidentsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },
  },

  mixins: [],

  components: { Empty, Skeleton, IncidentListItem },

  created() {
    // this.loadConversations();
  },

  methods: {
    ...mapActions('tsosWidget', {
      navigateToRoute: 'navigateToRoute'
    }),

    ...mapActions('incidents', {
      loadIncidents: "loadIncidents",
      setSearchQuery: "setSearchQuery",
    }),

    handleConversationSelected() {
    },

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getIncidentUrl(incident) {
      let organisation = this.getOrganisationById(incident.ownerId);
      let tenantId = organisations.getOrganisationTenantId(organisation);
      return "/incidents/" + tenantId + "/" + incident.id;
    }
  }
}
</script>

<style scoped lang="scss">
.tsos-popup-conversations-view {
  padding: 10px 15px !important;
  min-height: 250px;

  max-height: calc(100vh - 80px - 115px - 60px - 50px);
  overflow-y: scroll;

  .search-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 9px;
    padding-bottom: 15px;
  }

  .loading-wrapper {
    padding: 15px 10px;
  }

  .no-conversations-wrapper {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .conversations {
    .tsos-popup-body__link-info {
      padding-top: 0 !important;
      display: flex;
      align-items: center;

      .tsos-popup-body__link-info__name {
        margin-top: 0;
      }
    }
  }
}
</style>
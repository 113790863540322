import axios from 'axios'

export default {
    addTopic(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Topics', params);
    },

    getTopics(tenantId, orgId, includeHidden = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Topics';
        if (includeHidden) {
            query = query + '?includeHidden=true'
        }
        return axios.get(query);
    },

    getTopic(tenantId, topicId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId);
    },

    updateTopic(tenantId, topicId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId, params);
    },

    deleteTopic(tenantId, topicId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId);
    },

    addTopicButtonStrip(tenantId, topicId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId + '/ButtonStrip',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    getTopicButtonStripProtectedUrl(tenantId, topicId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId + '/ButtonStrip');
    },

    deleteTopicButtonStrip(tenantId, topicId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId + '/ButtonStrip');
    }
}
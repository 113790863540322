<template>
  <a-layout-header
    v-show="outOfDate"
    class="verison-header"
    style="
      background: #000;
      color: #fff;
      text-align: center;
      font-weight: bold;
      height: 55px;
      line-height: 55px;
    "
  >
    <span style="margin-right: 20px"
      >A newer version of our web app is available.</span
    >
    <a-button
      @click.prevent="reload"
      class="btn-rounded"
      style="font-weight: 600"
      >Please click here to reload
    </a-button>
  </a-layout-header>
</template>

<script>
import axios from "axios";
import eventBus from "../event-bus";
export default {
  data() {
    return {
      isLoading: false,
      initialVersion: null,
      latestVersion: 1,

      pollingInterval: null,
    };
  },
  computed: {
    outOfDate() {
      return this.latestVersion !== this.initialVersion;
    },
  },
  created() {
    let vm = this;
    vm.getVersion();
    vm.pollingInterval = setInterval(vm.getVersion, 30 * 60000); // every 30 mins
    // vm.pollingInterval = setInterval(vm.getVersion, 1 * 60000); // every 1 min
  },
  methods: {
    getVersion() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get(
          window.API_BASE +
            "/api/v1/ComponentVersion/" +
            window.location.hostname
        )
        .then((r) => {
          if (vm.initialVersion == null) {
            vm.initialVersion = r.data.currentVersion;
            vm.latestVersion = r.data.currentVersion;
          }
          vm.latestVersion = r.data.currentVersion;
          vm.isLoading = false;
          eventBus.$emit("appVersion", vm.latestVersion);
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
        });
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style>
</style>
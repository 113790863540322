<template>
  <div class="matching-chat-card" @click.prevent="$emit('selected')">
    <div class="left">
      <chat-thumbnail
        size="large"
        v-if="!isLoadingMembership"
        :has-custom-avatar="chat.hasCustomAvatar"
        :multiple-members="groupMembers && groupMembers.length > 2"
        :first-presence-id="
          membership && membership.length ? membership[0].id : null
        "
        :first-presence-owner-id="
          membership && membership.length ? membership[0].ownerId : null
        "
        :chat-id="chat.id"
        :chat-owner-id="chat.ownerId"
        :tenant-id="tenantId"
      ></chat-thumbnail>
    </div>
    <div class="right" :key="timeAgoRefreshKey">
      <div class="name">
        {{ displayName }}
      </div>
      {{ getLastActivityHuman(chat) }}
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
import { mapGetters } from "vuex";
import Chats from "../../../mixins/Chats";
import Dates from "../../../mixins/Dates";
import organisations from "../../../helpers/organisations";
import ChatThumbnail from "../../../components/Chats/ChatThumbnail.vue";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
export default {
  props: ["chat"],
  mixins: [Chats, Dates, TimeAgoRefresher],
  components: { ChatThumbnail },
  methods: {
    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      presences: "presences",
    }),

    tenantId() {
      let organisation = this.getOrganisationById(this.chat.ownerId);
      return organisations.getOrganisationTenantId(organisation);
    },

    isLoadingMembership() {
      return this.chat.isLoadingMembership !== false;
    },

    membership() {
      return this.chat.membership ? this.chat.membership : [];
    },

    isLoadingGroupMembers() {
      return this.chat.isLoadingGroupMembers !== false;
    },

    groupMembers() {
      return this.chat.groupMembers ? this.chat.groupMembers : [];
    },

    displayName() {
      if (this.chat.displayName && this.chat.displayName.length) {
        return this.chat.displayName;
      }
      if (this.isLoadingMembership) {
        return "Loading";
      }
      if (this.membership.length) {
        let myPresenceIds = _.map(this.presences, "id");
        let membersNotMe = _.filter(this.membership, (member) => {
          return !myPresenceIds.includes(member.id);
        });
        let displayNames = _.map(membersNotMe, "displayName");
        let numberOfExtraPeople = null;
        if (displayNames.length > 2) {
          numberOfExtraPeople = displayNames.length - 2;
        }
        if (displayNames.length) {
          if (numberOfExtraPeople) {
            return (
              displayNames.splice(0, 2).join(", ") +
              " and " +
              numberOfExtraPeople +
              " " +
              (numberOfExtraPeople > 1 ? "others" : "other")
            );
          } else {
            return displayNames.join(", ");
          }
        } else {
          return "Unknown";
        }
      }
      return "Unknown";
    },
  },
};
</script>

<style scoped lang="scss">
.matching-chat-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 8px 9px;

  .left {
    flex-shrink: 1;
  }

  .right {
    flex-grow: 1;
    padding-left: 15px;

    .name {
      font-weight: 500;
    }
  }
}
</style>
<template>
    <div class="update-personal-availability-button-and-modal">
        <a-button @click.prevent="showModal" class="btn-rounded update-personal-availability-button">Update Availability</a-button>

        <a-modal width="1000px" title="Update Your Availability" v-model="modalVisible" centered :footer="null">
            <div v-if="modalVisible" class="update-personal-availability-modal-inner">

                <organisation-selector :selected-organisation="selectedOrganisation" @select-organisation="selectOrganisation"></organisation-selector>

                <organisation-personal-availability
                    v-if="(selectedOrganisation && modalVisible)"
                    :organisation="selectedOrganisation"
                    @close-modal="closeModal"
                ></organisation-personal-availability>

            </div>
        </a-modal>
    </div>
</template>

<script>
import OrganisationSelector from "./UpdatePersonalAvailabilityButtonAndModal/OrganisationSelector";
import OrganisationPersonalAvailability from "./UpdatePersonalAvailabilityButtonAndModal/OrganisationPersonalAvailability.vue";

export default {
    data() {
        return {
            modalVisible: false,
            selectedOrganisation: null,
            currentAvailability: null
        }
    },
    components: {OrganisationSelector, OrganisationPersonalAvailability},
    methods: {
        showModal() {
            this.modalVisible = true;
        },

        closeModal() {
            this.modalVisible = false;
        },

        selectOrganisation(org) {
            this.selectedOrganisation = org;
        },

        loadCurrentAvailability() {
            if (this.selectedOrganisation) {
                return false;
            }
            return null;
        }
    },
    watch: {
        selectedOrganisation() {
            this.loadCurrentAvailability(); 
        }
    }
}
</script>

<style lang="scss">
.update-personal-availability-button-and-modal {
    display: inline-block;
    margin-right: 15px;

    .update-personal-availability-button {
        font-weight: 500;
    }
}

.update-personal-availability-modal-inner {
        // display: flex;
        // align-items: center;
        // align-content: center;
        // justify-items: center;
        // justify-content: center;
    }
</style>
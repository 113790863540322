<template>
  <div class="pin-button">
      <a-button :type="isPinned ? 'primary' : 'default'" :size="sizeToUse"
       @click.prevent.stop="toggle" icon="pushpin"></a-button>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
    props: ['guid', 'size'],

    computed: {
        ...mapGetters('pinned', {
            pinnedIds: 'pinnedIds'
        }),

        isPinned() {
            return this.pinnedIds.includes(this.guid)
        },

        sizeToUse() {
            if (this.size) {
                return this.size;
            }
            return 'normal';
        }
    },

    methods: {
        ...mapActions('pinned', {
            togglePinned: 'togglePinned'
        }),

        toggle() {
            this.togglePinned(this.guid)
        }
    }
}
</script>

<style>

</style>
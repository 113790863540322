import axios from 'axios'

export default {
    addExternalLink(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/ExternalLinks', params);
    },

    getExternalLinks(tenantId, orgId, includeHidden = false) {
        let query = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ExternalLinks';
        if (includeHidden) {
            query = query + '?includeHidden=true'
        }
        return axios.get(query);
    },

    getExternalLink(tenantId, externalLinkId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/ExternalLinks/' + externalLinkId);
    },

    updateExternalLink(tenantId, externalLinkId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/ExternalLinks/' + externalLinkId, params);
    },

    deleteExternalLink(tenantId, externalLinkId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/ExternalLinks/' + externalLinkId); 
    }
}
<template>
  <div class="topic-tabs-wrapper">
    <div class="topic-header" v-if="!noHeader">
      <div class="left">
        <a-button
          @click.prevent="() => backToPreviousTopic()"
          type="link"
          icon="left"
        ></a-button>
      </div>
      <div class="right">
        <div class="name">
          {{ selectedTopic["$v"]["displayName"] }}
        </div>
      </div>
    </div>

    <div class="tabs-wrapper">
      <a-tabs class="tabs">
        <a-tab-pane
          key="article"
          class="tab-pane"
          v-if="isArticle"
          tab="Article"
        >
          <article-media :media="media"></article-media>
        </a-tab-pane>
        <a-tab-pane key="video" class="tab-pane" v-if="isVideo" tab="Video">
          <video-media :media="media"></video-media>
        </a-tab-pane>
        <a-tab-pane key="policy" class="tab-pane" v-if="isPolicy" tab="Policy">
          <policy-media :media="media"></policy-media>
        </a-tab-pane>
        <a-tab-pane key="checklist" class="tab-pane" v-if="isChecklist" tab="Checklist">
          <checklist :tenant-id="tenantId"
          :details="selectedTopic['$v']"></checklist>
        </a-tab-pane>
        <a-tab-pane
          key="resources"
          class="tab-pane"
          v-if="selectedTopicChildren && selectedTopicChildren.length"
          :tab="resourcesTabTitle"
        >
          <resource-card
            v-for="(topic, i) in selectedTopicChildren"
            @selected="() => handleTopicSelected(topic)"
            :key="i"
            :topic="topic"
          ></resource-card>
        </a-tab-pane>
        <a-tab-pane key="share" class="tab-pane" tab="Send" v-if="showSendButton">
          <div class="share-wrapper">
              <a-button type="primary" @click.prevent.stop="emitSend">Click here to share</a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ArticleMedia from "./TopicTabs/ArticleMedia.vue";
import PolicyMedia from "./TopicTabs/PolicyMedia.vue";
import ResourceCard from "./TopicTabs/ResourceCard.vue";
import VideoMedia from "./TopicTabs/VideoMedia.vue";
import Checklist from "./TopicTabs/Checklist.vue";
export default {
  props: ['noHeader', 'showSendButton'],
  components: { ArticleMedia, VideoMedia, PolicyMedia, ResourceCard, Checklist },
  computed: {
    ...mapGetters("guidancePackViewer", {
      selectedTopic: "selectedTopic",
      selectedTopicChildren: "selectedTopicChildren",
      tenantId: "tenantId",
      guidancePack: "guidancePack",
    }),

    resourcesTabTitle() {
      return 'Resources (' + this.selectedTopicChildren.length + ')';
    },

    isArticle() {
      return (
        this.selectedTopic["$t"] ==
        "Soteria.Models.ArticleDetails, Soteria.Models"
      );
    },

    isVideo() {
      return (
        this.selectedTopic["$t"] ==
        "Soteria.Models.HelpVideoDetails, Soteria.Models"
      );
    },

    isPolicy() {
      return (
        this.selectedTopic["$t"] ==
        "Soteria.Models.PolicyDetails, Soteria.Models"
      );
    },

     isChecklist() {
      return (
        this.selectedTopic["$t"] ==
        "Soteria.Models.ChecklistContentDetails, Soteria.Models"
      );
    },

    media() {
      return this.selectedTopic["$v"]["media"];
    },
  },
  methods: {
    ...mapActions("guidancePackViewer", {
      selectTopic: "selectTopic",
      backToPreviousTopic: "backToPreviousTopic"
    }),

    handleTopicSelected(topic) {
      if (topic["$t"] == "Soteria.Models.ExternalLinkDetails, Soteria.Models") {
        window.open(topic["$v"]["url"], "_blank");
      } else {
        this.selectTopic(topic["$v"]["id"]);
      }
    },

    emitSend() {
       this.$emit('send', {
        guidancePackId: this.guidancePack['$v']['id'],
        contentId: this.selectedTopic['$v']['id'],
        name: this.guidancePack['$v']['displayName'] + ' ' + this.selectedTopic['$v']['displayName']
      });
    }
  },
};
</script>

<style lang="scss">
.topic-tabs-wrapper {
  .resource-card {
    margin-bottom: 15px;
  }

  .topic-header {
    background: #fff;
    border-radius: 10px;
    padding: 13px 10px;

    display: flex;

    .left {
      flex-shrink: 1;
      padding-right: 10px;
    }

    .right {
      flex-grow: 1;
      display: flex;
      align-items: center;
      .name {
        font-weight: 500;
      }
    }
  }

  display: flex;
  flex-direction: column;
  height: 100%;

  .topic-header {
    flex-shrink: 1;
  }

  .tabs-wrapper {
    flex-grow: 1;
    padding-bottom: 10px;

    .tabs,
    .ant-tabs-content,
    .tab-pane {
      height: 100%;
    }
  }

  .share-wrapper {
    padding: 10px 20px;
  }
}
</style>
<template>
  <div class="resource-card" @click.prevent="$emit('selected', topic)">
    <div class="left">
      <a-icon
        :style="{ fontSize: '19px', color: '#1890ff' }"
        :type="icon"
      ></a-icon>
    </div>
    <div class="right">
      <div class="name">
        <!-- {{ topic["$v"] }} -->
        {{ topic["$v"]["displayName"] }}
      </div>
      <div>
        {{ topic["$v"]["contextQuote"] }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["topic"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("guidancePackViewer", {
      tenantId: "tenantId",
    }),
    icon() {
      if (this.topic["$t"] == "Soteria.Models.ArticleDetails, Soteria.Models") {
        return "book";
      }
      if (this.topic["$t"] == "Soteria.Models.HelpVideoDetails, Soteria.Models") {
        return "play-circle";
      }
      if (this.topic["$t"] == "Soteria.Models.PolicyDetails, Soteria.Models") {
          return "copy"
      }
      if (this.topic["$t"] == "Soteria.Models.ExternalLinkDetails, Soteria.Models") {
          return "link"
      }
       if (this.topic["$t"] == "Soteria.Models.ChecklistContentDetails, Soteria.Models") {
          return "unordered-list"
      }
      return null;
    },
  },
  created() {
    this.getButtonStripUrl();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.resource-card {
  background: #fff;
padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  display: flex;
  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 30px;
    text-align: center;
  }
  .right {
      padding-left: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    .name {
    //   margin-bottom: 5px;
      font-weight: 500;
    }
  }
}
</style>
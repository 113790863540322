export const state = {
    route: 'call-for-help'
};

export const mutations = {
    SET_ROUTE(state, route) {
        state.route = route;
    }
};

export const getters = {
    route: (state) => {
        return state.route;
    }
};

export const actions = {
    navigateToRoute({ commit }, route) {
        commit('SET_ROUTE', route);
    }
};

import axios from 'axios'

export default {
    getGuidancePackContents(guidancePackId) {
        return axios.get(window.API_BASE + '/api/v1/GuidancePacks/' + guidancePackId)
    },

    getTopicButtonStripProtectedUrl(tenantId, topicId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId + '/ButtonStrip');
    },

    getTopic(tenantId, topicId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Topics/' + topicId);
    },

    getArticleButtonStripProtectedUrl(tenantId, id) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Articles/' + id + '/ButtonStrip');
    },

    getHelpVideoButtonStripProtectedUrl(tenantId, id) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/HelpVideos/' + id + '/Thumb');
    },
}
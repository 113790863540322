import axios from 'axios'

export default {
    getIncidentTypes(tenantId, orgId, includeHidden = false) {
        let path = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/IncidentTypes';
        if (includeHidden) {
            path = path + '?includeHidden=true'
        }
        return axios.get(path)
    },

    getIncidentType(tenantId, incidentTypeId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/IncidentTypes/' + incidentTypeId)
    },

    addIncidentType(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/IncidentTypes', params);
    },

    updateIncidentType(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/IncidentTypes', params);
    },

   deleteIncidentType(tenantId, incidentTypeId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/IncidentTypes/' + incidentTypeId);
    },
}
<template>
  <a-modal
    v-model="visible"
    class="fullscreen-modal"
    centered
    :title="selectedTopic['$v']['displayName']"
  >
    <topic-tabs
     @send="(params) => emitSend(params)"
     :show-send-button="showSendButton" :no-header="true"></topic-tabs>
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopicTabs from "./TopicTabs.vue";
export default {
  props: ['showSendButton'],
  components: { TopicTabs },
  methods: {
    ...mapActions("guidancePackViewer", {
      backToPreviousTopic: "backToPreviousTopic",
    }),

    emitSend(params) {
      this.backToPreviousTopic();
      this.$emit('send', params);
    }
  },
  computed: {
    ...mapGetters("guidancePackViewer", {
      selectedTopic: "selectedTopic",
    }),

    visible: {
      get() {
        return true;
      },
      set(val) {
        if (!val) {
          this.backToPreviousTopic();
        }
      },
    },
  },
};
</script>

<style>
</style>
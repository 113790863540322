<template>
  <div class="task">
    <!-- Task card -->
    <div class="task-card">
      <div class="left">
        <a-icon v-if="completed" class="complete" type="check-circle" />
        <a-icon
          v-if="notApplicable"
          class="not-applicable"
          type="minus-circle"
        />
        <a-icon
          v-if="incomplete"
          class="incomplete"
          type="exclamation-circle"
        />
      </div>
      <div class="center">
        <h3 class="title">
          {{ task.displayName }}
        </h3>
        <div class="description">
          {{ description }}
          <!-- {{ task }} -->
        </div>
        <div class="assigned-to" v-if="incomplete">
          <div v-if="isLoadingGroupMembers || isLoadingMembership">
            <a-spin size="small"></a-spin>
          </div>
          <div v-else>
            <!-- <a-badge
              v-for="member in taskMembersToShow"
              :key="member.id"
              :number-style="{
                color: 'rgba(0, 0, 0, 0.65)',
                background: '#f5c2cb',
              }"
              :count="member.displayName"
            /> -->
            <a-popconfirm
              title="Assign task?"
              ok-text="Yes"
              cancel-text="No"
              v-for="member in taskMembersToShow"
              :key="member.id"
              @confirm="() => assignTask(member)"
            >
              <presence-badge
                :presence-id="member.id"
                :owner-id="member.ownerId"
                :name="member.displayName"
              ></presence-badge>
              <!-- <a-badge 
                :number-style="{
                  color: 'rgba(0, 0, 0, 0.65)',
                  background: '#fff',
                  border: '1px solid #5155ea',
                  cursor: 'pointer'
                }"
                :count="member.displayName"
              >
              </a-badge> -->
            </a-popconfirm>
            <!-- <a-badge
              v-for="member in taskMembersToShow"
              :key="member.id"
              :number-style="{
                color: 'rgba(0, 0, 0, 0.65)',
                background: '#f5c2cb',
              }"
              :count="member.displayName"
            /> -->
            <a-badge
              v-if="howManyTaskMembersNotShown"
              :number-style="{
                color: 'rgba(0, 0, 0, 0.65)',
                background: '#f5c2cb',
              }"
              :count="howManyTaskMembersNotShownText"
            ></a-badge>

            <a-button
              type="link"
              icon="plus"
              class="assign-task-button"
              @click.prevent="openAssignTaskModal"
              >Assign task</a-button
            >
          </div>
        </div>
      </div>
      <div class="right" v-if="notApplicable">
        <a-badge>Not applicable</a-badge>
      </div>
      <div class="right" v-if="incomplete">
        <a-button
          @click.prevent="markAsComplete"
          type="primary"
          class="mark-as-complete-button"
        >
          Mark as complete
        </a-button>

        <a-button @click.prevent="markAsNotApplicable">
          Not applicable
        </a-button>
      </div>
    </div>
    <!-- / Task card -->

    <!-- Assign task modal -->
    <a-modal
      v-model="assignTaskModalVisible"
      title="Assign Task"
      :footer="null"
    >
      <div class="members" v-if="!isAssigning">
        <div
          @click.prevent="() => assignTask(member)"
          class="member-to-assign"
          v-for="member in membership"
          :key="member.id"
        >
          {{ formatMemberName(member.displayName, member.id) }}
          <!-- {{ member.displayName }} -->
        </div>
      </div>
      <div v-if="isAssigning" class="loader">
        <a-spin></a-spin>
      </div>
    </a-modal>
    <!-- / Assign task modal -->
  </div>
</template>

<script>
import assignments from "../../../../../api/assignments";
import { mapActions, mapGetters } from "vuex";
const _ = require("lodash");
import PresenceBadge from "../../../../../components/Presences/PresenceBadge.vue";

export default {
  props: ["tenantId", "incidentId", "task", "membership", "myPresenceId"],

  components: { PresenceBadge },

  data() {
    return {
      isLoadingMembership: false,
      taskMembership: [],

      isLoadingGroupMembers: false,
      taskGroupMembers: [],

      assignTaskModalVisible: false,
      isAssigning: false,

      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    taskMembersToShow() {
      if (this.howManyTaskMembersNotShown > 0) {
        return _.cloneDeep(this.taskMembership).splice(0, 5);
      }
      return this.taskMembership;
    },

    howManyTaskMembersNotShown() {
      if (this.taskMembership && this.taskMembership.length) {
        if (this.taskMembership.length > 5) {
          return this.taskMembership.length - 5;
        }
      }
      return 0;
    },

    howManyTaskMembersNotShownText() {
      return "+ " + this.howManyTaskMembersNotShown + " more";
    },

    incomplete() {
      return (
        !this.task['content']['$v']['status']
        ||
        this.task["content"]["$v"]["status"] ==
        assignments.getAssignmentNoneStatusEnumValue()
      );
    },

    notApplicable() {
      return (
        this.task["content"]["$v"]["status"] ==
        assignments.getAssignmentNotApplicableStatusEnumValue()
      );
    },

    completed() {
      return (
        this.task["content"]["$v"]["status"] ==
        assignments.getAssignmentCompletedStatusEnumValue()
      );
    },

    description() {
      return this.task["content"]["$v"]["description"];
    },
  },

  created() {
    this.getMembership();
    this.getGroupMembers();
  },

  methods: {
    ...mapActions("tasks", {
      loadTasks: "loadTasks",
    }),

    formatMemberName(memberName, presenceId) {
      if (_.map(this.presences, 'id').includes(presenceId)) {
        return 'You (' + memberName + ')';
      }
      return memberName;
    },

    getMembership() {
      let vm = this;
      vm.isLoadingMembership = true;
      assignments
        .getMembershipForAssignment(this.tenantId, this.task.id)
        .then((r) => {
          vm.isLoadingMembership = false;
          vm.taskMembership = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingMembership = false;
          // vm.$message.error("Error loading taskmembership");
        });
    },

    getGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;
      assignments
        .getGroupMembersForAssignment(this.tenantId, this.task.id)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.taskGroupMembers = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingMembership = false;
          // vm.$message.error("Error loading membership");
        });
    },

    openAssignTaskModal() {
      this.assignTaskModalVisible = true;
    },

    closeAssignTaskModal() {
      this.assignTaskModalVisible = false;
    },

    assignTask(member) {
      let vm = this;
      vm.isAssigning = true;
      assignments
        .setAssignmentStatus(
          this.tenantId,
          this.task.id,
          member.id,
          assignments.getAssignmentNoneStatusEnumValue()
        )
        .then(() => {
          vm.isAssigning = false;
          vm.$message.success("Task assigned successfully");
          vm.getMembership();
          vm.getGroupMembers();
          vm.closeAssignTaskModal();
          vm.loadTasks();
        })
        .catch((e) => {
          console.log(e);
          vm.isAssigning = false;
          vm.$message.error("Error assigning task");
        });
    },

    markAsComplete() {
      let vm = this;
      vm.isUpdating = true;
      assignments
        .setAssignmentStatus(
          this.tenantId,
          this.task.id,
          this.myPresenceId,
          assignments.getAssignmentCompletedStatusEnumValue()
        )
        .then(() => {
          vm.isUpdating = false;
          vm.$message.success("Task marked as complete successfully");
          vm.task["content"]["$v"]["status"] =
            assignments.getAssignmentCompletedStatusEnumValue();
        })
        .catch((e) => {
          console.log(e);
          vm.isUpdating = false;
          vm.$message.error("Error marking as complete");
        });
    },

    markAsNotApplicable() {
      let vm = this;
      vm.isUpdating = true;
      assignments
        .setAssignmentStatus(
          this.tenantId,
          this.task.id,
          this.myPresenceId,
          assignments.getAssignmentNotApplicableStatusEnumValue()
        )
        .then(() => {
          vm.isUpdating = false;
          vm.$message.success("Task marked as not applicable successfully");
          vm.task["content"]["$v"]["status"] =
            assignments.getAssignmentNotApplicableStatusEnumValue();
        })
        .catch((e) => {
          console.log(e);
          vm.isUpdating = false;
          vm.$message.error("Error marking as not applicable");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.task-card {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;
  margin-bottom: 15px;

  &.expanded {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .description {
    font-weight: 400;
    margin-top: 10px;
  }

  .assigned-to {
    font-weight: 400;
    margin-top: 14px;

    .ant-badge {
      margin-right: 5px;
    }
  }

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 50px;

    .anticon {
      font-size: 25px;
      color: #5155ea;

      &.complete {
        color: #00ff00;
      }

      &.incomplete {
        // color: red;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center {
    flex-grow: 1;
    padding-left: 15px;
  }
  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    padding-left: 15px;

    .mark-as-complete-button {
      margin-right: 7px;
    }
  }
}

.assign-task-button {
  padding-left: 6px;
}

.member-to-assign {
  padding: 15px 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #eee;

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0 !important;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}

.loader {
  text-align: center;
  margin-top: 20px;
}

.presence-badge {
  cursor: pointer;
  margin-right: 8px;
}
</style>
import externalLinks from "../../api/guidance/external-links";
import organisationHelpers from "../../helpers/organisations";
const _ = require("lodash");

export const state = {
    isLoading: false,
    externalLinks: []
}

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_EXTERNAL_LINKS(state, externalLinks) {
        state.externalLinks = externalLinks
    },

    UPDATE_EXTERNAL_LINK_LOCALLY(state, updatedExternalLink) {
        state.externalLinks = _.map(state.externalLinks, externalLink => {
            if (updatedExternalLink['$v']['id'] == externalLink['$v']['id']) {
                return updatedExternalLink;
            } else {
                return externalLink;
            }
        });
    }
}

export const getters = {
    isLoading: (state) => (state.isLoading),
    externalLinks: (state) => (state.externalLinks),
    externalLinksToShow: (state, getters, rootState, rootGetters) => {
        let ownerIdFilter = rootGetters['adminGuidance/ownerIdFilter'];
        if (ownerIdFilter) {
            return _.filter(state.externalLinks, externalLink => {
                return externalLink['$v']['ownerId'] == ownerIdFilter
            });
        }
        return state.externalLinks;
    }
}

export const actions = {
    reset({commit}) {
        commit('SET_EXTERNAL_LINKS', []);
    },

    startLoading({commit}) {
        commit('START_LOADING');
    },

    stopLoading({commit}) {
        commit('STOP_LOADING');
    },

    loadExternalLinks({commit, rootGetters}) {
        commit('START_LOADING');

        let org = rootGetters['admin/selectedOrganisation'];
        if (!org) {
            commit('SET_EXTERNAL_LINKS', []);
            return false;
        }
        let tenantId = organisationHelpers.getOrganisationTenantId(org);

        externalLinks.getExternalLinks(tenantId, org.id).then(r => {
            commit('STOP_LOADING');
            commit('SET_EXTERNAL_LINKS', r.data);
        }).catch(e => {
            console.log(e);
            this._vm.$message.error('Error loading external links');
        })
    },

    updateExternalLinkLocally({ commit }, externalLink) {
        commit('UPDATE_EXTERNAL_LINK_LOCALLY', externalLink);
    }
}